import React, { useMemo, useCallback } from 'react';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';
import { useForm, Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import UsersApi from '@dex/bubl-dash/src/lib/views/Users/UsersApi';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldUpload from '@dex/bubl-dash/src/lib/components/Fields/FieldUpload';

const UsersSingleSideEditPanel: React.FC<any> = (props: any) => {

    const { data } = props;

    const app = useApp();

    const form = useForm(data);

    const process = UsersApi.update(data.id, "profileCover", {
        onComplete: (data) => {

            app.alert('User Saved', 'success');

        }
    });

    const handleSubmit = useCallback((values) => {

        process.run({ data: values });

    }, [props]);

    return useMemo(() => (

        <Panel
            heading={"Cover Photo"}
        >

            <Form
                form={form}
                loading={process.loading}
                onSubmit={handleSubmit}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24 }}>

                        <FieldUpload
                            form={form}
                            folder={'user-media'}
                            label={"Cover Photo"}
                            name={`profileCover`}
                            required={false}
                            defaultValue={form.defaults.profileCover}
                            limit={1}
                        />

                    </Col>

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={process.loading}
                        disabled={form.uploading.length}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props, form, process]);

}

export default UsersSingleSideEditPanel;
