import PickupOperatorIndex from './PickupOperatorIndex';
import PickupOperatorCreate from './PickupOperatorCreate';
import PickupOperatorSettings from './PickupOperatorSettings';
import PickupOperatorSingle from './PickupOperatorSingle';

export default function (app) {

    const settings = PickupOperatorSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    // app.addMenu({
    //     key: settings.key,
    //     zone: settings.zone,
    //     label: settings.title,
    //     priority: 9,
    //     items: [
    //         {
    //             label: "All Operators",
    //             path: settings.path,
    //             caps: settings.indexCaps
    //         },
    //         // {
    //         //     label: "Draft",
    //         //     path: settings.path + "/status/draft",
    //         //     caps: settings.indexCaps
    //         // },
    //         // {
    //         //     label: "Preview",
    //         //     path: settings.path + "/status/preview",
    //         //     caps: settings.indexCaps
    //         // },
    //         // {
    //         //     label: "Publish",
    //         //     path: settings.path + "/status/publish",
    //         //     caps: settings.indexCaps
    //         // },
    //         // {
    //         //     label: "Archive",
    //         //     path: settings.path + "/status/archive",
    //         //     caps: settings.indexCaps
    //         // },
    //         {
    //             label: "Add New",
    //             path: settings.path + "/create",
    //             caps: settings.createCaps
    //         }
    //     ],
    // });

    PickupOperatorIndex(app, settings);

    PickupOperatorCreate(app, settings);

    PickupOperatorSingle(app, settings);

}