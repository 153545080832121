import React, { useMemo, useCallback, useState } from 'react';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form, useForm } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { Col, FieldDate, FieldList, FieldRelationship, FieldRepeater, FieldSelect, FieldText, Row, useApp } from '@dex/bubl-dash';
import LeagueSeasonGameApi from '../LeagueSeasonGameApi';
import Modal from '@dex/bubl-dash/src/lib/components/Modal/Modal';
import { FieldHidden } from '@dex/bubl-dash';
import LeagueSeasonGameBoxEventsImport from './LeagueSeasonGameBoxEventsImport';

const LeagueSeasonGameEventsForm: React.FC<any> = (props: any) => {

    const { data, events, fields } = props;

    const [showImport, setShowImport] = useState(false);

    const app = useApp();

    const form = useForm({ ...data, events: events });

    const process = LeagueSeasonGameApi.update(data.id, "updateEvents", {
        onComplete: (data) => {

            app.alert('Events Saved', 'success');

        }
    });

    const handleSubmit = useCallback((values) => {

        process.run({ data: values });

    }, [props]);

    const teams = useMemo(() => {

        if (!fields) return [];

        const teams = fields.teams.map(team => {

            return { value: team.id, label: team.name };

        });

        return teams;

    }, [fields]);

    const players = useMemo(() => {

        if (!fields) return [];

        const players: any = {};

        fields.teams.map(team => {

            players[team.id] = team.roster.map(player => {

                return { value: player.id, label: [player.jerseyNumber, player.name].join(" | ") };

            });

        });

        return players;

    }, [fields]);

    return useMemo(() => (

        <Panel
            heading={"League Game Events"}
            actions={(
                <ButtonRow>
                    <Button
                        label={"Import Events"}
                        size="small"
                        type={"light"}
                        onClick={setShowImport.bind(null, true)}
                    />
                </ButtonRow>
            )}
        >

            {showImport &&
                <Modal
                    show={true}
                    onClose={setShowImport.bind(null, false)}
                    width={1180}
                    className={'modal-top'}
                >

                    <LeagueSeasonGameBoxEventsImport
                        data={data}
                        teams={teams}
                        players={players}
                        boxForm={form}
                        fields={fields}
                        handleClose={setShowImport.bind(null, false)}
                    />

                </Modal>
            }

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <FieldList
                    form={form}
                    name={'events'}
                    defaultValues={{}}
                    addButtonLabel={"Add Event"}
                >

                    {Array.isArray(form.values.events) && form.values.events.map((row, n) => (

                        <React.Fragment key={form.key + "-" + n}>

                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 3 }}>

                                    <FieldSelect
                                        form={form}
                                        label={!n ? 'Team' : ''}
                                        name={`events[${n}].teamId`}
                                        defaultValue={row.teamId}
                                        options={teams}
                                    />

                                </Col>

                                <Col col={{ xs: 3 }}>

                                    <FieldSelect
                                        key={row.teamId}
                                        disabled={!row.teamId}
                                        form={form}
                                        label={!n ? 'Player' : ''}
                                        name={`events[${n}].playerId`}
                                        defaultValue={row.playerId}
                                        options={players[row.teamId]}
                                    />

                                </Col>

                                <Col col={{ xs: 3 }}>

                                    <FieldText
                                        form={form}
                                        label={!n ? 'Position' : ''}
                                        name={`events[${n}].position`}
                                        defaultValue={row.position}
                                        placeholder={"00:00:00"}
                                    />

                                </Col>

                                <Col col={{ xs: 3 }}>

                                    <FieldText
                                        form={form}
                                        label={!n ? 'Duration' : ''}
                                        name={`events[${n}].duration`}
                                        defaultValue={row.duration}
                                        placeholder={"00:00:00"}
                                    />

                                </Col>

                                <Col col={{ xs: 3 }}>

                                    <FieldText
                                        form={form}
                                        label={!n ? 'Event' : ''}
                                        name={`events[${n}].event`}
                                        defaultValue={row.event}
                                    />

                                </Col>

                                <Col col={{ xs: 3 }}>

                                    <FieldText
                                        form={form}
                                        label={!n ? 'Quarter' : ''}
                                        name={`events[${n}].quarter`}
                                        defaultValue={row.quarter}
                                    />

                                </Col>


                                <Col col={{ xs: 3 }}>

                                    <FieldText
                                        form={form}
                                        label={!n ? 'Field Position' : ''}
                                        name={`events[${n}].fieldPosition`}
                                        defaultValue={row.fieldPosition}
                                    />

                                </Col>

                                <Col col={{ xs: 3 }}>

                                    <FieldText
                                        form={form}
                                        label={!n ? 'Coordinates' : ''}
                                        name={`events[${n}].coordinates`}
                                        defaultValue={row.coordinates}
                                    />

                                </Col>


                            </Row>

                        </React.Fragment>

                    ))}

                </FieldList>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel >

    ), [form, showImport, process.loading]);

}

export default LeagueSeasonGameEventsForm;
