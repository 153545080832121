import React from 'react';
import { titleCase, get } from "@dex/bubl-helpers";
import PickupSessionApi from './PickupSessionApi';
import PickupSessionActions from './Components/PickupSessionActions';
import ModelLink from '@dex/bubl-dash/src/lib/components/ModelLink/ModelLink';

let settings;

export default settings = {
    key: 'pickup/sessions',
    zone: 'pickup',
    path: '/pickup/sessions',
    title: "Pickup Sessions",
    endpoint: PickupSessionApi.endpoint,
    modelName: 'PickupSession',
    idKey: 'id',
    primaryKey: 'id',
    indexCaps: ["viewPickupSession"],
    viewCaps: ["viewPickupSession"],
    createCaps: ["createPickupSession"],
    indexTitle: (routeName, params, user) => {

        let title: string = settings.title;

        if (params && params.name) title = title + " / " + titleCase(params.name);

        return title;

    },
    indexTabs: (routeName, params, user) => {

        return [
            {
                label: "All",
                path: settings.path
            },
            {
                label: "Draft",
                path: settings.path + "/status/draft",
            },
            {
                label: "Preview",
                path: settings.path + "/status/preview",
            },
            {
                label: "Publish",
                path: settings.path + "/status/publish",
            },
            {
                label: "Archive",
                path: settings.path + "/status/archive",
            },
            {
                label: "Add New",
                path: settings.path + "/create",
                caps: settings.createCaps
            },
        ]

    },
    indexButtons: (routeName, params, user) => {

        return [
            {
                label: "Add New",
                path: settings.path + '/create',
                caps: settings.createCaps
            }
        ]

    },
    indexTabCounts: (routeName, params, dontation) => {

        return settings.endpoint + "/getCounts";

    },
    indexFetch: PickupSessionApi.get,
    indexFetchParams: (routeName, params, user) => {

        const filters: any = { where: {}, include: ['operator', 'sessionGroup', 'host'], order: ['created.date DESC'], limit: 10, skip: 0 };

        if (params && params.status) filters.where.status = { inq: [params.status] };

        return filters;

    },
    indexQuickSearch: (routeName, params, user) => {

        const fields = ['venue'];

        return fields;

    },
    indexSearchFields: (routeName, params, user) => {

        const fields = [
            {
                label: "Date & Time",
                key: "dateTime",
                type: "date",
                zone: true,
            },
            {
                label: "Operator",
                key: "operatorId",
                type: "relationship",
                model: "PickupOperator",
            },
            {
                label: "Host",
                key: "hostId",
                type: "relationship",
                model: "Hosts",
            },
            {
                label: "Session Group",
                key: "sessionGroupId",
                type: "relationship",
                model: "PickupSessionGroup",
            },
            {
                label: "Status",
                key: "status",
                type: "choice",
                options: [
                    { value: "draft", label: "Draft" },
                    { value: "preview", label: "Preview" },
                    { value: "publish", label: "Publish" },
                    { value: "archive", label: "Archive" },
                ],
            },
            {
                label: "Attendees",
                key: "attendeeCount",
                type: "number",
            }
        ];

        return fields;

    },
    indexColumns: (routeName, params, user) => {

        const columns = [
            {
                label: "Date & Time",
                key: "dateTime",
                sort: true,
                render: (value, data, app) => {
                    data.dateTimeStr = app.formatValue('datetime', {}, value);
                    return <ModelLink data={data} primaryKey={"dateTimeStr"} />;
                }
            },
            {
                label: "Status",
                key: "status",
                format: "titleCase"
            },
            {
                label: "Host",
                key: "host",
                format: "ModelLink",
            },
            {
                label: "Operator",
                key: "operator",
                format: "ModelLink",
            },
            {
                label: "Session Group",
                key: "sessionGroup",
                format: "ModelLink",
            },
            {
                label: "Duration",
                key: "duration"
            },
            {
                label: "Venue",
                key: "venue"
            },
            {
                label: "Attendees",
                key: "attendeeCount",
                format: "number"
            },
            {
                label: "Actions",
                key: "actions",
                alignEnd: true,
                render: (value, data) => {

                    return <PickupSessionActions data={data} />;

                }
            }
        ];

        return columns;

    },

    indexExportFormats: (routeName, params, user) => {

        const formats = [
            { value: "pickupSessions", label: "Pickup Sessions" }
        ];

        return formats;

    },

    singleFetch: PickupSessionApi.getOne,
    singleFetchParams: (routeName, params, user, fetch) => {

        const filters: any = { include: ['sessionGroup'] };

        if (routeName === settings.key + "View") filters.include = ['operator', 'sessionGroup', 'host'];

        return filters;

    },
    singleTitle: (routeName, params, user, fetch, app) => {

        let title: string = "Loading...";

        const complete = get(fetch, 'complete');
        const error = get(fetch, 'error');
        const data = get(fetch, 'data');

        if (error) title = "Error";
        else if (data) title = app.formatValue('datetime', {}, data.dateTime) + ' - ' + get(data, 'sessionGroup.title');
        else if (complete && !data) title = "Not Found";

        return title;

    },
    singleTabs: (routeName, params, user, fetch) => {

        const path = settings.path + "/" + params.id;

        return [
            {
                label: "View",
                path: path + "/view"
            },
            {
                label: "Edit",
                path: path + "/edit"
            },
        ]

    },
};