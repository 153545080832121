import React, { useMemo } from 'react';
import UsersInfoPanel from './Components/UsersInfoPanel';
import { Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import UsersHostPanel from './Components/UsersHostPanel';

const UsersSingleViewMain: React.FC<any> = (props: any) => {

    return useMemo(() => (

        <>

            {props.data.hostId && props.data.roles.includes('host') &&
                <>

                    <Space />

                    <UsersHostPanel {...props} />

                </>
            }

            <Space />

            <UsersInfoPanel {...props} />


        </>

    ), [props]);

}

export default UsersSingleViewMain;
