import WatchAdsIndex from './WatchAdsIndex';
import WatchAdsCreate from './WatchAdsCreate';
import WatchAdsSettings from './WatchAdsSettings';
import WatchAdsSingle from './WatchAdsSingle';

export default function (app) {

    const settings = WatchAdsSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });


    WatchAdsIndex(app, settings);

    WatchAdsCreate(app, settings);

    WatchAdsSingle(app, settings);

}