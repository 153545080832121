import FormEntryIndex from './FormEntryIndex';
import FormEntryCreate from './FormEntryCreate';
import FormEntrySettings from './FormEntrySettings';
import FormEntrySingle from './FormEntrySingle';

export default function (app) {

    const settings = FormEntrySettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    // app.addMenu({
    //     key: settings.key,
    //     zone: settings.zone,
    //     label: settings.title,
    //     priority: 14,
    //     items: [
    //         {
    //             label: "All",
    //             path: settings.path,
    //             caps: settings.indexCaps
    //         },
    //         // {
    //         //     label: "Add New",
    //         //     path: settings.path + "/create",
    //         //     caps: settings.indexCaps
    //         // }
    //     ],
    // });

    FormEntryIndex(app, settings);

    FormEntryCreate(app, settings);

    FormEntrySingle(app, settings);

}