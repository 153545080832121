import React, { useMemo, useCallback, useState } from 'react';
import { Row, Col, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ActivityPanel from '@dex/bubl-dash/src/lib/components/SinglePanels/ActivityPanel';
import Button from '@dex/bubl-dash/src/lib/components/Button/Button';
import PushNotificationApi from '../PushNotificationApi';
import PushNotificationSelectedUsers from '../Components/PushNotificationSelectedUsers/PushNotificationSelectedUsers';

const PushNotificationSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    const app = useApp();

    const [nav] = app.store("nav");

    const [sending, setSending] = useState(false);

    const message = PushNotificationApi.sendMessage(data.id);

    const sendMessage = useCallback(() => {

        setSending(true)

        message.run({
            onComplete: (response) => {

                setSending(false);
                app.navigate(nav, 'update', {});

            }
        });

    }, [message]);

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Push Notification Details"}
                    actions={(
                        <>

                            {data.status !== "sent" &&
                                <Button
                                    label="Send Message"
                                    size="small"
                                    type="primary"
                                    onClick={sendMessage}
                                />
                            }

                        </>

                    )}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Title",
                                key: "title",
                            },
                            {
                                label: "Message",
                                key: "message",
                            },
                            {
                                label: "Status",
                                key: "status",
                                format: 'titleCase'
                            }
                        ]}
                        columnB={[
                            {
                                label: "Topic",
                                key: "topic",
                                format: 'titleCase'
                            },
                            {
                                label: "To",
                                key: "to",
                                format: 'titleCase'
                            }
                        ]}
                        columnC={[
                            {
                                label: "Created On",
                                key: "created",
                                format: "onAndBy",
                            }
                        ]}
                    />

                </Panel>

                <Space />

                {data.users.length > 0 &&
                    <PushNotificationSelectedUsers data={data} />
                }

            </Col>

            <Col className={'side'}>

                <ActivityPanel parent={data} />

            </Col>

        </Row>

    ), [data]);

}

export default PushNotificationSingleView;
