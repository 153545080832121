import { apiGet, baseApi, options, response } from "@dex/bubl-fetch";

const endpoint = 'VenueOperator';

const VenueOperatorApi: any = baseApi(endpoint);

VenueOperatorApi.sessionList = (id: string, options: options = {}): response => {

    return apiGet({
        url: endpoint + "/public/" + id + '/sessionList',
        params: {},
        ...options
    });

};

export default VenueOperatorApi;