import React, { useMemo } from 'react';
import { Row, Col, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ActivityPanel from '@dex/bubl-dash/src/lib/components/SinglePanels/ActivityPanel';
import HostRequestsSingleResolved from './HostRequestsSingleResolved';
import HostRequestsSinglePending from './HostRequestsSinglePending';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';

const HostRequestsSingleView: React.FC<any> = (props: any) => {

    const app = useApp();

    const manageGlobal = app.userCan(['manageGlobal']);

    const { data } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Request Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Subject",
                                key: "subject"
                            },
                            {
                                label: "Message",
                                key: "message"
                            }
                        ]}
                        columnB={[
                            {
                                label: "Host",
                                key: "host",
                                format: "ModelLink",
                            },
                            {
                                label: "Host Status",
                                key: "host.status",
                                format: "titleCase",
                            }
                        ]}
                        columnC={[
                            {
                                label: "Property Type",
                                key: "property.modelName",
                                format: "titleCase"
                            },
                            {
                                label: "Property",
                                key: "property",
                                format: "ModelLink",
                            },
                            {
                                label: "Property Status",
                                key: "property.status",
                                format: "titleCase",
                            },
                        ]}
                    />

                </Panel>

                <>
                    <Space />

                    {data.status === "pending" && manageGlobal &&
                        <HostRequestsSinglePending data={data} />
                    }

                    {data.status === "resolved" &&
                        <HostRequestsSingleResolved data={data} />
                    }
                </>

            </Col>

            <Col className={'side'}>

                <ActivityPanel parent={data} />

            </Col>

        </Row>

    ), [data]);

}

export default HostRequestsSingleView;
