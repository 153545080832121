import React, { useCallback, useMemo, useState } from 'react';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldSwitch from '@dex/bubl-dash/src/lib/components/Fields/FieldSwitch';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldTextArea from '@dex/bubl-dash/src/lib/components/Fields/FieldTextArea';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Modal from '@dex/bubl-dash/src/lib/components/Modal/Modal';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';
import { Form, useForm } from '@dex/bubl-dash/src/lib/functions/useForm';
import VenueLiveStreamApi from '../VenueLiveStreamApi';

const VenueLiveStreamCancelButton: React.FC<any> = (props: any) => {

    const { session } = props;

    const [showModal, setShowModal] = useState(false);

    const app = useApp();

    const [nav] = app.store("nav");

    const title = session.title

    const form = useForm({ subject: title + " Session Cancelled." });

    const cancel = VenueLiveStreamApi.update(session.id, "cancelSession", {

        onComplete: (data) => {

            app.alert('Session Cancelled', 'success');

            app.navigate(nav, 'update', {});

        }
    });

    const handleSubmit = useCallback((values) => {

        cancel.run({ data: values });

    }, [props]);

    return useMemo(() => (

        <>

            {session.status !== "cancelled" &&
                <>

                    <Button
                        label="Cancel Session"
                        type="faded"
                        size="small"
                        onClick={setShowModal.bind(null, true)}
                    />

                    <Modal
                        show={showModal}
                        onClose={setShowModal.bind(null, false)}
                        width={720}
                    >

                        <Panel
                            heading={"Cancel Session"}
                        >

                            <Form
                                form={form}
                                onSubmit={handleSubmit}
                                loading={cancel.loading}
                            >

                                <FieldSwitch
                                    form={form}
                                    name={"confirm"}
                                    label="Are you sure you want to cancel this session?"
                                    required={true}
                                    defaultValue={form.defaults.confirm}
                                />

                                {form.values.confirm &&
                                    <>

                                        <FieldText
                                            form={form}
                                            name={"subject"}
                                            label="Announcement Subject"
                                            required={true}
                                            disabled={true}
                                            defaultValue={form.defaults.subject}
                                        />

                                        <FieldTextArea
                                            form={form}
                                            name={"message"}
                                            label="Announcement Message"
                                            required={true}
                                            defaultValue={form.defaults.message}
                                        />

                                    </>
                                }

                                <Line />

                                <ButtonRow>

                                    <Button
                                        label={"Submit"}
                                        loading={cancel.loading}
                                    />

                                </ButtonRow>

                            </Form>

                        </Panel>

                    </Modal>

                </>
            }

        </>

    ), [session, showModal, cancel, form]);

}

export default VenueLiveStreamCancelButton;
