import IndexPage from '@dex/bubl-dash/src/lib/pages/IndexPage';
import HelpTopicCreateEditForm from './Components/HelpTopicCreateEditForm';
import HelpTopicApi from './HelpTopicApi';

export default function (app, settings) {

    app.addRoute({
        name: settings.key + "New",
        zone: settings.zone,
        path: settings.key + "/create",
        caps: settings.createCaps,
        component: IndexPage,
        componentProps: {
            header: {
                getTitle: () => "Add New",
                getButtons: settings.indexButtons,
                getTabs: settings.indexTabs,
                getTabCounts: settings.indexTabCounts,
            },
            create: {
                form: HelpTopicCreateEditForm,
                getCreate: HelpTopicApi.create,
                defaults: {},
                successMessage: "HelpTopic Saved",
                successRouteName: settings.key + "View",
                idKey: settings.idKey
            }
        },
    });

}