import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship';
import FieldSelect from '@dex/bubl-dash/src/lib/components/Fields/FieldSelect';


const PushNotificationCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <Panel
            heading={"Push Notification Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 16 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24, md: 24 }}>

                                <FieldText
                                    form={form}
                                    label={"Title"}
                                    name='title'
                                    required={true}
                                    defaultValue={form.defaults.title}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 24 }}>

                                <FieldText
                                    form={form}
                                    label={"Message"}
                                    name='message'
                                    required={true}
                                    defaultValue={form.defaults.message}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 24 }}>

                                <FieldRadio
                                    form={form}
                                    label={"Send To"}
                                    name='to'
                                    required={true}
                                    defaultValue={form.defaults.to}
                                    options={[
                                        { value: 'all', label: 'All' },
                                        { value: 'selectedUsers', label: 'Selected Users' },
                                    ]}
                                />

                            </Col>

                            {form.values.to === 'selectedUsers' &&

                                <Col col={{ xs: 24, md: 24 }}>

                                    <FieldRelationship
                                        form={form}
                                        label={"Selected Users"}
                                        name='userIds'
                                        isMulti={true}
                                        required={true}
                                        preload={true}
                                        endpoint={'Users'}
                                        defaultValue={form.defaults.userIds}
                                    />

                                </Col>

                            }

                        </Row>

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24, md: 24 }}>

                                <FieldRadio
                                    form={form}
                                    label={"Topic"}
                                    name='topic'
                                    required={true}
                                    defaultValue={form.defaults.topic}
                                    options={[
                                        { value: 'others', label: 'Others' },
                                        { value: 'news', label: 'News' },
                                        { value: 'announcements', label: 'Announcements' },
                                    ]}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 24 }}>

                                {console.log(form)}

                                <FieldSelect
                                    form={form}
                                    label={"Link To"}
                                    name='link[screen]'
                                    required={false}
                                    defaultValue={form.defaults.link.screen}
                                    options={[
                                        { value: "", label: "None" },
                                        { value: "NewsArticle", label: "News Article" },
                                        { value: "League", label: "League" },
                                        { value: "LeagueSeason", label: "League Season" },
                                        { value: "PickupOperator", label: "Pickup Session Group" },
                                        { value: "CampOperator", label: "Camp Session Group" },
                                    ]}
                                />


                            </Col>

                            {form.values.link.screen === 'NewsArticle' &&

                                <Col col={{ xs: 24, md: 24 }}>

                                    <FieldRelationship
                                        form={form}
                                        label={"News Article"}
                                        name='link[id]'
                                        isMulti={false}
                                        required={true}
                                        preload={true}
                                        endpoint={'News'}
                                        defaultValue={form.defaults.link.id}
                                    />

                                </Col>

                            }

                            {form.values.link.screen === 'League' &&

                                <Col col={{ xs: 24, md: 24 }}>

                                    <FieldRelationship
                                        form={form}
                                        label={"League"}
                                        name='link[id]'
                                        isMulti={false}
                                        required={true}
                                        preload={true}
                                        endpoint={'League'}
                                        defaultValue={form.defaults.link.id}
                                    />

                                </Col>

                            }

                            {form.values.link.screen === 'LeagueSeason' &&

                                <Col col={{ xs: 24, md: 24 }}>

                                    <FieldRelationship
                                        form={form}
                                        label={"League Season"}
                                        name='link[id]'
                                        isMulti={false}
                                        required={true}
                                        preload={true}
                                        endpoint={'LeagueSeason'}
                                        defaultValue={form.defaults.link.id}
                                    />

                                </Col>

                            }

                            {form.values.link.screen === 'PickupOperator' &&

                                <Col col={{ xs: 24, md: 24 }}>

                                    <FieldRelationship
                                        form={form}
                                        label={"Pickup Session Group"}
                                        name='link[id]'
                                        isMulti={false}
                                        required={true}
                                        preload={true}
                                        endpoint={'PickupSessionGroup'}
                                        defaultValue={form.defaults.link.id}
                                    />

                                </Col>

                            }

                            {form.values.link.screen === 'CampOperator' &&

                                <Col col={{ xs: 24, md: 24 }}>

                                    <FieldRelationship
                                        form={form}
                                        label={"Camp Session Group"}
                                        name='link[id]'
                                        isMulti={false}
                                        required={true}
                                        preload={true}
                                        endpoint={'CampSessionGroup'}
                                        defaultValue={form.defaults.link.id}
                                    />

                                </Col>

                            }

                        </Row>

                    </Col>

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default PushNotificationCreateEditForm;
