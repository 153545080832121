import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ActivityPanel from '@dex/bubl-dash/src/lib/components/SinglePanels/ActivityPanel';
import HostRequestsGoLive from '../../HostRequests/Components/HostRequestsGoLive';
import WebLink from '../../../components/WebLink/WebLink';

const LeagueSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <WebLink
                path={`/leagues/operator/${data.id}/${data.slug}`}
            />

            {!data.public && (data.status === "preview" || data.status === "publish") &&
                <Col col={{ xs: 24 }}>
                    <HostRequestsGoLive data={data} />
                </Col>
            }

            <Col
                className={'main'}
            >

                <Panel
                    heading={"League Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Title",
                                key: "title",
                                format: "link",
                            },
                            {
                                label: "Description",
                                key: "description",
                            },
                            {
                                label: "Website Link",
                                key: "websiteLink",
                                format: "link",
                            },
                        ]}
                        columnB={[
                            {
                                label: "Status",
                                key: "status",
                                format: "titleCase"
                            },
                            {
                                label: "Featured",
                                key: "isFeatured",
                                format: "switch"
                            },
                            {
                                label: "API Type",
                                key: "sourceType",
                            },
                            {
                                label: "API Source URL",
                                key: "sourceUrl",
                            }
                        ]}
                        columnC={[
                            {
                                label: "Host",
                                key: "host",
                                format: "modelLink"
                            },
                            {
                                label: "Sport",
                                key: "sport",
                                format: "ModelLink",
                            },
                            {
                                label: "Category",
                                key: "category",
                                format: "ModelLink",
                            }
                        ]}
                    />

                </Panel>

            </Col>

            <Col className={'side'}>

                <ActivityPanel parent={data} />

            </Col>

        </Row>

    ), [data]);

}

export default LeagueSingleView;
