import React, { useMemo } from 'react';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';

import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText'
import FieldSelect from '@dex/bubl-dash/src/lib/components/Fields/FieldSelect'
import FieldSwitch from '@dex/bubl-dash/src/lib/components/Fields/FieldSwitch';
import FieldRepeater from '@dex/bubl-dash/src/lib/components/Fields/FieldRepeater';
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';

const FormFieldCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    const app = useApp();

    const manageGlobal = app.userCan(['manageGlobal'], false);

    // eslint-disable-next-line
    let data = props.data || {};

    let userOptions = [
        { value: "None", label: "None" },
        { value: "First Name", label: "First Name" },
        { value: "Last Name", label: "Last Name" },
        { value: "Email", label: "Email" },
        { value: "Phone", label: "Phone" },
        { value: "Location", label: "Location" },
        { value: "Bio", label: "Bio" },
        { value: "Date Of Birth", label: "Date Of Birth" },
        { value: "Gender", label: "Gender" },
        { value: "Custom Field", label: "Custom Field" },
    ]

    return useMemo(() => (

        <Panel
            heading={"Form Field Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldText
                            form={form}
                            label="Title"
                            name="title"
                            required={true}
                            defaultValue={form.defaults.title}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldSelect
                            form={form}
                            label='Type'
                            name="type"
                            required={true}
                            defaultValue={form.defaults.type}
                            options={[
                                { value: "text", label: "Text" },
                                { value: "textArea", label: "Long Text" },
                                { value: "number", label: "Number" },
                                { value: "dropdown", label: "Dropdown" },
                                { value: "bool", label: "True/False" },
                                { value: "date", label: "Date Picker" },
                                { value: "section", label: "Section Seperator" },
                            ]}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        {manageGlobal &&
                            <FieldRelationship
                                form={form}
                                label={"Host"}
                                name={`hostId`}
                                required={true}
                                preload={true}
                                endpoint={'Hosts'}
                                defaultValue={form.defaults.hostId}
                            />
                        }

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldText
                            form={form}
                            label="Label"
                            name="label"
                            required={false}
                            defaultValue={form.defaults.label}
                            placeholder={"Uses title by default"}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldText
                            form={form}
                            label="Placeholder"
                            name="placeholder"
                            required={false}
                            defaultValue={form.defaults.placeholder}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldSwitch
                            form={form}
                            label="Required"
                            name="required"
                            required={false}
                            defaultValue={form.defaults.required}
                        />

                    </Col>

                </Row>

                {manageGlobal &&
                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 12 }}>

                            <FieldSelect
                                form={form}
                                label="Auto Fill From User Bio (Primary Value)"
                                name="autoFillFrom"
                                required={false}
                                defaultValue={form.defaults.autoFillFrom}
                                options={userOptions}
                            />

                        </Col>

                        {form.values.autoFillFrom === "Custom Field" &&
                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldText
                                    form={form}
                                    label="Auto Fill From Custom Key"
                                    name="autoFillFromCustomKey"
                                    required={true}
                                    defaultValue={form.defaults.autoFillFromCustomKey}
                                />

                            </Col>
                        }

                    </Row>
                }

                {manageGlobal &&
                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 12 }}>

                            <FieldSelect
                                form={form}
                                label="Auto Fill From User Bio (Secondary Value)"
                                name="autoFillFromSecondary"
                                required={false}
                                defaultValue={form.defaults.autoFillFromSecondary}
                                options={userOptions}
                            />

                        </Col>

                        {form.values.autoFillFromSecondary === "Custom Field" &&
                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldText
                                    form={form}
                                    label="Auto Fill From Secondary Custom Key"
                                    name="autoFillFromSecondaryCustomKey"
                                    required={true}
                                    defaultValue={form.defaults.autoFillFromSecondaryCustomKey}
                                />

                            </Col>
                        }

                    </Row>
                }

                {manageGlobal &&
                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 12 }}>

                            <FieldSelect
                                form={form}
                                label="Save To User Bio"
                                name="saveTo"
                                required={false}
                                defaultValue={form.defaults.saveTo}
                                options={userOptions}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 12 }}>

                            {form.values.saveTo === "Custom Field" &&

                                <FieldText
                                    form={form}
                                    label="Save To Custom Key"
                                    name="saveToCustomKey"
                                    required={true}
                                    defaultValue={form.defaults.saveToCustomKey}
                                />
                            }

                        </Col>

                    </Row>
                }

                {form.values.type === 'dropdown' &&

                    <>

                        <Seperator
                            heading={"Dropdown Options"}
                            top={"auto"}
                            bottom={"auto"}
                        />

                        <FieldRepeater
                            form={form}
                            name={'options'}
                            defaultValues={""}
                            addButtonLabel={"Add Option"}
                        >

                            {form.values.options.map((row, n) => (

                                <React.Fragment key={form.key + "-" + n}>

                                    <FieldText
                                        form={form}
                                        label={"Option Label"}
                                        name={`options[${n}]`}
                                        required={true}
                                        defaultValue={row}
                                    />

                                </React.Fragment>

                            ))}

                        </FieldRepeater>

                    </>

                }

                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form >

        </Panel >


    ), [props]);

}

export default FormFieldCreateEditForm;
