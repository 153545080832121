import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldHidden from '@dex/bubl-dash/src/lib/components/Fields/FieldHidden';
import FieldNumber from '@dex/bubl-dash/src/lib/components/Fields/FieldNumber';
import { FieldSelect } from '@dex/bubl-dash';
import { loop } from '@dex/bubl-helpers';
import LeagueSeasonApi from '../../LeagueSeason/LeagueSeasonApi';

const LeagueSeasonLeaderboardCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit, mode } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    const fields = LeagueSeasonApi.getSportFields('-');

    useEffect(() => {

        if (!form.values.seasonId) return;

        fields.run({
            url: LeagueSeasonApi.endpoint + "/" + form.values.seasonId + "/getSportFields"
        });

    }, [form.values.seasonId]);

    const statOptions: any = [];

    if (fields.data && fields.data.fields) {

        loop(fields.data.fields.totalStats, (stat) => {
            statOptions.push({ value: stat.key, label: stat.title || stat.label });
        });

    }

    return useMemo(() => (

        <Panel
            heading={"Leaderboard Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                {mode === "attach" && form.defaults.type &&
                    <FieldHidden
                        form={form}
                        name="type"
                        label="Type"
                        defaultValue={form.defaults.type}
                    />
                }
                {mode === "attach" && form.defaults.seasonId &&
                    <FieldHidden
                        form={form}
                        name="seasonId"
                        label="Season"
                        defaultValue={form.defaults.seasonId}
                    />
                }

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldText
                            form={form}
                            label={"Name"}
                            name='name'
                            required={true}
                            defaultValue={form.defaults.name}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldNumber
                            form={form}
                            label={"Minimum Number of Games Played"}
                            name='minGamesPlayed'
                            required={true}
                            min={1}
                            defaultValue={form.defaults.minGamesPlayed}
                        />

                    </Col>

                    {fields.data &&
                        <Col col={{ xs: 24, md: 12 }}>

                            <FieldSelect
                                form={form}
                                label={"Stat"}
                                name='stat'
                                required={true}
                                defaultValue={form.defaults.stat}
                                options={statOptions}
                            />

                        </Col>
                    }

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldRadio
                            form={form}
                            label={"Stat Type"}
                            name='statType'
                            required={true}
                            defaultValue={form.defaults.statType}
                            options={[
                                { value: "total", label: "Total" },
                                { value: "average", label: "Per Game" },
                            ]}
                        />

                    </Col>

                    {(mode !== "attach" && mode === "create") &&

                        <>
                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldRadio
                                    form={form}
                                    label={"Type"}
                                    name='type'
                                    required={true}
                                    defaultValue={form.defaults.type}
                                    options={[
                                        { value: "season", label: "Season" },
                                        // { value: "league", label: "League" },
                                        // { value: "group", label: "Group" }
                                    ]}
                                />

                            </Col>

                            {form.values.type === "season" &&
                                <Col col={{ xs: 24, md: 12 }}>

                                    <FieldRelationship
                                        form={form}
                                        label='Season'
                                        name='seasonId'
                                        required={true}
                                        preload={true}
                                        endpoint={'LeagueSeason'}
                                        defaultValue={form.defaults.seasonId}
                                    />

                                </Col>
                            }

                        </>
                    }


                    {form.values.type === "season" &&
                        <Col col={{ xs: 24 }}>

                            <FieldRelationship
                                form={form}
                                label='Divisions'
                                name='divisionIds'
                                required={false}
                                preload={true}
                                endpoint={'LeagueSeasonDivision'}
                                defaultValue={form.defaults.divisionIds}
                                where={{ seasonId: form.values.seasonId }}
                                isMulti={true}
                                placeholder={"Select division(s) or leave blank to combine all divisions."}
                            />

                        </Col>
                    }

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>

    ), [props, fields.loading]);

}

export default LeagueSeasonLeaderboardCreateEditForm;
