import HelpTopicIndex from './HelpTopicIndex';
import HelpTopicCreate from './HelpTopicCreate';
import HelpTopicSettings from './HelpTopicSettings';
import HelpTopicSingle from './HelpTopicSingle';

export default function (app) {

    const settings = HelpTopicSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    app.addMenu({
        key: settings.key,
        zone: settings.zone,
        label: settings.title,
        priority: 14,
        items: [
            {
                label: "All",
                path: settings.path,
                caps: settings.indexCaps
            },
            {
                label: "Add New",
                path: settings.path + "/create",
                caps: settings.createCaps
            }
        ],
    });

    HelpTopicIndex(app, settings);

    HelpTopicCreate(app, settings);

    HelpTopicSingle(app, settings);

}