import StatsSetIndex from './StatsSetIndex';
import StatsSetCreate from './StatsSetCreate';
import StatsSetSettings from './StatsSetSettings';
import StatsSetSingle from './StatsSetSingle';

export default function (app) {

    const settings = StatsSetSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    app.addMenu({
        key: settings.key,
        zone: settings.zone,
        label: settings.title,
        priority: 14,
        items: [
            {
                label: "All",
                path: settings.path,
                caps: settings.indexCaps
            },
            {
                label: "Add New",
                path: settings.path + "/create",
                caps: settings.createCaps
            }
        ],
    });

    StatsSetIndex(app, settings);

    StatsSetCreate(app, settings);

    StatsSetSingle(app, settings);

}