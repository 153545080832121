import React from 'react';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';

import RenderContentBlocks from '../../../components/RenderContentBlocks/RenderContentBlocks';
import VenueLiveStreamPurchaseVideoButton from './VenueLiveStreamPurchaseVideoButton';

const VenueLiveStreamSessionVideoPanel: React.FC<VenueLiveStreamSessionVideoPanelProps> = (props) => {

    const { stream } = props;

    const VideoBlocks = [{ type: "video", video: stream.video, width: '100%', height: '100%' }]

    return (
        <>

            <Panel
                heading={"Session Video"}
                actions={(
                    stream.hasVideo && stream.court.videoProductId && !stream.videoOrderId &&
                    <VenueLiveStreamPurchaseVideoButton stream={stream} />
                )}
            >

                <MetaTable
                    data={stream}
                    columnSize={{ xs: 24 }}
                    columnA={[
                        {
                            label: 'Video Status',
                            key: 'videoStatus',
                            format: 'titleCase',

                        },
                        {
                            label: "Video Order Id",
                            key: "videoOrder",
                            format: 'ModelLink'
                        }
                    ]}
                />

                {stream.video && stream.video.id && stream.video.source &&

                    <div>

                        <RenderContentBlocks blocks={VideoBlocks} />

                    </div>
                }

            </Panel>

        </>
    )
}

interface VenueLiveStreamSessionVideoPanelProps {
    [key: string]: any
}

export default VenueLiveStreamSessionVideoPanel;