import React, { useMemo, useState } from 'react';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import LeagueSeasonManageTeams from './LeagueSeasonManageTeams';
import LeagueSeasonManagePlayers from './LeagueSeasonManagePlayers';
import LeagueSeasonManageGames from './LeagueSeasonManageGames';
import LeagueSeasonManageDivisions from './LeagueSeasonManageDivisions';
import LeagueSeasonManageLeaderboards from './LeagueSeasonManageLeaderboards';
import LeagueSeasonManageNews from './LeagueSeasonManageNews';

const LeagueSeasonSingleManage: React.FC<any> = (props: any) => {

    const { data } = props;

    const [tab, setTab] = useState("teams");

    return useMemo(() => (

        <Panel
            heading={"Manage Season"}
            activeTab={tab}
            setTab={setTab}
            full={true}
            tabs={[
                { name: "teams", label: "Teams" },
                { name: "players", label: "Players" },
                { name: "games", label: "Games" },
                { name: "divisions", label: "Divisions" },
                { name: "leaderboards", label: "Leaderboards" },
                { name: "news", label: "News" },
            ]}
        >

            {tab === "teams" &&
                <LeagueSeasonManageTeams data={data} />
            }

            {tab === "players" &&
                <LeagueSeasonManagePlayers data={data} />
            }

            {tab === "games" &&
                <LeagueSeasonManageGames data={data} />
            }

            {tab === "divisions" &&
                <LeagueSeasonManageDivisions data={data} />
            }

            {tab === "leaderboards" &&
                <LeagueSeasonManageLeaderboards data={data} />
            }

            {tab === "news" &&
                <LeagueSeasonManageNews data={data} />
            }

        </Panel>

    ), [data, tab]);

}

export default LeagueSeasonSingleManage;
