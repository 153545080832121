import React, { useMemo, useState, useEffect } from 'react';
import { apiGet } from '@dex/bubl-fetch';

import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';

import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio'
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship'
import FieldUpload from '@dex/bubl-dash/src/lib/components/Fields/FieldUpload';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';


const StatsSetCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    const statusProp = (form) => {

        let options: any[] = [];

        if (form.values.type === 'spBasketball') {

            return options = [
                { value: "requested", label: "Requested" },
                { value: "approved", label: "Approved" },
                { value: "rejected", label: "Rejected" }
            ]

        } else if (form.values.type === 'customGroup') {

            return options = [
                { value: "draft", label: "Draft" },
                { value: "publish", label: "Publish" }
            ]

        }

        return options

    }

    let group:any = form.defaults.group || {}

    const [statsGroup, setStatsGroup] = useState({});

    const fetch = apiGet({
        url: "StatsGroup",
    });

    useEffect(() => {

        fetch.run({
            params: {},
            onComplete: (result) => {

                if (result && result.data && result.data.length > 0) {

                    const groups: any = {}

                    result.data.forEach(group => {

                        return groups[group.id] = group

                    })

                    setStatsGroup(groups)

                } else {

                }

            }
        });

    }, [])

    if (form.values.groupId && form.values.type === "customGroup" && statsGroup[form.values.groupId || form.defaults.groupId]) {

        group = statsGroup[form.values.groupId]

    }

    return useMemo(() => (

        <Panel
            heading={"Stats Set Details"}
        >
            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ md: 16, xs: 24 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24 }}>

                                <FieldRadio
                                    form={form}
                                    label={"Type"}
                                    name='type'
                                    required={true}
                                    defaultValue={form.defaults.type}
                                    options={[
                                        { value: "spBasketball", label: "Basketball" },
                                        { value: "customGroup", label: "Custom Group" }
                                    ]}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldRelationship
                                    form={form}
                                    label={"User"}
                                    name='ownerId'
                                    isMulti={false}
                                    required={true}
                                    preload={true}
                                    endpoint={'Users'}
                                    defaultValue={form.defaults.ownerId}
                                />

                            </Col>

                            {form.values.type === 'spBasketball' &&

                                <>

                                    <Col col={{ xs: 24, md: 12 }}></Col>

                                    <Col col={{ xs: 24, md: 12 }}>

                                        <FieldRelationship
                                            form={form}
                                            label={"League"}
                                            name='leagueId'
                                            isMulti={false}
                                            required={true}
                                            preload={true}
                                            endpoint={'League'}
                                            defaultValue={form.defaults.leagueId}
                                        />

                                    </Col>

                                    {form.values.leagueId &&

                                        <Col col={{ xs: 24, md: 12 }}>

                                            <FieldRelationship
                                                form={form}
                                                label={"Season"}
                                                name='seasonId'
                                                isMulti={false}
                                                required={true}
                                                preload={true}
                                                endpoint={'LeagueSeason'}
                                                defaultValue={form.defaults.seasonId}
                                            />

                                        </Col>

                                    }

                                    {form.values.leagueId && form.values.seasonId &&

                                        <Col col={{ xs: 24, md: 12 }}>

                                            <FieldRelationship
                                                form={form}
                                                label={"Team"}
                                                name='teamId'
                                                isMulti={false}
                                                required={true}
                                                preload={true}
                                                endpoint={'LeagueTeam'}
                                                defaultValue={form.defaults.teamId}
                                            />

                                        </Col>

                                    }

                                    {form.values.leagueId && form.values.seasonId && form.values.teamId &&

                                        <Col col={{ xs: 24, md: 12 }}>

                                            <FieldRelationship
                                                form={form}
                                                label={"Player"}
                                                name='playerId'
                                                isMulti={false}
                                                required={true}
                                                preload={true}
                                                endpoint={'LeaguePlayer'}
                                                defaultValue={form.defaults.playerId}
                                            />

                                        </Col>

                                    }

                                </>

                            }

                            {/* if type === custom group */}

                            {form.values.type === 'customGroup' &&

                                <>

                                    <Col col={{ xs: 24, md: 12 }}>

                                        <FieldRelationship
                                            form={form}
                                            label={"Group"}
                                            name='groupId'
                                            isMulti={false}
                                            required={true}
                                            preload={true}
                                            endpoint={'StatsGroup'}
                                            defaultValue={form.defaults.groupId}
                                        />

                                    </Col>

                                    <Col col={{ xs: 24, md: 12 }}>

                                        <FieldText
                                            form={form}
                                            label={"Name"}
                                            name='name'
                                            required={false}
                                            defaultValue={form.defaults.name}
                                        />

                                    </Col>

                                    <Col col={{ xs: 24, md: 12 }}>

                                        <FieldText
                                            form={form}
                                            label={"Position"}
                                            name='position'
                                            required={false}
                                            defaultValue={form.defaults.position}
                                        />

                                    </Col>

                                    {form.values.groupId && group && group.matrix && group.matrix.length > 0 &&

                                        <>

                                            {group.matrix.map((stat, index) => (

                                                <Col col={{ xs: 6, md: 6 }} key={index}>

                                                    <FieldText
                                                        form={form}
                                                        label={stat.label}
                                                        name={`stats[${stat.key}]`}
                                                        required={false}
                                                        defaultValue={form.defaults.stats[stat.key]}
                                                    />

                                                </Col>

                                            ))}

                                        </>

                                    }

                                </>

                            }

                            {/* if type === custom group END */}

                        </Row>

                    </Col>

                    <Col col={{ md: 8, xs: 24 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24 }}>

                                <FieldRadio
                                    form={form}
                                    label={"Status"}
                                    name='status'
                                    required={true}
                                    defaultValue={form.defaults.status}
                                    options={statusProp(form)}
                                />

                            </Col>

                            {form.values.type === 'customGroup' &&

                                <Col col={{ xs: 24 }}>

                                    <FieldUpload
                                        form={form}
                                        folder={'admin-media'}
                                        label={"Thumbnail"}
                                        name={`thumbnail`}
                                        required={false}
                                        defaultValue={form.defaults.thumbnail}
                                        limit={1}
                                    />

                                </Col>

                            }

                        </Row>

                    </Col>

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default StatsSetCreateEditForm;
