import UsersSingleViewMain from './UsersSingleViewMain';
import UsersSingleEditMain from './UsersSingleEditMain';

import UsersSingleEditSide from './UsersSingleEditSide';
import { loop } from '@dex/bubl-helpers';

export default function (app) {

    app.mods.UsersSingleViewMain = UsersSingleViewMain;
    app.mods.UsersSingleEditMain = UsersSingleEditMain;

    app.mods.UsersSingleEditSide = UsersSingleEditSide;

    app.mods.UsersSettings = (settings) => {

        const _singleFetchParams = settings.singleFetchParams;

        settings.singleFetchParams = (routeName, params, user, fetch) => {

            const filters = _singleFetchParams(routeName, params, user, fetch);

            filters.include = [...filters.include];

            return filters;

        }

        const _indexColumns = settings.indexColumns;

        settings.indexColumns = (routeName, params, users, app) => {

            const columns = _indexColumns(routeName, params, users, app);

            loop(columns, (column, index) => {

                if (column.key === "roles") {

                    columns.splice(index + 1, 0, {
                        label: "Host",
                        key: "host",
                        format: "modelLink"
                    })

                }

            });

            return columns;

        };

        const _indexFetchParams = settings.indexFetchParams;

        settings.indexFetchParams = (routeName, params, users, app) => {

            const filters = _indexFetchParams(routeName, params, users, app);

            filters.include.push('host');

            return filters;

        };

        const _indexSearchFields = settings.indexSearchFields;

        settings.indexSearchFields = (routeName, params, users, app) => {

            const fields = _indexSearchFields(routeName, params, users, app);

            fields.push({
                label: "Host",
                key: "hostId",
                type: "relationship",
                model: "Hosts",
            });

            return fields;

        };

        return settings;

    };

}