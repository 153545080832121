import React, { useCallback, useMemo, useState } from 'react';
import { Button, ButtonRow, Col, FieldHidden, FieldNumber, FieldText, FieldWrap, Form, InputTable, InputTableCell, InputTableRow, Line, MetaTable, Modal, ModelLink, Panel, Row, Seperator, Space, useApp, useForm } from '@dex/bubl-dash';
import LeaguePlayerApi from '../../LeaguePlayer/LeaguePlayerApi';
import CSVReader from 'react-csv-reader';
import { get, loop } from '@dex/bubl-helpers';

const LeagueSeasonTeamImportPlayers: React.FC<any> = (props: any) => {

    const { data, onClose } = props;

    const app = useApp();

    const [players, setPlayers] = useState<any>(null);

    const [results, setResults] = useState<any>(null);

    const form = useForm({ teamId: data.id, seasonId: data.seasonId });

    const process = LeaguePlayerApi.create({}, "import");

    const handleSubmit = useCallback((values) => {

        process.run({
            data: values,
            onComplete: (data) => {

                const rows: any = [];

                loop(players, (player, index) => {

                    rows.push({
                        ...player,
                        status: null,
                        error: null,
                        id: null,
                        ...data.results[index],
                    })

                })

                setResults(data.results);
                setPlayers(rows);

                if (data.count.success) app.alert(data.count.success + ' Players Imported', 'success');
                if (data.count.exists) app.alert(data.count.exists + ' Players Already Attached To Team', 'info');
                if (data.count.error) app.alert(data.count.error + ' Erros Importing Players', 'error');

            }
        });

    }, [form.values, players, props]);

    const parseCsv = (data, fileInfo, originalFile) => {

        const rows: any = [];

        loop(data, (row, index) => {

            rows.push({
                status: "Pending",
                name: row["0"],
                icNumber: row["1"],
                nickName: row["2"],
                jerseyNumber: row["3"],
                position: row["4"],
                city: row["5"],
                photo: row["6"],
            });

        });

        setPlayers(rows);

        form.reset({ ...form.values, players: rows });
        form.setKey();

    };

    const columns: any = [
        {
            key: "status",
            label: "Status",
            style: { width: 100 }
        },
        {
            key: "name",
            label: "Name *",
            style: { width: 120 },
            data: "text",
            required: true,
        },
        {
            key: "icNumber",
            label: "IC Number *",
            style: { width: 80 },
            data: "text",
            required: true,
        },
        {
            key: "nickName",
            label: "Nick Name",
            style: { width: 80 },
            data: "text",
        },
        {
            key: "jerseyNumber",
            label: "Jersey #",
            style: { width: 60 },
            data: "number",
        },
        {
            key: "position",
            label: "Position",
            style: { width: 60 },
            data: "text",
        },
        {
            key: "city",
            label: "City",
            style: { width: 80 },
            data: "text",
        },
        {
            key: "photo",
            label: "Photo",
            style: { width: 80 },
            data: "text",
        },
    ];

    return useMemo(() => (

        <Modal
            show={true}
            onClose={onClose}
            width={1180}
        >

            <Panel
                heading={"Import Players"}
            >

                <Form
                    form={form}
                    onSubmit={handleSubmit}
                    loading={process.loading}
                >

                    <FieldHidden
                        form={form}
                        name={"seasonId"}
                        defaultValue={data.seasonId}
                    />

                    <FieldHidden
                        form={form}
                        name={"teamId"}
                        defaultValue={data.id}
                    />

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, lg: 16 }}>
                            <MetaTable
                                data={data}
                                columnA={[
                                    {
                                        label: "Team",
                                        key: "name",
                                    },
                                ]}
                                columnB={[
                                    {
                                        label: "Season",
                                        key: "season.title",
                                    },
                                ]}
                            />
                        </Col>

                        <Col col={{ xs: 24, lg: 8 }}>
                            <FieldWrap
                                label={"Import File"}
                                required={true}
                            >

                                <div>
                                    <Space height={2} />

                                    <CSVReader
                                        onFileLoaded={parseCsv}
                                        parserOptions={{
                                            header: true,
                                            dynamicTyping: false,
                                            skipEmptyLines: true,
                                            transformHeader: (text, index) => index.toString(),
                                        }}
                                        inputName={"csv"}
                                    />

                                </div>

                            </FieldWrap>

                        </Col>

                    </Row>

                    {players && players.length > 0 &&
                        <>

                            <Seperator
                                heading={`${players.length} Players To Import`}
                                bottom={"auto"}
                            />

                            <InputTable
                                columns={columns}
                                fixed={true}
                            >

                                {players.map((player, index) => (

                                    <InputTableRow key={index}>

                                        {columns.map((column, colIndex) => (

                                            <InputTableCell
                                                {...column}
                                                data={undefined}
                                            >

                                                {column.key === "status" &&
                                                    <>
                                                        {player.id &&
                                                            <>Imported: <strong>{player.fullName}</strong></>
                                                        }
                                                        {player.error &&
                                                            <>{player.error}</>
                                                        }
                                                        {player.status &&
                                                            <>{player.status}</>
                                                        }
                                                    </>
                                                }

                                                {column.data === "number" &&
                                                    <FieldNumber
                                                        form={form}
                                                        name={`players[${index}].${column.key}`}
                                                        defaultValue={get(player, column.key, "")}
                                                        required={column.required}
                                                        disabled={player.id}
                                                    />
                                                }

                                                {column.data === "text" &&
                                                    <FieldText
                                                        form={form}
                                                        name={`players[${index}].${column.key}`}
                                                        defaultValue={get(player, column.key, "")}
                                                        required={column.required}
                                                        disabled={player.id}
                                                    />
                                                }

                                            </InputTableCell>

                                        ))}

                                    </InputTableRow>


                                ))}

                            </InputTable>
                        </>
                    }

                    <Line />

                    <ButtonRow>

                        {players && players.length > 0 &&
                            <Button
                                label={"Submit"}
                                onClick={form.triggerSubmit}
                                loading={process.loading}
                            />
                        }

                        <Button
                            label={"Cancel"}
                            type={"faded"}
                            onClick={onClose}
                        />

                    </ButtonRow>

                </Form>

            </Panel>

        </Modal>

    ), [form, data, process.loading, players, results]);

}

export default LeagueSeasonTeamImportPlayers;
