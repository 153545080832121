import React, { useCallback, useMemo, useState } from 'react';
import LeagueTeamSettings from '../../LeagueTeam/LeagueTeamSettings';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import Modal from '@dex/bubl-dash/src/lib/components/Modal/Modal';
import { Space, useApp } from '@dex/bubl-dash';
// import LeagueTeamCreateEditForm from '../../LeagueTeam/Components/LeagueTeamCreateEditForm';
import CreateView from '@dex/bubl-dash/src/lib/components/CreateView/CreateView';
import LeagueTeamCreateEditForm from '../../LeagueTeam/Components/LeagueTeamCreateEditForm';
import LeagueTeamApi from '../../LeagueTeam/LeagueTeamApi';
import ModalPage from '@dex/bubl-dash/src/lib/pages/ModalPage';

const LeagueSeasonAddNewTeam: React.FC<any> = (props: any) => {

    const { data, onClose } = props;

    const app = useApp();

    const user = app.store("currentUser");

    const [createNew, setCreateNew] = useState(false);

    return useMemo(() => (

        <>

            <ModalPage
                show={true}
                onClose={onClose}
                routePath={"league/teams/create"}
                routeParams={{
                    create: {
                        mode: "attach",
                        defaults: {
                            seasonId: data.id
                        },
                        rowProps: {
                            gutter: null
                        },
                        formProps: {
                            mode: "attach",
                            title: "Add New Team",
                            onAttach: (data) => {
                                onClose();
                            },
                        },
                        successRouteName: null,
                        successCallback: (data) => {
                            onClose();
                        },
                    }
                }}
            />

        </>

    ), [data]);

}

export default LeagueSeasonAddNewTeam;
