import React, { useMemo } from 'react';
import CampSessionApi from '../CampSessionApi';
import CampSessionSettings from '../CampSessionSettings';
import DropMenu from '@dex/bubl-dash/src/lib/components/DropMenu/DropMenu';
import Link from '@dex/bubl-dash/src/lib/components/Link/Link';

const CampSessionActions: React.FC<any> = (props: any) => {

    const { data } = props;

    const del = CampSessionApi.delete(data.id, {
        onComplete: (response) => {

            if (data.modelName && data.id) {
                const event = new CustomEvent(data.modelName + data.id, { detail: 'deleted' });
                window.dispatchEvent(event);
            }

        }
    });

    return useMemo(() => {

        return (

            <DropMenu>

                <Link
                    path={`${ CampSessionSettings.path }/${data.id}/view`}
                >
                    View
                </Link>

                <Link
                    path={`${ CampSessionSettings.path }/${data.id}/edit`}
                >
                    Edit
                </Link>

                <Link
                    confirm={ {
                        title: `Delete ${ data[CampSessionSettings.primaryKey] }`,
                        message: "Are your sure you want to perform this action?"
                    } }
                    onClick={del.run}
                >
                    Delete
                </Link>

            </DropMenu>

        )

    }, [data, del]);

}

export default CampSessionActions;
