import React, { useMemo } from 'react';
import { Row, Col, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ActivityPanel from '@dex/bubl-dash/src/lib/components/SinglePanels/ActivityPanel';

const WatchCamerasSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Watch Cameras Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Name",
                                key: "name",
                                format: "link",
                            }
                        ]}
                        columnB={[
                            {
                                label: "Status",
                                key: "status",
                                format: "titleCase",
                            },
                        ]}
                        columnC={[
                            {
                                label: "Online",
                                key: "online",
                                format: "switch",
                            },
                        ]}
                    />

                </Panel>
                <Space />
                <Panel
                    heading={"Metrics"}
                >

                    <MetaTable
                        data={data.metrics}
                        columnA={[
                            {
                                label: "CPU",
                                key: "cpu.severity",
                                format: "titleCase",
                            },

                            {
                                label: "Health",
                                key: "health.severity",
                                format: "titleCase",
                            },
                            {
                                label: "Pano Bandwidth",
                                key: "panoBandwidth.severity",
                                format: "titleCase",
                            },


                            {
                                label: "HD AudioVolume",
                                key: "hdAudioVolume.severity",
                                format: "titleCase",
                            },
                            {
                                label: "HD Bandwidth",
                                key: "hdBandwidth.severity",
                                format: "titleCase",
                            },

                            {
                                label: "Pano AudioVolume",
                                key: "panoAudioVolume.severity",
                                format: "titleCase",
                            },

                        ]}
                        columnB={[
                            {
                                label: "GPU",
                                key: "gpu.severity",
                                format: "titleCase",
                            },
                            {
                                label: "Connection",
                                key: "connection.severity",
                                format: "titleCase",
                            },

                            {
                                label: "Audio Indication",
                                key: "audioIndication.severity",
                                format: "titleCase",
                            },

                            {
                                label: "Hard Drive Available MB",
                                key: "hardDriveAvailableMB.severity",
                                format: "titleCase",
                            },
                            {
                                label: "Dark Court",
                                key: "darkCourt.severity",
                                format: "titleCase",
                            },

                        ]}
                        columnC={[
                            {
                                label: "Camera",
                                key: "camera.severity",
                                format: "titleCase",
                            },
                            {
                                label: "Status",
                                key: "status.severity",
                                format: "titleCase",
                            },
                            {
                                label: "CPU Temperature",
                                key: "cpuTemperature.severity",
                                format: "titleCase",
                            },

                            {
                                label: "Scoreboard Data",
                                key: "scoreboardData.severity",
                                format: "titleCase",
                            },
                            {
                                label: "Scoreboard Connection",
                                key: "scoreboardConnection.severity",
                                format: "titleCase",
                            },

                        ]}
                    />

                </Panel>
            </Col>

            <Col className={'side'}>

                <ActivityPanel parent={data} />

            </Col>

        </Row>

    ), [data]);

}

export default WatchCamerasSingleView;
