import React, { useMemo, useCallback } from 'react';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { Form, useForm } from '@dex/bubl-dash/src/lib/functions/useForm';
import OrdersApi from '../../OrderApi';
import { Col, Row } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import FieldNumber from '@dex/bubl-dash/src/lib/components/Fields/FieldNumber';
import FieldDate from '@dex/bubl-dash/src/lib/components/Fields/FieldDate';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';

const OrderPaymentForm: React.FC<any> = (props: any) => {

    const { data } = props;

    const app = useApp();

    const [nav] = app.store('nav');

    const form = useForm({
        gateway: data.gateway,
        datePaid: data.datePaid,
        referrenceNo: data.referrenceNo,
        paymentNotes: data.paymentNotes,
        discount: data.discount,
        amountPaid: data.amountPaid,
    });

    const process = OrdersApi.update(data.id, 'pay', {
        onComplete: (data) => {

            //refresh view

            //temporary solution
            app.navigate(nav, 'update', {});

        }
    });

    const handleSubmit = useCallback((values) => {

        process.run({
            data: values
        });

    }, [])

    return useMemo(() => (

        <Panel
            heading={"Order Payment"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24 }}>

                        <FieldRadio
                            form={form}
                            label={"Payment Gateway"}
                            name='gateway'
                            required={true}
                            defaultValue={form.defaults.gateway}
                            options={[
                                { value: "cash", label: "Cash" },
                                { value: "bank", label: "Bank Transfer" },
                                { value: "paypal", label: "Paypal" },
                                { value: "billplz", label: "Billplz" },
                            ]}
                        />

                    </Col>

                    {form.values.gateway &&

                        <>

                            <Col col={{ xs: 24 }}>

                                <FieldDate
                                    form={form}
                                    label={"Date Paid"}
                                    name={`datePaid`}
                                    required={true}
                                    defaultValue={form.defaults.datePaid || {}}
                                    date={true}
                                    zone={false}
                                    time={true}
                                />

                            </Col>

                            {(form.values.gateway === "paypal" || form.values.gateway === "billplz" || form.values.gateway === "bank") &&

                                <Col col={{ xs: 24 }}>

                                    <FieldText
                                        form={form}
                                        label={"Gateway Ref #"}
                                        name='referrenceNo'
                                        required={true}
                                        defaultValue={form.defaults.referrenceNo}
                                    />

                                </Col>

                            }

                            <Col col={{ xs: 24 }}>

                                <FieldText
                                    form={form}
                                    label={"Payment Notes"}
                                    name='paymentNotes'
                                    required={true}
                                    defaultValue={form.defaults.paymentNotes}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldNumber
                                    form={form}
                                    label="Discount"
                                    name="discount"
                                    required={false}
                                    defaultValue={form.defaults.discount}
                                />

                            </Col>


                            <Col col={{ xs: 24 }}>

                                <FieldNumber
                                    key={form.values.discount}
                                    form={form}
                                    label="Ammount Paid"
                                    name="amountPaid"
                                    disabled={true}
                                    required={false}
                                    defaultValue={data.total - form.values.discount}
                                />

                            </Col>

                        </>

                    }

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>

    ), [data, form, process]);

}

export default OrderPaymentForm;
