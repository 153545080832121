import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ActivityPanel from '@dex/bubl-dash/src/lib/components/SinglePanels/ActivityPanel';
import WebLink from '../../../components/WebLink/WebLink';

const VenueCourtSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <WebLink
                path={`/venues/court/${data.id}/${data.slug}`}
            />

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Court Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Title",
                                key: "title",
                                format: "link",
                            },
                            {
                                label: "Venue",
                                key: "venue",
                                format: "titleCase"
                            },
                            {
                                label: "Location",
                                key: "address",
                                format: "titleCase"
                            },
                            {
                                label: "Contact No.",
                                key: "contact",

                            },
                            {
                                label: "Email",
                                key: "email",

                            },
                            {
                                label: "Thumbnail",
                                key: "thumbnail.fileId",
                                format: "image",
                                link: true,
                                hideEmpty: true,
                                filters: {
                                    width: 100,
                                    height: 100
                                }
                            },
                        ]}

                        columnB={[
                            {
                                label: "Host",
                                key: "host",
                                format: "ModelLink",
                            },
                            {
                                label: "Operator",
                                key: "operator",
                                format: "ModelLink",
                            },
                            {
                                label: "Sport",
                                key: "sport",
                                format: "ModelLink",
                            },
                            {
                                label: "Category",
                                key: "category",
                                format: "ModelLink",
                            },
                            {
                                label: "Status",
                                key: "status",
                                format: "titleCase"
                            },
                        ]}
                        columnC={[

                            {
                                label: "Featured",
                                key: "isFeatured",
                                format: "switch"
                            },
                            {
                                label: "Priority",
                                key: "priority"
                            },
                        ]}
                    />

                </Panel>

            </Col>

            <Col className={'side'}>

                <ActivityPanel parent={data} />

            </Col>

        </Row>

    ), [data]);

}

export default VenueCourtSingleView;
