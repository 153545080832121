import React, { useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship';
import FieldUpload from '@dex/bubl-dash/src/lib/components/Fields/FieldUpload';
import FieldHidden from '@dex/bubl-dash/src/lib/components/Fields/FieldHidden';
import LeagueTeamValidateForm from './LeagueTeamValidateForm';

const LeagueTeamCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit, mode, onAttach } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    const [validate, setValidate] = useState(mode === "edit" ? false : true);

    return useMemo(() => (

        <Panel
            heading={props.title || "League Team Details"}
        >

            {validate &&
                <>
                    <LeagueTeamValidateForm
                        form={form}
                        mode={mode}
                        onValidated={setValidate.bind(null, false)}
                        onAttach={onAttach}
                    />
                </>
            }

            {!validate &&
                <Form
                    form={form}
                    onSubmit={handleSubmit}
                    loading={process.loading}
                >

                    {mode === "attach" && form.defaults.seasonId &&
                        <FieldHidden
                            form={form}
                            name="seasonId"
                            defaultValue={form.defaults.seasonId}
                        />
                    }

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 16 }}>

                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24 }}>

                                    <FieldText
                                        form={form}
                                        name="name"
                                        label="Name"
                                        required={true}
                                        defaultValue={form.defaults.name}
                                    />

                                </Col>

                                <Col col={{ xs: 24 }}>

                                    <FieldText
                                        form={form}
                                        name="shortName"
                                        label="Short Name"
                                        required={true}
                                        defaultValue={form.defaults.shortName}
                                    />

                                </Col>

                                <Col col={{ xs: 24 }}>

                                    <FieldText
                                        form={form}
                                        name="city"
                                        label="City"
                                        required={false}
                                        defaultValue={form.defaults.city}
                                    />

                                </Col>

                                <Col col={{ xs: 24 }}>

                                    <FieldText
                                        form={form}
                                        name="coach"
                                        label="Coach"
                                        defaultValue={form.defaults.coach}
                                    />

                                </Col>

                            </Row>

                        </Col>

                        <Col col={{ xs: 24, md: 8 }}>

                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24 }}>

                                    <FieldRelationship
                                        form={form}
                                        name="managerId"
                                        label="Manager"
                                        defaultValue={form.defaults.managerId}
                                        endpoint={'Users'}
                                    />

                                </Col>

                                {!form.values.managerId &&
                                    <Col col={{ xs: 24 }}>

                                        <FieldText
                                            form={form}
                                            name="managersEmail"
                                            label="Manager's Email"
                                            defaultValue={form.defaults.managersEmail}
                                            required={true}
                                        />

                                    </Col>
                                }

                                <Col col={{ xs: 24 }}>

                                    <FieldUpload
                                        form={form}
                                        name="logo"
                                        label="Logo"
                                        defaultValue={form.defaults.logo}
                                        folder='admin-media'
                                    />

                                </Col>

                            </Row>

                        </Col>

                    </Row>

                    <Line />

                    <ButtonRow>
                        <Button
                            label={"Submit"}
                            loading={process.loading}
                        />
                        <Button
                            label={"Reset"}
                            type={"faded"}
                            onClick={form.reset}
                        />
                    </ButtonRow>

                </Form>
            }

        </Panel>

    ), [props, validate]);

}

export default LeagueTeamCreateEditForm;
