import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio'
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship'
import FieldUpload from '@dex/bubl-dash/src/lib/components/Fields/FieldUpload';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldNumber from '@dex/bubl-dash/src/lib/components/Fields/FieldNumber';
import { FieldDate } from '@dex/bubl-dash';

const LeagueSeasonCreateEditForm: React.FC<any> = (props: any) => {

    const { form, mode, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <Panel
            heading={"League Season Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ md: 16, xs: 24 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldText
                                    form={form}
                                    label={"Title"}
                                    name='title'
                                    required={true}
                                    defaultValue={form.defaults.title}
                                />
                            </Col>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldRelationship
                                    form={form}
                                    label={"League"}
                                    name='leagueId'
                                    isMulti={false}
                                    required={true}
                                    preload={true}
                                    endpoint={'League'}
                                    defaultValue={form.defaults.leagueId}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldText
                                    form={form}
                                    label={"Short Title"}
                                    name='shortTitle'
                                    placeholder={"Season X - Division"}
                                    required={true}
                                    defaultValue={form.defaults.shortTitle}
                                />
                            </Col>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldNumber
                                    form={form}
                                    label={"Season Number"}
                                    name='seasonNumber'
                                    required={true}
                                    defaultValue={form.defaults.seasonNumber}
                                />
                            </Col>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldRelationship
                                    form={form}
                                    label={"Sport"}
                                    name='sportId'
                                    required={true}
                                    defaultValue={form.defaults.sportId}
                                    preload={true}
                                    endpoint={'Sport'}
                                />

                            </Col>

                        </Row>

                        <Line />

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldDate
                                    form={form}
                                    label={"Season Start Date"}
                                    name='startDate'
                                    required={false}
                                    defaultValue={form.defaults.stateDate}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldDate
                                    form={form}
                                    label={"Registration End Date"}
                                    name='registrationEndDate'
                                    required={false}
                                    defaultValue={form.defaults.registrationEndDate}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldDate
                                    form={form}
                                    label={"Roster Lock Date"}
                                    name='rosterLockDate'
                                    required={false}
                                    defaultValue={form.defaults.rosterLockDate}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldNumber
                                    form={form}
                                    label={"Roster Limit"}
                                    name='rosterLimit'
                                    required={false}
                                    defaultValue={form.defaults.rosterLimit}
                                />

                            </Col>

                        </Row>

                    </Col>

                    <Col col={{ md: 8, xs: 24 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24 }}>

                                <FieldRadio
                                    form={form}
                                    label={"Status"}
                                    name='status'
                                    required={true}
                                    defaultValue={form.defaults.status}
                                    options={[
                                        { value: "draft", label: "Draft" },
                                        { value: "preview", label: "Preview" },
                                        { value: "publish", label: "Publish" },
                                        { value: "archive", label: "Archive" },
                                    ]}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldUpload
                                    form={form}
                                    folder={'admin-media'}
                                    label={"Thumbnail"}
                                    name={`thumbnail`}
                                    required={false}
                                    defaultValue={form.defaults.thumbnail}
                                    limit={1}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldRadio
                                    form={form}
                                    label={"API Type"}
                                    name='sourceType'
                                    required={false}
                                    defaultValue={form.defaults.sourceType}
                                    options={[
                                        { value: "none", label: "None" },
                                        { value: "spBasketball", label: "Sports Press BB" }
                                    ]}
                                />

                            </Col>

                            {form.values.sourceType === "spBasketball" &&
                                <Col col={24}>

                                    <FieldText
                                        form={form}
                                        label={"Season ID"}
                                        name='sourceSesaonId'
                                        required={true}
                                        defaultValue={form.defaults.sourceSesaonId}
                                    />

                                </Col>
                            }

                        </Row>

                    </Col>







                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default LeagueSeasonCreateEditForm;
