import React, { useMemo, useEffect } from 'react';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import HostsApi from '../../Hosts/HostsApi';

const UsersHostPanel: React.FC<any> = (props: any) => {

    const { data } = props;

    const host = HostsApi.getOne(data.hostId);

    useEffect(() => {

        if (data.hostId) host.run();

    }, [])

    return useMemo(() => (

        <Panel
            heading={"User Host"}
        >

            <MetaTable
                data={host.data}
                columnA={[
                    {
                        label: "Host",
                        key: "this",
                        format: "modelLink"
                    },
                ]}
                columnB={[
                    {
                        label: "Status",
                        key: "status",
                        format: "titleCase"
                    },
                ]}
                columnC={[]}
            />

        </Panel>

    ), [data, host]);

}

export default UsersHostPanel;
