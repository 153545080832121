import React, { useMemo, useCallback } from 'react';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { useForm, Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import HostRequestsApi from '../HostRequestsApi';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldTextArea from '@dex/bubl-dash/src/lib/components/Fields/FieldTextArea';

const HostRequestsSinglePending: React.FC<any> = (props: any) => {

    const app = useApp();

    const [nav] = app.store('nav');

    const { data } = props;

    const form = useForm({});

    const process = HostRequestsApi.update(data.id, "verification", {
        onComplete: (data) => {

            app.navigate(nav, 'update', {});

        }
    });

    const handleSubmit = useCallback((values) => {

        process.run({ data: values });

    }, [props]);

    return useMemo(() => (

        <Panel
            heading={"Request Verification"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24 }}>

                        <FieldRadio
                            form={form}
                            label={"Action"}
                            name='action'
                            required={true}
                            defaultValue={form.defaults.status}
                            options={[
                                { value: "goLive", label: "Go Live" },
                                { value: "goLiveFeature", label: "Go Live & Feature" },
                                { value: "none", label: "None" },
                            ]}
                        />

                    </Col>

                    <Col col={{ xs: 24 }}>

                        <FieldTextArea
                            form={form}
                            label={"Message To Host"}
                            name='actionMessage'
                            required={true}
                            defaultValue={form.defaults.actionMessage}
                        />

                    </Col>

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>

    ), [data, form, process]);

}

export default HostRequestsSinglePending;
