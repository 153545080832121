import React, { useCallback } from 'react';
import { Button, ButtonRow, Line, Col, Row, Panel, FieldRadio, FieldUpload, FieldText, useForm, useApp } from '@dex/bubl-dash';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import { get } from '@dex/bubl-helpers';
import LeagueSeasonGameApi from '../LeagueSeasonGameApi';

const LeagueSeasonGameEditRecap: React.FC<LeagueSeasonGameEditRecapProps> = (props) => {

    const { data } = props;

    const app = useApp();

    const form = useForm(data);

    const updateRecap = LeagueSeasonGameApi.update(data.id, "updateRecap", {
        onComplete: (data) => {

            app.alert('Recap Saved', 'success');

        }
    });

    const handleSubmit = useCallback((values) => {

        updateRecap.run({ data: values });

    }, [props]);

    return (
        <Panel
            heading={"Recap"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={updateRecap.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24 }}>

                        <FieldRadio
                            form={form}
                            name="video[source]"
                            label="Video Source"
                            required={true}
                            options={[
                                { label: "Youtube", value: "youtube" },
                                { label: "Facebook", value: "facebook" },
                                { label: "Pixellot", value: "pixellot" }
                            ]}
                            defaultValue={get(form.defaults, 'video.source')}
                        />

                    </Col>

                    <Col col={{ xs: 24 }}>

                        <FieldText
                            form={form}
                            label={"Video Source ID"}
                            name={`video[id]`}
                            required={true}
                            defaultValue={get(form.defaults, 'video.id')}
                        />

                    </Col>

                    <Col col={{ xs: 24 }}>

                        <FieldUpload
                            form={form}
                            folder={'admin-media'}
                            label={"Gallery"}
                            name={`gallery`}
                            required={false}
                            defaultValue={form.defaults.gallery}
                            limit={10}
                        />

                    </Col>

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={updateRecap.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>


            </Form>

        </Panel>
    )
}

interface LeagueSeasonGameEditRecapProps {
    [key: string]: any
}

export default LeagueSeasonGameEditRecap;