import { baseApi, apiGet, response, options } from "@dex/bubl-fetch";

const endpoint = 'Help';

const HelpApi: any = baseApi(endpoint);

HelpApi.getList = (options: options): response => {

    return apiGet({
        "url": endpoint + "/getList",
        ...options
    });

}


export default HelpApi;