import React from 'react';
import DataTable from '@dex/bubl-dash/src/lib/components/DataTable/DataTable';

const VenueLiveStreamAttendees: React.FC<VenueLiveStreamAttendeesProps> = (props) => {

    const { data } = props;

    const columns = [
        {
            label: "Customer",
            key: "fullName",
        },
        {
            label: "Email",
            key: "email"
        },
        {
            label: "Phone",
            key: "phone"
        },
    ]

    return (
        <>
            <DataTable
                columns={columns}
                data={data || []}
                loading={!data}
            />
        </>
    )
}

interface VenueLiveStreamAttendeesProps {
    [key: string]: any
}

export default VenueLiveStreamAttendees;