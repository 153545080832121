import { apiGet, baseApi, options, response } from "@dex/bubl-fetch";

const endpoint = 'LeagueSeasonGame';

const LeagueSeasonGameApi: any = baseApi(endpoint);

LeagueSeasonGameApi.getBoxScores = (id: string, options: options): response => {

    return apiGet({
        "url": endpoint + "/public/" + id,
        ...options
    });

}

LeagueSeasonGameApi.getSportFields = (id: string, options: options): response => {

    return apiGet({
        "url": endpoint + "/" + id + "/getSportFields",
        ...options
    });

}

LeagueSeasonGameApi.getEvents = (id: string, options: options): response => {

    return apiGet({
        "url": endpoint + "/" + id + "/getEvents",
        ...options
    });

}

export default LeagueSeasonGameApi;