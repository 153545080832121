import VenueOperatorIndex from './VenueOperatorIndex';
import VenueOperatorCreate from './VenueOperatorCreate';
import VenueOperatorSettings from './VenueOperatorSettings';
import VenueOperatorSingle from './VenueOperatorSingle';

export default function (app) {

    const settings = VenueOperatorSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    VenueOperatorIndex(app, settings);

    VenueOperatorCreate(app, settings);

    VenueOperatorSingle(app, settings);

}