import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ActivityPanel from '@dex/bubl-dash/src/lib/components/SinglePanels/ActivityPanel';

const ProductSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Product Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Title",
                                key: "title"
                            },
                            {
                                label: "Short Title",
                                key: "shortTitle"
                            },
                            {
                                label: "Description",
                                key: "description"
                            }
                        ]}
                        columnB={[
                            {
                                label: "Status",
                                key: "status",
                                format: "titleCase"
                            },
                            {
                                label: "Type",
                                key: "type",
                                format: "titleCase"
                            },
                            {
                                label: "Camp / League / Operator",
                                key: "operator.title",
                                type: 'text',
                                format: "titleCase",
                                render: (text, row) => {
                                    if (row.operator) return row.operator.title;
                                    else if (row.league) return row.league.title;
                                    else if (row.camp) return row.camp.title;
                                }
                            },
                            {
                                label: "Pass Type",
                                key: "passType",
                                format: "titleCase"
                            }
                        ]}
                        columnC={[
                            {
                                label: "Form",
                                key: "form.title"
                            },
                            {
                                label: "Price",
                                key: "price"
                            },
                            {
                                label: "Priority",
                                key: "priority"
                            },
                        ]}
                    />

                </Panel>

            </Col>

            <Col className={'side'}>

                <ActivityPanel parent={data} />

            </Col>

        </Row>

    ), [data]);

}

export default ProductSingleView;
