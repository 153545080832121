import React, { useMemo } from 'react';
import UsersInfoEditPanel from './Components/UsersInfoEditPanel'
import { Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import UsersHostEditPanel from './Components/UsersHostEditPanel';

const UsersSingleViewMain: React.FC<any> = (props: any) => {

    return useMemo(() => (

        <>

            {props.data.roles.includes('host') &&
                <>

                    <Space />

                    <UsersHostEditPanel {...props} />

                </>
            }

            <Space />

            <UsersInfoEditPanel {...props} />


        </>

    ), [props]);

}

export default UsersSingleViewMain;
