import React, { useCallback, useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import LeagueTeamApi from '../LeagueTeamApi';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';
import DataTable from '@dex/bubl-dash/src/lib/components/DataTable/DataTable';
import FieldHidden from '@dex/bubl-dash/src/lib/components/Fields/FieldHidden';

const LeagueTeamValidateForm: React.FC<any> = (props: any) => {

    const { form, mode, onValidated, onAttach } = props;

    const teams = LeagueTeamApi.get();
    const attach = LeagueTeamApi.update("-", "attach");

    const handleCheckTeams = useCallback((values) => {

        teams.run({
            params: { limit: 100, where: { managersEmail: values.managersEmail }, include: ["manager"] }
        });

    }, []);

    const handleSelectTeam = useCallback((team) => {

        attach.run({
            url: LeagueTeamApi.endpoint + "/" + team.id + "/attach",
            data: { seasonId: form.values.seasonId },
            onComplete: (response) => {

                if (onAttach) onAttach(response);

            }
        })

    }, []);

    const handleCreateNew = useCallback(() => {

        form.reset(form.values);

        form.setKey();

        onValidated();

    }, [form.values.managersEmail]);

    const columns: any = [
        {
            label: "Team Name",
            key: "this",
            format: "ModelLink",
        },
        {
            label: "City",
            key: "city",
        },
        {
            label: "Manager",
            key: "manager",
            format: "ModelLink",
        },
        (form.values.seasonId && mode === "attach") ? {
            label: "Actions",
            key: "actions",
            alignEnd: true,
            render: (value, data, app) => {
                return <Button
                    size={"small"}
                    type={"primary"}
                    label={"Select Team"}
                    loading={attach.loading}
                    onClick={handleSelectTeam.bind(null, data)}
                />;
            }
        } : null,
    ].filter(Boolean);

    return useMemo(() => (

        <>

            <Form
                form={form}
                onSubmit={handleCheckTeams}
                loading={teams.loading}
            >

                {mode === "attach" && form.defaults.seasonId &&
                    <FieldHidden
                        form={form}
                        name="seasonId"
                        defaultValue={form.defaults.seasonId}
                    />
                }

                <Row gutter={8} edge={true}>

                    <Col col={{ md: 16 }}>

                        <FieldText
                            form={form}
                            name="managersEmail"
                            label="Manager's Email"
                            required={true}
                            defaultValue={form.defaults.managersEmail}
                        />

                    </Col>
                    <Col col={{ md: 8 }}>
                        <div style={{ height: 30 }}></div>
                        <Button
                            size='normal'
                            type={!teams.data ? 'primary' : 'faded'}
                            label={"Check Existing Teams"}
                            loading={teams.loading}
                        />
                    </Col>

                </Row>

                {teams.data &&
                    <Col col={{ xs: 24 }}>

                        <Seperator
                            top={"auto"}
                            bottom={"auto"}
                            heading={teams.data.count.total + " Team(s) Found"}
                            description={mode === "attach" && teams.data.count.total > 0 ? "Select an existing team to continue or create a new team." : null}
                        />

                        {teams.data.count.total > 0 &&
                            <DataTable
                                data={teams.data.data}
                                columns={columns}
                                loading={false}
                            />
                        }

                        <Line />

                        <ButtonRow>
                            <Button
                                label={"Create A New Team"}
                                onClick={handleCreateNew}
                            />
                        </ButtonRow>

                    </Col>
                }

            </Form>

        </>

    ), [props, teams, attach.loading]);

}

export default LeagueTeamValidateForm;
