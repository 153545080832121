import React, { useMemo } from 'react';
import Modal from '@dex/bubl-dash/src/lib/components/Modal/Modal';
import { useApp } from '@dex/bubl-dash';
import CreateView from '@dex/bubl-dash/src/lib/components/CreateView/CreateView';
import LeagueSeasonLeaderboardCreateEditForm from '../../LeagueSeasonLeaderboard/Components/LeagueSeasonLeaderboardCreateEditForm';
import LeagueSeasonLeaderboardApi from '../../LeagueSeasonLeaderboard/LeagueSeasonLeaderboardApi';

const LeagueSeasonAddNewLeaderboard: React.FC<any> = (props: any) => {

    const { data, onClose } = props;

    const app = useApp();

    const user = app.store("currentUser");

    return useMemo(() => (

        <>

            <Modal
                show={true}
                onClose={onClose}
                width={1080}
            >

                <CreateView
                    params={{}}
                    user={user}
                    form={LeagueSeasonLeaderboardCreateEditForm}
                    getCreate={LeagueSeasonLeaderboardApi.create}
                    defaults={{ seasonId: data.id, type: 'season', minGamesPlayed: 1 }}
                    successMessage={"Leaderboard Saved"}
                    successCallback={(data) => {
                        onClose()
                    }}
                    rowProps={{
                        gutter: null
                    }}
                    formProps={{
                        mode: "attach",
                        title: "Add New Leaderboard"
                    }}
                />

            </Modal>

        </>

    ), [data]);

}

export default LeagueSeasonAddNewLeaderboard;
