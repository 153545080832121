import React, { useMemo } from 'react';
import { Col, Row } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';
import { FieldUpload } from '@dex/bubl-dash';

const SettingsWeb: React.FC<any> = (props: any) => {

    const { form } = props;

    return useMemo(() => (

        <>

            <Seperator
                heading={"Homepage Banner"}
                bottom={"auto"}
            />

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldText
                        form={form}
                        label={"Banner Link"}
                        name={`bannerLink`}
                        required={true}
                        defaultValue={form.defaults.bannerLink}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldUpload
                        form={form}
                        folder={'admin-media'}
                        label={"Banner Image"}
                        name={`bannerImage`}
                        required={true}
                        defaultValue={form.defaults.bannerImage}
                        limit={1}
                    />

                </Col>

            </Row>

        </>

    ), [form]);

}

export default SettingsWeb;
