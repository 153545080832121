import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ActivityPanel from '@dex/bubl-dash/src/lib/components/SinglePanels/ActivityPanel';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';

const FormSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Form Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Title",
                                key: "title",
                            },
                        ]}
                        columnB={[
                            {
                                label: "Button Text",
                                key: "buttonText",
                            },
                        ]}
                        columnC={[
                            {
                                label: "Host",
                                key: "host",
                                format: "modelLink"
                            },
                        ]}
                    />

                    <Seperator
                        top={"auto"}
                        heading={"Fields"}
                        bottom={"auto"}
                    />

                    <MetaTable
                        data={data.fields}
                        columnA={[
                            {
                                label: "Title",
                                key: "title",
                            },
                            {
                                label: "Type",
                                key: "type",
                                format: "titleCase"
                            },
                        ]}
                        columnB={[
                            {
                                label: "Label",
                                key: "label",
                            },
                            {
                                label: "Placeholder",
                                key: "placeholder",
                            }
                        ]}
                        columnC={[
                            {
                                label: "Required",
                                key: "required",
                                format: "switch"
                            }
                        ]}
                    />

                </Panel>

            </Col>

            <Col className={'side'}>

                <ActivityPanel parent={data} />

            </Col>

        </Row>

    ), [data]);

}

export default FormSingleView;
