import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship';
import FieldDate from '@dex/bubl-dash/src/lib/components/Fields/FieldDate';
import FieldSelect from '@dex/bubl-dash/src/lib/components/Fields/FieldSelect';
import { useApp } from '@dex/bubl-dash';
import FieldSwitch from '@dex/bubl-dash/src/lib/components/Fields/FieldSwitch';

const startTimes = [
    "00:00", "00:30", "01:00", "01:30", "02:00", "02:30", "03:00", "03:30", "04:00", "04:30", "05:00", "05:30", "06:00", "06:30", "07:00", "07:30",
    "08:00", "08:30", "09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30",
    "16:00", "16:30", "17:00", "17:30", "18:00", "18:30", "19:00", "19:30", "20:00", "20:30", "21:00", "21:30", "22:00", "22:30", "23:00", "23:30",
].map((time) => { return { label: time, value: time }; });

const endTimes = [
    "01:00", "01:30", "02:00", "02:30", "03:00", "03:30", "04:00", "04:30", "05:00", "05:30", "06:00", "06:30", "07:00", "07:30", "08:00", "08:30",
    "09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30", "16:00", "16:30",
    "17:00", "17:30", "18:00", "18:30", "19:00", "19:30", "20:00", "20:30", "21:00", "21:30", "22:00", "22:30", "23:00", "23:30", "24:00", "24:30"
].map((time) => { return { label: time, value: time }; });

const VenueBlockedDatesCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    const app = useApp();

    const manageGlobal = app.userCan(['manageGlobal'], false);

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <Panel
            heading={"Blocked Date Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>


                    <Col col={{ md: 16, xs: 24 }}>

                        <FieldRelationship
                            form={form}
                            label='Courts'
                            name='courtIds'
                            required={true}
                            preload={true}
                            endpoint={'VenueCourt'}
                            defaultValue={form.defaults.courtIds}
                            isMulti={true}
                        />

                        <Row gutter={8} edge={true}>

                            <Col col={{ md: 12, xs: 24 }}>

                                <FieldDate
                                    form={form}
                                    label={"Date"}
                                    name={`date`}
                                    required={true}
                                    defaultValue={form.defaults.date}
                                    date={true}

                                />

                            </Col>

                            <Col col={{ md: 6, xs: 12 }}>

                                <FieldSelect
                                    form={form}
                                    label={"Start Time"}
                                    name={`startTime`}
                                    date={false}
                                    zone={false}
                                    time={true}
                                    required={true}
                                    defaultValue={form.defaults.startTime}
                                    options={startTimes}
                                />

                            </Col>

                            <Col col={{ md: 6, xs: 12 }}>

                                <FieldSelect
                                    form={form}
                                    label={"End Time"}
                                    name={`endTime`}
                                    date={false}
                                    zone={false}
                                    time={true}
                                    required={true}
                                    defaultValue={form.defaults.endTime}
                                    options={endTimes}
                                />

                            </Col>

                        </Row>

                    </Col>

                    <Col col={{ md: 8, xs: 24 }}>

                        <Row gutter={8} edge={true}>

                            {manageGlobal &&
                                <Col col={{ xs: 24 }}>

                                    <FieldRelationship
                                        form={form}
                                        label='Host'
                                        name='hostId'
                                        required={true}
                                        preload={true}
                                        endpoint={'Hosts'}
                                        defaultValue={form.defaults.hostId}
                                    />

                                </Col>
                            }

                        </Row>

                    </Col>

                </Row>

                <Line />

                <ButtonRow>

                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />

                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />

                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default VenueBlockedDatesCreateEditForm;
