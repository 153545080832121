import React, { useMemo, useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { DayPilotCalendar } from "../../components/DaypilotCalendar/DaypilotCalendar";
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import HostsApi from '../Hosts/HostsApi';
import { ButtonRow, Space } from '@dex/bubl-dash';
import Button from '@dex/bubl-dash/src/lib/components/Button/Button';
import { useApp } from '@dex/bubl-dash';

const DashboardViewWeek: React.FC<any> = (props: any) => {

    const app = useApp();

    const [nav] = app.store("nav");

    const list = HostsApi.thisWeek();

    const [date, setDate] = useState<any>(moment());

    const calendarRef: any = useRef(null);

    useEffect(() => {

        list.run({
            params: { date: date.format("YYYY-MM-DD") }
        });

    }, [date.format("YYYY-MM-DD")]);

    const now = moment();

    let data: any = [
        {
            id: "now",
            start: now.format("YYYY-MM-DDTHH:mm:ss") + "+00:00",
            end: now.add(10, "minutes").format("YYYY-MM-DDTHH:mm:ss") + "+00:00",
            modelName: "now",
        },
    ];


    if (list.data && list.data.length) {

        const events = JSON.parse(JSON.stringify(list.data));

        events.map(event => {

            //convert all times to utc timezone without changing displayed time

            event.start = moment.parseZone(event.start).format("YYYY-MM-DDTHH:mm:ss") + "+00:00";
            event.end = moment.parseZone(event.end).format("YYYY-MM-DDTHH:mm:ss") + "+00:00";

            data.push(event);

        });


    }

    return useMemo(() => (

        <Panel
            heading={"Schedule - " + date.format("MMMM YYYY")}
            actions={(
                <ButtonRow>
                    <Button
                        label="Prev Week"
                        type="light"
                        size="small"
                        onClick={setDate.bind(null, moment(date).subtract(1, "week"))}
                    />
                    <Button
                        label="Next Week"
                        type="light"
                        size="small"
                        onClick={setDate.bind(null, moment(date).add(1, "week"))}
                    />
                </ButtonRow>
            )}
            full={true}
        >

            <Space />

            <div className='calendar-week'>

                <DayPilotCalendar
                    key={date.format("YYYY-MM-DD")}
                    ref={calendarRef}
                    viewType="Week"
                    startDate={date.format("YYYY-MM-DD")}
                    events={data || []}
                    cellHeight={40}
                    hourWidth={80}
                    timeRangeSelectedHandling={"Disabled"}
                    eventResizeHandling="Disabled"
                    eventMoveHandling="Disabled"
                    onBeforeEventRender={args => {

                        if (args.data.id === "now") {

                            args.data.html = `<div id="current_time" class="event_custom_inner event_now"> </div> `;

                        } else {

                            args.data.html = `
                                <div class="event_custom_inner ${args.data.modelName}">
                                    <p class="event_custom_title">${args.data.title}</p>
                                    <p  class="event_custom_location">${args.data.location} (${args.data.attendeesCount})</p>
                                    <p class="event_custom_time">
                                        ${moment(args.data.start.value).format('HH:mm')} - ${moment(args.data.end.value).format('HH:mm')}
                                    </p>
                                </div>
                            `;

                        }

                    }}
                    onEventClicked={args => {

                        const data = args.e.data;

                        if (data.id === 'now') return;

                        let routeName = "";

                        if (data.modelName === 'VenueBooking') routeName = "venue/bookingsView";
                        else if (data.modelName === 'CampSession') routeName = "camp/sessionsView";
                        else if (data.modelName === 'PickupSession') routeName = "pickup/sessionsView";

                        const route = { "type": "Navigation/NAVIGATE", routeName: routeName, params: { id: data.id } }

                        app.navigate(nav, 'push', route);

                    }}
                />

            </div>

        </Panel>

    ), [data, date]);

}

export default DashboardViewWeek;
