import PaymentIndex from './PaymentIndex';
// import PaymentCreate from './PaymentCreate';
import PaymentSettings from './PaymentSettings';
import PaymentSingle from './PaymentSingle';

export default function (app) {

    const settings = PaymentSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    // app.addMenu({
    //     key: settings.key,
    //     zone: settings.zone,
    //     label: settings.title,
    //     priority: 14,
    //     items: [
    //         {
    //             label: "All Payments",
    //             path: settings.path,
    //             caps: settings.indexCaps
    //         },
    //         // {
    //         //     label: "Paid",
    //         //     path: settings.path + "/status/paid",
    //         //     caps: settings.indexCaps
    //         // },
    //         // {
    //         //     label: "Due",
    //         //     path: settings.path + "/status/due",
    //         //     caps: settings.indexCaps
    //         // },
    //         // {
    //         //     label: "Add New",
    //         //     path: settings.path + "/create",
    //         //     caps: settings.indexCaps
    //         // }
    //     ],
    // });

    PaymentIndex(app, settings);

    // PaymentCreate(app, settings);

    PaymentSingle(app, settings);

}