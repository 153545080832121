import IndexPage from '@dex/bubl-dash/src/lib/pages/IndexPage';
import LeagueCategoryCreateEditForm from './Components/LeagueCategoryCreateEditForm';
import LeagueCategoryApi from './LeagueCategoryApi';

export default function (app, settings) {

    app.addRoute({
        name: settings.key + "New",
        zone: settings.zone,
        path: settings.key + "/create",
        caps: settings.createCaps,
        component: IndexPage,
        componentProps: {
            header: {
                getTitle: () => "Add New",
                getButtons: settings.indexButtons,
                getTabs: settings.indexTabs,
                getTabCounts: settings.indexTabCounts,
            },
            create: {
                form: LeagueCategoryCreateEditForm,
                getCreate: LeagueCategoryApi.create,
                defaults: {},
                successMessage: "LeagueCategory Saved",
                successRouteName: settings.key + "View",
                idKey: settings.idKey
            }
        },
    });

}