import VenueCourtIndex from './VenueCourtIndex';
import VenueCourtCreate from './VenueCourtCreate';
import VenueCourtSettings from './VenueCourtSettings';
import VenueCourtSingle from './VenueCourtSingle';
import VenueOperatorSettings from '../VenueOperator/VenueOperatorSettings';
import VenueCategorySettings from '../VenueCategory/VenueCategorySettings';
import VenueBookingSettings from '../VenueBooking/VenueBookingSettings';
import VenueBlockedDatesSettings from '../VenueBlockedDates/VenueBlockedDatesSettings';
import VenueLiveStreamSettings from '../VenueLiveStream/VenueLiveStreamSettings';


export default function (app) {

    const settings = VenueCourtSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    app.addMenu({
        key: settings.key,
        zone: settings.zone,
        label: 'Venues',
        priority: 14,
        items: [
            {
                label: "Bookings",
                path: VenueBookingSettings.path,
                caps: VenueBookingSettings.indexCaps
            },
            {
                label: "Live Streams",
                path: VenueLiveStreamSettings.path,
                caps: VenueLiveStreamSettings.indexCaps
            },
            {
                label: "Courts",
                path: settings.path,
                caps: settings.indexCaps
            },
            {
                label: "Operators",
                path: VenueOperatorSettings.path,
                caps: VenueOperatorSettings.indexCaps
            },
            {
                label: "Categories",
                path: VenueCategorySettings.path,
                caps: VenueCategorySettings.indexCaps
            },
            {
                label: "Blocked Dates",
                path: VenueBlockedDatesSettings.path,
                caps: VenueBlockedDatesSettings.indexCaps
            },
        ],
    });

    app.addMenu({
        key: settings.key,
        zone: settings.zone,
        label: "Actions",
        priority: 14,
        items: [
            {
                label: "Add New Court",
                path: settings.path + "/create",
                caps: settings.createCaps
            },
            {
                label: "Add New Operator",
                path: VenueOperatorSettings.path + "/create",
                caps: VenueOperatorSettings.createCaps
            },
            // {
            //     label: "Add New Booking",
            //     path: VenueBookingSettings.path + "/create",
            //     caps: VenueBookingSettings.createCaps
            // },
            {
                label: "Add New Category",
                path: VenueCategorySettings.path + "/create",
                caps: VenueCategorySettings.createCaps
            },
            {
                label: "Add New Blocked Date",
                path: VenueBlockedDatesSettings.path + "/create",
                caps: VenueBlockedDatesSettings.createCaps
            },
        ]
    })

    VenueCourtIndex(app, settings);

    VenueCourtCreate(app, settings);

    VenueCourtSingle(app, settings);

}