import React, { useMemo, useCallback } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form, useForm } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { useApp } from '@dex/bubl-dash';
import VenueLiveStreamApi from '../VenueLiveStreamApi';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import { get } from '@dex/bubl-helpers';

const VenueLiveStreamEditSessionVideo: React.FC<any> = (props: any) => {

    const { data } = props;

    const app = useApp();

    const form = useForm(data);

    const process = VenueLiveStreamApi.update(data.id, "sessionVideo", {
        onComplete: (data) => {

            app.alert('Booking Saved', 'success');

        }
    });

    const handleSubmit = useCallback((values) => {

        process.run({ data: values });

    }, [props]);

    return useMemo(() => (

        <Panel
            heading={"Session Video"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24 }}>

                        <FieldRadio
                            form={form}
                            label='Session Video Status'
                            name='videoStatus'
                            required={true}
                            defaultValue={form.defaults.videoStatus}
                            options={[
                                { value: "available", label: "Available" },
                                { value: "purchased", label: "Purchased" },
                                { value: "ready", label: "Ready" },
                            ]}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldRadio
                            form={form}
                            label="Video Source"
                            name={`video[source]`}
                            required={true}
                            defaultValue={get(form.defaults, 'video.source')}
                            options={[
                                { value: 'youtube', label: 'Youtube' },
                                { value: 'facebook', label: 'Facebook' },
                                { value: 'pixellot', label: 'Pixellot' },
                            ]}
                        />

                        <FieldText
                            form={form}
                            label={"Video Source ID"}
                            name={`video[id]`}
                            required={false}
                            defaultValue={get(form.defaults, 'video.id')}
                        />

                    </Col>

                </Row>

                <Line />

                <ButtonRow>

                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />

                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />

                </ButtonRow>

            </Form>

        </Panel>

    ), [form.values, process.loading]);

}

export default VenueLiveStreamEditSessionVideo;
