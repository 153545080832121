import { MediaImage } from '@dex/bubl-dash';
import { Link } from '@dex/bubl-dash';
import React from 'react';

import styles from "./Bracket.module.scss";

const BracketItem: React.FC<BracketItemProps> = (props) => {

    const { matchup, onPress } = props;

    const team = matchup.teams[0] || {};
    const teamB = matchup.teams[1] || {};

    return (

        <Link
            onClick={onPress.bind(null, matchup)}
            className={styles.item}
        >

            <div className={styles.itemMatchupKey}>
                {matchup.key}
            </div>

            {matchup.teams.map((team: any, index: any) => (

                <React.Fragment key={index}>

                    <div className={[styles.itemContainer, matchup.winner && matchup.winner !== team.id ? styles.itemContainerLoser : ""].join(" ")}>

                        <div className={styles.itemSeed}>
                            <div className={styles.seedText}>
                                {team.seed || "-"}
                            </div>
                        </div>

                        <div className={styles.itemContent}>

                            <MediaImage
                                className={styles.logo}
                                type={'thumb'}
                                resizeMode={"center"}
                                filters={{ width: 100, height: 100 }}
                                id={team.logo?.fileId}
                            />

                            <div className={[styles.name, team.winner ? styles.winner : ''].join(" ")}>
                                {team.name}
                            </div>

                            {matchup.teams[0].id && matchup.teams[1].id &&
                                <div className={[styles.score, team.winner ? styles.winner : ''].join(" ")}>
                                    {team.score}
                                </div>
                            }

                        </div>

                    </div>

                    {!index &&
                        <div className={styles.separator} />
                    }

                </React.Fragment>

            ))}

        </Link>

    )
}

interface BracketItemProps {
    [key: string]: any
}

export default BracketItem;