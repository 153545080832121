import React, { useMemo } from 'react';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship';
import FieldRepeater from '@dex/bubl-dash/src/lib/components/Fields/FieldRepeater';
import FieldRich from '@dex/bubl-dash/src/lib/components/Fields/FieldRich';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import FieldNumber from '@dex/bubl-dash/src/lib/components/Fields/FieldNumber';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';
import FieldUpload from '@dex/bubl-dash/src/lib/components/Fields/FieldUpload';
import FieldSwitch from '@dex/bubl-dash/src/lib/components/Fields/FieldSwitch';
import { get } from '@dex/bubl-helpers';

const LeagueCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    const app = useApp();

    const manageGlobal = app.userCan(['manageGlobal'], false);

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <Panel
            heading={"League Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 16 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={24}>

                                <FieldText

                                    form={form}
                                    label={"Title"}
                                    name='title'
                                    required={true}
                                    defaultValue={form.defaults.title}
                                />

                            </Col>

                            <Col col={24}>

                                <FieldRelationship
                                    form={form}
                                    label={"Sport"}
                                    name='sportId'
                                    required={true}
                                    preload={true}
                                    endpoint={'Sport'}
                                    defaultValue={form.defaults.sportId}
                                />

                            </Col>

                            <Col col={24}>

                                <FieldRelationship
                                    form={form}
                                    label={"Category"}
                                    name='categoryId'
                                    required={true}
                                    preload={true}
                                    endpoint={'LeagueCategory'}
                                    defaultValue={form.defaults.categoryId}
                                />

                            </Col>

                            <Col col={24}>

                                <FieldText
                                    form={form}
                                    label={"Short Description"}
                                    name='description'
                                    required={false}
                                    defaultValue={form.defaults.description}
                                />

                            </Col>

                            <Col col={24}>

                                <FieldText
                                    form={form}
                                    label={"Website Link"}
                                    name='websiteLink'
                                    required={false}
                                    placeholder={"http://"}
                                    defaultValue={form.defaults.websiteLink}
                                />

                            </Col>

                            <Col col={24}>

                                <FieldText
                                    form={form}
                                    label={"Website Label"}
                                    name='websiteLabel'
                                    required={false}
                                    defaultValue={form.defaults.websiteLabel}
                                />

                            </Col>

                        </Row>

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <Row gutter={8} edge={true}>

                            {manageGlobal &&
                                <Col col={{ md: 24, xs: 24 }}>

                                    <FieldRelationship
                                        form={form}
                                        label='Host'
                                        name='hostId'
                                        required={true}
                                        preload={true}
                                        endpoint={'Hosts'}
                                        defaultValue={form.defaults.hostId}
                                    />

                                </Col>
                            }

                            <Col col={24}>

                                <FieldRadio
                                    form={form}
                                    label={"Status"}
                                    name='status'
                                    required={true}
                                    defaultValue={form.defaults.status}
                                    options={[
                                        { value: "draft", label: "Draft" },
                                        { value: "preview", label: "Preview" },
                                        { value: "publish", label: "Publish" },
                                        { value: "archive", label: "Archive" },
                                    ]}
                                />

                            </Col>

                            <Col col={24}>

                                <FieldSwitch
                                    form={form}
                                    label={"Featured"}
                                    name='isFeatured'
                                    required={false}
                                    defaultValue={form.defaults.isFeatured}
                                />

                            </Col>

                            <Col col={24}>

                                <FieldRadio
                                    form={form}
                                    label={"API Type"}
                                    name='sourceType'
                                    required={false}
                                    defaultValue={form.defaults.sourceType}
                                    options={[
                                        { value: "none", label: "None" },
                                        { value: "spBasketball", label: "Sports Press BB" }
                                    ]}
                                />

                            </Col>

                            {form.values.sourceType === "spBasketball" &&
                                <Col col={24}>

                                    <FieldText
                                        form={form}
                                        label={"API Source URL"}
                                        name='sourceUrl'
                                        required={true}
                                        defaultValue={form.defaults.sourceUrl}
                                    />

                                </Col>
                            }

                            <Col col={24}>

                                <FieldNumber
                                    form={form}
                                    label={"Priority"}
                                    name={`priority`}
                                    required={false}
                                    defaultValue={form.defaults.priority}
                                />

                            </Col>

                        </Row>

                    </Col>



                    <Col col={{ xs: 24, md: 16 }}>

                        <FieldUpload
                            form={form}
                            folder={'admin-media'}
                            label={"Icon"}
                            name={`icon`}
                            required={false}
                            defaultValue={form.defaults.icon}
                            limit={1}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 16 }}>

                        <FieldUpload
                            form={form}
                            folder={'admin-media'}
                            label={"Logo"}
                            name={`logo`}
                            required={false}
                            defaultValue={form.defaults.logo}
                            limit={1}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 16 }}>

                        <FieldUpload
                            form={form}
                            folder={'admin-media'}
                            label={"Thumbnail"}
                            name={`thumbnail`}
                            required={false}
                            defaultValue={form.defaults.thumbnail}
                            limit={1}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 16 }}>

                        <FieldUpload
                            form={form}
                            folder={'admin-media'}
                            label={"Cover"}
                            name={`cover`}
                            required={false}
                            defaultValue={form.defaults.cover}
                            limit={1}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 16 }}>

                        <FieldUpload
                            form={form}
                            folder={'admin-media'}
                            label={"Photo Gallery"}
                            name={`gallery`}
                            required={false}
                            defaultValue={form.defaults.gallery}
                            limit={50}
                        />

                    </Col>

                </Row>

                <Seperator
                    top="auto"
                    bottom="auto"
                    heading="Key Features"
                />

                <FieldRepeater
                    form={form}
                    name={'keyFeatures'}
                    addButtonLabel={"Key Features"}
                >

                    {Array.isArray(form.values.keyFeatures) && form.values.keyFeatures.map((row, n) => (

                        <Row gutter={8} edge={true} key={form.key + "-" + n}>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldText
                                    form={form}
                                    label={"Feature Label"}
                                    name={`keyFeatures[${n}][label]`}
                                    required={false}
                                    defaultValue={row.label}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldText
                                    form={form}
                                    label={"Feature Value"}
                                    name={`keyFeatures[${n}][value]`}
                                    required={false}
                                    defaultValue={row.value}
                                />

                            </Col>

                        </Row>

                    ))}

                </FieldRepeater>

                <Seperator
                    top="auto"
                    bottom="auto"
                    heading="Full Description"
                />

                <FieldRepeater
                    form={form}
                    name={'contentBlocks'}
                    addButtonLabel={"Add Block"}
                >

                    {Array.isArray(form.values.contentBlocks) && form.values.contentBlocks.map((row, n) => (

                        <React.Fragment key={form.key + "-" + n}>

                            <FieldRadio
                                form={form}
                                label="Block Type"
                                name={`contentBlocks[${n}][type]`}
                                required={true}
                                defaultValue={row.type}
                                options={[
                                    { value: 'text', label: 'Text' },
                                    { value: 'image', label: 'Image' },
                                    { value: 'video', label: 'Video' },
                                ]}
                            />

                            {row.type === 'text' &&

                                <FieldRich
                                    form={form}
                                    label={"Content"}
                                    name={`contentBlocks[${n}][content]`}
                                    required={false}
                                    defaultValue={row.content}
                                />

                            }

                            {row.type === 'image' &&

                                <FieldUpload
                                    form={form}
                                    folder={'admin-media'}
                                    label={"Image"}
                                    name={`contentBlocks[${n}][image]`}
                                    required={false}
                                    defaultValue={row.image}
                                    limit={1}
                                />

                            }

                            {row.type === 'video' &&

                                <>

                                    <FieldRadio
                                        form={form}
                                        label="Video Source"
                                        name={`contentBlocks[${n}][video][source]`}
                                        required={true}
                                        defaultValue={get(row, 'video.source')}
                                        options={[
                                            { value: 'youtube', label: 'Youtube' },
                                            { value: 'facebook', label: 'Facebook' },
                                        ]}
                                    />

                                    <FieldText
                                        form={form}
                                        label={"Video Source ID"}
                                        name={`contentBlocks[${n}][video][id]`}
                                        required={false}
                                        defaultValue={get(row, 'video.id')}
                                    />

                                </>

                            }

                        </React.Fragment>

                    ))}

                </FieldRepeater>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel >


    ), [props]);

}

export default LeagueCreateEditForm;
