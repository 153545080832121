import React, { useMemo } from 'react';
import { Row, Col, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ActivityPanel from '@dex/bubl-dash/src/lib/components/SinglePanels/ActivityPanel';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';
import HostsSingleUsers from './HostsSingleUsers';

const HostsSingleView: React.FC<any> = (props: any) => {

    const app = useApp();

    const manageGlobal = app.userCan(['manageGlobal']);

    const { data } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Hosts Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Organization Name",
                                key: "orgName"
                            },
                            {
                                label: "Organization Description",
                                key: "orgDescription"
                            },
                            {
                                label: "Company Name",
                                key: "companyName"
                            },
                            {
                                label: "Company Reg. No",
                                key: "companyRegNo"
                            },
                            {
                                label: "Website Link",
                                key: "websiteLink"
                            },
                            {
                                label: "Social Links",
                                key: "socialLink"
                            }
                        ]}
                        columnB={[
                            {
                                label: "Contact Person",
                                key: "contactPerson"
                            },
                            {
                                label: "Contact Email",
                                key: "contactEmail"
                            },
                            {
                                label: "Contact Phone",
                                key: "contactPhone"
                            },
                            {
                                label: "Notifications Email",
                                key: "notificationsEmail"
                            },
                        ]}
                        columnC={[
                            {
                                label: "Status",
                                key: "status",
                                format: "titleCase",
                            },
                            {
                                label: "Owner",
                                key: "owner",
                                format: "ModelLink",
                            },
                            {
                                label: "Community Status",
                                key: "communityStatus",
                                format: "titleCase",
                            },
                            {
                                label: "Featured Community",
                                key: "featuredCommunity",
                                format: "switch",
                            },
                        ]}
                    />

                    {/* <Seperator
                        heading={"Tokens"}
                        top={"auto"}
                        bottom={"auto"}
                    />

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Tokens Allocated",
                                key: "tokensAllocated"
                            }
                        ]}
                        columnB={[
                            {
                                label: "Tokens Used",
                                key: "tokensUsed"
                            }
                        ]}
                        columnC={[
                            {
                                label: "Tokens Available",
                                key: "tokensAvailable"
                            }
                        ]}
                    /> */}

                </Panel>

                <>
                    <Space />

                    <HostsSingleUsers data={data} />
                </>

            </Col>

            <Col className={'side'}>

                <ActivityPanel parent={data} />

                {/* <HostsSingleProperties parent={data} /> */}

            </Col>

        </Row>

    ), [data]);

}

export default HostsSingleView;
