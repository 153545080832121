import React from 'react';
import { titleCase, get } from "@dex/bubl-helpers";
import ReactionApi from './ReactionApi';
import ReactionActions from './Components/ReactionActions';

let settings;

export default settings = {
    key: 'content/reactions',
    zone: 'content',
    path: '/content/reactions',
    title: "Reactions",
    endpoint: ReactionApi.endpoint,
    modelName: 'Reaction',
    idKey: 'id',
    primaryKey: 'reaction',
    indexCaps: ["viewReaction"],
    viewCaps: ["viewReaction"],
    createCaps: ["createReaction"],
    indexTitle: (routeName, params, users) => {

        let title: string = settings.title;

        if (params && params.name) title = title + " / " + titleCase(params.name);

        return title;

    },
    indexTabs: (routeName, params, users) => {

        return [
            {
                label: "All",
                path: settings.path,
                caps: settings.indexCaps
            },
            {
                label: "Add New",
                path: settings.path + "/create",
                caps: settings.createCaps
            },
        ]

    },
    indexButtons: (routeName, params, users) => {

        return [
            {
                label: "Add New",
                path: settings.path + '/create',
                caps: settings.createCaps
            }
        ]

    },
    indexTabCounts: (routeName, params, dontation) => {

        return settings.endpoint + "/getCounts";

    },
    indexFetch: ReactionApi.get,
    indexFetchParams: (routeName, params, users) => {

        const filters: any = { where: {}, include: ['owner', 'parent'], order: ['created.date DESC'], limit: 10, skip: 0 };

        if (params && params.status) filters.where.status = { inq: [params.status] };

        return filters;

    },
    indexQuickSearch: (routeName, params, users) => {

        const fields = ['reaction', 'parentType'];

        return fields;

    },
    indexSearchFields: (routeName, params, users) => {

        const fields = [
            {
                label: "Reaction",
                key: "this",
                format: "ModelLink",
            },
            {
                label: "Owner",
                key: "ownerId",
                type: "relationship",
                model: "Users",
            }
        ];

        return fields;

    },

    indexColumns: (routeName, params, users) => {

        const columns = [
            {
                label: "Reaction",
                key: "this",
                format: "ModelLink",
            },
            {
                label: "Owner",
                key: "owner",
                format: "ModelLink",
            },
            {
                label: "Parent Type",
                key: "parentType",
            },
            {
                label: "Parent Title",
                key: "parent",
                format: "ModelLink",
            },
            {
                label: "Created Date",
                key: "created",
                format: "OnAndBy",
                sort: "created.date",
                alignEnd: true
            },
            {
                label: "Actions",
                key: "actions",
                alignEnd: true,
                render: (value, data) => {

                    return <ReactionActions data={data} />;

                }
            }
        ];

        return columns;

    },
    singleFetch: ReactionApi.getOne,
    singleFetchParams: (routeName, params, users, fetch) => {

        const filters = { include: ['owner', 'parent'] };

        return filters;

    },
    singleTitle: (routeName, params, users, fetch) => {

        let title: string = "Loading...";

        const complete = get(fetch, 'complete');
        const error = get(fetch, 'error');
        const data = get(fetch, 'data');

        if (error) title = "Error";
        else if (data) title = data[settings.primaryKey];
        else if (complete && !data) title = "Not Found";

        return title;

    },
    singleTabs: (routeName, params, users, fetch) => {

        const path = settings.path + "/" + params.id;

        return [
            {
                label: "View",
                path: path + "/view"
            },
            {
                label: "Edit",
                path: path + "/edit"
            },
        ]

    },
};