import React, { useEffect, useMemo, useState } from 'react';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Space } from '@dex/bubl-dash';
import LeagueSeasonLeaderboardApi from '../LeagueSeasonLeaderboardApi';
import { DataTable } from '@dex/bubl-dash';

const LeagueSeasonLeaderboardPlayers: React.FC<any> = (props: any) => {

    const { data } = props;

    const leaderboard = LeagueSeasonLeaderboardApi.getPublic(data.id);

    useEffect(() => {

        leaderboard.run();

    }, [data.id]);

    const columns = [
        {
            label: "#",
            key: "jerseyNumber",
            format: "number",
            style: { width: 30 }
        },
        {
            label: "Player",
            key: "this",
            format: "ModelLink",
        },
        {
            label: data.statSuffix || "Stat",
            key: "stat",
            format: "number",
            alignEnd: true
        },
    ];

    console.log(leaderboard.data);

    return useMemo(() => (

        <Panel
            heading={"Players"}
            full={true}
        >

            <Space />

            <DataTable
                data={leaderboard?.data?.players}
                columns={columns}
                loading={leaderboard.loading}
            />

        </Panel>

    ), [data, leaderboard.loading]);

}

export default LeagueSeasonLeaderboardPlayers;
