import LeagueIndex from "./LeagueIndex";
import LeagueCreate from "./LeagueCreate";
import LeagueSettings from "./LeagueSettings";
import LeagueSingle from "./LeagueSingle";
import LeagueSeasonSettings from "../LeagueSeason/LeagueSeasonSettings";
import LeagueCategorySettings from "../LeagueCategory/LeagueCategorySettings";
import LeagueTeamSettings from "../LeagueTeam/LeagueTeamSettings";
import LeaguePlayerSettings from "../LeaguePlayer/LeaguePlayerSettings";
import LeagueSeasonGameSettings from "../LeagueSeasonGame/LeagueSeasonGameSettings";
import LeagueSeasonDivisionSettings from "../LeagueSeasonDivision/LeagueSeasonDivisionSettings";

export default function (app) {
    const settings = LeagueSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    app.addMenu({
        key: settings.key,
        zone: settings.zone,
        label: "Leagues",
        priority: 10,
        items: [
            {
                label: "Seasons",
                path: LeagueSeasonSettings.path,
                caps: LeagueSeasonSettings.indexCaps,
            },
            {
                label: "Leagues",
                path: settings.path,
                caps: settings.indexCaps,
            },
            {
                label: "Categories",
                path: LeagueCategorySettings.path,
                caps: LeagueCategorySettings.indexCaps,
            },
            {
                label: "Teams",
                path: LeagueTeamSettings.path,
                caps: LeagueTeamSettings.indexCaps,
            },
            {
                label: "Players",
                path: LeaguePlayerSettings.path,
                caps: LeaguePlayerSettings.indexCaps,
            },
            {
                label: "Games",
                path: LeagueSeasonGameSettings.path,
                caps: LeagueSeasonGameSettings.indexCaps,
            },
            {
                label: "Divisions",
                path: LeagueSeasonDivisionSettings.path,
                caps: LeagueSeasonDivisionSettings.indexCaps,
            },
        ],
    });

    app.addMenu({
        key: settings.key,
        zone: settings.zone,
        label: "Actions",
        priority: 10,
        items: [
            {
                label: "Add New League",
                path: settings.path + "/create",
                caps: settings.createCaps,
            },
            {
                label: "Add New Season",
                path: LeagueSeasonSettings.path + "/create",
                caps: LeagueSeasonSettings.createCaps,
            },
            {
                label: "Add New Category",
                path: LeagueCategorySettings.path + "/create",
                caps: LeagueCategorySettings.createCaps,
            },
            // {
            //     label: "Add New Team",
            //     path: LeagueTeamSettings.path + "/create",
            //     caps: LeagueTeamSettings.createCaps,
            // },
            // {
            //     label: "Add New Player",
            //     path: LeaguePlayerSettings.path + "/create",
            //     caps: LeaguePlayerSettings.createCaps,
            // },
            // {
            //     label: "Add New Game",
            //     path: LeagueSeasonGameSettings.path + "/create",
            //     caps: LeagueSeasonGameSettings.createCaps,
            // },
            // {
            //     label: "Add New Division",
            //     path: LeagueSeasonDivisionSettings.path + "/create",
            //     caps: LeagueSeasonDivisionSettings.createCaps,
            // },
        ],
    });

    LeagueIndex(app, settings);

    LeagueCreate(app, settings);

    LeagueSingle(app, settings);
}
