import React from 'react';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';

import RenderContentBlocks from '../../../components/RenderContentBlocks/RenderContentBlocks';
import VenueBookingPurchaseVideoButton from './VenueBookingPurchaseVideoButton';

const VenueBookingSessionVideoPanel: React.FC<VenueBookingSessionVideoPanelProps> = (props) => {

    const { booking } = props;

    const VideoBlocks = [{ type: "video", video: booking.video, width: '100%', height: '100%' }]

    return (
        <>

            <Panel
                heading={"Session Video"}
                actions={(
                    booking.hasVideo && booking.court.videoProductId && !booking.videoOrderId &&
                    <VenueBookingPurchaseVideoButton booking={booking} />
                )}
            >

                <MetaTable
                    data={booking}
                    columnSize={{ xs: 24 }}
                    columnA={[
                        {
                            label: 'Video Status',
                            key: 'videoStatus',
                            format: 'titleCase',

                        },
                        {
                            label: "Video Order Id",
                            key: "videoOrder",
                            format: 'ModelLink'
                        }
                    ]}
                />

                {booking.video && booking.video.id && booking.video.source &&

                    <div>

                        <RenderContentBlocks blocks={VideoBlocks} />

                    </div>
                }

            </Panel>

        </>
    )
}

interface VenueBookingSessionVideoPanelProps {
    [key: string]: any
}

export default VenueBookingSessionVideoPanel;