import React, { useMemo } from 'react';
import { get } from '@dex/bubl-helpers';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldUpload from '@dex/bubl-dash/src/lib/components/Fields/FieldUpload';
import FieldRepeater from '@dex/bubl-dash/src/lib/components/Fields/FieldRepeater';
import FieldRich from '@dex/bubl-dash/src/lib/components/Fields/FieldRich';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship';

const HelpCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <Panel
            heading={"Help Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldText
                            form={form}
                            label="Title"
                            name="title"
                            required={true}
                            defaultValue={form.defaults.title}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldRelationship
                            form={form}
                            label="Topic"
                            name="topicId"
                            required={true}
                            preload={true}
                            defaultValue={form.defaults.topicId}
                            model="HelpTopic"
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldRadio
                            form={form}
                            label='Status'
                            name='status'
                            required={true}
                            defaultValue={form.defaults.status}
                            options={[
                                { value: "draft", label: "Draft" },
                                { value: "publish", label: "Publish" },
                            ]}
                        />

                    </Col>

                    <Col col={{ xs: 24 }}>

                        <FieldRepeater
                            form={form}
                            name={'contentBlocks'}
                            addButtonLabel={"Add Block"}
                        >

                            {Array.isArray(form.values.contentBlocks) && form.values.contentBlocks.map((row, n) => (

                                <React.Fragment key={form.key + "-" + n}>

                                    <FieldRadio
                                        form={form}
                                        label="Block Type"
                                        name={`contentBlocks[${n}][type]`}
                                        required={true}
                                        defaultValue={row.type}
                                        options={[
                                            { value: 'text', label: 'Text' },
                                            { value: 'image', label: 'Image' },
                                            { value: 'video', label: 'Video' },
                                        ]}
                                    />

                                    {row.type === 'text' &&

                                        <FieldRich
                                            form={form}
                                            label={"Content"}
                                            name={`contentBlocks[${n}][content]`}
                                            required={false}
                                            defaultValue={row.content}
                                        />

                                    }

                                    {row.type === 'image' &&

                                        <FieldUpload
                                            form={form}
                                            folder={'admin-media'}
                                            label={"Image"}
                                            name={`contentBlocks[${n}][image]`}
                                            required={false}
                                            defaultValue={row.image}
                                            limit={1}
                                        />

                                    }

                                    {row.type === 'video' &&

                                        <>

                                            <FieldRadio
                                                form={form}
                                                label="Video Source"
                                                name={`contentBlocks[${n}][video][source]`}
                                                required={true}
                                                defaultValue={get(row, 'video.source')}
                                                options={[
                                                    { value: 'youtube', label: 'Youtube' },
                                                    { value: 'facebook', label: 'Facebook' },
                                                ]}
                                            />

                                            <FieldText
                                                form={form}
                                                label={"Video Source ID"}
                                                name={`contentBlocks[${n}][video][id]`}
                                                required={false}
                                                defaultValue={get(row, 'video.id')}
                                            />

                                        </>

                                    }

                                </React.Fragment>

                            ))}

                        </FieldRepeater>

                    </Col>

                </Row>


                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default HelpCreateEditForm;
