import SinglePage from '@dex/bubl-dash/src/lib/pages/SinglePage';
import CampAttendanceSingleView from './Components/CampAttendanceSingleView';
import CampAttendanceSingleEdit from './Components/CampAttendanceSingleEdit';

export default function (app, settings) {

    app.addRoute({
        name: settings.key + "View",
        zone: settings.zone,
        path: settings.key + "/:id/view",
        caps: settings.viewCaps,
        component: SinglePage,
        componentProps: {
            getFetch: settings.singleFetch,
            getFetchParams: settings.singleFetchParams,
            header: {
                getTitle: settings.singleTitle,
                getTabs: settings.singleTabs
            },
            page: CampAttendanceSingleView,
        },
    });

    // app.addRoute({
    //     name: settings.key + "Edit",
    //     zone: settings.zone,
    //     path: settings.key + "/:id/edit",
    //     caps: settings.updateCaps,
    //     component: SinglePage,
    //     componentProps: {
    //         getFetch: settings.singleFetch,
    //         getFetchParams: settings.singleFetchParams,
    //         header: {
    //             getTitle: settings.singleTitle,
    //             getTabs: settings.singleTabs
    //         },
    //         page: CampAttendanceSingleEdit,
    //     },
    // });

}