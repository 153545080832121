import React, { useMemo, useEffect } from 'react';
import SidebarMenu from '@dex/bubl-dash/src/lib/components/SideBar/SidebarMenu';
import CampSessionSettings from '../CampSession/CampSessionSettings';
import PickupSessionSettings from '../PickupSession/PickupSessionSettings';
import LeagueSeasonSettings from '../LeagueSeason/LeagueSeasonSettings';
import NewsSettings from '../News/NewsSettings';
import ProductSettings from '../Product/ProductSettings';
import OrderSettings from '../Order/OrderSettings';

const DashboardMenu: React.FC<any> = (props: any) => {

    return useMemo(() => (

        <>

            <SidebarMenu
                menu={{
                    label: "Browse",
                    items: [
                        {
                            path: CampSessionSettings.path,
                            label: "Camp Sessions"
                        },
                        {
                            path: PickupSessionSettings.path,
                            label: "Pickup Sessions"
                        },
                        {
                            path: LeagueSeasonSettings.path,
                            label: "League Sessions"
                        },
                        {
                            path: NewsSettings.path,
                            label: "News Articles"
                        },
                        {
                            path: ProductSettings.path,
                            label: "Product"
                        },
                        {
                            path: OrderSettings.path,
                            label: "Orders"
                        }
                    ]
                }}
            />

            <SidebarMenu
                menu={{
                    label: "Create",
                    items: [
                        {
                            path: CampSessionSettings.path + '/create',
                            label: "Camp Sessions"
                        },
                        {
                            path: PickupSessionSettings.path + '/create',
                            label: "Pickup Sessions"
                        },
                        {
                            path: LeagueSeasonSettings.path + '/create',
                            label: "League Sessions"
                        },
                        {
                            path: NewsSettings.path + '/create',
                            label: "News Articles"
                        },
                        {
                            path: ProductSettings.path + '/create',
                            label: "Product"
                        },
                        {
                            path: OrderSettings.path + '/create',
                            label: "Orders"
                        }
                    ]
                }}
            />

        </>

    ), []);

}

export default DashboardMenu;
