import React from 'react';
import { titleCase, get } from "@dex/bubl-helpers";
import OrderApi from './OrderApi';
import OrderActions from './Components/OrderActions';

let settings;

export default settings = {
    key: 'shop/orders',
    zone: 'shop',
    path: '/shop/orders',
    title: "Orders",
    endpoint: OrderApi.endpoint,
    modelName: 'Order',
    idKey: 'id',
    primaryKey: 'id',
    indexCaps: ["viewOrder"],
    viewCaps: ["viewOrder"],
    createCaps: ["createOrder"],
    indexTitle: (routeName, params, users) => {

        let title: string = settings.title;

        if (params && params.name) title = title + " / " + titleCase(params.name);

        return title;

    },
    indexTabs: (routeName, params, users) => {

        return [
            {
                label: "All",
                path: settings.path,
                caps: settings.indexCaps
            },
            {
                label: "Paid",
                path: settings.path + "/status/paid",
                caps: settings.indexCaps
            },
            {
                label: "Cart",
                path: settings.path + "/status/cart",
                caps: settings.indexCaps
            },
            {
                label: "Failed",
                path: settings.path + "/status/failed",
                caps: settings.indexCaps
            },
            {
                label: "Add New",
                path: settings.path + "/create",
                caps: settings.createCaps
            },
        ]

    },
    indexButtons: (routeName, params, users) => {

        return [
            {
                label: "Add New",
                path: settings.path + '/create',
                caps: settings.createCaps
            }
        ]

    },
    indexTabCounts: (routeName, params, dontation) => {

        return settings.endpoint + "/getCounts";

    },
    indexFetch: OrderApi.get,
    indexFetchParams: (routeName, params, users) => {

        const filters: any = { where: {}, include: ['product', 'owner', 'host'], order: ['created.date DESC'], limit: 10, skip: 0 };

        if (params && params.status) filters.where.status = { inq: [params.status] };

        return filters;

    },
    indexQuickSearch: (routeName, params, users) => {

        const fields = ['id'];

        return fields;

    },
    indexSearchFields: (routeName, params, users) => {

        const fields = [
            {
                label: "ID",
                key: "id",
                type: "text",
            },
            {
                label: "Product",
                key: "productId",
                type: "relationship",
                model: "Product",
            },
            {
                label: "Customer",
                key: "ownerId",
                type: "relationship",
                model: "Users",
            },
            {
                label: 'Date',
                key: 'created.date',
                type: 'date',
            },
            {
                label: "Host",
                key: "hostId",
                type: "relationship",
                model: "Hosts"
            },
            {
                label: "Product",
                key:"productId",
                type: "relationship",
                model: "Product"
            },
            { 
                label:"Status",
                key: "status",
                type: "choice",
                options: [
                    { value: "cart", label: "Cart" },
                    { value: "paid", label: "Paid" },
                    { value: "failed", label: "Failed" }
                ],
            }
        ];

        return fields;

    },

    indexColumns: (routeName, params, users) => {

        const columns = [
            {
                label: "Order #",
                key: "this",
                format: "ModelLink",
                sort: "id",
            },
            {
                label: "Status",
                key: "status",
                format: "titleCase",
            },
            {
                label: "Customer",
                key: "owner",
                format: "ModelLink",
            },
            {
                label: "Host",
                key: "host",
                format: "ModelLink",
            },
            {
                label: "Product",
                key: "product",
                format: "ModelLink",
            },
            {
                label: "Total",
                key: "total",
                format: "number"
            },
            {
                label: "Discount",
                key: "discount",
                format: "number"
            },
            {
                label: "Amount Paid",
                key: "amountPaid",
                format: "number"
            },
            {
                label: "Date Paid",
                key: "datePaid",
                format: "dateTime",
                sort: "datePaid"
            },
            {
                label: "Created Date",
                key: "created",
                format: "OnAndBy",
                sort: "created.date",
                alignEnd: true
            },
            {
                label: "Actions",
                key: "actions",
                alignEnd: true,
                render: (value, data) => {

                    return <OrderActions data={data} />;

                }
            }
        ];

        return columns;

    },

    indexExportFormats: (routeName, params, user) => {

        const formats = [
            { value: "orders", label: "Orders" }
        ];

        return formats;

    },

    singleFetch: OrderApi.getOne,
    singleFetchParams: (routeName, params, users, fetch) => {

        const filters = { include: ['product', 'owner', 'formEntry', 'form', 'host', 'payment'] };

        return filters;

    },
    singleTitle: (routeName, params, users, fetch) => {

        let title: string = "Loading...";

        const complete = get(fetch, 'complete');
        const error = get(fetch, 'error');
        const data = get(fetch, 'data');

        if (error) title = "Error";
        else if (data) title = data[settings.primaryKey];
        else if (complete && !data) title = "Not Found";

        return title;

    },
    singleTabs: (routeName, params, users, fetch) => {

        const path = settings.path + "/" + params.id;

        return [
            {
                label: "View",
                path: path + "/view"
            },
            // {
            //     label: "Edit",
            //     path: path + "/edit"
            // },
        ]

    },
};