import React, { useMemo, useState } from 'react';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
// import LeagueSeasonDivisionStandings from './LeagueSeasonDivisionStandings';
// import LeagueSeasonDivisionGames from './LeagueSeasonDivisionGames';
import Button from '@dex/bubl-dash/src/lib/components/Button/Button';
import LeagueSeasonDivisionStandings from './LeagueSeasonDivisionStandings';
import LeagueSeasonDivisionBracket from './LeagueSeasonDivisionBracket';
import LeagueSeasonDivisionGames from './LeagueSeasonDivisionGames';

const LeagueSeasonDivisionTabs: React.FC<any> = (props: any) => {

    const { data } = props;

    const [tab, setTab] = useState(data.type === "bracket" ? "bracket" : "standings");

    return useMemo(() => (

        <Panel
            heading={"Manage Division"}
            activeTab={tab}
            setTab={setTab}
            full={true}
            tabs={[
                data.type === "bracket" ? { name: "bracket", label: "Bracket" } : { name: "standings", label: "Standings" },
                { name: "games", label: "Games" },
            ]}
        >

            {tab === "standings" &&
                <LeagueSeasonDivisionStandings data={data} />
            }

            {tab === "bracket" &&
                <LeagueSeasonDivisionBracket data={data} />
            }

            {tab === "games" &&
                <LeagueSeasonDivisionGames data={data} />
            }

        </Panel>

    ), [data, tab]);

}

export default LeagueSeasonDivisionTabs;
