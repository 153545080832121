import { apiGet, baseApi, options, response } from "@dex/bubl-fetch";

const endpoint = 'Hosts';

const HostsApi: any = baseApi(endpoint);

HostsApi.thisWeek = (options: options): response => {

    return apiGet({
        "url": endpoint + "/thisWeek",
        ...options
    });

}

export default HostsApi;