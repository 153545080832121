import React, { useMemo, useCallback, useState } from 'react';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form, useForm } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { useApp } from '@dex/bubl-dash';
import LeagueSeasonGameApi from '../LeagueSeasonGameApi';
import FieldNumber from '@dex/bubl-dash/src/lib/components/Fields/FieldNumber';
import InputTable, { InputTableRow, InputTableCell } from '@dex/bubl-dash/src/lib/components/InputTable/InputTable';
import { get } from '@dex/bubl-helpers';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';
import FieldSwitch from '@dex/bubl-dash/src/lib/components/Fields/FieldSwitch';
import Modal from '@dex/bubl-dash/src/lib/components/Modal/Modal';
import LeagueSeasonGameBoxScoresImport from './LeagueSeasonGameBoxScoresImport';
import { FieldHidden } from '@dex/bubl-dash';

const LeagueSeasonGameBoxScoresForm: React.FC<any> = (props: any) => {

    const { data, fields } = props;

    const [showImport, setShowImport] = useState(false);

    const app = useApp();

    if (!data.boxScore.length) {
        data.boxScore = data.teamIds.map((teamId) => {

            return { [teamId]: [] };

        });
    }

    const form = useForm({ ...data });

    const process = LeagueSeasonGameApi.update(data.id, "updateBoxScores", {
        onComplete: (data) => {

            app.alert('Box Scores Saved', 'success');

        }
    });

    const clear = LeagueSeasonGameApi.update(data.id, "clearBoxScores", {
        onComplete: (data) => {

            form.handleChange({ name: "boxScore", value: [{}, {}], reset: true });

            form.setKey();

            app.alert('Box Scores Cleared', 'success');

        }
    });

    const handleSubmit = useCallback((values) => {

        if (typeof values.boxScore[0] === "undefined") values.boxScore[0] = {};
        if (typeof values.boxScore[1] === "undefined") values.boxScore[1] = {};

        process.run({ data: values });

    }, [props]);

    const handleClear = useCallback(() => {

        clear.run({ data: {} });

    }, [props]);

    const columns = useMemo(() => {

        const columns: any = [
            {
                key: "jerseyNumber",
                label: "#",
                data: true,
                alignMiddle: true,
                style: { width: 26 }
            },
            {
                key: "name",
                label: "Player",
                data: true,
                style: { width: 100 }
            }
        ];

        fields.fields.boxScores.map((field) => {

            if (field.calc) return;
            if (field.requires) return; // also a calc field

            field.style = { width: 50 };

            columns.push(field);

        });

        return columns;

    }, [fields]);


    return useMemo(() => (

        <Panel
            heading={"Box Scores"}
            actions={(
                <ButtonRow>
                    {data.hasGameData &&
                        <Button
                            label={"Clear Box Scores"}
                            size="small"
                            type={"faded"}
                            onClick={handleClear}
                            confirm={{
                                title: "Clear Box Scores",
                                message: "Are you sure you want to clear box scores for this game?"
                            }}
                        />
                    }
                    <Button
                        label={"Import Box Scores"}
                        size="small"
                        type={"light"}
                        onClick={setShowImport.bind(null, true)}
                    />
                </ButtonRow>
            )}
        >

            {showImport &&
                <Modal
                    show={true}
                    onClose={setShowImport.bind(null, false)}
                    width={1180}
                    className={'modal-top'}
                >

                    <LeagueSeasonGameBoxScoresImport
                        data={data}
                        boxForm={form}
                        fields={fields}
                        columns={columns}
                        handleClose={setShowImport.bind(null, false)}
                    />

                </Modal>
            }

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                {fields.teams.map((team, teamIndex) => (

                    <React.Fragment key={team.id}>

                        <Seperator
                            top={teamIndex ? "auto" : ""}
                            bottom={"auto"}
                            heading={team.name}
                        />

                        <InputTable
                            columns={columns}
                            fixed={true}
                        >

                            {team.roster.map((player, playerIndex) => (

                                <InputTableRow key={player.id}>

                                    {!columns.length &&
                                        <FieldHidden
                                            form={form}
                                            name={"boxScore[" + teamIndex + "]"}
                                            defaultValue={{}}
                                        />
                                    }

                                    {columns.map(column => (

                                        <InputTableCell
                                            {...column}
                                            data={undefined}
                                        >

                                            {column.data &&
                                                <>{get(player, column.key, "")}</>
                                            }

                                            {!column.data &&
                                                <>

                                                    {column.type === "number" &&
                                                        <FieldNumber
                                                            form={form}
                                                            name={"boxScore[" + teamIndex + "][" + player.id + "][" + column.key + "]"}
                                                            defaultValue={get(form.defaults, "boxScore[" + teamIndex + "][" + player.id + "][" + column.key + "]")}
                                                            placeholder={column.placeholder}
                                                            prefix={column.prefix}
                                                            suffix={column.suffix}
                                                            disabled={get(form.defaults, "boxScore[" + teamIndex + "][" + player.id + "][dnp]") ? true : false}
                                                        />
                                                    }

                                                    {column.type === "switch" &&
                                                        <FieldSwitch
                                                            form={form}
                                                            name={"boxScore[" + teamIndex + "][" + player.id + "][" + column.key + "]"}
                                                            defaultValue={get(form.defaults, "boxScore[" + teamIndex + "][" + player.id + "][" + column.key + "]")}
                                                        />
                                                    }

                                                </>
                                            }

                                        </InputTableCell>

                                    ))}

                                </InputTableRow>

                            ))}

                        </InputTable>

                    </React.Fragment>

                ))}

                <Line />

                <ButtonRow>

                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />

                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />

                </ButtonRow>

            </Form>

        </Panel>

    ), [form, showImport, process.loading]);

}

export default LeagueSeasonGameBoxScoresForm;
