import React from 'react';
import { titleCase, get } from "@dex/bubl-helpers";
import CampPassesApi from './CampPassesApi';
import CampPassesActions from './Components/CampPassesActions';
import ModelLink from '@dex/bubl-dash/src/lib/components/ModelLink/ModelLink';

let settings;

export default settings = {
    key: 'camp/passes',
    zone: 'camp',
    path: '/camp/passes',
    title: "Camp Passes",
    endpoint: CampPassesApi.endpoint,
    modelName: 'CampPass',
    idKey: 'id',
    primaryKey: 'title',
    indexCaps: ["viewCampPass"],
    viewCaps: ["viewCampPass"],
    createCaps: ["createCampPass"],
    indexTitle: (routeName, params, users) => {

        let title: string = settings.title;

        if (params && params.name) title = title + " / " + titleCase(params.name);

        return title;

    },
    indexTabs: (routeName, params, users) => {

        return [
            {
                label: "All",
                path: settings.path,
                caps: settings.indexCaps
            },
            {
                label: "Active",
                path: settings.path + "/status/active",
                caps: settings.indexCaps
            },
            {
                label: "Expired",
                path: settings.path + "/status/expired",
                caps: settings.indexCaps
            },
            // {
            //     label: "Add New",
            //     path: settings.path + "/create"
            // },
        ]

    },
    indexButtons: (routeName, params, users) => {

        return [
            // {
            //     label: "Add New",
            //     path: settings.path + '/create',
            //     caps: settings.createCaps
            // }
        ]

    },
    indexTabCounts: (routeName, params, dontation) => {

        return settings.endpoint + "/getCounts";

    },
    indexFetch: CampPassesApi.get,
    indexFetchParams: (routeName, params, users) => {

        const filters: any = { where: {}, include: ['order', 'owner', 'operator', 'host', 'athlete'], order: ['created.date DESC'], limit: 10, skip: 0 };

        if (params && params.status) filters.where.status = { inq: [params.status] };

        return filters;

    },
    indexQuickSearch: (routeName, params, users) => {

        const fields = ['title', 'passType'];

        return fields;

    },
    indexSearchFields: (routeName, params, users) => {

        const fields = [

            {
                label: "Host",
                key: "hostId",
                type: "relationship",
                model: "Hosts",
            },
            {
                label: "Status",
                key: "status",
                type: "choice",
                options: [
                    { value: 'active', label: 'Active' },
                    { value: 'expired', label: 'Expired' },
                ]
            },
            {
                label: "Customer",
                key: "ownerId",
                type: "relationship",
                model: "Users",
            },
            {
                label: "Operator",
                key: "operatorId",
                type: "relationship",
                model: "CampOperator",
            },
        ];

        return fields;

    },

    indexColumns: (routeName, params, users) => {

        const columns = [
            {
                label: "",
                key: "this",
                // format: "ModelLink",
                render: (value, data) => {
                    return <ModelLink modelName="CampPass" data={{ id: data.id, title: "View Pass" }} />
                }
            },
            {
                label: "Athlete",
                key: "athlete",
                format: "ModelLink",
            },
            {
                label: "Customer",
                key: "owner",
                format: "ModelLink",
            },
            {
                label: "Status",
                key: "status",
                format: 'titleCase'
            },
            {
                label: "Host",
                key: "host",
                format: "ModelLink",
            },
            {
                label: "Operator",
                key: "operator",
                format: "ModelLink",
            },
            {
                label: "Type",
                key: "passType",
                format: "titleCase"
            },
            {
                label: "Session Or Days",
                key: "description",
            },
            {
                label: "Date Purchased",
                key: "order.datePaid",
                format: "dateTime"
            },
            {
                label: "Actions",
                key: "actions",
                alignEnd: true,
                render: (value, data) => {

                    return <CampPassesActions data={data} />;

                }
            }
        ];

        return columns;

    },

    indexExportFormats: (routeName, params, user) => {

        const formats = [
            { value: "campPasses", label: "Camp Passes" }
        ];

        return formats;

    },

    singleFetch: CampPassesApi.getOne,
    singleFetchParams: (routeName, params, users, fetch) => {

        const filters = {
            include: [
                'product', 'order', 'owner', 'operator', 'host', 'athlete',
                {
                    relation: 'sessions',
                    scope: { include: ['host', 'sessionGroup'] }
                }
            ]
        };

        return filters;

    },
    singleTitle: (routeName, params, users, fetch) => {

        let title: string = "Loading...";

        const complete = get(fetch, 'complete');
        const error = get(fetch, 'error');
        const data = get(fetch, 'data');

        if (error) title = "Error";
        else if (data) title = data[settings.primaryKey];
        else if (complete && !data) title = "Not Found";

        return title;

    },
    singleTabs: (routeName, params, users, fetch) => {

        const path = settings.path + "/" + params.id;

        return [
            {
                label: "View",
                path: path + "/view"
            },
            // {
            //     label: "Edit",
            //     path: path + "/edit"
            // },
        ]

    },
};