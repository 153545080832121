import WatchVideosIndex from './WatchVideosIndex';
import WatchVideosCreate from './WatchVideosCreate';
import WatchVideosSettings from './WatchVideosSettings';
import WatchVideosSingle from './WatchVideosSingle';
import WatchEventsSettings from '../WatchEvents/WatchEventsSettings';
import WatchCamerasSettings from '../WatchCameras/WatchCamerasSettings';
import WatchCategorySettings from '../WatchCategory/WatchCategorySettings';
import WatchTagSettings from '../WatchTag/WatchTagSettings';
import WatchAdsSettings from '../WatchAds/WatchAdsSettings';

export default function (app) {

    const settings = WatchVideosSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    app.addMenu({
        key: settings.key,
        zone: settings.zone,
        label: "Watch",
        priority: 14,
        items: [
            {
                label: "Videos",
                path: settings.path,
                caps: settings.indexCaps
            },
            {
                label: "Cameras",
                path: WatchCamerasSettings.path,
                caps: WatchCamerasSettings.indexCaps
            },
            {
                label: "Categories",
                path: WatchCategorySettings.path,
                caps: WatchCategorySettings.indexCaps
            },
            {
                label: "Tags",
                path: WatchTagSettings.path,
                caps: WatchTagSettings.indexCaps
            },
            {
                label: "Ads",
                path: WatchAdsSettings.path,
                caps: WatchAdsSettings.indexCaps
            }

        ],
    });

    app.addMenu({
        key: settings.key,
        zone: settings.zone,
        label: "Actions",
        priority: 14,
        items: [
            {
                label: "Add New Video",
                path: settings.path + '/create',
                caps: settings.createCaps
            },
            {
                label: "Add New Category",
                path: WatchCategorySettings.path + "/create",
                caps: WatchCategorySettings.createCaps
            },
            {
                label: "Add New Tag",
                path: WatchTagSettings.path + "/create",
                caps: WatchTagSettings.createCaps
            },
            {
                label: "Add New Ad",
                path: WatchAdsSettings.path + "/create",
                caps: WatchAdsSettings.createCaps
            }
        ],
    });

    WatchVideosIndex(app, settings);

    WatchVideosCreate(app, settings);

    WatchVideosSingle(app, settings);

}