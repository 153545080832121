import PickupPassesIndex from './PickupPassesIndex';
import PickupPassesCreate from './PickupPassesCreate';
import PickupPassesSettings from './PickupPassesSettings';
import PickupPassesSingle from './PickupPassesSingle';

export default function (app) {

    const settings = PickupPassesSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    // app.addMenu({
    //     key: settings.key,
    //     zone: settings.zone,
    //     label: settings.title,
    //     priority: 9,
    //     items: [
    //         {
    //             label: "All",
    //             path: settings.path,
    //             caps: settings.indexCaps
    //         },
    //         {
    //             label: "Active",
    //             path: settings.path + "/status/active",
    //             caps: settings.indexCaps
    //         },
    //         {
    //             label: "Expired",
    //             path: settings.path + "/status/expired",
    //             caps: settings.indexCaps
    //         }
    //     ],
    // });

    PickupPassesIndex(app, settings);

    PickupPassesCreate(app, settings);

    PickupPassesSingle(app, settings);

}