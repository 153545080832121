import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import { get, hash } from '@dex/bubl-helpers';
import FieldSwitch from '@dex/bubl-dash/src/lib/components/Fields/FieldSwitch';
import FieldUpload from '@dex/bubl-dash/src/lib/components/Fields/FieldUpload';
import FieldDate from '@dex/bubl-dash/src/lib/components/Fields/FieldDate';
import FieldTextArea from '@dex/bubl-dash/src/lib/components/Fields/FieldTextArea';
import { useApp } from '@dex/bubl-dash';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';
import FieldNumber from '@dex/bubl-dash/src/lib/components/Fields/FieldNumber';

const WatchVideosCreateEditForm: React.FC<any> = (props: any) => {

    const app = useApp();

    const manageGlobal = app.userCan(['manageGlobal'], false);

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <Panel
            heading={"Watch Videos Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ md: 16, xs: 24 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ md: 24, xs: 24 }}>

                                <FieldText
                                    form={form}
                                    label={"Title"}
                                    name='title'
                                    required={true}
                                    defaultValue={form.defaults.title}
                                />

                            </Col>

                            <Col col={{ md: 24, xs: 24 }}>

                                <FieldRadio
                                    form={form}
                                    label="Video Source"
                                    name={`video[source]`}
                                    required={true}
                                    defaultValue={get(form.defaults, 'video.source')}
                                    options={[
                                        { value: 'youtube', label: 'Youtube' },
                                        { value: 'facebook', label: 'Facebook' },
                                        { value: 'pixellot', label: 'Pixellot' },
                                        { value: 'external', label: 'External' },
                                    ]}
                                />

                            </Col>

                            {get(form.values, 'video.source') === "external" &&
                                <Col col={{ md: 24, xs: 24 }}>

                                    <FieldRadio
                                        form={form}
                                        label={"Video Stream Type"}
                                        name={`video[type]`}
                                        required={true}
                                        defaultValue={get(form.defaults, 'video.type')}
                                        options={[
                                            { value: "hls", label: "HLS" },
                                            { value: "mp4", label: "MP4" },
                                            { value: "dash", label: "Dash" },
                                            { value: "smooth", label: "Smooth" },
                                        ]}
                                    />

                                </Col>
                            }

                            <Col col={{ md: 24, xs: 24 }}>

                                <FieldText
                                    form={form}
                                    label={get(form.values, 'video.source') === "external" ? "Video External Link" : "Video Source ID"}
                                    name={`video[id]`}
                                    required={true}
                                    defaultValue={get(form.defaults, 'video.id')}
                                />

                            </Col>

                            <Col col={{ md: 24, xs: 24 }}>

                                <FieldTextArea
                                    form={form}
                                    label="Description"
                                    name="description"
                                    required={false}
                                    defaultValue={form.defaults.description}
                                    rows={4}
                                />

                            </Col>

                            <Col col={{ xs: 6 }}>

                                <FieldSwitch
                                    form={form}
                                    label="Private"
                                    name="private"
                                    required={false}
                                    defaultValue={form.defaults.private}
                                />

                            </Col>

                            {!form.values.private && manageGlobal &&
                                <Col col={{ xs: 6 }}>


                                    <FieldSwitch
                                        form={form}
                                        label="Global Feed"
                                        name="globalFeed"
                                        required={false}
                                        defaultValue={form.defaults.globalFeed}
                                    />

                                </Col>
                            }

                            <Col col={{ xs: 6 }}>

                                <FieldSwitch
                                    form={form}
                                    label="Has Game Log"
                                    name="hasGameLog"
                                    required={false}
                                    defaultValue={form.defaults.hasGameLog}
                                />

                            </Col>

                            {
                                !form.values.private && <Col col={{ xs: 6 }}>

                                    <FieldSwitch
                                        form={form}
                                        label="Enable PPV"
                                        name="enablePPV"
                                        required={false}
                                        defaultValue={form.defaults.enablePPV}
                                    />

                                </Col>

                            }

                            {
                                !form.values.private && form.values.enablePPV &&
                                <Col col={{ sm: 12, xs: 24 }}>

                                    <FieldRelationship
                                        form={form}
                                        label='PPV Product'
                                        name='ppvProductId'
                                        required={true}
                                        preload={true}
                                        isMulti={false}
                                        endpoint={'Product'}
                                        defaultValue={form.defaults.ppvProductId}
                                        where={{ type: 'payPerView' }}
                                    />

                                </Col>
                            }

                        </Row>

                    </Col>

                    <Col col={{ md: 8, xs: 24 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ md: 24, xs: 24 }}>

                                <FieldRadio
                                    form={form}
                                    label={"Type"}
                                    name='type'
                                    required={true}
                                    defaultValue={form.defaults.type}
                                    options={[
                                        { value: "live", label: "Live" },
                                        { value: "playback", label: "Playback" }
                                    ]}
                                />

                            </Col>

                            <Col col={{ md: 24, xs: 24 }}>

                                <FieldRadio
                                    key={form.values.type}
                                    form={form}
                                    label={"Status"}
                                    name='status'
                                    required={true}
                                    defaultValue={form.defaults.status}
                                    options={[
                                        { value: "draft", label: "Draft" },
                                        { value: "preview", label: "Preview" },
                                        { value: "publish", label: "Publish" },
                                        { value: "archive", label: "Archive" },
                                    ]}
                                />

                            </Col>

                            {form.values.status !== "draft" &&
                                <Col col={{ md: 24, xs: 24 }}>

                                    <FieldDate
                                        form={form}
                                        label={"Date"}
                                        name={`date`}
                                        required={true}
                                        time={true}
                                        zone={true}
                                        defaultValue={form.defaults.date}
                                    />

                                </Col>
                            }

                            {form.values.type === "live" &&
                                <Col col={{ md: 24, xs: 24 }}>

                                    <FieldNumber
                                        form={form}
                                        label={"Live Duration"}
                                        name={`liveDuration`}
                                        required={true}
                                        defaultValue={form.defaults.liveDuration}
                                        suffix={" Minutes"}
                                    />

                                </Col>
                            }

                            <Col col={{ md: 24, xs: 24 }}>

                                <FieldUpload
                                    form={form}
                                    folder={'admin-media'}
                                    label={"Thumbnail"}
                                    name={`thumbnail`}
                                    required={true}
                                    defaultValue={form.defaults.thumbnail}
                                    limit={1}
                                />

                            </Col>

                        </Row>

                    </Col>

                </Row>



                <Seperator
                    top={"auto"}
                    heading={"Tags"}
                    bottom={"auto"}
                />

                <Row gutter={8} edge={true}>

                    {manageGlobal &&
                        <Col col={{ xs: 24, sm: 12, md: 8 }}>

                            <FieldRelationship
                                form={form}
                                label='Host'
                                name='hostId'
                                required={true}
                                preload={true}
                                endpoint={'Hosts'}
                                defaultValue={form.defaults.hostId}
                            />

                        </Col>
                    }

                    <Col col={{ xs: 24, sm: 12, md: 8 }}>

                        <FieldRelationship
                            form={form}
                            label={"Sport"}
                            name='sportId'
                            required={true}
                            preload={true}
                            endpoint={'Sport'}
                            defaultValue={form.defaults.sportId}
                        />

                    </Col>

                    {!form.values.private &&
                        <Col col={{ xs: 24, sm: 12, md: 8 }}>

                            <FieldRelationship
                                form={form}
                                label='Categories'
                                name='categoryIds'
                                required={form.values.globalFeed}
                                preload={true}
                                isMulti={true}
                                endpoint={'WatchCategory'}
                                defaultValue={form.defaults.categoryIds}
                            />

                        </Col>
                    }

                    {!form.values.private && (!manageGlobal || form.values.hostId) &&
                        <Col col={{ xs: 24, sm: 12, md: 8 }}>

                            <FieldRelationship
                                form={form}
                                label='Tags'
                                name='tagIds'
                                required={true}
                                preload={true}
                                isMulti={true}
                                endpoint={'WatchTag'}
                                defaultValue={form.defaults.tagIds}
                                where={{ hostId: form.values.hostId }}
                            />

                        </Col>
                    }

                </Row>

                {form.values.hasGameLog &&
                    <>

                        <Seperator
                            top={"auto"}
                            heading={"Box Score"}
                            description={"Please Select League, Season, Teams & Game Log"}
                            bottom={"auto"}
                        />

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24, sm: 12 }}>

                                <FieldRelationship
                                    key={form.values.hostId}
                                    form={form}
                                    label='League'
                                    name='leagueId'
                                    required={true}
                                    preload={true}
                                    endpoint={'League'}
                                    defaultValue={form.defaults.leagueId}
                                    where={{ hostId: form.values.hostId }}
                                />

                            </Col>

                            {form.values.leagueId &&
                                <Col col={{ xs: 24, sm: 12 }}>

                                    <FieldRelationship
                                        key={form.values.leagueId}
                                        form={form}
                                        label='Season'
                                        name='seasonId'
                                        required={true}
                                        preload={true}
                                        endpoint={'LeagueSeason'}
                                        where={{ leagueId: form.values.leagueId }}
                                        defaultValue={form.defaults.seasonId}
                                    />

                                </Col>
                            }

                            {form.values.leagueId && form.values.seasonId &&
                                <Col col={{ md: 24, xs: 24 }}>

                                    <FieldRelationship
                                        key={form.values.seasonId}
                                        form={form}
                                        label='Teams'
                                        name='seasonTeamIds'
                                        required={true}
                                        preload={true}
                                        isMulti={true}
                                        endpoint={'LeagueSeasonTeam'}
                                        where={{ seasonId: form.values.seasonId }}
                                        defaultValue={form.defaults.seasonTeamIds}
                                    />

                                </Col>
                            }

                            {form.values.leagueId && form.values.seasonId && form.values.seasonTeamIds && form.values.seasonTeamIds.length >= 2 &&
                                <Col col={{ xs: 24 }}>

                                    <FieldRelationship
                                        key={hash(form.values.seasonTeamIds)}
                                        form={form}
                                        label='Game Log'
                                        name='gameId'
                                        required={true}
                                        preload={true}
                                        endpoint={'LeagueSeasonGame'}
                                        defaultValue={form.defaults.gameId}
                                        where={{ leagueId: form.values.leagueId, seasonId: form.values.seasonId, teamIds: { inq: form.values.seasonTeamIds } }}
                                    />

                                </Col>
                            }

                        </Row>

                    </>
                }

                <Line />

                <ButtonRow>

                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />

                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />

                </ButtonRow>

            </Form >

        </Panel >


    ), [props]);

}

export default WatchVideosCreateEditForm;
