import React, { useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship';
import FieldUpload from '@dex/bubl-dash/src/lib/components/Fields/FieldUpload';
import FieldHidden from '@dex/bubl-dash/src/lib/components/Fields/FieldHidden';
import LeaguePlayerValidateForm from './LeaguePlayerValidateForm';

const LeaguePlayerCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit, mode, onAttach } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    const [validate, setValidate] = useState(mode === "edit" ? false : true);

    return useMemo(() => (

        <Panel
            heading={props.title || "League Player Details"}
        >

            {validate &&
                <>
                    <LeaguePlayerValidateForm
                        form={form}
                        mode={mode}
                        onValidated={setValidate.bind(null, false)}
                        onAttach={onAttach}
                    />
                </>
            }

            {!validate &&
                <Form
                    form={form}
                    onSubmit={handleSubmit}
                    loading={process.loading}
                >

                    {mode === "attach" && form.defaults.seasonId &&
                        <FieldHidden
                            form={form}
                            name="seasonId"
                            defaultValue={form.defaults.seasonId}
                        />
                    }

                    {mode === "attach" && form.defaults.teamId &&
                        <FieldHidden
                            form={form}
                            name="teamId"
                            defaultValue={form.defaults.teamId}
                        />
                    }

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 16 }}>

                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24 }}>

                                    <FieldText
                                        form={form}
                                        name="name"
                                        label="Name"
                                        required={true}
                                        defaultValue={form.defaults.name}
                                    />

                                </Col>

                                <Col col={{ xs: 24 }}>

                                    <FieldText
                                        form={form}
                                        name="nickName"
                                        label="Nick Name"
                                        defaultValue={form.defaults.nickName}
                                    />

                                </Col>

                                <Col col={{ xs: 24 }}>

                                    <FieldText
                                        form={form}
                                        name="position"
                                        label="Position"
                                        defaultValue={form.defaults.position}
                                    />

                                </Col>

                                <Col col={{ xs: 24 }}>

                                    <FieldText
                                        form={form}
                                        name="city"
                                        label="City"
                                        required={false}
                                        defaultValue={form.defaults.city}
                                    />

                                </Col>

                            </Row>

                        </Col>

                        <Col col={{ xs: 24, md: 8 }}>

                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24 }}>

                                    <FieldText
                                        form={form}
                                        name="icNumber"
                                        label="IC Number"
                                        required={true}
                                        defaultValue={form.defaults.icNumber}
                                    />

                                </Col>

                                <Col col={{ xs: 24 }}>

                                    <FieldText
                                        form={form}
                                        name="jerseyNumber"
                                        label="Jersey Number"
                                        defaultValue={form.defaults.jerseyNumber}
                                    />

                                </Col>

                                {mode !== "attach" &&
                                    <Col col={{ xs: 24 }}>

                                        <FieldRelationship
                                            form={form}
                                            name="ownerId"
                                            label="Tied to User Account"
                                            defaultValue={form.defaults.ownerId}
                                            endpoint="Users"
                                        />

                                    </Col>
                                }

                                <Col col={{ xs: 24 }}>

                                    <FieldUpload
                                        form={form}
                                        name="photo"
                                        label="Photo"
                                        defaultValue={form.defaults.photo}
                                        folder="admin-media"
                                    />

                                </Col>

                            </Row>

                        </Col>

                    </Row>

                    <Line />

                    <ButtonRow>
                        <Button
                            label={"Submit"}
                            loading={process.loading}
                        />
                        <Button
                            label={"Reset"}
                            type={"faded"}
                            onClick={form.reset}
                        />
                    </ButtonRow>

                </Form>
            }

        </Panel>

    ), [props, validate]);

}

export default LeaguePlayerCreateEditForm;
