import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldDate from '@dex/bubl-dash/src/lib/components/Fields/FieldDate';
import FieldSelect from '@dex/bubl-dash/src/lib/components/Fields/FieldSelect';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';


const CampSessionCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <Panel
            heading={"Camp Session Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 24 }}>

                        <FieldText
                            form={form}
                            label={"Title"}
                            name='title'
                            required={true}
                            defaultValue={form.defaults.title}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldRelationship
                            form={form}
                            label={"Operator"}
                            name='operatorId'
                            required={true}
                            preload={true}
                            endpoint={'CampOperator'}
                            defaultValue={form.defaults.operatorId}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        {form.values.operatorId &&
                            <FieldRelationship
                                form={form}
                                label={"Session Group"}
                                name='sessionGroupId'
                                required={true}
                                preload={true}
                                endpoint={'CampSessionGroup'}
                                where={{ operatorId: form.values.operatorId }}
                                defaultValue={form.defaults.sessionGroupId}
                            />
                        }

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldText
                            form={form}
                            label={"Venue"}
                            name='venue'
                            required={true}
                            defaultValue={form.defaults.venue}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldDate
                            form={form}
                            label={"Date"}
                            name={`dateTime`}
                            required={true}
                            defaultValue={form.defaults.dateTime}
                            date={true}
                            zone={true}
                            time={true}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldSelect
                            form={form}
                            label={"Duration"}
                            name='duration'
                            required={true}
                            defaultValue={form.defaults.duration}
                            options={[
                                { value: 1, label: "1 Hour" },
                                { value: 1.5, label: "1 Hour 30 Minutes" },
                                { value: 2, label: "2 Hours" },
                                { value: 3, label: "2 Hours 30 Minutes" },
                                { value: 3, label: "3 Hours" },
                                { value: 3.5, label: "3 Hours 30 Minutes" },
                                { value: 4, label: "4 Hours" },
                                { value: 4.5, label: "4 Hours 30 Minutes" },
                                { value: 5, label: "5 Hours" },
                                { value: 5.5, label: "5 Hours 30 Minutes" },
                                { value: 6, label: "6 Hours" },
                                { value: 6.5, label: "6 Hours 30 Minutes" }
                            ]}
                        />

                    </Col>

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default CampSessionCreateEditForm;
