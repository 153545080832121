import React, { useMemo } from 'react';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';

const OrderFormEntry: React.FC<any> = (props: any) => {

    const { data } = props;

    const rows: any[] = []
    const formData = {};
    let n = 0;

    data.formEntry.data.map((data, key) => {

        n++;

        if (!data.key) data.key = n.toString();

        formData[data.key] = data.value;

        let format: any = undefined;

        rows.push({
            label: data.label,
            key: "formData." + data.key,
            format: format,
        });

        return rows

    });

    data.formData = formData;

    return useMemo(() => (

        <Panel heading={"Order Form Entry"}>

            <MetaTable
                data={data}
                columnA={rows}
                columnB={[
                    {
                        label: "Entry ID",
                        key: "formEntry",
                        format: "ModelLink"
                    },
                    {
                        label: "Form",
                        key: "form",
                        format: "ModelLink"
                    },
                    {
                        label: "Date Submitted",
                        key: "created",
                        format: "onAndBy",
                    }
                ]}
            />

        </Panel>

    ), [data]);

}

export default OrderFormEntry;