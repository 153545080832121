import IndexPage from '@dex/bubl-dash/src/lib/pages/IndexPage';
import LeagueSeasonDivisionCreateEditForm from './Components/LeagueSeasonDivisionCreateEditForm';
import LeagueSeasonDivisionApi from './LeagueSeasonDivisionApi';

export default function (app, settings) {

    app.addRoute({
        name: settings.key + "New",
        zone: settings.zone,
        path: settings.key + "/create",
        caps: settings.createCaps,
        component: IndexPage,
        componentProps: {
            header: {
                getTitle: () => "Add New",
                getButtons: settings.indexButtons,
                getTabs: settings.indexTabs,
                getTabCounts: settings.indexTabCounts,
            },
            create: {
                form: LeagueSeasonDivisionCreateEditForm,
                getCreate: LeagueSeasonDivisionApi.create,
                getClone: LeagueSeasonDivisionApi.getOne,
                defaults: {
                    pointsForWin: 3,
                    pointsForDraw: 1,
                    pointsForLoss: 0,
                    pointsForNc: 0,
                    noOfRounds: 3,
                    bracketRounds: [],
                    teamIds: []
                },
                successMessage: "Division Saved",
                successRouteName: settings.key + "View",
                idKey: settings.idKey
            }
        },
    });

}