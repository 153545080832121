import React, { useMemo, useState } from 'react';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import LeagueSeasonTeamPlayers from './LeagueSeasonTeamPlayers';
import LeagueSeasonTeamGames from './LeagueSeasonTeamGames';
import LeagueSeasonTeamLeaderboards from './LeagueSeasonTeamLeaderboards';
import LeagueSeasonTeamNews from './LeagueSeasonTeamNews';
import Button from '@dex/bubl-dash/src/lib/components/Button/Button';

const LeagueSeasonTeamTabs: React.FC<any> = (props: any) => {

    const { data } = props;

    const [tab, setTab] = useState("players");

    return useMemo(() => (

        <Panel
            heading={"Manage Team"}
            activeTab={tab}
            setTab={setTab}
            full={true}
            tabs={[
                { name: "players", label: "Players" },
                { name: "games", label: "Games" },
                { name: "leaderboards", label: "Leaderboards" },
                { name: "news", label: "News" },
            ]}
        // actions={() => (
        //     <Button
        //         type="faded"
        //         size="small"
        //         label={"Manage Season"}
        //         path={"/league/seasons/" + data.seasonId + "/view"}
        //     />
        // )}
        >

            {tab === "players" &&
                <LeagueSeasonTeamPlayers data={data} />
            }

            {tab === "games" &&
                <LeagueSeasonTeamGames data={data} />
            }

            {tab === "leaderboards" &&
                <LeagueSeasonTeamLeaderboards data={data} />
            }

            {tab === "news" &&
                <LeagueSeasonTeamNews data={data} />
            }

        </Panel>

    ), [data, tab]);

}

export default LeagueSeasonTeamTabs;
