import React, { useMemo } from 'react';
import { Row, Col, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ActivityPanel from '@dex/bubl-dash/src/lib/components/SinglePanels/ActivityPanel';
import LeagueSeasonDivisionTabs from './LeagueSeasonDivisionTabs';

const LeagueSeasonDivisionSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <Panel
                    heading={"League Division Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Season",
                                key: "season",
                                format: "ModelLink",
                            }
                        ]}
                        columnB={[
                            {
                                label: "Type",
                                key: "type",
                                format: "titleCase",
                            },
                        ]}
                        columnC={[
                            {
                                label: "Host",
                                key: "host",
                                format: "ModelLink",
                            }
                        ]}
                    />

                </Panel>

                <Space />

                <LeagueSeasonDivisionTabs
                    data={data}
                />


            </Col>

            <Col className={'side'}>

                <ActivityPanel parent={data} />

            </Col>

        </Row>

    ), [data]);

}

export default LeagueSeasonDivisionSingleView;
