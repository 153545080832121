import React, { useMemo } from 'react';
import Modal from '@dex/bubl-dash/src/lib/components/Modal/Modal';
import { useApp } from '@dex/bubl-dash';
import CreateView from '@dex/bubl-dash/src/lib/components/CreateView/CreateView';
import LeagueSeasonDivisionCreateEditForm from '../../LeagueSeasonDivision/Components/LeagueSeasonDivisionCreateEditForm';
import LeagueSeasonDivisionApi from '../../LeagueSeasonDivision/LeagueSeasonDivisionApi';

const LeagueSeasonAddNewDivision: React.FC<any> = (props: any) => {

    const { data, onClose } = props;

    const app = useApp();

    const user = app.store("currentUser");

    return useMemo(() => (

        <>

            <Modal
                show={true}
                onClose={onClose}
                width={1080}
            >

                <CreateView
                    params={{}}
                    user={user}
                    form={LeagueSeasonDivisionCreateEditForm}
                    getCreate={LeagueSeasonDivisionApi.create}
                    defaults={{
                        seasonId: data.id,
                        pointsForWin: 3,
                        pointsForDraw: 1,
                        pointsForLoss: 0,
                        pointsForNc: 0,
                        noOfRounds: 3,
                        bracketRounds: [],
                        teamIds: []
                    }}
                    successMessage={"Division Saved"}
                    successCallback={(data) => {
                        onClose()
                    }}
                    rowProps={{
                        gutter: null
                    }}
                    formProps={{
                        mode: "attach",
                        title: "Add New Division"
                    }}
                />

            </Modal>

        </>

    ), [data]);

}

export default LeagueSeasonAddNewDivision;
