import React, { useCallback, useEffect, useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldRepeater from '@dex/bubl-dash/src/lib/components/Fields/FieldRepeater';
import FieldNumber from '@dex/bubl-dash/src/lib/components/Fields/FieldNumber';
import FieldTextArea from '@dex/bubl-dash/src/lib/components/Fields/FieldTextArea'
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';
import AssessmentFormsApi from '../../AssessmentForms/AssessmentFormsApi';
import FieldDate from '@dex/bubl-dash/src/lib/components/Fields/FieldDate';


const AssessmentsCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit, mode } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    const app = useApp();

    const manageGlobal = app.userCan(['manageGlobal'], false);

    const assessment = AssessmentFormsApi.getOne(':id');

    const handleFormIdChange = useCallback(({ name, value }) => {

        form.handleChange({ name: "criteria", value: [] });

        assessment.run({
            url: assessment.options.url.replace(":id", value),
            onComplete: (data) => {

                form.handleChange({ name: "criteria", value: data.criteria });

            }
        });

    }, [form]);


    return useMemo(() => (

        <Panel
            heading={"Assessment Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldRelationship
                            form={form}
                            label='Host'
                            name='hostId'
                            required={true}
                            preload={true}
                            endpoint={'Hosts'}
                            disabled={form.defaults.hostId ? true : false}
                            defaultValue={form.defaults.hostId}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldRelationship
                            form={form}
                            label='Athlete'
                            name='athleteId'
                            required={true}
                            preload={true}
                            endpoint={'CampAthletes'}
                            disabled={form.defaults.athleteId ? true : false}
                            defaultValue={form.defaults.athleteId}
                        />

                    </Col>

                    {mode === "edit" &&
                        <Col col={{ xs: 24, md: 12 }}>

                            <FieldText
                                form={form}
                                label='Title'
                                name='title'
                                required={true}
                                defaultValue={form.defaults.title}
                            />

                        </Col>
                    }

                    {mode === "create" &&
                        <Col col={{ xs: 24, md: 12 }}>

                            <FieldRelationship
                                form={form}
                                label='Assessment Form'
                                name='formId'
                                required={true}
                                preload={true}
                                endpoint={'AssessmentForms'}
                                defaultValue={form.defaults.formId}
                                onChange={handleFormIdChange}
                            />

                        </Col>
                    }

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldRelationship
                            form={form}
                            label='Coach'
                            name='coachId'
                            required={false}
                            preload={true}
                            endpoint={'CampCoaches'}
                            defaultValue={form.defaults.coachId}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldDate
                            form={form}
                            label='Date'
                            name='date'
                            required={true}
                            defaultValue={form.defaults.date}
                        />

                    </Col>

                </Row>

                <>

                    <Seperator
                        heading={"Assesment Criteria"}
                        top="auto"
                        bottom="auto"
                    />

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24 }}>

                            <FieldRepeater
                                form={form}
                                name={'criteria'}
                                defaultValues={{}}
                                addButtonLabel={"Add Criteria"}
                            >

                                {
                                    Array.isArray(form.values.criteria) &&
                                    form.values.criteria.map((row, n) => (

                                        <React.Fragment key={form.key + "-" + n}>

                                            <Row gutter={8} edge={true}>

                                                <Col col={{ xs: 12, md: 12 }}>

                                                    <FieldText
                                                        form={form}
                                                        label={"Title"}
                                                        name={`criteria[${n}][title]`}
                                                        required={true}
                                                        defaultValue={row.title}
                                                    />

                                                </Col>

                                                <Col col={{ xs: 6, md: 6 }}>

                                                    <FieldNumber
                                                        form={form}
                                                        label={"Score (0 to 4)"}
                                                        name={`criteria[${n}][score]`}
                                                        required={true}
                                                        defaultValue={row.score}
                                                        min={0}
                                                        max={4}
                                                    />

                                                </Col>

                                                <Col col={{ xs: 24, md: 24 }}>

                                                    <FieldTextArea
                                                        form={form}
                                                        label={"Description"}
                                                        name={`criteria[${n}][description]`}
                                                        required={false}
                                                        defaultValue={row.description}
                                                    />

                                                </Col>

                                            </Row>

                                        </React.Fragment>

                                    ))}

                            </FieldRepeater>

                        </Col>

                    </Row>

                </>

                <Seperator
                    heading={"Summary"}
                    top="auto"
                    bottom="auto"
                />

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 24 }} >

                        <FieldTextArea
                            form={form}
                            label={"Notes"}
                            name='notes'
                            required={false}
                            defaultValue={form.defaults.notes}
                        />

                    </Col>

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props, form, assessment, process]);

}

export default AssessmentsCreateEditForm;
