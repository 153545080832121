import VenueBlockedDatesIndex from './VenueBlockedDatesIndex';
import VenueBlockedDatesCreate from './VenueBlockedDatesCreate';
import VenueBlockedDatesSettings from './VenueBlockedDatesSettings';
import VenueBlockedDatesSingle from './VenueBlockedDatesSingle';

export default function (app) {

    const settings = VenueBlockedDatesSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });


    VenueBlockedDatesIndex(app, settings);

    VenueBlockedDatesCreate(app, settings);

    VenueBlockedDatesSingle(app, settings);

}