import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ActivityPanel from '@dex/bubl-dash/src/lib/components/SinglePanels/ActivityPanel';

const LeagueSeasonPlayerSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <Panel
                    heading={"League Player Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Name",
                                key: "name",
                            },
                            {
                                label: "Nick Name",
                                key: "nickName",
                            },
                            {
                                label: "Position",
                                key: "position",
                            },
                            {
                                label: "City",
                                key: "city",
                            }
                        ]}
                        columnB={[
                            {
                                label: "IC Number",
                                key: "icNumber",
                            },
                            {
                                label: "Jersey Number",
                                key: "jerseyNumber",
                            },
                            {
                                label: "Owner",
                                key: "owner",
                                format: "ModelLink",
                            }
                        ]}
                        columnC={[
                            {
                                label: "Photo",
                                key: "photo",
                                format: "image",
                                filters: {
                                    width: 100,
                                }
                            },
                            {
                                label: "Created By",
                                key: "created",
                                format: "OnAndBy",
                            }
                        ]}
                    />

                </Panel>

            </Col>

            <Col className={'side'}>

                <ActivityPanel parent={data} />

            </Col>

        </Row>

    ), [data]);

}

export default LeagueSeasonPlayerSingleView;
