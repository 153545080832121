import React, { useMemo, useCallback, useEffect } from 'react';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';
import { useForm } from '@dex/bubl-dash/src/lib/functions/useForm';
import { Row, Col, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import DeletePanel from '@dex/bubl-dash/src/lib/components/SinglePanels/DeletePanel';
import LeagueSeasonGameApi from '../LeagueSeasonGameApi';
import LeagueSeasonGameCreateEditForm from './LeagueSeasonGameCreateEditForm';
import LeagueSeasonGameResultForm from './LeagueSeasonGameResultForm';
import LeagueSeasonGameBoxScoresForm from './LeagueSeasonGameBoxScoresForm';
import LeagueSeasonGameEditRecap from './LeagueSeasonGameEditRecap';

const LeagueSeasonGameSingleEdit: React.FC<any> = (props: any) => {

    const app = useApp();

    const { data, mode } = props;

    const form = useForm(data);

    const update = LeagueSeasonGameApi.update(data.id, "update", {
        onComplete: (data) => {

            app.alert('Game Saved', 'success');

        }
    });

    const fields = LeagueSeasonGameApi.getSportFields(data.id);

    const handleSubmit = useCallback((values) => {

        update.run({ data: values });

    }, [props]);

    useEffect(() => {

        fields.run();

    }, [data.teamIds]);

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <LeagueSeasonGameCreateEditForm
                    mode={"edit"}
                    form={form}
                    data={data}
                    handleSubmit={handleSubmit}
                    process={update}
                />


            </Col>

            <Col
                className={'side'}
            >

                <DeletePanel
                    data={data}
                />

            </Col>


            <Col
                col={{ xs: 24 }}
            >

                {fields.data &&
                    <>

                        <LeagueSeasonGameResultForm
                            data={data}
                            fields={fields.data}
                        />

                        <Space />

                        <LeagueSeasonGameBoxScoresForm
                            data={data}
                            fields={fields.data}
                        />

                    </>
                }

                <Space />

                <LeagueSeasonGameEditRecap
                    data={data}
                />

            </Col>

        </Row>

    ), [data, fields.data, form, update]);

}

export default LeagueSeasonGameSingleEdit;
