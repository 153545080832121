import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship';
import FieldRepeater from '@dex/bubl-dash/src/lib/components/Fields/FieldRepeater';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';


const StatsGroupCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <Panel
            heading={"Stats Group Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 24 }}>

                        <FieldText
                            form={form}
                            label="Group Name"
                            name="groupName"
                            required={true}
                            defaultValue={form.defaults.groupName}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 24 }}>

                        <FieldRelationship
                            form={form}
                            label={"Sport"}
                            name='sportId'
                            isMulti={false}
                            required={true}
                            preload={true}
                            endpoint={'Sport'}
                            defaultValue={form.defaults.sportId}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 24 }}>

                        <FieldRepeater
                            form={form}
                            name={'matrix'}
                            addButtonLabel={"Add Stat"}
                        >

                            {form.values.matrix.map((row, n) => (

                                <React.Fragment key={form.key + "-" + n}>

                                    <Row gutter={8} edge={true}>

                                        <Col col={{ xs: 24, md: 8 }}>

                                            <FieldText
                                                form={form}
                                                label="Stat Label"
                                                name={`matrix[${n}][label]`}
                                                required={true}
                                                defaultValue={form.defaults.matrix[n].label}
                                            />

                                        </Col>

                                        <Col col={{ xs: 24, md: 8 }}>

                                            <FieldText
                                                form={form}
                                                label="Data Key"
                                                name={`matrix[${n}][key]`}
                                                required={true}
                                                defaultValue={form.defaults.matrix[n].key}
                                            />

                                        </Col>

                                        <Col col={{ xs: 24, md: 8 }}>

                                            <FieldRadio
                                                form={form}
                                                label={"Type"}
                                                name={`matrix[${n}][type]`}
                                                required={true}
                                                defaultValue={form.defaults.matrix[n].type}
                                                options={[
                                                    { value: "number", label: "#" },
                                                    { value: "percentage", label: "%" },
                                                    { value: "ratio", label: "x/y" },
                                                ]}
                                            />

                                        </Col>

                                    </Row>

                                </React.Fragment>

                            ))}

                        </FieldRepeater>

                    </Col>

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default StatsGroupCreateEditForm;
