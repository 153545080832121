import React from 'react';
import { titleCase, get } from "@dex/bubl-helpers";
import AssessmentsApi from './AssessmentsApi';

let settings;

export default settings = {
    key: 'camp/assessments',
    zone: 'camp',
    path: '/camp/assessments',
    title: "Assessments",
    endpoint: AssessmentsApi.endpoint,
    modelName: 'Assessments',
    idKey: 'id',
    primaryKey: 'id',
    indexCaps: ["viewAssessments"],
    viewCaps: ["viewAssessments"],
    createCaps: ["createAssessments"],
    updateCaps: ["updateAssessments"],
    indexTitle: (routeName, params, users) => {

        let title: string = settings.title;

        if (params && params.name) title = title + " / " + titleCase(params.name);

        return title;

    },
    indexTabs: (routeName, params, users) => {

        return [
            {
                label: "All",
                path: settings.path,
                caps: settings.indexCaps
            },
            // {
            //     label: "Add New",
            //     path: settings.path + "/create",
            //     caps: settings.createCaps
            // },
        ]

    },
    indexButtons: (routeName, params, users) => {

        return [
            // {
            //     label: "Add New",
            //     path: settings.path + '/create',
            //     caps: settings.createCaps
            // }
        ]

    },
    indexTabCounts: (routeName, params, dontation) => {

        return settings.endpoint + "/getCounts";

    },
    indexFetch: AssessmentsApi.get,
    indexFetchParams: (routeName, params, users) => {

        const filters: any = { where: {}, include: ["host", "form", "coach", "athlete"], order: ['created.date DESC'], limit: 10, skip: 0 };

        if (params && params.status) filters.where.status = { inq: [params.status] };

        return filters;

    },
    indexQuickSearch: (routeName, params, users) => {

        const fields = ['id', 'title'];

        return fields;

    },
    indexSearchFields: (routeName, params, users) => {

        const fields = [
            {
                label: "Combined Score",
                key: "combinedScore",
                type: "text",
            },
            {
                label: "Host",
                key: "hostId",
                type: "relationship",
                model: "Hosts",
            },
            {
                label: "Assessment Forms",
                key: "formId",
                type: "relationship",
                model: "AssessmentForms",
            },
            {
                label: "Camp Athletes",
                key: "athleteId",
                type: "relationship",
                model: "CampAthletes",
            },
        ];

        return fields;

    },

    indexColumns: (routeName, params, users) => {

        const columns = [
            {
                label: "Assessment #",
                key: "this",
                format: "ModelLink",
            },
            {
                label: "Title",
                key: "title",
            },
            {
                label: "Athlete",
                key: "athlete",
                format: "ModelLink",
            },
            {
                label: "Host",
                key: "host",
                format: "ModelLink",
            },
            {
                label: "Assessment Form",
                key: "form",
                format: "ModelLink",
            },
            {
                label: "Combined Score",
                key: "combinedScore"
            },
            {
                label: "Coach",
                key: "coach",
                format: "ModelLink",
            },
            {
                label: "Created Date",
                key: "created",
                format: "OnAndBy",
                sort: "created.date",
                alignEnd: true
            },
            {
                label: "Actions",
                key: "actions",
                alignEnd: true,
                format: "actions",
                api: AssessmentsApi,
                settings: settings,
            }
        ];

        return columns;

    },
    singleFetch: AssessmentsApi.getOne,
    singleFetchParams: (routeName, params, users, fetch) => {

        const filters = { include: ["host", "form", "coach", "athlete"] };

        return filters;

    },
    singleTitle: (routeName, params, users, fetch) => {

        let title: string = "Loading...";

        const complete = get(fetch, 'complete');
        const error = get(fetch, 'error');
        const data = get(fetch, 'data');

        if (error) title = "Error";
        else if (data) title = data[settings.primaryKey];
        else if (complete && !data) title = "Not Found";

        return title;

    },
    singleTabs: (routeName, params, users, fetch) => {

        const path = settings.path + "/" + params.id;

        return [
            {
                label: "View",
                path: path + "/view"
            },
            {
                label: "Edit",
                path: path + "/edit",
                caps: settings.updateCaps
            },
        ]

    },
};