import React, { useMemo } from 'react';
import { Row, Col, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import OrderPaymentView from './OrderPayment/OrderPaymentView'
import ActivityPanel from '@dex/bubl-dash/src/lib/components/SinglePanels/ActivityPanel';
import OrderPaymentForm from './OrderPayment/OrderPaymentForm';
import OrderFormEntryView from './OrderFormEntry/OrderFormEntryView';

const OrderSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Order Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Customer",
                                key: "owner",
                                format: "ModelLink",
                            },
                            {
                                label: "Product",
                                key: "product",
                                format: "ModelLink",
                            },
                            {
                                label: "Description",
                                key: "product.description",
                            },
                        ]}
                        columnB={[
                            {
                                label: "Status",
                                key: "status",
                                format: "titleCase",
                            },
                            {
                                label: "Created At",
                                key: "created.date",
                                format: "dateTime",
                            },
                            {
                                label: "Total",
                                key: "total",
                                format: "number",
                            },
                        ]}
                        columnC={[
                            {
                                label: "Host",
                                key: "host",
                                format: "ModelLink",
                            },
                        ]}
                    />

                </Panel>

                {(data.status === 'paid' && data.paymentId) &&
                    <>
                        <Space />

                        <OrderPaymentView
                            data={data}
                        />

                    </>
                }

                {(data.status === 'cart' || data.status === 'due' || data.status === 'failed') &&
                    <>
                        <Space />

                        <OrderPaymentForm
                            data={data}
                        />

                    </>
                }

                {(data.formStatus === 'complete' && data.formEntryId && data.formEntry) &&
                    <>
                        <Space />

                        <OrderFormEntryView
                            data={data}
                        />

                    </>
                }

            </Col>

            <Col className={'side'}>

                <ActivityPanel parent={data} />

            </Col>

        </Row>

    ), [data]);

}

export default OrderSingleView;
