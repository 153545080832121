import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { FieldNumber } from '@dex/bubl-dash';
import { FieldRadio } from '@dex/bubl-dash';
import { FieldRelationship } from '@dex/bubl-dash';
import { FieldText } from '@dex/bubl-dash';


const WatchAdsCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <Panel
            heading={"Watch Ads Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ md: 24, xs: 24 }}>

                        <FieldText
                            form={form}
                            label={"Title"}
                            name='title'
                            required={true}
                            defaultValue={form.defaults.title}
                        />

                    </Col>

                    <Col col={{ md: 24, xs: 24 }}>

                        <FieldText
                            form={form}
                            label={"Ad Tag"}
                            name='adTag'
                            required={true}
                            defaultValue={form.defaults.adTag}
                        />

                    </Col>


                    <Col col={{ md: 16, xs: 24 }}>

                        <FieldRadio
                            form={form}
                            label={"Position"}
                            name='position'
                            required={true}
                            defaultValue={form.defaults.position}
                            options={[
                                { value: "pre", label: "Pre" },
                                { value: "post", label: "Post" },
                                { value: "custom", label: "Custom" },
                            ]}
                        />

                    </Col>

                    {form.values.position === "custom" &&
                        <Col col={{ md: 8, xs: 24 }}>

                            <FieldNumber
                                form={form}
                                label={"Position Custom Time"}
                                name='positionTime'
                                required={true}
                                defaultValue={form.defaults.positionTime}
                                suffix={" Seconds"}
                            />

                        </Col>
                    }

                    <Col col={{ md: 16, xs: 24 }}>

                        <FieldRadio
                            form={form}
                            label={"Attach By"}
                            name='attachBy'
                            required={true}
                            defaultValue={form.defaults.attachBy}
                            options={[
                                { value: "all", label: "All" },
                                { value: "sport", label: "Sport" },
                                { value: "host", label: "Host" },
                                { value: "league", label: "League" },
                                { value: "category", label: "Category" },
                            ]}
                        />

                    </Col>


                    <Col col={{ md: 8, xs: 24 }}>

                        {form.values.attachBy === "host" &&
                            <FieldRelationship
                                form={form}
                                label='Host'
                                name='hostId'
                                required={true}
                                preload={true}
                                endpoint={'Hosts'}
                                defaultValue={form.defaults.hostId}
                            />
                        }

                        {form.values.attachBy === "league" &&
                            <FieldRelationship
                                form={form}
                                label='League'
                                name='leagueId'
                                required={true}
                                preload={true}
                                endpoint={'League'}
                                defaultValue={form.defaults.leagueId}
                            />
                        }

                        {form.values.attachBy === "category" &&
                            <FieldRelationship
                                form={form}
                                label='Watch Category'
                                name='categoryId'
                                required={true}
                                preload={true}
                                endpoint={'WatchCategory'}
                                defaultValue={form.defaults.categoryId}
                            />
                        }

                        {form.values.attachBy === "sport" &&
                            <FieldRelationship
                                form={form}
                                label='Sport'
                                name='sportId'
                                required={true}
                                preload={true}
                                endpoint={'Sport'}
                                defaultValue={form.defaults.sportId}
                            />
                        }

                    </Col>

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default WatchAdsCreateEditForm;
