import { useApp } from '@dex/bubl-dash';
import usePortal from 'react-useportal';
import React from 'react';
import { FiExternalLink } from 'react-icons/fi';

import styles from "./WebLink.module.scss";

const WebLink: React.FC<WebLinkProps> = (props) => {

    let { prefix, path, label } = props;

    const app = useApp();

    const { Portal } = usePortal();

    if (prefix === undefined) {

        if (process.env.REACT_APP_WEB_LINK) {

            prefix = process.env.REACT_APP_WEB_LINK;

        } else if (app.options.endpoint) {

            prefix = app.options.endpoint; // https://api.stg.huddlehuddle.co

            prefix = prefix.replace("api.stg.", "stg.");
            prefix = prefix.replace("v2api.", "www.");
            prefix = prefix.replace(":3000/api", ":3004");
            prefix = prefix.replace("/api", "");

        }

    };

    return (

        <>

            {path &&
                <Portal>
                    <a
                        title={label || 'View On Web'}
                        className={styles.link}
                        href={(prefix || "") + path}
                        target='_blank'
                    >
                        <FiExternalLink className={styles.icon} /> {label || "View"}
                    </a>
                </Portal>

            }

        </>

    )

}

interface WebLinkProps {
    [key: string]: any,
}

export default WebLink;