import IndexPage from '@dex/bubl-dash/src/lib/pages/IndexPage';
import HostRequestsCreateEditForm from './Components/HostRequestsCreateEditForm';
import HostRequestsApi from './HostRequestsApi';
import { get } from '@dex/bubl-helpers';

export default function (app, settings) {

    app.addRoute({
        name: settings.key + "New",
        zone: settings.zone,
        path: settings.key + "/create",
        caps: settings.createCaps,
        component: IndexPage,
        componentProps: {
            header: {
                getTitle: () => "Add New",
                getButtons: settings.indexButtons,
                getTabs: settings.indexTabs,
                getTabCounts: settings.indexTabCounts,
            },
            create: {
                form: HostRequestsCreateEditForm,
                getCreate: HostRequestsApi.create,
                defaults: (app, nav) => {

                    const defaults: any = {};

                    const params = get(nav, 'currentRoute.state.params', {});

                    if (params.propertyType) defaults.propertyType = params.propertyType;

                    if (params.propertyId) defaults.propertyId = params.propertyId;

                    defaults.subject = "Reqest Go Live";

                    if (params.propertyName) defaults.subject += " For " + params.propertyName;

                    if (params.hostId) defaults.hostId = params.hostId;

                    return defaults;

                },
                successMessage: "Host Requests Saved",
                successRouteName: settings.key + "View",
                idKey: settings.idKey
            }
        },
    });

}