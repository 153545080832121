import React from 'react';
import { titleCase, get } from "@dex/bubl-helpers";
import ShopPayoutApi from './ShopPayoutApi';

let settings;

export default settings = {
    key: 'shop/payout',
    zone: 'shop',
    path: '/shop/payout',
    title: "Payout",
    endpoint: ShopPayoutApi.endpoint,
    modelName: 'ShopPayout',
    idKey: 'id',
    primaryKey: 'id',
    actions: ['trash', 'clone'],
    indexCaps: ["viewShopPayout"],
    viewCaps: ["viewShopPayout"],
    createCaps: ["createShopPayout"],
    updateCaps: ["updateShopPayout"],
    indexTitle: (routeName, params, users) => {

        let title: string = settings.title;

        if (params && params.name) title = title + " / " + titleCase(params.name);

        return title;

    },
    indexTabs: (routeName, params, users) => {

        return [
            {
                label: "All",
                path: settings.path,
                caps: settings.indexCaps
            },
            {
                label: "Add New",
                path: settings.path + "/create",
                caps: settings.createCaps
            },
        ]

    },
    indexButtons: (routeName, params, users) => {

        return [
            {
                label: "Add New",
                path: settings.path + '/create',
                caps: settings.createCaps
            }
        ]

    },
    indexTabCounts: (routeName, params, dontation) => {

        return settings.endpoint + "/getCounts";

    },
    indexFetch: ShopPayoutApi.get,
    indexFetchParams: (routeName, params, users) => {

        const filters: any = { where: {}, include: ['host'], order: ['created.date DESC'], limit: 10, skip: 0 };

        if (params && params.status) filters.where.status = { inq: [params.status] };

        return filters;

    },
    indexQuickSearch: (routeName, params, users) => {

        const fields = ['id', 'status'];

        return fields;

    },
    indexSearchFields: (routeName, params, users) => {

        const fields = [
            {
                label: "ID",
                key: "id",
                type: "text",
            },
            {
                label: "Status",
                key: "status",
                type: "choice",
                options: [
                    {
                        label: "Review", value: "review"
                    },
                    {
                        label: "Approved", value: "approved"
                    },
                    {
                        label: "Rejected", value: "rejected"
                    },
                    {
                        label: "Paid", value: "paid"
                    },
                ],
            },
            {
                label: "Host",
                key: "hostId",
                type: "relationship",
                model: "Hosts"
            },
            {
                label: "Period",
                key: "period",
                type: "text",
            },
        ];

        return fields;

    },

    indexColumns: (routeName, params, users) => {

        const columns = [
            {
                label: "ID",
                key: "this",
                format: "ModelLink",
            },
            {
                label: "Status",
                key: "status",
                type: 'text',
                format: "titleCase",
            },
            {
                label: "Host",
                key: "host",
                format: "ModelLink",
            },
            {
                label: "Period",
                key: "period",
                type: 'text',
            },
            {
                label: "Total Payout",
                key: "totalPayout",
            },
            {
                label: "Created Date",
                key: "created",
                format: "OnAndBy",
                sort: "created.date",
                alignEnd: true
            },
            {
                label: "Actions",
                key: "actions",
                alignEnd: true,
                format: "actions",
                api: ShopPayoutApi,
                settings: settings,
            }
        ];

        return columns;

    },
    singleFetch: ShopPayoutApi.getOne,
    singleFetchParams: (routeName, params, users, fetch) => {

        const filters = { include: ['host', 'orders'] };

        return filters;

    },
    singleTitle: (routeName, params, users, fetch) => {

        let title: string = "Loading...";

        const complete = get(fetch, 'complete');
        const error = get(fetch, 'error');
        const data = get(fetch, 'data');

        if (error) title = "Error";
        else if (data) title = data[settings.primaryKey];
        else if (complete && !data) title = "Not Found";

        return title;

    },
    singleTabs: (routeName, params, users, fetch) => {

        const path = settings.path + "/" + params.id;

        return [
            {
                label: "View",
                path: path + "/view"
            },
        ]

    },
};