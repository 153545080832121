import React, { useCallback } from "react";
import Panel from "@dex/bubl-dash/src/lib/components/Panel/Panel";
import { Col, Space } from "@dex/bubl-dash/src/lib/components/Layout/Layout";
import FieldRelationship from "@dex/bubl-dash/src/lib/components/Fields/FieldRelationship";
import FieldSwitch from "@dex/bubl-dash/src/lib/components/Fields/FieldSwitch";
import Button from "@dex/bubl-dash/src/lib/components/Button/Button";
import { FieldSelect, Form, useApp, useForm } from "@dex/bubl-dash";
import VenueCourtApi from "../VenueCourtApi";

function VenueCourtVideoSettingsPanel(props) {

    const { data } = props;

    const app = useApp();

    const manageGlobal = app.userCan(["manageGlobal"], false);

    const form = useForm(data);

    const update = VenueCourtApi.update(data.id, "updateVideoSettings", {
        onComplete: (data) => {
            app.alert("Court Updated", "success");
        },
    });

    const handleSubmit = useCallback((values) => {

        update.run({ data: values });

    }, [props]);

    return (
        <>

            <Panel heading={"Video Settings"}>

                <Form
                    form={form}
                    onSubmit={handleSubmit}
                    loading={update.loading}
                >

                    <Col col={{ md: 24, xs: 24 }}>
                        <FieldSwitch
                            form={form}
                            label={"Has Video"}
                            name="hasVideo"
                            required={false}
                            defaultValue={form.defaults.hasVideo}
                        />
                    </Col>

                    {form.values.hasVideo && manageGlobal && (
                        <Col col={{ md: 24, xs: 24 }}>
                            <FieldSelect
                                form={form}
                                label={"Pixellot Production Type"}
                                name={`pixellotProductionType`}
                                required={true}
                                defaultValue={form.defaults.pixellotProductionType}
                                options={[
                                    { value: "none", label: "none" },
                                    { value: "basketball", label: "basketball" },
                                    { value: "basketballTactical", label: "basketballTactical" },
                                    { value: "soccer", label: "soccer" },
                                    { value: "rugby", label: "rugby" },
                                    { value: "handball", label: "handball" },
                                    { value: "handballTactical", label: "handballTactical" },
                                    { value: "icehockey", label: "icehockey" },
                                    { value: "fieldhockey", label: "fieldhockey" },
                                    { value: "futsal", label: "futsal" },
                                    { value: "futsalTactical", label: "futsalTactical" },
                                    { value: "americanFootball", label: "americanFootball" },
                                    { value: "rollerhockey", label: "rollerhockey" },
                                    { value: "rollerhockeyTactical", label: "rollerhockeyTactical", },
                                    { value: "wrestling", label: "wrestling" },
                                    { value: "volleyball", label: "volleyball" },
                                    { value: "gymnastics", label: "gymnastics" },
                                    { value: "soccerTactical", label: "soccerTactical" },
                                    { value: "other", label: "other" },
                                    { value: "leftside", label: "leftside" },
                                    { value: "center", label: "center" },
                                    { value: "rightside", label: "rightside" },
                                    { value: "lacrosse", label: "lacrosse" },
                                    { value: "baseball", label: "baseball" },
                                    { value: "beachvolleyball", label: "beachvolleyball" },
                                    { value: "snooker", label: "snooker" },
                                    { value: "softball", label: "softball" },
                                    { value: "floorball", label: "floorball" },
                                ]}
                            />
                        </Col>
                    )}

                    {form.values.hasVideo && manageGlobal && (
                        <Col col={{ md: 24, xs: 24 }}>
                            <FieldRelationship
                                form={form}
                                label={"Pixellot Camera"}
                                name={`cameraId`}
                                required={true}
                                defaultValue={form.defaults.cameraId}
                                preload={true}
                                endpoint={"Pixellot/venues"}
                            />
                        </Col>
                    )}

                    {form.values.hasVideo && (
                        <Col col={{ md: 24, xs: 24 }}>
                            <FieldRelationship
                                form={form}
                                label={"Video Product"}
                                name="videoProductId"
                                required={true}
                                preload={true}
                                endpoint={"Product"}
                                defaultValue={form.defaults.videoProductId}
                                where={{ type: "videoPlayback" }}
                            />
                        </Col>
                    )}

                    <Space />

                    <Button
                        label={"Submit"}
                        loading={update.loading}
                    />

                </Form>

            </Panel>

        </>

    );

}

export default VenueCourtVideoSettingsPanel;
