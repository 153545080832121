import React, { useMemo, useState, useEffect } from 'react';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import DataTable from '@dex/bubl-dash/src/lib/components/DataTable/DataTable';
import { Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import UsersApi from '@dex/bubl-dash/src/lib/views/Users/UsersApi';
import { get } from '@dex/bubl-helpers';

const HostsSingleUsers: React.FC<any> = (props: any) => {

    const { data } = props;

    const users = UsersApi.get({ params: { limit: 100, where: { hostId: data.id } } });

    const columns = [
        {
            label: "Photo",
            key: "profilePhoto.fileId",
            format: "image",
            filters: { width: 40, height: 40 },
            link: false,
            style: { width: 60 }
        },
        {
            label: 'Name',
            key: 'this',
            format: "modelLink"
        },
        {
            label: 'Email Address',
            key: 'email'
        },
        {
            label: 'Roles',
            key: 'roles',
            format: "titleCase"
        }
    ];

    useEffect(() => {

        users.run();

    }, [])

    return useMemo(() => (

        <Panel
            heading={`${get(users, 'data.count.total')} Host User(s)`}
            full={true}
        >

            <Space />

            <DataTable
                data={get(users, 'data.data')}
                columns={columns}
                loading={users.loading}
            />

        </Panel>

    ), [data, users]);

}

export default HostsSingleUsers;
