import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import DataTable from '@dex/bubl-dash/src/lib/components/DataTable/DataTable';
import { Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { useForm } from '@dex/bubl-dash/src/lib/functions/useForm';
import CampSessionApi from '../../CampSessionApi';
import CampSessionAttendeesMark from './CampSessionAttendeesMark';
import ModelLink from '@dex/bubl-dash/src/lib/components/ModelLink/ModelLink';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import Modal from '@dex/bubl-dash/src/lib/components/Modal/Modal';
import CampSessionAttendeesAssessment from './CampSessionAttendeesAssessment';

const CampSessionAttendeesView: React.FC<any> = (props: any) => {

    const { data } = props;

    const [key, setKey] = useState(0);

    const [showCancelled, setShowCancelled] = useState(data.status === "cancelled");

    const [addAssessment, setAddAssessment] = useState<any>(null);

    const form = useForm({});

    const attendance = CampSessionApi.getAttendance(data.id);

    const attending = CampSessionApi.updateAttending(':id');

    useEffect(() => {

        const params = attendance.options.params;

        params.where.cancelled = showCancelled;

        attendance.run({
            params: params
        });

    }, [showCancelled, key]);

    const updateAttending = useCallback((id, cancelled) => {

        attending.run({
            url: attending.options.url.replace(":id", id),
            data: { cancelled: cancelled },
            onComplete: () => {

                setKey(key + 1);

            }
        });

    }, [key]);

    const closeAndRefresh = useCallback(() => {

        setAddAssessment(null);
        setKey(key + 1);

    }, [key]);

    const columns: any = [
        showCancelled ? null : {
            label: "Attended",
            key: "attended",
            style: { width: 120 },
            render: (value, row) => (

                <CampSessionAttendeesMark
                    form={form}
                    attendee={row}
                />

            )
        },
        {
            label: 'Athelete',
            key: 'athlete',
            render: (value, row) => (
                <>
                    {row.athlete &&
                        <>
                            <ModelLink data={row.athlete} modelName='CampAthletes' />
                            {row.athlete.email && <div>{row.athlete.email}</div>}
                            {row.athlete.phone && <div>{row.athlete.phone}</div>}
                        </>
                    }
                </>
            )
        },
        {
            label: 'Gaurdian',
            key: 'owner',
            render: (value, row) => (
                <>
                    {row.owner &&
                        <>
                            {row.owner.fullName}
                            {row.owner.email && <div>{row.owner.email}</div>}
                            {row.owner.phone && <div>{row.owner.phone}</div>}
                        </>
                    }
                </>
            )
        },
        {
            label: showCancelled ? 'Cancelled On' : 'Joined On',
            key: showCancelled ? 'cancelledOn' : 'joinedOn',
            format: 'OnAndBy',
        },
        showCancelled ? null : {
            label: 'Assessment',
            key: 'assessment',
            format: 'ModelLink',
            style: { width: 200 },
            render: (value, row) => (
                <>
                    {row.assessmentId &&
                        <ModelLink modelName="Assessments" data={{ id: row.assessmentId }} />
                    }
                    {!row.assessmentId &&
                        <Button
                            label="Add Assessment"
                            type="inline"
                            size="small"
                            onClick={setAddAssessment.bind(null, row)}
                        />
                    }
                </>
            )
        },
        {
            label: 'Actions',
            key: 'actions',
            alignEnd: true,
            style: { width: 100 },
            render: (value, row) => (
                <>
                    {!row.cancelled &&
                        <Button
                            label="Cancel"
                            type="inline"
                            size="small"
                            confirm={{
                                title: `Mark As Cancelled`,
                                message: "Are your sure you want to perform this action?"
                            }}
                            onClick={updateAttending.bind(null, row.id, true)}
                        />
                    }
                    {row.cancelled && data.status !== "cancelled" &&
                        <Button
                            label="Attending"
                            type="inline"
                            size="small"
                            confirm={{
                                title: `Mark As Attending`,
                                message: "Are your sure you want to perform this action?"
                            }}
                            onClick={updateAttending.bind(null, row.id, false)}
                        />
                    }
                </>
            )
        }
    ].filter(Boolean);

    return useMemo(() => (

        <>

            {addAssessment &&
                <Modal
                    show={true}
                    onClose={setAddAssessment.bind(null, null)}
                    width={1180}
                >

                    <CampSessionAttendeesAssessment
                        owner={addAssessment.owner}
                        athlete={addAssessment.athlete}
                        attendanceId={addAssessment.id}
                        session={data}
                        onComplete={closeAndRefresh}
                    />

                </Modal>
            }

            <Panel
                heading={`${attendance.loading ? "Loading" : (attendance.data && attendance.data.data) ? attendance.data.data.length : "0"} ` + (showCancelled ? 'Cancelled' : 'Attendee(s)')}
                full={true}
                actions={() => (
                    <>
                        {!showCancelled &&
                            <Button
                                label="Show Cancelled"
                                type="faded"
                                size="small"
                                onClick={setShowCancelled.bind(null, true)}
                            />
                        }
                        {showCancelled && data.status !== "cancelled" &&
                            <Button
                                label="Hide Cancelled"
                                type="secondary"
                                size="small"
                                onClick={setShowCancelled.bind(null, false)}
                            />
                        }
                    </>
                )}
            >

                <Space />

                <DataTable
                    data={attendance.data && attendance.data.data ? attendance.data.data : []}
                    columns={columns}
                    loading={attendance.loading}
                />

            </Panel>

        </>

    ), [data, attendance.data, showCancelled, addAssessment]);

}

export default CampSessionAttendeesView;
