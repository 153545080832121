import React, { useMemo } from 'react';
import { Row, Col, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ActivityPanel from '@dex/bubl-dash/src/lib/components/SinglePanels/ActivityPanel';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';
import RenderContentBlocks from '../../../components/RenderContentBlocks/RenderContentBlocks';

const HelpSingleView: React.FC<any> = (props: any) => {

    const app = useApp();

    const manageGlobal = app.userCan(['manageGlobal']);

    const { data } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                {manageGlobal &&

                    <>

                        <Panel
                            heading={"Help Details"}
                        >

                            <MetaTable
                                data={data}
                                columnA={[
                                    {
                                        label: 'Title',
                                        key: 'title'
                                    },
                                ]}
                                columnB={[
                                    {
                                        label: 'Topic',
                                        key: 'topic',
                                        format: 'modelLink'
                                    },
                                ]}
                                columnC={[
                                    {
                                        label: "Status",
                                        key: "status",
                                        format: "titleCase",
                                    }
                                ]}
                            />

                        </Panel>

                        <Space />

                    </>
                }

                <Panel
                    heading={data.title}
                >

                    <RenderContentBlocks
                        blocks={data.contentBlocks}
                    />

                </Panel>

            </Col>

            <Col className={'side'}>

                {manageGlobal &&
                    <ActivityPanel parent={data} />
                }

            </Col>

        </Row>

    ), [data]);

}

export default HelpSingleView;
