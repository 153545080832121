import { baseApi, response, options, apiPatch } from "@dex/bubl-fetch";

const endpoint = 'PushNotifications';

const PushNotificationApi: any = baseApi(endpoint);

PushNotificationApi.sendMessage = (id: string, options: options = {}): response => {

    return apiPatch({
        url: "PushNotifications/" + id + "/sendMessage",
        ...options
    })

}

export default PushNotificationApi;