import React, { useMemo } from 'react';
import UsersSingleSideEditPanel from './Components/UsersSingleSideEditPanel';
import { Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';

const UsersSingleViewSide: React.FC<any> = (props: any) => {

    return useMemo(() => (

        <>

            <Space />

            <UsersSingleSideEditPanel {...props} />


        </>

    ), [props]);

}

export default UsersSingleViewSide;
