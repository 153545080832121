import React, { useMemo, useCallback } from 'react';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';
import { useForm } from '@dex/bubl-dash/src/lib/functions/useForm';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import PushNotificationApi from '../PushNotificationApi';
import PushNotificationCreateEditForm from './PushNotificationCreateEditForm';

const PushNotificationSingleEdit: React.FC<any> = (props: any) => {

    const app = useApp();

    const { data } = props;

    const form = useForm(data);

    const update = PushNotificationApi.update(data.id, "update", {
        onComplete: (data) => {

            app.alert('Push Notification Saved', 'success');

        }
    });

    const handleSubmit = useCallback((values) => {

        update.run({ data: values });

    }, [props]);

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <PushNotificationCreateEditForm
                    mode={"edit"}
                    form={form}
                    data={data}
                    handleSubmit={handleSubmit}
                    process={update}
                />

            </Col>

        </Row>

    ), [data, form, update]);

}

export default PushNotificationSingleEdit;
