import { Box } from '@dex/bubl-dash';
import React, { useMemo } from 'react';
import Bracket from '../../../components/Bracket/Bracket';

const LeagueSeasonDivisionBracket: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <>
            <Box
                gutter={"auto"}
            >

                <Bracket
                    data={data.bracket}
                />

            </Box>

        </>

    ), [data]);

}

export default LeagueSeasonDivisionBracket;
