import React, { useMemo } from 'react';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';

const UsersInfoPanel: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Panel
            heading={"User Info"}
        >

            <MetaTable
                data={data}
                columnA={[

                    {
                        label: "About Me",
                        key: "aboutMe",
                    },
                    {
                        label: "Location",
                        key: "location",
                    },
                    {
                        label: "Sport",
                        key: "sportsPlayedNames"
                    }
                ]}
                columnB={[]}
                columnC={[]}
            />

        </Panel>

    ), [data]);

}

export default UsersInfoPanel;
