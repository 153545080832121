import React, { useEffect, useMemo } from 'react';
import { Row, Col, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ActivityPanel from '@dex/bubl-dash/src/lib/components/SinglePanels/ActivityPanel';
import LeagueSeasonGameApi from '../LeagueSeasonGameApi';
import LeagueSeasonGameSingleViewResult from './LeagueSeasonGameSingleViewResult';
import LeagueSeasonGameSingleViewBoxScores from './LeagueSeasonGameSingleViewBoxScores';
import { ModelLink } from '@dex/bubl-dash';
import WebLink from '../../../components/WebLink/WebLink';

const LeagueSeasonGameSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    const game = LeagueSeasonGameApi.getBoxScores(data.id);

    useEffect(() => {

        if (!data.hasGameScores && !data.hasGameData) return;

        game.run();

    }, []);

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >
            <WebLink
                path={`/leagues/season/${data.season.id}/${data.season.slug}/game/${data.id}`}
            />

            <Col
                className={'main'}
            >

                <Panel
                    heading={"League Game Details"}
                >

                    <MetaTable
                        data={{ ...data, teams: game?.data?.teams || data.teams }}
                        columnA={[
                            {
                                key: 'teams[0]',
                                label: 'Home Team',
                                render: (value, row) => {
                                    return value ? <ModelLink modelName="LeagueSeasonTeam" data={{ id: value.id, name: value.name }} /> : "-";
                                }
                            },
                            {
                                key: 'teams[1]',
                                label: 'Away Team',
                                render: (value, row) => {
                                    return value ? <ModelLink modelName="LeagueSeasonTeam" data={{ id: value.id, name: value.name }} /> : "-";
                                }
                            },
                            {
                                key: 'description',
                                label: 'Description',
                            }
                        ]}
                        columnB={[
                            {
                                key: 'host',
                                label: 'Host',
                                format: 'ModelLink',
                            },
                            {
                                key: 'season',
                                label: 'Season',
                                format: "ModelLink",
                            },
                            {
                                key: 'division',
                                label: 'Division',
                                format: "ModelLink",
                            }
                        ]}
                        columnC={[
                            {
                                key: 'status',
                                label: 'Status',
                                format: "titleCase"
                            },
                            {
                                key: 'venue',
                                label: 'Venue',
                            },
                            {
                                key: "date",
                                label: "Date",
                                format: "datetime"
                            }
                        ]}
                    />

                </Panel>


            </Col>

            <Col className={'side'}>

                <ActivityPanel parent={data} />

            </Col>

            <Col col={{ xs: 24 }}>

                {game.data &&
                    <>

                        <LeagueSeasonGameSingleViewResult
                            data={game.data}
                        />

                        <Space />

                        <LeagueSeasonGameSingleViewBoxScores
                            data={game.data}
                        />

                    </>
                }

            </Col>

        </Row>

    ), [data, game.data]);

}

export default LeagueSeasonGameSingleView;
