import { apiGet, baseApi, options, response } from "@dex/bubl-fetch";

const endpoint = 'LeagueSeasonLeaderboard';

const LeagueSeasonLeaderboardApi: any = baseApi(endpoint);

LeagueSeasonLeaderboardApi.getPublic = (id: string, options: options = {}): response => {

    return apiGet({
        "url": endpoint + "/public/" + id,
        ...options
    })

}

export default LeagueSeasonLeaderboardApi;