import React, { useMemo } from 'react';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';

const OrderPaymentView: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Panel
            heading={"Order Payment"}
        >

            <MetaTable
                data={data}
                columnA={[

                    {
                        label: "Transaction #",
                        key: "payment",
                        format: "ModelLink"
                    },
                    {
                        label: "Date Paid",
                        key: "datePaid",
                        format: "dateTime",
                    },
                    {
                        label: "Discount",
                        key: "discount",
                        format: "number",
                    },
                    {
                        label: "Amount Paid",
                        key: "amountPaid",
                        format: "number",
                    },
                ]}
                columnB={[
                    {
                        label: "Payment Gateway",
                        key: "payment.gateway",
                        format: "titleCase"
                    },
                    {
                        label: "Gateway Ref #",
                        key: "payment.bill.id",
                    },
                    {
                        label: "Notes",
                        key: "paymentNotes",
                    },
                ]}
            />


        </Panel>

    ), [data]);

}

export default OrderPaymentView;
