import React, { useMemo, useCallback, useEffect } from 'react';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';
import { useForm } from '@dex/bubl-dash/src/lib/functions/useForm';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import LeagueSeasonGameApi from '../LeagueSeasonGameApi';

import LeagueSeasonGameEventsForm from './LeagueSeasonGameEventsForm';

const LeagueSeasonGameSingleEvents: React.FC<any> = (props: any) => {

    const app = useApp();

    const { data, mode } = props;

    const form = useForm(data);

    const update = LeagueSeasonGameApi.update(data.id, "updateEvents", {
        onComplete: (data) => {

            app.alert('Game Saved', 'success');

        }
    });

    const events = LeagueSeasonGameApi.getEvents(data.id);
    const fields = LeagueSeasonGameApi.getSportFields(data.id);

    const handleSubmit = useCallback((values) => {

        update.run({ data: values });

    }, [props]);

    useEffect(() => {

        events.run();
        fields.run();

    }, [data.teamIds]);

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                col={{ xs: 24 }}
            >

                {!events.loading && !fields.loading &&
                    <LeagueSeasonGameEventsForm
                        form={form}
                        data={data}
                        handleSubmit={handleSubmit}
                        process={update}
                        events={events.data || []}
                        fields={fields.data}
                    />
                }

            </Col>

        </Row>

    ), [data, events.loading, fields.loading, form, update]);

}

export default LeagueSeasonGameSingleEvents;
