import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship';
import { hash } from '@dex/bubl-helpers';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import FieldDate from '@dex/bubl-dash/src/lib/components/Fields/FieldDate';


const LeagueSeasonGameCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <Panel
            heading={"League Game Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 16 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldRelationship
                                    form={form}
                                    name="seasonId"
                                    label="Season"
                                    defaultValue={form.defaults.seasonId}
                                    preload={true}
                                    required={true}
                                    model={"LeagueSeason"}
                                />

                            </Col>

                            {form.values.seasonId &&
                                <Col col={{ xs: 24, md: 12 }}>

                                    <FieldRelationship
                                        key={form.values.seasonId}
                                        form={form}
                                        name="divisionId"
                                        label="Division"
                                        defaultValue={form.defaults.divisionId}
                                        preload={true}
                                        where={{ seasonId: form.values.seasonId }}
                                        model={"LeagueSeasonDivision"}
                                    />

                                </Col>
                            }

                            {form.values.seasonId &&
                                <Col col={{ xs: 24, sm: 12 }}>

                                    <FieldRelationship
                                        key={hash([form.values.seasonId, form.values.divisionId])}
                                        form={form}
                                        name="teamIds[0]"
                                        label="Home Team"
                                        defaultValue={form.defaults.teamIds[0]}
                                        preload={true}
                                        required={true}
                                        where={{ seasonId: form.values.seasonId }}
                                        model={"LeagueSeasonTeam"}
                                    />

                                </Col>
                            }

                            {form.values.seasonId &&
                                <Col col={{ xs: 24, sm: 12 }}>

                                    <FieldRelationship
                                        key={hash([form.values.seasonId, form.values.divisionId])}
                                        form={form}
                                        name="teamIds[1]"
                                        label="Away Team"
                                        defaultValue={form.defaults.teamIds[1]}
                                        preload={true}
                                        required={true}
                                        where={{ seasonId: form.values.seasonId }}
                                        model={"LeagueSeasonTeam"}
                                    />

                                </Col>
                            }

                            <Col col={{ xs: 24 }}>

                                <FieldText
                                    form={form}
                                    label='Description'
                                    name='description'
                                    defaultValue={form.defaults.description}
                                />

                            </Col>

                        </Row>

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24 }}>

                                <FieldRadio
                                    form={form}
                                    label='Status'
                                    name='status'
                                    required={true}
                                    defaultValue={form.defaults.status}
                                    options={[
                                        { value: "scheduled", label: "Scheduled" },
                                        { value: "playing", label: "Playing" },
                                        { value: "ended", label: "Ended" },
                                    ]}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldDate
                                    form={form}
                                    label='Date'
                                    name='date'
                                    defaultValue={form.defaults.date}
                                    date={true}
                                    time={true}
                                    zone={true}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldText
                                    form={form}
                                    label='Venue'
                                    name='venue'
                                    defaultValue={form.defaults.venue}
                                />

                            </Col>

                        </Row>

                    </Col>

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default LeagueSeasonGameCreateEditForm;
