import React, { useMemo, useCallback } from 'react';
import { Space, Box } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form, useForm } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { useApp } from '@dex/bubl-dash';
import LeagueSeasonGameApi from '../LeagueSeasonGameApi';
import FieldNumber from '@dex/bubl-dash/src/lib/components/Fields/FieldNumber';
import InputTable, { InputTableRow, InputTableCell } from '@dex/bubl-dash/src/lib/components/InputTable/InputTable';
import FieldSelect from '@dex/bubl-dash/src/lib/components/Fields/FieldSelect';
import { get } from '@dex/bubl-helpers';

const LeagueSeasonGameResultForm: React.FC<any> = (props: any) => {

    const { data, fields } = props;

    const app = useApp();

    const form = useForm(data);

    const process = LeagueSeasonGameApi.update(data.id, "updateTotals", {
        onComplete: (data) => {

            app.alert('Result Saved', 'success');

        }
    });

    const handleSubmit = useCallback((values) => {

        process.run({ data: values });

    }, [props]);

    const columns = useMemo(() => {

        const columns: any = [
            {
                key: "team",
                label: "Team",
                style: { width: 120 }
            },
        ];

        fields.fields.totals.map((field) => {

            if (field.calc) return;

            field.style = { width: 50 };
            field.breakTotal = true;

            columns.push(field);

        });

        columns.push({
            key: "final",
            label: "Final",
            style: { width: 80 }
        });

        columns.push({
            key: "result",
            label: "Result",
            style: { width: 120 }
        });

        return columns;

    }, [fields]);

    return useMemo(() => (

        <Panel
            heading={"Result"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Box
                    // gutter={"auto"}
                    rev={true}
                >

                    <InputTable
                        columns={columns}
                    >

                        {fields.teams.map((team, index) => (

                            <InputTableRow key={team.id}>

                                {columns.map(column => (

                                    <InputTableCell
                                        {...column}
                                        data={undefined}
                                    >

                                        {column.key === "team" &&
                                            <strong>{team.name}</strong>
                                        }

                                        {column.key === "final" &&
                                            <FieldNumber
                                                form={form}
                                                name={"totals[" + index + "]"}
                                                defaultValue={get(form.defaults, "totals[" + index + "]")}
                                                placeholder={""}
                                            />

                                        }

                                        {column.key === "result" &&
                                            <FieldSelect
                                                form={form}
                                                name={"outcome[" + index + "]"}
                                                defaultValue={get(form.defaults, "outcome[" + index + "]")}
                                                options={[
                                                    { value: "w", label: "Win" },
                                                    { value: "l", label: "Loss" },
                                                    { value: "d", label: "Draw" },
                                                    { value: "n", label: "No Contest" },
                                                ]}
                                            />
                                        }

                                        {column.breakTotal &&
                                            <FieldNumber
                                                form={form}
                                                name={"breakTotals[" + index + "][" + column.key + "]"}
                                                defaultValue={get(form.defaults, "breakTotals[" + index + "][" + column.key + "]")}
                                                placeholder={""}
                                            />
                                        }


                                    </InputTableCell>

                                ))}

                            </InputTableRow>

                        ))}

                    </InputTable>

                </Box>

                <Line />

                <ButtonRow>

                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />

                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />

                </ButtonRow>


            </Form>

        </Panel>

    ), [form.values, process.loading]);

}

export default LeagueSeasonGameResultForm;
