import React from 'react';

import BublDash from '@dex/bubl-dash/src/lib/Dash';
import Media from '@dex/bubl-dash/src/lib/views/Media/Media';
import Users from '@dex/bubl-dash/src/lib/views/Users/Users';
import Defaults from '@dex/bubl-dash/src/lib/views/Defaults';
import Wrapper from '@dex/bubl-dash/src/lib/Wrapper';

import './styles/App.scss';

import UsersMods from './views/Users/UsersMods';

import Sport from './views/Sport/Sport';
import Reaction from './views/Reaction/Reaction';
import Page from './views/Page/Page';
import Help from './views/Help/Help';
import HelpTopic from './views/HelpTopic/HelpTopic';
import Announcements from './views/Announcements/Announcements';
import PushNotification from './views/PushNotification/PushNotification';

//League
import League from './views/League/League';
import LeagueCategory from './views/LeagueCategory/LeagueCategory';
import LeagueSeason from './views/LeagueSeason/LeagueSeason';

//Pickup
import PickupOperator from "./views/PickupOperator/PickupOperator"
import PickupCategory from "./views/PickupCategory/PickupCategory";
import PickupSessions from "./views/PickupSession/PickupSession";
import PickupSessionGroup from "./views/PickupSessionGroup/PickupSessionGroup";
import PickupPasses from "./views/PickupPasses/PickupPasses";
import PickupAttendance from './views/PickupAttendance/PickupAttendance';

//Camp
import CampOperator from './views/CampOperator/CampOperator';
import CampCategory from './views/CampCategory/CampCategory';
import CampSession from './views/CampSession/CampSession';
import CampSessionGroup from './views/CampSessionGroup/CampSessionGroup';
import CampAthletes from './views/CampAthletes/CampAthletes';
import CampCoaches from './views/CampCoaches/CampCoaches';
import AssessmentForms from './views/AssessmentForms/AssessmentForms';
import Assessments from './views/Assessments/Assessments';
import CampPasses from './views/CampPasses/CampPasses';
import CampAttendance from './views/CampAttendance/CampAttendance';

//Forms
import Form from './views/Form/Form'
import FormField from './views/FormField/FormField'
import FormEntry from './views/FormEntry/FormEntry'

//News
import News from './views/News/News'
import NewsCategory from './views/NewsCategory/NewsCategory'
import NewsTag from './views/NewsTag/NewsTag'
import Comment from './views/Comment/Comment';

//Shop
import Products from './views/Product/Product';
import Payment from './views/Payment/Payment';
import Order from './views/Order/Order';
import ShopPayout from './views/ShopPayout/ShopPayout';

//Stats
import StatsGroup from './views/StatsGroup/StatsGroup'
import StatsSet from './views/StatsSet/StatsSet'

//Hosts
import Hosts from './views/Hosts/Hosts';
import HostRequests from './views/HostRequests/HostRequests';

// Follow
import Following from './views/Following/Following';

//Dashboard
import DashboardView from './views/Dashboard/DashboardView';
import DashboardMenu from './views/Dashboard/DashboardMenu';

import Migrate from './views/Migrate/Migrate';

//Icons
import CampBlack from './assets/Camp-Black.svg';
import CampWhite from './assets/Camp-White.svg';
import PickupBlack from './assets/Pickup-Black.svg';
import PickupWhite from './assets/Pickup-White.svg';
import LeagueBlack from './assets/League-Black.svg';
import LeagueWhite from './assets/League-White.svg';
import ShopBlack from './assets/Shop-Black.svg';
import ShopWhite from './assets/Shop-White.svg';
import HostBlack from './assets/Host-Black.svg';
import HostWhite from './assets/Host-White.svg';
import VenueBlack from './assets/Venue-Black.svg';
import VenueWhite from './assets/Venue-White.svg';
import WatchBlack from './assets/Watch-Black.svg';
import WatchWhite from './assets/Watch-White.svg';

//Venue
import VenueOperator from './views/VenueOperator/VenueOperator';
import VenueCourt from './views/VenueCourt/VenueCourt';
import VenueBlockedDates from './views/VenueBlockedDates/VenueBlockedDates';
import VenueCategory from './views/VenueCategory/VenueCategory';
import VenueBooking from './views/VenueBooking/VenueBooking';
import VenueLiveStream from './views/VenueLiveStream/VenueLiveStream';


// watch
import WatchCameras from './views/WatchCameras/WatchCameras';
// import WatchEvents from './views/WatchEvents/WatchEvents';
import WatchVideos from './views/WatchVideos/WatchVideos';
import WatchCategory from './views/WatchCategory/WatchCategory';
import WatchTag from './views/WatchTag/WatchTag';
import WatchAds from './views/WatchAds/WatchAds'

// league manage
import LeagueTeam from './views/LeagueTeam/LeagueTeam';
import LeaguePlayer from './views/LeaguePlayer/LeaguePlayer';
import LeagueSeasonGame from './views/LeagueSeasonGame/LeagueSeasonGame';
import LeagueSeasonTeam from './views/LeagueSeasonTeam/LeagueSeasonTeam';
import LeagueSeasonPlayer from './views/LeagueSeasonPlayer/LeagueSeasonPlayer';
import LeagueSeasonDivision from './views/LeagueSeasonDivision/LeagueSeasonDivision';
import LeagueSeasonLeaderboard from './views/LeagueSeasonLeaderboard/LeagueSeasonLeaderboard';

//Settings
import SettingsMods from './views/Settings/SettingsMods';


const app = new BublDash({
    name: "Huddle - Host",
    //icon: "assets/logo.png",
    accessRoles: ['admin', 'host', 'developer', 'super', 'watchManager'], //must be one of these roles in order to sign in
    userRoles: [
        {
            key: 'user',
            label: 'User'
        },
        {
            key: 'host',
            label: 'Host'
        },
        {
            key: 'admin',
            label: 'Admin'
        },
        {
            key: 'super',
            label: 'Super'
        },
        {
            key: "watchManager",
            label: "Watch Manager"
        }
    ],
});

app.addRoute({
    name: "home",
    path: "/",
    zone: "home",
    component: DashboardView,
    componentProps: {},
    caps: []
});

app.addZone({
    key: "home",
    label: "Home",
    hidden: true,
    menuComponent: DashboardMenu
});

app.addZone({
    key: "camp",
    label: "Camps",
    priority: 10,
    icon: CampBlack,
    iconActive: CampWhite,
});

app.addZone({
    key: "pickup",
    label: "Pickup",
    priority: 11,
    icon: PickupBlack,
    iconActive: PickupWhite,
});

app.addZone({
    key: "leagues",
    label: "Leagues",
    priority: 12,
    icon: LeagueBlack,
    iconActive: LeagueWhite,
});

app.addZone({
    key: "venue",
    label: "Venues",
    priority: 13,
    icon: VenueBlack,
    iconActive: VenueWhite,
});

app.addZone({
    key: "watch",
    label: "Watch",
    priority: 14,
    icon: WatchBlack,
    iconActive: WatchWhite,
});

app.addZone({
    key: "shop",
    label: "Shop",
    priority: 23,
    icon: ShopBlack,
    iconActive: ShopWhite,
});

app.addZone({
    key: "hosts",
    label: "Hosts",
    priority: 24,
    icon: HostBlack,
    iconActive: HostWhite,
});

//Add New Models Here
const extensions: any = [
    UsersMods,
    SettingsMods,
    Defaults,
    Users,
    Sport,
    League,
    LeagueSeason,
    LeagueTeam,
    LeagueCategory,
    LeagueSeasonDivision,
    LeagueSeasonLeaderboard,
    LeaguePlayer,
    LeagueSeasonGame,
    LeagueSeasonTeam,
    LeagueSeasonPlayer,
    CampOperator,
    CampSessionGroup,
    CampSession,
    CampCategory,
    CampAthletes,
    CampCoaches,
    CampPasses,
    CampAttendance,
    AssessmentForms,
    Assessments,
    PickupOperator,
    PickupSessionGroup,
    PickupSessions,
    PickupCategory,
    PickupPasses,
    PickupAttendance,
    VenueOperator,
    VenueCourt,
    VenueBooking,
    VenueLiveStream,
    VenueCategory,
    VenueBlockedDates,
    Comment,
    News,
    NewsCategory,
    NewsTag,
    Products,
    Payment,
    Order,
    ShopPayout,
    Reaction,
    Form,
    FormField,
    FormEntry,
    Hosts,
    HostRequests,
    Page,
    Announcements,
    Help,
    HelpTopic,
    PushNotification,
    Following,
    StatsGroup,
    StatsSet,
    Media,
    WatchCameras,
    // WatchEvents,
    WatchVideos,
    WatchCategory,
    WatchTag,
    WatchAds
    // Migrate
];

extensions.map(extension => {

    extension(app);

    return null;

});

const App: React.FC = () => {

    return (
        <Wrapper>
            {app.render()}
        </Wrapper>
    )

}

export default App;