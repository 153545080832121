import React, { useCallback, useEffect, useMemo, useState } from 'react';
import CampSessionApi from '../../CampSessionApi';
import FieldSwitch from '@dex/bubl-dash/src/lib/components/Fields/FieldSwitch';

const CampSessionAttendeesMark: React.FC<any> = (props: any) => {

    const { attendee, form } = props;

    const [key, setKey] = useState(0);

    const [attended, setAttended] = useState(attendee.attended);

    const mark = CampSessionApi.markAttendance(attendee.id);

    const handleMark = useCallback((value) => {

        mark.run({
            data: { attended: value },
            onComplete: (response) => {

                setAttended(response.attended);

            },
            onEnd: () => {
                setKey(key + 1);
            }
        });

    }, [mark, key]);

    return useMemo(() => (

        <>

            <FieldSwitch
                key={key}
                form={form}
                name={`${attendee.id}.attended`}
                onChange={mark.loading ? null : handleMark}
                defaultValue={attended}
            />

        </>

    ), [attendee, key, mark.loading]);

}

export default CampSessionAttendeesMark;
