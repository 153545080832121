import CommentIndex from './CommentIndex';
import CommentCreate from './CommentCreate';
import CommentSettings from './CommentSettings';
import CommentSingle from './CommentSingle';

export default function (app) {

    const settings = CommentSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    // app.addMenu({
    //     key: settings.key,
    //     zone: settings.zone,
    //     label: settings.title,
    //     priority: 24,
    //     items: [
    //         {
    //             label: "All",
    //             path: settings.path,
    //             caps: settings.indexCaps
    //         },
    //         // {
    //         //     label: "Add New",
    //         //     path: settings.path + "/create",
    //         //     caps: settings.indexCaps
    //         // }
    //     ],
    // });

    CommentIndex(app, settings);

    CommentCreate(app, settings);

    CommentSingle(app, settings);

}