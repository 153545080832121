import React, { useMemo } from 'react';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import DataTable from '@dex/bubl-dash/src/lib/components/DataTable/DataTable';
import { Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';

const PushNotificationSelectedUsers: React.FC<any> = (props: any) => {

    const { data } = props;

    const columns = [
        {
            label: "Photo",
            key: "profilePhoto.fileId",
            format: "image",
            filters: { width: 40, height: 40 },
            link: false,
            style: { width: 60 }
        },
        {
            label: "First Name",
            key: "firstName",
            style: { width: 120 },

        },
        {
            label: "Last Name",
            key: "lastName",
            style: { width: 120 },

        },
        {
            label: "Email Address",
            key: "email",
            style: { width: 120 },

        },
        {
            label: "Phone No.",
            key: "phone",
            style: { width: 120 },

        }
    ];

    return useMemo(() => (

        <Panel
            heading={`${data.loading ? "Loading" : (data && data.users) ? data.users.length : "0"} Selected User(s)`}
            full={true}
        >

            <Space />

            <DataTable
                data={data && data.users ? data.users : []}
                columns={columns}
                loading={data.loading}
            />

        </Panel>

    ), [data]);

}

export default PushNotificationSelectedUsers;
