import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ActivityPanel from '@dex/bubl-dash/src/lib/components/SinglePanels/ActivityPanel';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';

const AssessmentsSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Assessments Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Title",
                                key: "title"
                            },
                            {
                                label: "Athlete",
                                key: "athlete",
                                format: "modelLink"
                            },
                            {
                                label: "Combined Score",
                                key: "combinedScore"
                            },
                            {
                                label: "Notes",
                                key: "notes"
                            },
                        ]}
                        columnB={[
                            {
                                label: "Host",
                                key: "host",
                                format: "modelLink"
                            },
                            {
                                label: "Coach",
                                key: "coach",
                                format: "modelLink"
                            },
                        ]}
                        columnC={[
                            {
                                label: "Assessment Form",
                                key: "form",
                                format: "modelLink"
                            },
                        ]}
                    />

                    <Seperator
                        heading="Criteria"
                        top="auto"
                        bottom="auto"
                    />

                    <MetaTable
                        data={data.criteria || []}
                        columnA={[
                            {
                                label: "Title",
                                key: "title",
                            }

                        ]}
                        columnB={[
                            {
                                label: "Description",
                                key: "description",
                            }
                        ]}
                        columnC={[
                            {
                                label: "Score",
                                key: "score",
                            }
                        ]}
                    />

                </Panel>

            </Col>

            <Col className={'side'}>

                <ActivityPanel parent={data} />

            </Col>

        </Row>

    ), [data]);

}

export default AssessmentsSingleView;
