import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import VenueOperatorApi from '../../VenueOperator/VenueOperatorApi';

import styles from './OrderVenueTimeSlots.module.scss';
import useLongPress from '../../../hooks/useLongPress';
import moment from 'moment';

const OrderVenueTimeSlots: React.FC<any> = (props: any) => {

    const { operatorId, courtId, date, onChange } = props;

    const [selectedKey, setSelectedKey]: any = useState(null);
    const [selected, setSelected]: any = useState([]);

    const sessions = VenueOperatorApi.sessionList(operatorId);

    const [list, setList] = useState<any>(null);

    useEffect(() => {

        setList(null);

        sessions.run({
            params: {
                courtId: courtId,
                includeGroups: false,
                allowAllDates: true,
                date: date,
            },
            onComplete: (response: any) => {

                response.slots[0] && setList(response.slots[0].slots);

            }
        });

    }, [date, operatorId]);


    useEffect(() => {

        if (list) {

            let selectedKey: any = null;

            list.map((slot: any, key: number) => {

                if (selected.includes(courtId + '.' + slot.time)) selectedKey = key;

                return null;
            })

            setSelectedKey(selectedKey);

        }

    }, [list, selected])

    const onClick = useCallback((event) => {


        const key = parseInt(event.target.id);
        const slot = list[key];

        if (slot.disabled) return;

        if (selected.includes(courtId + "." + slot.time)) {

            setSelected([], null);
            return;

        }

        setSelected([courtId + "." + slot.time]);
        onChange([courtId + "." + slot.time], { courtId, ...slot })

    }, [setSelected, selected, list]);

    const onLongPress = useCallback((event) => {

        const key = parseInt(event.target.id);
        const slot = list[key];

        if (!canExtendTo(slot, key)) return;

        const newSelected = [...selected, courtId + "." + slot.time];

        setSelected(newSelected);
        onChange(newSelected, { courtId, ...slot });

    }, [selectedKey, setSelected, selected]);

    const canExtendTo = useCallback((slot, key) => {

        if (selectedKey < 0 || selectedKey == null) return false;

        if (!slot) return false
        if (slot.disabled) return false

        const current = list[selectedKey];

        if (slot.productId !== current.productId) return false;
        if (slot.size !== current.size) return false;

        if (selectedKey + 1 !== key) return false;

        return true;

    }, [selectedKey, list]);


    const defaultOptions = {
        shouldPreventDefault: true,
        delay: 500,
    };

    const longPressEvent = useLongPress(onLongPress, onClick, defaultOptions);

    return useMemo(() => (

        <>

            <Line />

            <div className={styles.slots}>

                {list && list.map((slot: any, key: number) => (

                    <div
                        key={key}
                        id={key.toString()}
                        {...longPressEvent}
                        className={[
                            slot.disabled ? styles.slotsDisabled : styles.slot,
                            selected.includes(courtId + "." + slot.time) ? styles.slotSelected : ''
                        ].join(' ')}
                    >
                        {slot.time}
                    </div>

                ))}

                {(!list || list.length === 0) &&
                    <p className={styles.noSlotsOpen}>No slots open for {moment(date).format("dddd, Do MMMM YYYY")}.</p>
                }

            </div>

            {selectedKey >= 0 && list && canExtendTo(list[selectedKey + 1], selectedKey + 1) && (
                <p className={styles.info}>
                    * Long press {list[selectedKey + 1].time} to extend your booking
                </p>
            )}

        </>

    ), [list, selected, longPressEvent, selectedKey]);

}

export default OrderVenueTimeSlots;
