import React, { useMemo, useCallback } from 'react';
import LeagueSeasonDivisionApi from '../LeagueSeasonDivisionApi';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';
import { useForm, Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import FieldNumber from '@dex/bubl-dash/src/lib/components/Fields/FieldNumber';
import FieldSwitch from '@dex/bubl-dash/src/lib/components/Fields/FieldSwitch';
import { FieldSelect } from '@dex/bubl-dash';
import { FieldCheckbox } from '@dex/bubl-dash';
import { howMany } from '@dex/bubl-helpers';

const LeagueSeasonDivisionSingleScheduleGames: React.FC<any> = (props: any) => {

    const app = useApp();

    const [nav] = app.store("nav");

    const { data } = props;

    const form = useForm(data);

    const update = LeagueSeasonDivisionApi.update(data.id, "scheduleGames", {
        onComplete: (data) => {

            app.alert('Games Scheduled', 'success');

            //return to view

            const route = { "type": "Navigation/NAVIGATE", routeName: "league/divisionsView", params: { id: data.id } }

            app.navigate(nav, 'push', route);


        }
    });

    const roundOptions: Array<any> = [];
    const matchupOptions: Array<any> = [];
    let noOfGames = 0;

    if (data.type === "bracket") {

        data.bracketRounds.map((round, index) => {

            if (form.values.round && form.values.round === index + 1 + "") {
                noOfGames = round.noOfGames;
            }

            roundOptions.push({ value: index + 1 + "", label: round.label });

        });

        data.bracket.map((column, index) => {

            if (form.values.round && form.values.round === column.no + "") {

                column.matchups.map(matchup => {

                    if (matchup.teams[0].id && matchup.teams[1].id) {
                        matchupOptions.push({ value: [matchup.key, matchup.teams[0].id, matchup.teams[1].id].join(":"), label: matchup.teams[0].name + " vs " + matchup.teams[1].name });
                    }

                });

            }

        });

    }

    const handleSubmit = useCallback((values) => {

        update.run({ data: values });

    }, [props, noOfGames]);

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Schedule Games"}
                >

                    <Form
                        form={form}
                        onSubmit={handleSubmit}
                        loading={update.loading}
                    >

                        <Row gutter={8} edge={true}>

                            {data.type === "bracket" &&
                                <>
                                    <Col col={{ xs: 24, md: 8 }}>

                                        <FieldSelect
                                            form={form}
                                            label='Select Round'
                                            name='round'
                                            required={true}
                                            defaultValue={form.defaults.round}
                                            options={roundOptions}
                                        />

                                    </Col>

                                    {form.values.round &&
                                        <Col col={{ xs: 24, md: 8 }}>

                                            <FieldNumber
                                                key={noOfGames}
                                                form={form}
                                                disabled={true}
                                                label='No. of Games in Series'
                                                name='noOfGames'
                                                suffix={howMany(noOfGames, " Game", " Games")}
                                                defaultValue={noOfGames}
                                            />

                                        </Col>
                                    }

                                    {form.values.round &&
                                        <Col col={{ xs: 24 }}>

                                            <FieldCheckbox
                                                list={true}
                                                form={form}
                                                label='Select Matchups'
                                                name='matchups'
                                                required={true}
                                                defaultValue={[]}
                                                options={matchupOptions}
                                            />

                                        </Col>
                                    }
                                </>
                            }

                            {data.type === "table" &&
                                <Col col={{ xs: 24, md: 8 }}>

                                    <FieldNumber
                                        form={form}
                                        name={"rounds"}
                                        label={"Number of Rounds"}
                                        defaultValue={form.defaults.rounds}
                                        required={true}
                                        min={1}
                                    />

                                </Col>
                            }

                            {data.type === "table" && data.hasGames &&
                                <Col col={{ xs: 24, md: 8 }}>

                                    <FieldSwitch
                                        form={form}
                                        name={"clearGames"}
                                        defaultValue={form.defaults.clearGames}
                                        label={"Clear Scheduled Games?"}
                                    />

                                </Col>
                            }

                        </Row>

                        <Line />

                        <ButtonRow>
                            <Button
                                label={"Submit"}
                                loading={update.loading}
                            />
                            <Button
                                label={"Reset"}
                                type={"faded"}
                                onClick={form.reset}
                            />
                        </ButtonRow>

                    </Form>

                </Panel>

            </Col>

        </Row>

    ), [data, form, update]);

}

export default LeagueSeasonDivisionSingleScheduleGames;
