import React, { useMemo, useCallback } from 'react';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { Form, useForm } from '@dex/bubl-dash/src/lib/functions/useForm';
import { Col, Row } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import FieldNumber from '@dex/bubl-dash/src/lib/components/Fields/FieldNumber';
import FieldDate from '@dex/bubl-dash/src/lib/components/Fields/FieldDate';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import WatchVideosApi from '../WatchVideosApi';
import { Seperator } from '@dex/bubl-dash';
import { FieldRepeater } from '@dex/bubl-dash';

const WatchVideosAdsForm: React.FC<any> = (props: any) => {

    const { data } = props;

    const app = useApp();

    const form = useForm(data);

    const process = WatchVideosApi.update(data.id, 'updateAds', {
        onComplete: (data) => {

            app.alert('Video Saved', 'success');

        }
    });

    const handleSubmit = useCallback((values) => {

        process.run({
            data: values
        });

    }, [])

    return useMemo(() => (

        <Panel
            heading={"Video Ads"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24 }}>

                        <FieldRadio
                            form={form}
                            label={"Ads Status"}
                            name='adsStatus'
                            required={true}
                            defaultValue={form.defaults.adsStatus}
                            options={[
                                { value: "auto", label: "Auto" },
                                { value: "enabled", label: "Enabled" },
                                { value: "disabled", label: "Disabled" },
                                { value: "custom", label: "Custom" },
                            ]}
                        />

                    </Col>

                </Row>


                {form.values.adsStatus === "custom" &&

                    <>

                        <Seperator
                            bottom="auto"
                            heading="Custom Ads"
                        />

                        <FieldRepeater
                            form={form}
                            name={'ads'}
                            // defaultValues={{ position:"pre"}}
                            addButtonLabel={"Add Video Ad"}
                        >

                            {Array.isArray(form.values.ads) && form.values.ads.map((row, n) => (

                                <React.Fragment key={form.key + "-" + n}>

                                    <Row gutter={8} edge={true}>

                                        <Col col={{ md: 16, xs: 24 }}>

                                            <FieldRadio
                                                form={form}
                                                label={"Postion"}
                                                name={`ads[${n}][postion]`}
                                                required={true}
                                                defaultValue={row.postion}
                                                options={[
                                                    { value: "pre", label: "Pre" },
                                                    { value: "post", label: "Post" },
                                                    { value: "custom", label: "Custom" },
                                                ]}
                                            />

                                        </Col>

                                        {row.postion === "custom" &&
                                            <Col col={{ md: 8, xs: 24 }}>

                                                <FieldNumber
                                                    form={form}
                                                    label={"Position Custom Time"}
                                                    name={`ads[${n}][postionTime]`}
                                                    required={true}
                                                    defaultValue={row.positionTime}
                                                    suffix={" Seconds"}
                                                />

                                            </Col>
                                        }

                                        <Col col={{ xs: 24 }}>

                                            <FieldText
                                                form={form}
                                                label={"Ad Tag"}
                                                name={`ads[${n}][adTag]`}
                                                required={true}
                                                defaultValue={row.adTag}
                                            />

                                        </Col>

                                    </Row>

                                </React.Fragment>

                            ))}

                        </FieldRepeater>

                    </>

                }

                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>

    ), [data, form, process]);

}

export default WatchVideosAdsForm;
