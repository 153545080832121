import React, { useMemo } from 'react';

const LeagueSeasonManageLeaderboards: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <>

            Leaderboards

        </>

    ), [data]);

}

export default LeagueSeasonManageLeaderboards;
