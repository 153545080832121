import SinglePage from '@dex/bubl-dash/src/lib/pages/SinglePage';
import LeagueSeasonGameSingleView from './Components/LeagueSeasonGameSingleView';
import LeagueSeasonGameSingleEdit from './Components/LeagueSeasonGameSingleEdit';
import LeagueSeasonGameSingleEvents from './Components/LeagueSeasonGameSingleEvents';

export default function (app, settings) {

    app.addRoute({
        name: settings.key + "View",
        zone: settings.zone,
        path: settings.key + "/:id/view",
        caps: settings.viewCaps,
        component: SinglePage,
        componentProps: {
            getFetch: settings.singleFetch,
            getFetchParams: settings.singleFetchParams,
            header: {
                getTitle: settings.singleTitle,
                getTabs: settings.singleTabs
            },
            page: LeagueSeasonGameSingleView,
        },
    });

    app.addRoute({
        name: settings.key + "Edit",
        zone: settings.zone,
        path: settings.key + "/:id/edit",
        caps: settings.updateCaps,
        component: SinglePage,
        componentProps: {
            getFetch: settings.singleFetch,
            getFetchParams: settings.singleFetchParams,
            header: {
                getTitle: settings.singleTitle,
                getTabs: settings.singleTabs
            },
            page: LeagueSeasonGameSingleEdit,
        },
    });

    app.addRoute({
        name: settings.key + "Events",
        zone: settings.zone,
        path: settings.key + "/:id/events",
        caps: settings.updateCaps,
        component: SinglePage,
        componentProps: {
            getFetch: settings.singleFetch,
            getFetchParams: settings.singleFetchParams,
            header: {
                getTitle: settings.singleTitle,
                getTabs: settings.singleTabs
            },
            page: LeagueSeasonGameSingleEvents,
        },
    });

}