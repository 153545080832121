import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { Col, Row, Space, useApp } from '@dex/bubl-dash';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form, useForm } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { get, loop, numberToLetter } from '@dex/bubl-helpers';
import CSVReader from 'react-csv-reader'
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import FieldWrap from '@dex/bubl-dash/src/lib/components/Fields/FieldWrap';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';
import FieldSelect from '@dex/bubl-dash/src/lib/components/Fields/FieldSelect';

const LeagueSeasonGameBoxScoresImport: React.FC<any> = (props: any) => {

    const { data, fields, boxForm, handleClose } = props;

    const app = useApp();

    const form = useForm(data);

    const [key, setKey] = useState<any>(0);

    const [csvFile, setCsvFile] = useState<any>(null);

    const [csvRows, setCvsRows] = useState<any>(null);

    const [csvCols, setCvsCols] = useState<any>(null);

    const getOptionsCols = useCallback(() => {

        const options: any = [];

        loop(csvCols, (label, key) => {

            options.push({ value: key, label: label });

        });

        return options;

    }, [csvCols]);

    const getOptionsRows = useCallback(() => {

        const options: any = [];

        loop(csvRows, (row, key) => {

            const [index, label] = key.split(":", 2);

            options.push({ value: key, label: `Row ${parseInt(index) + 2} - ${label}` });

        });

        return options;

    }, [csvCols]);

    const getColumns = useCallback(() => {

        const columns: any = [];

        fields.fields.boxScores.map((field) => {

            if (field.calc) return;
            if (field.hidden) return;

            columns.push(field);

        });

        return columns;

    }, [fields.fields.boxScores]);

    const getPlayers = useCallback(() => {

        const players: any = [];

        fields.teams[parseInt(form.values.teamIndex)].roster.map((player) => {

            players.push(player);

        });

        return players;

    }, [form.values.teamIndex]);

    const handleSubmit = useCallback((values) => {

        const boxScore: any = boxForm.values.boxScore || [];

        const teamIndex = parseInt(values.teamIndex);

        boxScore[teamIndex] = {};

        loop(values.rows, (csvRowKey, playerId) => {

            if (!csvRowKey || !csvRows[csvRowKey]) return;

            const row = csvRows[csvRowKey];

            const data = {};

            loop(values.cols, (csvColKey, statKey) => {

                if (!csvColKey) return;

                if (row[csvColKey] === 0) row[csvColKey] = '0';

                data[statKey] = row[csvColKey];

            });

            boxScore[teamIndex][playerId] = data;

        })

        boxForm.handleChange({ name: "boxScore", value: boxScore, reset: true });

        boxForm.setKey();

        handleClose();

    }, [props, csvRows, csvCols]);

    const parseCsv = (data, fileInfo, originalFile) => {

        const rows: any = {};
        const cols: any = {};

        loop(data, (cells, index) => {

            const row = {};

            let n = 0;

            const id = Object.values(cells)[0] || "";

            loop(cells, (value, header) => {

                n++;

                if (n === 1) return;

                const key = header.toLowerCase().replace(/W/i, "")

                row[key] = value;

                cols[key] = `Column ${numberToLetter(n - 1)} - ${header}`;

            });

            rows[index + ":" + id] = row;

        });

        setCvsRows(rows);
        setCvsCols(cols);
        setCsvFile(originalFile);

    };

    useEffect(() => {

        //Try to auto map colmns and rows

        if (!csvCols || !csvRows) return;

        if (form.values.teamIndex === undefined) return;

        const rows: any = {};
        const cols: any = {};

        loop(getColumns(), (column) => {

            let match: any = null;

            loop(csvCols, (label, key) => {

                if (key === column.key) match = key;
                else if (column.importKeys && column.importKeys.includes(key)) match = key;

            });

            cols[column.key] = match;

        });

        loop(getPlayers(), (player, index) => {

            let match: any = null;

            loop(csvRows, (row, key) => {

                const [index, nameNumber] = key.split(":", 2);
                const [number, name] = (nameNumber || "").split("-").map(s => s.trim());

                if (player.name === name || player.jerseyNumber === parseInt(number)) match = key;

            });

            rows[player.id] = match;

        });

        form.handleChange({ name: "cols", value: cols, reset: true });
        form.handleChange({ name: "rows", value: rows, reset: true });

    }, [csvCols, csvRows, form.values.teamIndex])

    // const columns = useMemo(() => {

    //     const columns: any = [
    //         {
    //             key: "jerseyNumber",
    //             label: "#"
    //         }
    //     ];

    //     fields.fields.boxScores.map((field) => {

    //         columns.push(field);

    //     });

    //     return columns;

    // }, [fields]);

    return useMemo(() => (

        <Panel
            heading={"Import Box Scores"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
            >

                <Row gutter={8} edge={true} key={key}>

                    <Col col={{ xs: 24, md: 12, lg: 8 }}>

                        <FieldRadio
                            form={form}
                            label='Team'
                            name='teamIndex'
                            required={true}
                            defaultValue={form.defaults.teamIndex}
                            options={[
                                { value: "0", label: fields.teams[0].name },
                                { value: "1", label: fields.teams[1].name },
                            ]}
                        />


                    </Col>

                    <Col col={{ xs: 24, md: 12, lg: 8 }}>

                        <FieldWrap
                            label={"Import File"}
                            required={true}
                        >

                            <div>
                                <Space height={12} />

                                <CSVReader
                                    onFileLoaded={parseCsv}
                                    parserOptions={{
                                        header: true,
                                        dynamicTyping: false,
                                        skipEmptyLines: true,
                                    }}
                                    inputName={"csv"}
                                />

                            </div>

                        </FieldWrap>

                    </Col>

                </Row>

                {(csvRows && csvCols && form.values.teamIndex) &&
                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 12 }}>

                            <Seperator
                                heading={"Assign Columns"}
                                description={"Link a column with a stat"}
                                top={"auto"}
                                bottom={"auto"}
                            />

                            {getColumns().map((column, index) => (
                                <div key={index} className={"w-300"}>

                                    <FieldSelect
                                        label={column.fullLabel || column.label}
                                        form={form}
                                        name={`cols[${column.key}]`}
                                        defaultValue={get(form.defaults, `cols[${column.key}]`)}
                                        options={getOptionsCols()}
                                    />

                                </div>
                            ))}

                        </Col>

                        <Col col={{ xs: 24, md: 12 }}>

                            <Seperator
                                heading={"Assign Rows"}
                                description={"Link a row with a player"}
                                top={"auto"}
                                bottom={"auto"}
                            />

                            {getPlayers().map((player, index) => (
                                <div key={index} className={"w-300"}>

                                    <FieldSelect
                                        label={"#" + player.jerseyNumber + " - " + player.name}
                                        form={form}
                                        name={`rows[${player.id}]`}
                                        defaultValue={get(form.defaults, `rows[${player.id}]`)}
                                        options={getOptionsRows()}
                                    />

                                </div>
                            ))}


                        </Col>

                    </Row>
                }

                <Line />

                <ButtonRow>

                    {(csvRows && csvCols && form.values.teamIndex) &&
                        <Button
                            label={"Update Box Scores"}
                        />
                    }

                    <Button
                        label={"Cancel"}
                        type={"faded"}
                        onClick={handleClose}
                    />

                </ButtonRow>

            </Form>

        </Panel>

    ), [form, csvCols, csvRows, key]);

}

export default LeagueSeasonGameBoxScoresImport;
