import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldHidden from '@dex/bubl-dash/src/lib/components/Fields/FieldHidden';
import FieldNumber from '@dex/bubl-dash/src/lib/components/Fields/FieldNumber';
import FieldList from '@dex/bubl-dash/src/lib/components/Fields/FieldList';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';
import { FieldSelect } from '@dex/bubl-dash';
import { hash, parseNum } from '@dex/bubl-helpers';

const LeagueSeasonDivisionCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit, mode } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    const handleChangeRounds = useCallback((option) => {

        if (form.values.type !== "bracket") return;

        const labels: any = [];
        const bracketRounds: any = [];
        const noOfRounds: any = parseNum(option.value);
        const teamIds: any = form.values.teamIds;

        if (noOfRounds >= 4) {

            let n = 0;
            while (n < noOfRounds - 3) {

                n++;
                labels.push("Round " + n);

            }

        }

        if (noOfRounds >= 3) labels.push("Quarter-Finals");
        if (noOfRounds >= 2) labels.push("Semi-Finals");
        if (noOfRounds >= 1) labels.push("Finals");

        labels.map((label: any, i: any) => {

            const noOfGames = (form.values.bracketRounds && form.values.bracketRounds[i]) ? form.values.bracketRounds[i].noOfGames : 1;
            bracketRounds.push({ label: label, noOfGames: noOfGames })

        })

        form.reset({ ...form.values, teamIds: teamIds, bracketRounds: bracketRounds });

    }, [form.key, form.values.type]);

    let getMaxNoOfTeams = (noOfRounds) => {

        noOfRounds = parseNum(noOfRounds);

        if (noOfRounds === 1) return 2;
        if (noOfRounds === 2) return 4;
        if (noOfRounds === 3) return 8;
        if (noOfRounds === 4) return 16;
        if (noOfRounds === 5) return 32;

    };

    let maxNoOfTeams: any = getMaxNoOfTeams(form.values.noOfRounds);

    return useMemo(() => (

        <Panel
            heading={"League Division Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                {mode === "attach" && form.defaults.seasonId &&
                    <FieldHidden
                        form={form}
                        name="seasonId"
                        label="Season"
                        defaultValue={form.defaults.seasonId}
                    />
                }

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 16 }}>

                        <FieldText
                            form={form}
                            label={"Name"}
                            name='name'
                            required={true}
                            defaultValue={form.defaults.name}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldRadio
                            form={form}
                            label={"Type"}
                            name='type'
                            required={true}
                            defaultValue={form.defaults.type}
                            options={[
                                { value: "table", label: "Table" },
                                { value: "bracket", label: "Bracket" }
                            ]}
                        />

                    </Col>

                    {(mode !== "attach" && mode === "create") &&
                        <Col col={{ xs: 24, md: 16 }}>

                            <FieldRelationship
                                form={form}
                                label='Season'
                                name='seasonId'
                                required={true}
                                preload={true}
                                endpoint={'LeagueSeason'}
                                defaultValue={form.defaults.seasonId}
                            />

                        </Col>
                    }

                    {form.values.type === "table" &&
                        <>
                            <Col col={{ xs: 12, md: 6 }}>

                                <FieldNumber
                                    form={form}
                                    label='Points For Win'
                                    name='pointsForWin'
                                    required={true}
                                    defaultValue={form.defaults.pointsForWin}
                                />

                            </Col>
                            <Col col={{ xs: 12, md: 6 }}>

                                <FieldNumber
                                    form={form}
                                    label='Points For Draw'
                                    name='pointsForDraw'
                                    required={false}
                                    defaultValue={form.defaults.pointsForDraw}
                                />

                            </Col>
                            <Col col={{ xs: 12, md: 6 }}>

                                <FieldNumber
                                    form={form}
                                    label='Points For Loss'
                                    name='pointsForLoss'
                                    required={false}
                                    defaultValue={form.defaults.pointsForLoss}
                                />

                            </Col>
                            <Col col={{ xs: 12, md: 6 }}>

                                <FieldNumber
                                    form={form}
                                    label='Points For No Contest'
                                    name='pointsForNc'
                                    required={false}
                                    defaultValue={form.defaults.pointsForNc}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <Seperator
                                    top={"auto"}
                                    bottom={"auto"}
                                    heading={"Teams"}
                                />

                                <FieldList
                                    form={form}
                                    name={`teamIds`}
                                    required={false}
                                    // label={"Teams"}
                                    addButtonLabel={"Add Team"}
                                    min={0}
                                >

                                    {(form.values.teamIds || []).map((teamId, i) => (

                                        <FieldRelationship
                                            form={form}
                                            // label='Team'
                                            name={`teamIds[${i}]`}
                                            required={true}
                                            preload={true}
                                            endpoint={'LeagueSeasonTeam'}
                                            defaultValue={teamId}
                                            where={{ seasonId: form.values.seasonId }}
                                        />

                                    ))}

                                </FieldList>

                            </Col>

                        </>
                    }

                    {form.values.type === "bracket" &&
                        <React.Fragment>
                            <Col col={{ xs: 24, md: 8 }}>

                                <FieldSelect
                                    form={form}
                                    label='No. of Rounds'
                                    name='noOfRounds'
                                    required={true}
                                    defaultValue={form.defaults.noOfRounds}
                                    options={[
                                        { value: "1", label: "1 Round, 2 Teams" },
                                        { value: "2", label: "2 Round, 4 Teams" },
                                        { value: "3", label: "3 Round, 8 Teams" },
                                        { value: "4", label: "4 Round, 16 Teams" },
                                        { value: "5", label: "5 Round, 32 Teams" },
                                        // { value: "6", label: "6 Round, 64 Teams" },
                                    ]}
                                    onChange={handleChangeRounds}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <Seperator
                                    top={"auto"}
                                    bottom={"auto"}
                                    heading={"Teams"}
                                />

                                {Array.apply(0, Array(maxNoOfTeams)).map((x, i) => (
                                    <FieldRelationship
                                        key={i}
                                        form={form}
                                        label={`Team (Seed: ${i + 1})`}
                                        name={`teamIds[${i}]`}
                                        preload={true}
                                        endpoint={'LeagueSeasonTeam'}
                                        defaultValue={form.defaults.teamIds[i]}
                                        where={{ seasonId: form.values.seasonId }}
                                    />
                                ))}

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <Seperator
                                    top={"auto"}
                                    bottom={"auto"}
                                    heading={"Rounds"}
                                />

                                {(form.values.bracketRounds || []).map((round, i) => (

                                    <Row gutter={8} edge={true} key={hash([form.key, i])}>

                                        <Col col={{ xs: 24, md: 12 }}>

                                            <FieldText
                                                form={form}
                                                label={'Round ' + (i + 1)}
                                                name={`bracketRounds[${i}].label`}
                                                required={true}
                                                defaultValue={round.label}
                                            />

                                        </Col>

                                        <Col col={{ xs: 24, md: 12 }}>

                                            <FieldSelect
                                                form={form}
                                                label={'No. of Games in Series'}
                                                name={`bracketRounds[${i}].noOfGames`}
                                                required={true}
                                                defaultValue={round.noOfGames}
                                                options={[
                                                    { value: 1, label: "1 Game" },
                                                    { value: 3, label: "3 Games" },
                                                    { value: 5, label: "5 Games" },
                                                    { value: 7, label: "7 Games" },
                                                ]}
                                            />

                                        </Col>

                                    </Row>


                                ))}

                            </Col>
                        </React.Fragment>
                    }


                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>

    ), [props, maxNoOfTeams]);

}

export default LeagueSeasonDivisionCreateEditForm;
