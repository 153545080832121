import { apiGet, apiPatch, baseApi, options, response } from "@dex/bubl-fetch";

const endpoint = 'CampSession';

const CampSessionApi: any = baseApi(endpoint);

CampSessionApi.getAttendance = (sessionId: string, options: options = {}): response => {

    return apiGet({
        url: "CampAttendance",
        params: {
            where: { sessionId: sessionId },
            limit: 10000,
            include: [
                {
                    relation: "owner",
                    scope: {
                        fields: ["id", "modelName", "firstName", "lastName", "fullName", "email", "phone"]
                    }
                },
                // {
                //     relation: "pass"
                // },
                {
                    relation: "athlete"
                }
            ]
        },
        ...options
    })

}

CampSessionApi.markAttendance = (id: string, options: options = {}): response => {

    return apiPatch({
        url: "CampAttendance/" + id + "/mark",
        ...options
    })

}

CampSessionApi.updateAttending = (id: string, options: options = {}): response => {

    return apiPatch({
        url: "CampAttendance/" + id + "/update",
        ...options
    })

}

export default CampSessionApi;