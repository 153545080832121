import React, { useState } from 'react'
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import OrderApi from '../../Order/OrderApi';
import { useApp } from '@dex/bubl-dash';
import { Form, useForm } from '@dex/bubl-dash/src/lib/functions/useForm';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import Modal from '@dex/bubl-dash/src/lib/components/Modal/Modal';
import FieldSwitch from '@dex/bubl-dash/src/lib/components/Fields/FieldSwitch';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';

const VenueBookingPurchaseVideoButton: React.FC<any> = (props: any) => {

    const { booking } = props;

    const [showModal, setShowModal] = useState(false);

    const app = useApp();

    const form = useForm({});

    const [nav] = app.store("nav");

    const create = OrderApi.create({

        onComplete: (data) => {

            app.alert('Order Created', 'success');

            const route = { "type": "Navigation/NAVIGATE", routeName: "shop/ordersView", params: { id: data.id } }

            app.navigate(nav, 'push', route);

        }
    });

    const handleSubmit = () => {

        const values = {
            type: 'videoPlayback',
            productId: booking.court.videoProductId,
            bookingId: booking.id,
            ownerId: booking.owner.id
        }

        create.run({ data: values });

    }


    return (
        <>
            <Button
                label="Purchase Video"
                type="primary"
                size="small"
                onClick={setShowModal.bind(null, true)}
            />

            <Modal
                show={showModal}
                onClose={setShowModal.bind(null, false)}
                width={720}
            >

                <Panel
                    heading={"Purchase Session Video"}
                >

                    <Form
                        form={form}
                        onSubmit={handleSubmit}
                        loading={create.loading}
                    >

                        <FieldSwitch
                            form={form}
                            name={"confirm"}
                            label="Generate Video Playback Order"
                            required={true}
                            defaultValue={form.defaults.confirm}
                        />

                        <Line />

                        <ButtonRow>

                            <Button
                                label={"Submit"}
                                loading={create.loading}
                            />

                        </ButtonRow>

                    </Form>

                </Panel>

            </Modal>
        </>
    )
}

export default VenueBookingPurchaseVideoButton
