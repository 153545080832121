import React, { useMemo, useEffect, useCallback } from 'react';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';
import HostRequestsApi from '../HostRequestsApi';
import Alert from '@dex/bubl-dash/src/lib/components/Alert/Alert';
import Link from '@dex/bubl-dash/src/lib/components/Link/Link';

const HostRequestsGoLive: React.FC<any> = (props: any) => {

    const app = useApp();

    const [nav] = app.store("nav");

    const { data } = props;

    const existing = HostRequestsApi.get({ params: { limit: 1, where: { status: "pending", propertyId: data.id, propertyType: data.modelName } } });

    const onClickRequest = useCallback(() => {

        if (existing.loading) return;

        existing.run({
            onComplete: (response) => {

                if (response.data && response.data[0]) {

                    const route = { "type": "Navigation/NAVIGATE", routeName: "hostRequestsView", params: { id: response.data[0].id } }

                    app.navigate(nav, 'push', route);

                } else {

                    const route = { "type": "Navigation/NAVIGATE", routeName: "hostRequestsNew", params: { propertyId: data.id, propertyType: data.modelName, propertyName: data.title, hostId: data.hostId } }

                    app.navigate(nav, 'push', route);

                }

            }
        });

    }, [data, existing]);

    return useMemo(() => (

        <>
            {!data.public &&

                <Alert
                    message={"Host Preview Only"}
                    description={<span>This property requires verification before it is publicly visible on the HUDDLE App. <Link onClick={onClickRequest}>Request Verification</Link>.<br />In the mean time you can preview it on the HUDDLE App if you are signed in to this Host user.</span>}
                />

            }
        </>

    ), [data, existing]);

}

export default HostRequestsGoLive;
