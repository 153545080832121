import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText'
import FieldTextArea from '@dex/bubl-dash/src/lib/components/Fields/FieldTextArea'
import FieldPhone from '@dex/bubl-dash/src/lib/components/Fields/FieldPhone'
import FieldAddress from '@dex/bubl-dash/src/lib/components/Fields/FieldAddress'
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import FieldUpload from '@dex/bubl-dash/src/lib/components/Fields/FieldUpload';
import FieldSwitch from '@dex/bubl-dash/src/lib/components/Fields/FieldSwitch';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship';

const HostsCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <Panel
            heading={"Hosts Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Seperator
                    heading={"Public Profile"}
                    bottom={"auto"}
                />

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 12 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24 }}>

                                <FieldUpload
                                    form={form}
                                    folder={'admin-media'}
                                    label={"Logo"}
                                    name={`logo`}
                                    required={true}
                                    defaultValue={form.defaults.logo}
                                    limit={1}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldUpload
                                    form={form}
                                    folder={'admin-media'}
                                    label={"Cover"}
                                    name={`cover`}
                                    required={false}
                                    defaultValue={form.defaults.cover}
                                    limit={1}
                                />

                            </Col>

                        </Row>

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24 }}>

                                <FieldRadio
                                    form={form}
                                    label={"Status"}
                                    name='status'
                                    required={true}
                                    defaultValue={form.defaults.status}
                                    options={[
                                        { value: "pendingVerification", label: "Pending Verification" },
                                        { value: "verified", label: "Verified" },
                                        { value: "rejected", label: "Rejected" },
                                        { value: "revoked", label: "Revoked" },
                                    ]}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldRadio
                                    form={form}
                                    label={"Community Status"}
                                    name='communityStatus'
                                    required={false}
                                    defaultValue={form.defaults.communityStatus}
                                    options={[
                                        { value: "draft", label: "Draft" },
                                        { value: "preview", label: "Preview" },
                                        { value: "publish", label: "Publish" },
                                        { value: "archive", label: "Archive" },
                                    ]}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldSwitch
                                    form={form}
                                    label={"Featured Community"}
                                    name='featuredCommunity'
                                    required={false}
                                    defaultValue={form.defaults.featuredCommunity}
                                />

                            </Col>

                        </Row>

                    </Col>

                </Row>

                <Seperator
                    heading={"Host Details"}
                    top={"auto"}
                    bottom={"auto"}
                />

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 12 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24 }}>

                                <FieldText
                                    form={form}
                                    label="Organization Name"
                                    name="orgName"
                                    required={true}
                                    defaultValue={form.defaults.orgName}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldTextArea
                                    form={form}
                                    label="Organization Description"
                                    name="orgDescription"
                                    required={false}
                                    defaultValue={form.defaults.orgDescription}
                                    rows={4}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldRelationship
                                    form={form}
                                    label="Sports Played"
                                    name="sportsIds"
                                    required={true}
                                    defaultValue={form.defaults.sportsIds}
                                    endpoint={'Sport'}
                                    preload={true}
                                    isMulti={true}
                                />

                            </Col>

                        </Row>

                    </Col>
                    <Col col={{ xs: 24, md: 12 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24 }}>

                                <FieldText
                                    form={form}
                                    label="Website Link"
                                    name="websiteLink"
                                    required={false}
                                    defaultValue={form.defaults.websiteLink}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldText
                                    form={form}
                                    label="Facebook Link"
                                    name="facebookLink"
                                    required={false}
                                    defaultValue={form.defaults.facebookLink}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldText
                                    form={form}
                                    label="Instagram Link"
                                    name="instagramLink"
                                    required={false}
                                    defaultValue={form.defaults.instagramLink}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldText
                                    form={form}
                                    label="Twitter Link"
                                    name="twitterLink"
                                    required={false}
                                    defaultValue={form.defaults.twitterLink}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldText
                                    form={form}
                                    label="Tiktok Link"
                                    name="tiktokLink"
                                    required={false}
                                    defaultValue={form.defaults.tiktokLink}
                                />

                            </Col>

                        </Row>

                    </Col>

                </Row>

                <Seperator
                    heading={"Company Details"}
                    top={"auto"}
                    bottom={"auto"}
                />

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 12 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24 }}>

                                <FieldText
                                    form={form}
                                    label="Comapny Name"
                                    name="companyName"
                                    required={false}
                                    defaultValue={form.defaults.companyName}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldText
                                    form={form}
                                    label="Comapny Reg. No"
                                    name="companyRegNo"
                                    required={false}
                                    defaultValue={form.defaults.companyRegNo}
                                />

                            </Col>

                        </Row>

                    </Col>
                    <Col col={{ xs: 24, md: 12 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24 }}>

                                <FieldText
                                    form={form}
                                    label="Contact Person"
                                    name="contactPerson"
                                    required={false}
                                    defaultValue={form.defaults.contactPerson}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldText
                                    form={form}
                                    label="Contact Email"
                                    name="contactEmail"
                                    required={false}
                                    defaultValue={form.defaults.contactEmail}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldPhone
                                    form={form}
                                    label={"Contact Phone"}
                                    name={'contactPhone'}
                                    required={false}
                                    defaultValue={form.defaults.contactPhone}
                                />

                            </Col>

                        </Row>

                    </Col>

                </Row>

                <Seperator
                    heading={"Address"}
                    top={"auto"}
                    bottom={"auto"}
                />

                <FieldAddress
                    form={form}
                    label={"Company Address"}
                    name={`companyAddress`}
                    required={false}
                    defaultValue={form.defaults.companyAddress}
                    gutter={8}
                />

                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default HostsCreateEditForm;
