import { baseApi, apiPatch, response, options, apiGet } from "@dex/bubl-fetch";

const endpoint = 'LeagueSeason';

const LeagueSeasonApi: any = baseApi(endpoint);

LeagueSeasonApi.fetchData = (id: string, options: options): response => {

    return apiPatch({
        "url": endpoint + "/" + id + "/fetchData",
        ...options,
        timeout: 120000
    });

}

LeagueSeasonApi.getSportFields = (id: string, options: options): response => {

    return apiGet({
        "url": endpoint + "/" + id + "/getSportFields",
        ...options
    });

}

export default LeagueSeasonApi;