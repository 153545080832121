import NewsIndex from './NewsIndex';
import NewsCreate from './NewsCreate';
import NewsSettings from './NewsSettings';
import NewsSingle from './NewsSingle';
import NewsCategorySettings from '../NewsCategory/NewsCategorySettings';
import NewsTagSettings from '../NewsTag/NewsTagSettings';
import CommentSettings from '../Comment/CommentSettings';
import ReactionSettings from '../Reaction/ReactionSettings';
import SportSettings from "../Sport/SportSettings";
import PageSettings from "../Page/PageSettings";
import AnnouncementsSettings from "../Announcements/AnnouncementsSettings";
import PushNotificationSettings from "../PushNotification/PushNotificationSettings";

export default function (app) {

    const settings = NewsSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    app.addMenu({
        key: settings.key,
        zone: settings.zone,
        label: "Content",
        priority: 10,
        items: [
            {
                label: "News",
                path: settings.path,
                caps: settings.indexCaps
            },
            {
                label: "Categories",
                path: NewsCategorySettings.path,
                caps: NewsCategorySettings.indexCaps
            },
            {
                label: "Tags",
                path: NewsTagSettings.path,
                caps: NewsTagSettings.indexCaps
            },
            {
                label: "Comments",
                path: CommentSettings.path,
                caps: CommentSettings.indexCaps
            },
            {
                label: "Reactions",
                path: ReactionSettings.path,
                caps: ReactionSettings.indexCaps
            },
            {
                label: "Sports",
                path: SportSettings.path,
                caps: SportSettings.indexCaps
            },
            {
                label: "Pages",
                path: PageSettings.path,
                caps: PageSettings.indexCaps
            },
            {
                label: "Announcements",
                path: AnnouncementsSettings.path,
                caps: AnnouncementsSettings.indexCaps
            },
            {
                label: "Push Notifications",
                path: PushNotificationSettings.path,
                caps: PushNotificationSettings.indexCaps
            }
        ],
    });

    app.addMenu({
        key: settings.key,
        zone: settings.zone,
        label: "Actions",
        priority: 10,
        items: [
            {
                label: "Add New News",
                path: settings.path + '/create',
                caps: settings.createCaps,
            },
            {
                label: "Add New Category",
                path: NewsCategorySettings.path + "/create",
                caps: NewsCategorySettings.createCaps,
            },
            {
                label: "Add New Tag",
                path: NewsTagSettings.path + "/create",
                caps: NewsTagSettings.createCaps,
            },
            {
                label: "Add New Reaction",
                path: ReactionSettings.path + "/create",
                caps: ReactionSettings.createCaps,
            },
            {
                label: "Add New Sport",
                path: SportSettings.path + "/create",
                caps: SportSettings.createCaps,
            },
            {
                label: "Add New Page",
                path: PageSettings.path + "/create",
                caps: PageSettings.createCaps,
            },
            {
                label: "Add New Announcement",
                path: AnnouncementsSettings.path + "/create",
                caps: AnnouncementsSettings.createCaps,
            },
            {
                label: "Add New Push Notification",
                path: PushNotificationSettings.path + "/create",
                caps: PushNotificationSettings.createCaps,
            }
        ],
    });

    NewsIndex(app, settings);

    NewsCreate(app, settings);

    NewsSingle(app, settings);

}