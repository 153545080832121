import React, { useCallback, useMemo, useState } from 'react';
import IndexTable from '@dex/bubl-dash/src/lib/components/IndexTable/IndexTable';
import LeagueSeasonGameSettings from '../../LeagueSeasonGame/LeagueSeasonGameSettings';

const LeagueSeasonTeamGames: React.FC<any> = (props: any) => {

    const { data } = props;

    const [tableState, setTableState] = useState({ search: undefined, where: undefined, order: undefined, skip: undefined });

    const preFetchFilters = useCallback((routeName, params, users) => {

        const filters = LeagueSeasonGameSettings.indexFetchParams(routeName, params, users);

        filters.where.teamIds = { inq: [data.id] };

        return filters;

    }, []);

    const getColumns = useCallback((routeName, params, users) => {

        const columns = LeagueSeasonGameSettings.indexColumns(routeName, params, users);

        // filters.where.seasonId = data.id;
        columns.map((column, index) => {

            if (column.key === "host") delete (columns[index]);
            else if (column.key === "season") delete (columns[index]);
            else if (column.key === "league") delete (columns[index]);
            else if (column.key === "division") delete (columns[index]);
            else if (column.key === "created") delete (columns[index]);

            return column;

        });

        return columns;

    }, []);

    return useMemo(() => (

        <>

            <IndexTable
                title={"Games"}
                getFetch={LeagueSeasonGameSettings.indexFetch}
                getColumns={getColumns}
                preFetchFilters={preFetchFilters}
                getQuickSearch={LeagueSeasonGameSettings.indexQuickSearch}
                getSearchFields={LeagueSeasonGameSettings.indexSearchFields}
                routeParams={tableState}
                manageState={setTableState}
                hideTrashSwitch={true}

            />

        </>

    ), [data, tableState]);

}

export default LeagueSeasonTeamGames;
