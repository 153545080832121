import React, { useCallback, useMemo, useState } from 'react';
import IndexTable from '@dex/bubl-dash/src/lib/components/IndexTable/IndexTable';
import LeagueSeasonPlayerSettings from '../../LeagueSeasonPlayer/LeagueSeasonPlayerSettings';
import Button from '@dex/bubl-dash/src/lib/components/Button/Button';
import LeagueSeasonTeamAddNewPlayer from './LeagueSeasonTeamAddNewPlayer';
import { ButtonRow } from '@dex/bubl-dash';
import LeagueSeasonTeamImportPlayers from './LeagueSeasonTeamImportPlayers';

const LeagueSeasonTeamPlayers: React.FC<any> = (props: any) => {

    const { data } = props;

    const [key, setKey] = useState(0);

    const [tableState, setTableState] = useState({ search: undefined, where: undefined, order: undefined, skip: undefined });

    const [showAddNew, setShowAddNew] = useState(false);

    const [showImport, setShowImport] = useState(false);

    const preFetchFilters = useCallback((routeName, params, users) => {

        const filters = LeagueSeasonPlayerSettings.indexFetchParams(routeName, params, users);

        filters.where.teamId = data.id;

        return filters;

    }, []);

    const getColumns = useCallback((routeName, params, users) => {

        const columns = LeagueSeasonPlayerSettings.indexColumns(routeName, params, users);

        // filters.where.seasonId = data.id;
        columns.map((column, index) => {

            if (column.key === "host") delete (columns[index]);
            else if (column.key === "season") delete (columns[index]);
            else if (column.key === "league") delete (columns[index]);
            else if (column.key === "team") delete (columns[index]);
            else if (column.key === "created") delete (columns[index]);

            return column;

        });

        return columns;

    }, []);

    const handleClose = useCallback(() => {

        setKey(key + 1);
        setShowAddNew(false);
        setShowImport(false);

    }, [key])

    return useMemo(() => (

        <>

            <IndexTable
                key={key}
                title={"Players"}
                getFetch={LeagueSeasonPlayerSettings.indexFetch}
                getColumns={getColumns}
                preFetchFilters={preFetchFilters}
                getQuickSearch={LeagueSeasonPlayerSettings.indexQuickSearch}
                getSearchFields={LeagueSeasonPlayerSettings.indexSearchFields}
                routeParams={tableState}
                manageState={setTableState}
                hideTrashSwitch={true}
                actionButtons={() => (
                    <ButtonRow>
                        <Button
                            label={"Import Players"}
                            size="small"
                            type="light"
                            onClick={setShowImport.bind(null, true)}
                        />
                        <Button
                            label={"Add New Player"}
                            size="small"
                            type="light"
                            onClick={setShowAddNew.bind(null, true)}
                        />
                    </ButtonRow>
                )}
            />

            {showAddNew &&
                <LeagueSeasonTeamAddNewPlayer
                    onClose={handleClose}
                    data={data}
                />
            }

            {showImport &&
                <LeagueSeasonTeamImportPlayers
                    onClose={handleClose}
                    data={data}
                />
            }

        </>

    ), [data, tableState, showAddNew, showImport, key]);

}

export default LeagueSeasonTeamPlayers;
