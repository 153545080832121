import React, { useMemo } from 'react';
import { Row, Col, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ActivityPanel from '@dex/bubl-dash/src/lib/components/SinglePanels/ActivityPanel';
import VenueBookingAttendees from './VenueBookingAttendees';
import VenueBookingCancelButton from './VenueBookingCancelButton';
import VenueBookingSessionVideoPanel from './VenueBookingSessionVideoPanel';
import WebLink from '../../../components/WebLink/WebLink';

const VenueBookingSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <WebLink
                path={`/venues/booking/${data.id}`}
            />

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Booking Details"}
                    actions={(
                        <VenueBookingCancelButton session={data} />
                    )}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: 'Booked By',
                                key: 'owner',
                                format: 'ModelLink'
                            },
                            {
                                label: "Court",
                                key: "court",
                                format: 'ModelLink'
                            },
                            {
                                label: "Host",
                                key: 'host',
                                format: 'ModelLink'
                            },
                            {
                                label: "Order",
                                key: "order",
                                format: 'ModelLink'
                            }
                        ]}
                        columnB={[
                            {
                                label: "Date",
                                key: "date",
                                format: 'date'
                            },
                            {
                                label: "Start Time",
                                key: "startTime",
                            },
                            {
                                label: "Duration",
                                key: "duration",
                                format: "number",
                                suffix: " Hour(s)"
                            },
                            {
                                label: "Status",
                                key: "status",
                                format: "titleCase"
                            }
                        ]}
                        columnC={[
                            {
                                label: "Created",
                                key: "created",
                                format: 'OnAndBy'
                            },
                            {
                                label: "Share Code",
                                key: "shareCode",
                            }
                        ]}
                    />

                </Panel>

                {data.attendees && data.attendees.length > 0 &&
                    <>

                        <Space />

                        <Panel
                            heading={"Attendees"}
                        >

                            <VenueBookingAttendees data={data.attendees} />

                        </Panel>

                    </>
                }

            </Col>

            <Col className={'side'}>

                <VenueBookingSessionVideoPanel booking={data} />

                <Space />

                <ActivityPanel parent={data} />

            </Col>

        </Row>

    ), [data]);

}

export default VenueBookingSingleView;
