import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ActivityPanel from '@dex/bubl-dash/src/lib/components/SinglePanels/ActivityPanel';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';
import RenderContentBlocks from '../../../components/RenderContentBlocks/RenderContentBlocks';
import WebLink from '../../../components/WebLink/WebLink';

const NewsSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <WebLink
                path={`/news/article/${data.id}/${data.slug}`}
            />

            <Col
                className={'main'}
            >

                <Panel
                    heading={"News Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Title",
                                key: "title",
                            },
                            {
                                label: "Type",
                                key: "type",
                                format: "titleCase"
                            },
                            {
                                label: "Short Description",
                                key: "description",
                            },
                            {
                                label: "Thumbnail",
                                key: "thumbnail.fileId",
                                format: "image",
                                filters: {
                                    width: 60,
                                    height: 60
                                },
                                link: true
                            },

                        ]}
                        columnB={[
                            {
                                label: "Status",
                                key: "status",
                                format: "titleCase"
                            },
                            {
                                label: "Global Feed",
                                key: "globalFeed",
                                format: "switch",
                            },
                            {
                                label: "Date Published",
                                key: "date",
                                format: "DateTime",
                            },
                        ]}
                        columnC={[
                            {
                                label: "Host",
                                key: "host",
                                format: "ModelLink"
                            },
                            {
                                label: "Tag",
                                key: "tag",
                                format: "ModelLink",
                            },
                            {
                                label: "League",
                                key: "league",
                                format: "ModelLink",
                            },
                            {
                                label: "Season",
                                key: "season",
                                format: "ModelLink",
                            },
                        ]}
                    />

                    {data.contentBlocks &&
                        <>

                            <Seperator
                                heading="Content"
                                top="auto"
                                bottom="auto"
                            />

                            <RenderContentBlocks
                                blocks={data.contentBlocks}
                            />

                        </>
                    }

                </Panel>

            </Col>

            <Col className={'side'}>

                <ActivityPanel parent={data} />

            </Col>

        </Row>

    ), [data]);

}

export default NewsSingleView;
