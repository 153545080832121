import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ActivityPanel from '@dex/bubl-dash/src/lib/components/SinglePanels/ActivityPanel';
import Seperator from "@dex/bubl-dash/src/lib/components/Seperator/Seperator";

const AssessmentFormsSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Assessment Form"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Title",
                                key: "this",
                                format: "ModelLink",
                            }
                        ]}
                        columnB={[
                            {
                                label: "Host",
                                key: "host",
                                format: "modelLink"
                            },
                        ]}
                        columnC={[]}
                    />

                    {data.criteria &&
                        <>

                            <Seperator
                                heading="Criteria"
                                top="auto"
                                bottom="auto"
                            />

                            <MetaTable
                                data={data.criteria || []}
                                columnA={[
                                    {
                                        label: "Title",
                                        key: "title",
                                    }

                                ]}
                                columnB={[
                                    {
                                        label: "Description",
                                        key: "description",
                                    }
                                ]}
                                columnC={[]}
                            />

                        </>
                    }

                </Panel>

            </Col>

            <Col className={'side'}>

                <ActivityPanel parent={data} />

            </Col>

        </Row>

    ), [data]);

}

export default AssessmentFormsSingleView;
