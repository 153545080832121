import React, { useCallback } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import BracketItem from './BracketItem';

import styles from "./Bracket.module.scss";

const Bracket: React.FC<BracketProps> = (props) => {

    const bracket = props.data;

    const height = ((bracket[0]?.matchups?.length || 3) * 152) + 10 + 10;

    const handlePress = useCallback((matchup) => {

    }, []);

    return (

        <div className={styles.bracketWrap}>

            <Scrollbars className={styles.bracketScroll} autoHeight={true} hideTracksWhenNotNeeded={true} autoHeightMin={height}>

                <div className={styles.bracketInner}>

                    {bracket.map((column: any, index: any) => (

                        <div
                            key={index}
                            className={[
                                styles.column,
                                column.rtl ? styles.columnRtl : '',
                                'columnSize' + column.matchups.length,
                                (index === 0) ? styles.columnFirst : '',
                                (index + 1 === bracket.length) ? styles.columnLast : ''
                            ].join(" ")}
                        >

                            <span className={styles.columnTitle}>{column.title}</span>

                            {(column.matchups || []).map((matchup: any, key: any) => (

                                <BracketItem
                                    key={key}
                                    matchup={matchup}
                                    onPress={handlePress}
                                />

                            ))}

                        </div>

                    ))}

                </div>

            </Scrollbars>

        </div>

    )

}

interface BracketProps {
    [key: string]: any
}

export default Bracket;