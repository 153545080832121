import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import FieldNumber from '@dex/bubl-dash/src/lib/components/Fields/FieldNumber';
import FieldSelect from '@dex/bubl-dash/src/lib/components/Fields/FieldSelect';

const ProductCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <Panel
            heading={"Product Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 16 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={24}>

                                <FieldText
                                    form={form}
                                    label={"Title"}
                                    name='title'
                                    required={true}
                                    defaultValue={form.defaults.title}
                                />

                            </Col>

                            <Col col={24}>

                                <FieldText
                                    form={form}
                                    label={"Short Title"}
                                    name='shortTitle'
                                    required={true}
                                    defaultValue={form.defaults.shortTitle}
                                />

                            </Col>

                            <Col col={24}>

                                <FieldText
                                    form={form}
                                    label={"Description"}
                                    name='description'
                                    required={false}
                                    defaultValue={form.defaults.description}
                                />

                            </Col>

                            <Col col={24}>

                                <FieldSelect
                                    form={form}
                                    label={"Type"}
                                    name='type'
                                    required={true}
                                    defaultValue={form.defaults.type}
                                    options={[
                                        { value: "pickupPass", label: "Pickup Pass" },
                                        { value: "campPass", label: "Camp Pass" },
                                        // { value: "leagueEntry", label: "League Entry" },
                                        { value: "leagueEntryTeam", label: "League Entry Team" },
                                        { value: "venueBooking", label: "Venue Booking" },
                                        { value: "venueBlockBooking", label: "Venue Block Booking" },
                                        { value: "venueLiveStream", label: "Venue Live Stream" },
                                        { value: "videoPlayback", label: "Video Playback" },
                                        { value: "payPerView", label: "Pay Per View" },
                                    ]}
                                />

                            </Col>

                            {form.values.type === "pickupPass" &&
                                <Col col={{ md: 24, xs: 24 }}>

                                    <FieldRelationship
                                        form={form}
                                        label={"Operator"}
                                        name='operatorId'
                                        required={true}
                                        preload={true}
                                        endpoint={'PickupOperator'}
                                        defaultValue={form.defaults.operatorId || form.values.operatorId}
                                    />

                                </Col>

                            }

                            {form.values.type === "pickupPass" && form.values.operatorId &&
                                <Col col={{ md: 24, xs: 24 }}>

                                    <FieldRelationship
                                        form={form}
                                        label={"Pickup Session Groups"}
                                        name='sessionGroupIds'
                                        required={true}
                                        preload={true}
                                        isMulti={true}
                                        endpoint={'PickupSessionGroup'}
                                        where={{ operatorId: form.values.operatorId }}
                                        defaultValue={form.defaults.sessionGroupIds || form.values.sessionGroupIds}
                                    />

                                </Col>

                            }

                            {(form.values.type === "pickupPass" || form.values.type === "campPass") &&
                                <>

                                    <Col col={{ md: 12, xs: 12 }}>

                                        <FieldRadio
                                            form={form}
                                            label={"Pass Type"}
                                            name='passType'
                                            required={true}
                                            defaultValue={form.defaults.passType}
                                            options={[
                                                { value: "session", label: "Session Based" },
                                                { value: "time", label: "Time Based" }
                                            ]}
                                        />

                                    </Col>

                                    <Col col={{ md: 12, xs: 12 }}>

                                        {form.values.passType === "session" &&

                                            <FieldNumber
                                                form={form}
                                                label={"No. of Sessions"}
                                                name={`validSessions`}
                                                required={true}
                                                defaultValue={form.defaults.validSessions}
                                            />

                                        }

                                        {form.values.passType === "time" &&

                                            <FieldNumber
                                                form={form}
                                                label={"No. of Days"}
                                                name={`validDays`}
                                                required={true}
                                                defaultValue={form.defaults.validDays}
                                            />

                                        }

                                    </Col>

                                </>
                            }

                            {(form.values.type === "leagueEntryTeam" || form.values.type === "leagueEntryPlayer") &&
                                <>
                                    <Col col={12}>

                                        <FieldRelationship
                                            form={form}
                                            label={"League"}
                                            name='leagueId'
                                            required={true}
                                            preload={true}
                                            endpoint={'League'}
                                            defaultValue={form.defaults.leagueId}
                                        />

                                    </Col>
                                    {form.values.leagueId &&
                                        <Col col={12}>

                                            <FieldRelationship
                                                key={form.values.leagueId}
                                                form={form}
                                                label={"League Season"}
                                                name='seasonId'
                                                required={true}
                                                preload={true}
                                                endpoint={'LeagueSeason'}
                                                defaultValue={form.defaults.seasonId}
                                                where={{ leagueId: form.values.leagueId }}
                                            />

                                        </Col>
                                    }
                                </>
                            }

                            {form.values.type === "campPass" &&
                                <Col col={24}>

                                    <FieldRelationship
                                        form={form}
                                        label={"Camp"}
                                        name='campId'
                                        required={true}
                                        preload={true}
                                        endpoint={'CampOperator'}
                                        defaultValue={form.defaults.campId || form.values.campId}
                                    />

                                </Col>
                            }

                            {form.values.type === "campPass" && form.values.campId &&
                                <Col col={24}>

                                    <FieldRelationship
                                        form={form}
                                        label={"Camp Session Groups"}
                                        name='sessionGroupIds'
                                        required={true}
                                        preload={true}
                                        isMulti={true}
                                        endpoint={'CampSessionGroup'}
                                        where={{ operatorId: form.values.campId }}
                                        defaultValue={form.defaults.sessionGroupIds || form.values.sessionGroupIds}
                                    />

                                </Col>
                            }

                        </Row>

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={24}>

                                <FieldRadio
                                    form={form}
                                    label={"Status"}
                                    name='status'
                                    required={true}
                                    defaultValue={form.defaults.status}
                                    options={[
                                        { value: "draft", label: "Draft" },
                                        { value: "publish", label: "Publish" },
                                        { value: "archive", label: "Archive" },
                                    ]}
                                />

                            </Col>

                            <Col col={24}>

                                <FieldRelationship
                                    form={form}
                                    label={"Data Form"}
                                    name='formId'
                                    required={false}
                                    preload={true}
                                    endpoint={'Form'}
                                    defaultValue={form.defaults.formId || form.values.formId}
                                />

                            </Col>

                            <Col col={24}>

                                <FieldNumber
                                    form={form}
                                    label={"Price"}
                                    name={`price`}
                                    required={false}
                                    defaultValue={form.defaults.price}
                                />


                            </Col>

                            <Col col={24}>

                                <FieldNumber
                                    form={form}
                                    label={"Priority"}
                                    name={`priority`}
                                    required={false}
                                    defaultValue={form.defaults.priority}
                                />


                            </Col>

                        </Row>

                    </Col>

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default ProductCreateEditForm;
