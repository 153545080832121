import PickupSessionIndex from './PickupSessionIndex';
import PickupSessionCreate from './PickupSessionCreate';
import PickupSessionSettings from './PickupSessionSettings';
import PickupSessionSingle from './PickupSessionSingle';
import PickupOperatorSettings from '../PickupOperator/PickupOperatorSettings';
import PickupSessionGroupSettings from '../PickupSessionGroup/PickupSessionGroupSettings';
import PickupCategorySettings from '../PickupCategory/PickupCategorySettings';
import PickupPassesSettings from '../PickupPasses/PickupPassesSettings';
import PickupAttendanceSettings from '../PickupAttendance/PickupAttendanceSettings';

export default function (app) {

    const settings = PickupSessionSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    app.addMenu({
        key: settings.key,
        zone: settings.zone,
        label: "Pickup",
        priority: 8,
        items: [
            {
                label: "Sessions",
                path: settings.path,
                caps: settings.indexCaps
            },
            {
                label: "Session Groups",
                path: PickupSessionGroupSettings.path,
                caps: PickupSessionGroupSettings.indexCaps
            },
            {
                label: "Operators",
                path: PickupOperatorSettings.path,
                caps: PickupOperatorSettings.indexCaps
            },
            {
                label: "Categories",
                path: PickupCategorySettings.path,
                caps: PickupCategorySettings.indexCaps
            },
            {
                label: "Passes",
                path: PickupPassesSettings.path,
                caps: PickupPassesSettings.indexCaps
            },
            {
                label: "Attendance",
                path: PickupAttendanceSettings.path,
                caps: PickupAttendanceSettings.indexCaps
            }
        ],
    });

    app.addMenu({
        key: settings.key,
        zone: settings.zone,
        label: "Actions",
        priority: 8,
        items: [
            {
                label: "Add New Session",
                path: settings.path + "/create",
                caps: settings.createCaps
            },
            {
                label: "Add New Operator",
                path: PickupOperatorSettings.path + "/create",
                caps: PickupOperatorSettings.createCaps
            },
            {
                label: "Add New Session Group",
                path: PickupSessionGroupSettings.path + "/create",
                caps: PickupSessionGroupSettings.createCaps
            },
            {
                label: "Add New Category",
                path: PickupCategorySettings.path + "/create",
                caps: PickupCategorySettings.createCaps
            }
        ],
    });


    PickupSessionIndex(app, settings);

    PickupSessionCreate(app, settings);

    PickupSessionSingle(app, settings);

}