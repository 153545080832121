import WatchCategoryIndex from './WatchCategoryIndex';
import WatchCategoryCreate from './WatchCategoryCreate';
import WatchCategorySettings from './WatchCategorySettings';
import WatchCategorySingle from './WatchCategorySingle';

export default function (app) {

    const settings = WatchCategorySettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    WatchCategoryIndex(app, settings);

    WatchCategoryCreate(app, settings);

    WatchCategorySingle(app, settings);

}