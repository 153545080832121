import React, { useMemo } from 'react';
import { Row, Col, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ActivityPanel from '@dex/bubl-dash/src/lib/components/SinglePanels/ActivityPanel';
import PickupSessionAttendees from './PickupSessionAttendees';
import PickupSessionCancelButton from './PickupSessionCancelButton';
import VenueBookingSessionVideoPanel from '../../VenueBooking/Components/VenueBookingSessionVideoPanel';
import WebLink from '../../../components/WebLink/WebLink';

const PickupSessionSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <WebLink
                path={`/pickups/session/${data.id}`}
            />


            <Col
                className={'main'}
            >

                <Panel
                    heading={"Pickup Session Details"}
                    actions={(
                        <PickupSessionCancelButton session={data} />
                    )}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Date & Time",
                                key: "dateTime",
                                format: "dateTime"
                            },
                            {
                                label: "Duration (Hours)",
                                key: "duration"
                            }
                        ]}
                        columnB={[
                            {
                                label: "Status",
                                key: "status",
                                format: "titleCase"
                            },
                            {
                                label: "Venue",
                                key: "venue"
                            },
                        ]}
                        columnC={[
                            {
                                label: "Host",
                                key: "host",
                                format: "ModelLink",
                            },
                            {
                                label: "Operator",
                                key: "operator",
                                format: "ModelLink",
                            },
                            {
                                label: "Session Group",
                                key: "sessionGroup",
                                format: "ModelLink",
                            }
                        ]}
                    />

                </Panel>

                {data.attendeeIds &&
                    <>
                        <Space />

                        <PickupSessionAttendees data={data} />
                    </>
                }

            </Col>

            <Col className={'side'}>

                <VenueBookingSessionVideoPanel booking={data} />

                <Space />

                <ActivityPanel parent={data} />

            </Col>

        </Row>

    ), [data]);

}

export default PickupSessionSingleView;
