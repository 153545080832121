import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ActivityPanel from '@dex/bubl-dash/src/lib/components/SinglePanels/ActivityPanel';

const CampAthletesSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Camp Athlete Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "User",
                                key: "user",
                                format: "ModelLink",
                            },
                            {
                                label: "Name",
                                key: "name",
                            },
                            {
                                label: "Email",
                                key: "email",
                            },
                            {
                                label: "Phone",
                                key: "phone",
                            },
                        ]}
                        columnB={[
                            {
                                label: "ID Number",
                                key: "idNumber",
                            },
                            {
                                label: "Gender",
                                key: "gender",
                                format: "titleCase",
                            },
                            {
                                label: "Date of Birth",
                                key: "dateOfBirth",
                                format: "date",
                            },
                        ]}
                        columnC={[

                        ]}
                    />

                </Panel>

            </Col>

            <Col className={'side'}>

                <ActivityPanel parent={data} />

            </Col>

        </Row>

    ), [data]);

}

export default CampAthletesSingleView;
