import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldUpload from '@dex/bubl-dash/src/lib/components/Fields/FieldUpload';
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship';
import FieldDate from '@dex/bubl-dash/src/lib/components/Fields/FieldDate';
import { useApp } from '@dex/bubl-dash';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';
import FieldRich from '@dex/bubl-dash/src/lib/components/Fields/FieldRich';


const VenueOperatorCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    const app = useApp();

    const manageGlobal = app.userCan(['manageGlobal'], false);

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <Panel
            heading={"Venue Operator Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ md: 16, xs: 24 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ md: 24, xs: 24 }}>

                                <FieldText
                                    form={form}
                                    label={"Title"}
                                    name='title'
                                    required={true}
                                    defaultValue={form.defaults.title}
                                />

                            </Col>

                            <Col col={{ md: 24, xs: 24 }}>

                                <FieldText
                                    form={form}
                                    label={"Short Description"}
                                    name='description'
                                    required={false}
                                    defaultValue={form.defaults.description}
                                />

                            </Col>

                            <Col col={{ md: 24, xs: 24 }}>

                                <FieldText
                                    form={form}
                                    label={"Website Link"}
                                    name='websiteLink'
                                    required={false}
                                    placeholder={"http://"}
                                    defaultValue={form.defaults.websiteLink}
                                />

                            </Col>


                            <Col col={{ md: 24, xs: 24 }}>

                                <FieldText
                                    form={form}
                                    label={"Website Label"}
                                    name='websiteLabel'
                                    required={false}
                                    defaultValue={form.defaults.websiteLabel}
                                />

                            </Col>

                        </Row>

                    </Col>

                    <Col col={{ md: 8, xs: 24 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ md: 24, xs: 24 }}>

                                <FieldDate
                                    form={form}
                                    label='Timezone'
                                    name='timezone'
                                    required={true}
                                    defaultValue={form.defaults.timezone}
                                    date={false}
                                    time={false}
                                    zone={true}
                                />

                            </Col>

                            {manageGlobal &&
                                <Col col={{ md: 24, xs: 24 }}>

                                    <FieldRelationship
                                        form={form}
                                        label='Host'
                                        name='hostId'
                                        required={true}
                                        preload={true}
                                        endpoint={'Hosts'}
                                        defaultValue={form.defaults.hostId}
                                    />

                                </Col>
                            }

                            <Col col={{ md: 24, xs: 24 }}>

                                <FieldUpload
                                    form={form}
                                    folder={'admin-media'}
                                    label={"Logo"}
                                    name={`logo`}
                                    required={false}
                                    defaultValue={form.defaults.logo}
                                    limit={1}
                                />

                            </Col>


                            <Col col={{ md: 24, xs: 24 }}>

                                <FieldUpload
                                    form={form}
                                    folder={'admin-media'}
                                    label={"Thumbnail"}
                                    name={`thumbnail`}
                                    required={false}
                                    defaultValue={form.defaults.thumbnail}
                                    limit={1}
                                />

                            </Col>

                            <Col col={{ md: 24, xs: 24 }}>

                                <FieldUpload
                                    form={form}
                                    folder={'admin-media'}
                                    label={"Cover"}
                                    name={`cover`}
                                    required={false}
                                    defaultValue={form.defaults.cover}
                                    limit={1}
                                />

                            </Col>

                            <Col col={{ md: 24, xs: 24 }}>

                                <FieldUpload
                                    form={form}
                                    folder={'admin-media'}
                                    label={"Photo Gallery"}
                                    name={`gallery`}
                                    required={false}
                                    defaultValue={form.defaults.gallery}
                                    limit={50}
                                />

                            </Col>

                        </Row>

                    </Col>

                </Row>

                <Seperator
                    top="auto"
                    bottom="auto"
                    heading="Full Description"
                />

                <FieldRich
                    form={form}
                    label={"Content"}
                    name={'contentBlocks'}
                    required={false}
                    defaultValue={form.defaults.contentBlocks}
                />

                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default VenueOperatorCreateEditForm;
