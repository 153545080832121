import React from 'react';
import { titleCase, get, dateTime } from "@dex/bubl-helpers";
import PickupAttendanceApi from './PickupAttendanceApi';
import ModelLink from '@dex/bubl-dash/src/lib/components/ModelLink/ModelLink';

let settings;

export default settings = {
    key: 'pickup/attendance',
    zone: 'pickup',
    path: '/pickup/attendance',
    title: "Pickup Attendance",
    endpoint: PickupAttendanceApi.endpoint,
    modelName: 'PickupAttendance',
    idKey: 'id',
    primaryKey: 'title',
    indexCaps: ["viewPickupAttendance"],
    viewCaps: ["viewPickupAttendance"],
    createCaps: ["createPickupAttendance"],
    updateCaps: ["updatePickupAttendance"],
    indexTitle: (routeName, params, users) => {

        let title: string = settings.title;

        if (params && params.name) title = title + " / " + titleCase(params.name);

        return title;

    },
    indexTabs: (routeName, params, users) => {

        return [
            {
                label: "All",
                path: settings.path,
                caps: settings.indexCaps
            },
            // {
            //     label: "Add New",
            //     path: settings.path + "/create",
            //     caps: settings.createCaps
            // },
        ]

    },
    indexButtons: (routeName, params, users) => {

        return [
            // {
            //     label: "Add New",
            //     path: settings.path + '/create',
            //     caps: settings.createCaps
            // }
        ]

    },
    indexTabCounts: (routeName, params, dontation) => {

        return settings.endpoint + "/getCounts";

    },
    indexFetch: PickupAttendanceApi.get,
    indexFetchParams: (routeName, params, users) => {

        const filters: any = { where: {}, include: ['owner', 'host', 'operator', 'sessionGroup', 'pass', 'session'], order: ['joinedOn.date DESC'], limit: 10, skip: 0 };

        if (params && params.status) filters.where.status = { inq: [params.status] };

        return filters;

    },
    indexQuickSearch: (routeName, params, users) => {

        const fields = ['id'];

        return fields;

    },
    indexSearchFields: (routeName, params, users) => {

        const fields = [
            {
                label: "Customer",
                key: "ownerId",
                type: "relationship",
                model: "Users"
            },
            {
                label: "Session Date",
                key: "sessionDate",
                type: "date",
            },
            {
                label: "Host",
                key: "hostId",
                type: "relationship",
                model: "Hosts"
            },
            {
                label: "Session Group",
                key: "sessionGroupId",
                type: "relationship",
                model: "PickupSessionGroup"

            },
        ];

        return fields;

    },

    indexColumns: (routeName, params, user) => {

        const columns = [
            // {
            //     label: "",
            //     key: "this",
            //     // format: "ModelLink",
            //     render: (value, data) => {
            //         return <ModelLink modelName="PickupAttendance" data={{ id: data.id, title: "View Attendance" }} />
            //     }
            // },
            {
                label: "Session Date",
                key: "sessionDate",
                sort: true,
                render: (value, data) => {
                    return <ModelLink modelName="PickupSession" primaryKey={"title"} data={{ id: data.sessionId, title: dateTime(data.sessionDate, "D MMM YYYY h:mm A") }} />
                }
            },
            {
                label: "Customer",
                key: "owner",
                format: "ModelLink"
            },
            {
                label: "Status",
                key: "cancelled",
                render: (value, data) => {
                    if (data.cancelled) return "Cancelled";
                    else if (data.attended) return "Attended";
                    else return "Attending";
                }
            },
            {
                label: "Host",
                key: "host",
                format: "ModelLink"
            },
            {
                label: "Session Group",
                key: "sessionGroup",
                format: "ModelLink"
            },
            {
                label: "Joined On",
                key: "joinedOn",
                format: "OnAndBy",
                sort: "joinedOn.date",
                alignEnd: true
            },
            {
                label: "Actions",
                key: "actions",
                alignEnd: true,
                format: "actions",
                api: PickupAttendanceApi,
                settings: settings,
            }
        ];

        return columns;

    },

    indexExportFormats: (routeName, params, user) => {

        const formats = [
            { value: "pickupAttendance", label: "Pickup Attendance" }
        ];

        return formats;

    },

    singleFetch: PickupAttendanceApi.getOne,
    singleFetchParams: (routeName, params, users, fetch) => {

        const filters = { include: ['owner', 'host', 'operator', 'sessionGroup', 'pass', 'session'] };

        return filters;

    },
    singleTitle: (routeName, params, users, fetch) => {

        let title: string = "Loading...";

        const complete = get(fetch, 'complete');
        const error = get(fetch, 'error');
        const data = get(fetch, 'data');

        if (error) title = "Error";
        else if (data) title = data[settings.primaryKey];
        else if (complete && !data) title = "Not Found";

        return title;

    },
    singleTabs: (routeName, params, users, fetch) => {

        const path = settings.path + "/" + params.id;

        return [
            {
                label: "View",
                path: path + "/view"
            },
            // {
            //     label: "Edit",
            //     path: path + "/edit",
            //     caps: settings.updateCaps
            // },
        ]

    },
};