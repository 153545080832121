import FollowingIndex from './FollowingIndex';
import FollowingCreate from './FollowingCreate';
import FollowingSettings from './FollowingSettings';
import FollowingSingle from './FollowingSingle';

export default function (app) {

    const settings = FollowingSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    // app.addMenu({
    //     key: settings.key,
    //     zone: settings.zone,
    //     label: settings.title,
    //     priority: 14,
    //     items: [
    //         {
    //             label: "All",
    //             path: settings.path,
    //             caps: settings.indexCaps
    //         }
    //     ],
    // });

    // FollowingIndex(app, settings);

    // FollowingCreate(app, settings);

    // FollowingSingle(app, settings);

}