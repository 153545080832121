import React, { useMemo, useEffect } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import DashboardViewHelp from './DashboardViewHelp';
import DashboardViewWeek from './DashboardViewWeek';

const DashboardView: React.FC<any> = (props: any) => {

    const say: any = {};

    return useMemo(() => (

        <>

            <Row
                gutter={'auto'}
            >

                <Col col={{ xs: 24 }}>

                    <DashboardViewWeek />

                </Col>

                {/* <Col col={{ xs: 24 }}>

                    <DashboardViewHelp />

                </Col> */}

            </Row>

        </>

    ), []);

}

export default DashboardView;
