import DataTable from '@dex/bubl-dash/src/lib/components/DataTable/DataTable';
import ModelLink from '@dex/bubl-dash/src/lib/components/ModelLink/ModelLink';
import React, { useMemo } from 'react';

const LeagueSeasonDivisionStandings: React.FC<any> = (props: any) => {

    const { data } = props;

    const columns = [
        {
            key: "pos",
            style: { width: 16 }
        },
        {
            label: "Team",
            key: "teamName",
            style: { width: 1000 },
            render: (value, data) => {
                return <ModelLink modelName="LeagueSeasonTeam" data={{ id: data.teamId, name: data.teamName }} />
            }
        },
        {
            label: "GP",
            key: "gp",
            format: "number",
            style: { width: 60 }
        },
        {
            label: "W",
            key: "w",
            format: "number",
            style: { width: 60 }
        },
        {
            label: "L",
            key: "l",
            format: "number",
            style: { width: 60 }
        },
        {
            label: "D",
            key: "d",
            align: "center",
            format: "number",
            style: { width: 60 }
        },
        {
            label: "PF",
            key: "pf",
            align: "center",
            format: "number",
            style: { width: 60 }
        },
        {
            label: "PA",
            key: "pa",
            align: "center",
            format: "number",
            style: { width: 60 }
        },
        {
            label: "Diff",
            key: "pd",
            align: "center",
            format: "number",
            style: { width: 60 }
        },
        {
            label: "Points",
            key: "points",
            format: "number",
            alignEnd: true,
            style: { width: 90 }
        },
    ];

    return useMemo(() => (

        <>

            <DataTable
                data={data.table}
                columns={columns}
                loading={false}
            />

        </>

    ), [data]);

}

export default LeagueSeasonDivisionStandings;
