import React from 'react';
import { titleCase, get } from "@dex/bubl-helpers";
import VenueBlockedDatesApi from './VenueBlockedDatesApi';
import moment from 'moment';
import ModelLink from '@dex/bubl-dash/src/lib/components/ModelLink/ModelLink';

let settings;

export default settings = {
    key: 'venue/blocked-dates',
    zone: 'venue',
    path: '/venue/blocked-dates',
    title: "Blocked Dates",
    endpoint: VenueBlockedDatesApi.endpoint,
    modelName: 'VenueBlockedDates',
    idKey: 'id',
    primaryKey: 'title',
    actions: ['trash', 'clone'],
    indexCaps: ["viewVenueBlockedDates"],
    viewCaps: ["viewVenueBlockedDates"],
    createCaps: ["createVenueBlockedDates"],
    updateCaps: ["updateVenueBlockedDates"],
    indexTitle: (routeName, params, users) => {

        let title: string = settings.title;

        if (params && params.name) title = title + " / " + titleCase(params.name);

        return title;

    },
    indexTabs: (routeName, params, users) => {

        return [
            {
                label: "All",
                path: settings.path,
                caps: settings.indexCaps
            },
            {
                label: "Today",
                path: settings.path + "/date/today",
                caps: settings.createCaps
            },
            {
                label: "Future",
                path: settings.path + "/date/future",
                caps: settings.createCaps
            },
            {
                label: "Past",
                path: settings.path + "/date/past",
                caps: settings.createCaps
            },
            {
                label: "Add New",
                path: settings.path + "/create",
                caps: settings.createCaps
            },
        ]

    },
    indexButtons: (routeName, params, users) => {

        return [
            {
                label: "Add New",
                path: settings.path + '/create',
                caps: settings.createCaps
            }
        ]

    },
    indexTabCounts: (routeName, params, dontation) => {

        return settings.endpoint + "/getCounts";

    },
    indexFetch: VenueBlockedDatesApi.get,
    indexFetchParams: (routeName, params, users) => {

        const filters: any = { where: {}, include: ['host', 'courts'], order: ['created.date DESC'], limit: 10, skip: 0 };

        if (params && params.date) {

            const today = moment().format("YYYY-MM-DD");

            if (params.date === "today") filters.where.date = today;
            else if (params.date === "past") filters.where.date = { lt: today };
            else if (params.date === "future") filters.where.date = { gt: today };

        }

        return filters;

    },
    indexQuickSearch: (routeName, params, users) => {

        const fields = ['id'];

        return fields;

    },
    indexSearchFields: (routeName, params, users) => {

        const fields = [
            {
                label: "Court",
                key: "courtIds",
                type: "relationship",
                model: "VenueCourt",
            },
            {
                label: "Host",
                key: "hostId",
                type: "relationship",
                model: "Hosts",
            },
            {
                label: "Date",
                key: "date",
                type: "date",
            },
        ];

        return fields;

    },

    indexColumns: (routeName, params, users) => {

        const columns = [
            {
                label: "Date",
                key: "this",
                style: { width: 120 },
                render: (value, data) => {
                    return <ModelLink modelName="VenueBlockedDates" data={{ id: data.id, title: moment(data.date).format("D MMM YYYY") }} />
                }
            },
            {
                label: "Start Time",
                key: "startTime",
            },
            {
                label: "End Time",
                key: "endTime",
            },
            {
                label: "Host",
                key: "host",
                format: "ModelLink",
            },
            {
                label: "Courts",
                key: "courts",
                format: "ModelLink",
            },
            {
                label: "Created Date",
                key: "created",
                format: "OnAndBy",
                sort: "created.date",
                alignEnd: true
            },
            {
                label: "Actions",
                key: "actions",
                alignEnd: true,
                format: "actions",
                api: VenueBlockedDatesApi,
                settings: settings,
            }
        ];

        return columns;

    },
    singleFetch: VenueBlockedDatesApi.getOne,
    singleFetchParams: (routeName, params, users, fetch) => {

        const filters = { include: ['host', 'courts'] };

        return filters;

    },
    singleTitle: (routeName, params, users, fetch) => {

        let title: string = "Loading...";

        const complete = get(fetch, 'complete');
        const error = get(fetch, 'error');
        const data = get(fetch, 'data');

        if (error) title = "Error";
        else if (data) title = 'Blocked Dates';
        // else if (data) title = `${moment(data.date).format("dddd, DD MMMM YYYY")}, ${data.startTime} - ${data.endTime}`;
        else if (complete && !data) title = "Not Found";

        return title;

    },
    singleTabs: (routeName, params, users, fetch) => {

        const path = settings.path + "/" + params.id;

        return [
            {
                label: "View",
                path: path + "/view"
            },
            {
                label: "Edit",
                path: path + "/edit",
                caps: settings.updateCaps
            },
        ]

    },
};