import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ActivityPanel from '@dex/bubl-dash/src/lib/components/SinglePanels/ActivityPanel';
import WebLink from '../../../components/WebLink/WebLink';

const WatchCategorySingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <WebLink
                path={`/watch/category/${data.id}/${data.slug}`}
            />

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Watch Category Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Title",
                                key: "this",
                                format: "ModelLink",
                            }
                        ]}
                        columnB={[
                            {
                                label: "Status",
                                key: "status",
                                format: "titleCase",
                            }
                        ]}
                        columnC={[
                            {
                                label: "Priority",
                                key: "priority",
                            }
                        ]}
                    />

                </Panel>

            </Col>

            <Col className={'side'}>

                <ActivityPanel parent={data} />

            </Col>

        </Row>

    ), [data]);

}

export default WatchCategorySingleView;
