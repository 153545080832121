import VenueLiveStreamIndex from './VenueLiveStreamIndex';
import VenueLiveStreamCreate from './VenueLiveStreamCreate';
import VenueLiveStreamSettings from './VenueLiveStreamSettings';
import VenueLiveStreamSingle from './VenueLiveStreamSingle';

export default function (app) {

    const settings = VenueLiveStreamSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    VenueLiveStreamIndex(app, settings);

    VenueLiveStreamCreate(app, settings);

    VenueLiveStreamSingle(app, settings);

}