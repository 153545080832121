import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ActivityPanel from '@dex/bubl-dash/src/lib/components/SinglePanels/ActivityPanel';

const WatchAdsSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Watch Ads Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Title",
                                key: "this",
                                format: "ModelLink",
                            },
                            {
                                label: "Ad Tag",
                                key: "adTag",
                                format: "titleCase",
                            }
                        ]}
                        columnB={[
                            {
                                label: "Position",
                                key: "position",
                                format: "titleCase",
                            },
                            {
                                label: "Position Time",
                                key: "positionTime",
                                conditions: data.position === "custom",
                                format: "number",
                                suffix: " Seconds"
                            }
                        ]}
                        columnC={[
                            {
                                label: "Attach By",
                                key: "attachBy",
                                format: "titleCase",
                            },
                            {
                                label: "Host",
                                key: "host",
                                format: "ModelLink",
                                conditions: data.attachBy === "host",
                            },
                            {
                                label: "Watch Tag",
                                key: "tag",
                                format: "ModelLink",
                                conditions: data.attachBy === "tag",
                            },
                            {
                                label: "League",
                                key: "league",
                                format: "ModelLink",
                                conditions: data.attachBy === "league",
                            },
                            {
                                label: "Watch Category",
                                key: "category",
                                format: "ModelLink",
                                conditions: data.attachBy === "category",
                            },
                            {
                                label: "Sport",
                                key: "sport",
                                format: "ModelLink",
                                conditions: data.attachBy === "sport",
                            }
                        ]}
                    />

                </Panel>

            </Col>

            <Col className={'side'}>

                <ActivityPanel parent={data} />

            </Col>

        </Row>

    ), [data]);

}

export default WatchAdsSingleView;
