import React, { useCallback, useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldNumber from '@dex/bubl-dash/src/lib/components/Fields/FieldNumber';
import FieldSwitch from '@dex/bubl-dash/src/lib/components/Fields/FieldSwitch';
import FieldSelect from '@dex/bubl-dash/src/lib/components/Fields/FieldSelect';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship';
import { useApp } from '@dex/bubl-dash';
import FieldUpload from '@dex/bubl-dash/src/lib/components/Fields/FieldUpload';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';
import FieldRepeater from '@dex/bubl-dash/src/lib/components/Fields/FieldRepeater';
import Heading from '@dex/bubl-dash/src/lib/components/Heading/Heading';
import FieldTextArea from '@dex/bubl-dash/src/lib/components/Fields/FieldTextArea';
import { loop, loopAsync } from '@dex/bubl-helpers';
import FieldDate from '@dex/bubl-dash/src/lib/components/Fields/FieldDate';

const startTimes = [
    "00:00", "00:30", "01:00", "01:30", "02:00", "02:30", "03:00", "03:30", "04:00", "04:30", "05:00", "05:30", "06:00", "06:30", "07:00", "07:30",
    "08:00", "08:30", "09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30",
    "16:00", "16:30", "17:00", "17:30", "18:00", "18:30", "19:00", "19:30", "20:00", "20:30", "21:00", "21:30", "22:00", "22:30", "23:00", "23:30",
].map((time) => { return { label: time, value: time }; });

const endTimes = [
    "01:00", "01:30", "02:00", "02:30", "03:00", "03:30", "04:00", "04:30", "05:00", "05:30", "06:00", "06:30", "07:00", "07:30", "08:00", "08:30",
    "09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30", "16:00", "16:30",
    "17:00", "17:30", "18:00", "18:30", "19:00", "19:30", "20:00", "20:30", "21:00", "21:30", "22:00", "22:30", "23:00", "23:30", "24:00", "24:30"
].map((time) => { return { label: time, value: time }; });

const VenueCourtCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    const app = useApp();

    const manageGlobal = app.userCan(['manageGlobal'], false);

    // eslint-disable-next-line
    let data = props.data || {};

    const onSubmit = useCallback((values) => {

        const slots = values.slots;

        let isOverlap = false;

        let day: any = []

        loop(slots, (value, key) => {

            const overlapCheck = (A, index, arr) => arr.some(B => A !== B && (A.startTime < B.endTime && A.endTime > B.startTime))

            const check = value.some(overlapCheck)

            isOverlap = (isOverlap) ? true : check;

            if (check) {
                day.push(key)
            }
        })

        if (isOverlap) {
            app.alert(`Overlapped time slots detected on ${day.join(',')}!`, 'error')
            return false;
        }

        if (!values.enableBlockBooking) {

            loop(values.slots, (value, key) => {

                value.forEach((slot, index) => {
                    delete (slot.blockBookingProductId);
                    delete (slot.maximumBlockSession);
                    delete (slot.minimumBlockSession);
                })

            })

        }

        handleSubmit(values);

    }, [form])

    return useMemo(() => (

        <Panel
            heading={"Court Details"}
        >

            <Form
                form={form}
                onSubmit={onSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ md: 16, xs: 24 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ md: 24, xs: 24 }}>

                                <FieldText
                                    form={form}
                                    label={"Title"}
                                    name='title'
                                    required={true}
                                    defaultValue={form.defaults.title}
                                />

                            </Col>

                            <Col col={{ md: 24, xs: 24 }}>

                                <FieldRelationship
                                    form={form}
                                    label={"Operator"}
                                    name='operatorId'
                                    required={true}
                                    preload={true}
                                    endpoint={'VenueOperator'}
                                    defaultValue={form.defaults.operatorId}
                                />

                            </Col>

                            <Col col={{ md: 24, xs: 24 }}>

                                <FieldRelationship
                                    form={form}
                                    label={"Sport"}
                                    name='sportId'
                                    required={true}
                                    preload={true}
                                    endpoint={'Sport'}
                                    defaultValue={form.defaults.sportId}
                                />

                            </Col>


                            <Col col={{ md: 24, xs: 24 }}>

                                <FieldRelationship
                                    form={form}
                                    label={"Category"}
                                    name='categoryId'
                                    required={true}
                                    preload={true}
                                    endpoint={'VenueCategory'}
                                    defaultValue={form.defaults.categoryId}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldTextArea
                                    form={form}
                                    label={"Address"}
                                    name='address'
                                    required={false}
                                    defaultValue={form.defaults.address}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldText
                                    form={form}
                                    label={"Contact No."}
                                    name='contact'
                                    required={false}
                                    defaultValue={form.defaults.contact}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldText
                                    form={form}
                                    label={"Email Address"}
                                    name='email'
                                    required={false}
                                    defaultValue={form.defaults.email}
                                />

                            </Col>

                        </Row>

                    </Col>

                    <Col col={{ md: 8, xs: 24 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ md: 24, xs: 24 }}>

                                <FieldRadio
                                    form={form}
                                    label={"Status"}
                                    name='status'
                                    required={true}
                                    defaultValue={form.defaults.status}
                                    options={[
                                        { value: "draft", label: "Draft" },
                                        { value: "preview", label: "Preview" },
                                        { value: "publish", label: "Publish" },
                                        { value: "archive", label: "Archive" },
                                    ]}
                                />

                            </Col>

                            {manageGlobal &&
                                <Col col={{ md: 24, xs: 24 }}>


                                    <FieldSwitch
                                        form={form}
                                        label={"Featured"}
                                        name='isFeatured'
                                        required={false}
                                        defaultValue={form.defaults.isFeatured}
                                    />

                                </Col>
                            }

                            {manageGlobal &&
                                <Col col={{ md: 24, xs: 24 }}>

                                    <FieldNumber
                                        form={form}
                                        label={"Priority"}
                                        name={`priority`}
                                        required={false}
                                        defaultValue={form.defaults.priority}
                                    />

                                </Col>
                            }

                            <Col col={{ md: 24, xs: 24 }}>

                                <FieldUpload
                                    form={form}
                                    folder={'admin-media'}
                                    label={"Thumbnail"}
                                    name={`thumbnail`}
                                    required={false}
                                    defaultValue={form.defaults.thumbnail}
                                    limit={1}
                                />

                            </Col>

                            <Col col={{ xs: 12 }}>

                                <FieldSwitch
                                    form={form}
                                    label={"Enable Court Booking"}
                                    name='enableCourtBooking'
                                    required={false}
                                    defaultValue={form.defaults.enableCourtBooking}
                                />

                            </Col>

                            {form.values.enableCourtBooking &&
                                <Col col={{ xs: 12 }}>

                                    <FieldSwitch
                                        form={form}
                                        label={"Enable Block Booking"}
                                        name='enableBlockBooking'
                                        required={false}
                                        defaultValue={form.values.enableBlockBooking}
                                    />

                                </Col>
                            }

                            <Col col={{ md: 24, xs: 24 }}>

                                <FieldSwitch
                                    form={form}
                                    label={"Enable Live Stream"}
                                    name='enableLiveStream'
                                    required={false}
                                    defaultValue={form.defaults.enableLiveStream}
                                />

                            </Col>

                        </Row>

                    </Col>

                </Row>

                {form.values.enableCourtBooking &&
                    <>
                        <Seperator
                            top="auto"
                            bottom="auto"
                            heading="Court Booking Restrictions"
                        />

                        <Row gutter={8} edge={true}>

                            <Col col={{ md: 12, xs: 24 }}>

                                <FieldNumber
                                    form={form}
                                    label={"Standard Booking - Allowed Up To X Days In Advance"}
                                    name={`daysInAdvance`}
                                    required={false}
                                    suffix={" days"}
                                    defaultValue={form.defaults.daysInAdvance}
                                />

                            </Col>

                            <Col col={{ md: 12, xs: 24 }}>

                                <FieldDate
                                    form={form}
                                    label={"Standard Booking - No Bookings After This Date"}
                                    name={`bookingsClosedDate`}
                                    required={false}
                                    defaultValue={form.defaults.bookingsClosedDate}
                                />

                            </Col>

                            {form.values.enableBlockBooking &&
                                <>

                                    <Col col={{ xs: 24 }}>
                                        <Line />
                                    </Col>

                                    <Col col={{ md: 12, xs: 24 }}>

                                        <FieldDate
                                            form={form}
                                            label={"Block Booking Window - Start Date"}
                                            name={`blockBookingWindowStartDate`}
                                            required={true}
                                            defaultValue={form.defaults.blockBookingWindowStartDate}
                                        />

                                    </Col>

                                    <Col col={{ md: 12, xs: 24 }}>

                                        <FieldDate
                                            form={form}
                                            label={"Block Booking Window - End Date"}
                                            name={`blockBookingWindowEndDate`}
                                            required={true}
                                            defaultValue={form.defaults.blockBookingWindowEndDate}
                                        />

                                    </Col>

                                    <Col col={{ md: 12, xs: 24 }}>

                                        <FieldDate
                                            form={form}
                                            label={"Block Booking Availability Period -  Start Date"}
                                            name={`blockBookingPeriodStartDate`}
                                            required={true}
                                            defaultValue={form.defaults.blockBookingPeriodStartDate}
                                        />

                                    </Col>

                                    <Col col={{ md: 12, xs: 24 }}>

                                        <FieldDate
                                            form={form}
                                            label={"Block Booking Availability Period - End Date"}
                                            name={`blockBookingPeriodEndDate`}
                                            required={true}
                                            defaultValue={form.defaults.blockBookingPeriodEndDate}
                                        />

                                    </Col>

                                    <Col col={{ md: 12, xs: 24 }}>

                                        <FieldSelect
                                            form={form}
                                            label={"Block Booking - Minimum Block Size"}
                                            name={`blockBookingMinBlockSize`}
                                            required={true}
                                            defaultValue={form.defaults.blockBookingMinBlockSize}
                                            options={[
                                                { label: "2 Sessions", value: 2 },
                                                { label: "3 Sessions", value: 3 },
                                                { label: "4 Sessions", value: 4 },
                                                { label: "5 Sessions", value: 5 },
                                                { label: "6 Sessions", value: 6 },
                                                { label: "7 Sessions", value: 7 },
                                                { label: "8 Sessions", value: 8 },
                                                { label: "9 Sessions", value: 9 },
                                                { label: "10 Sessions", value: 10 },
                                                { label: "11 Sessions", value: 11 },
                                                { label: "12 Sessions", value: 12 },
                                                { label: "13 Sessions", value: 13 },
                                                { label: "14 Sessions", value: 14 },
                                                { label: "15 Sessions", value: 15 },
                                                { label: "16 Sessions", value: 16 },
                                            ]}
                                        />

                                    </Col>

                                    <Col col={{ md: 12, xs: 24 }}>

                                        <FieldSelect
                                            form={form}
                                            label={"Block Booking - Maximum Block Size"}
                                            name={`blockBookingMaxBlockSize`}
                                            required={true}
                                            defaultValue={form.defaults.blockBookingMaxBlockSize}
                                            options={[
                                                { label: "2 Sessions", value: 2 },
                                                { label: "3 Sessions", value: 3 },
                                                { label: "4 Sessions", value: 4 },
                                                { label: "5 Sessions", value: 5 },
                                                { label: "6 Sessions", value: 6 },
                                                { label: "7 Sessions", value: 7 },
                                                { label: "8 Sessions", value: 8 },
                                                { label: "9 Sessions", value: 9 },
                                                { label: "10 Sessions", value: 10 },
                                                { label: "11 Sessions", value: 11 },
                                                { label: "12 Sessions", value: 12 },
                                                { label: "13 Sessions", value: 13 },
                                                { label: "14 Sessions", value: 14 },
                                                { label: "15 Sessions", value: 15 },
                                                { label: "16 Sessions", value: 16 },
                                            ]}
                                        />

                                    </Col>

                                </>
                            }

                        </Row>

                        <Seperator
                            top="auto"
                            bottom="auto"
                            heading="Court Booking Time Slots"
                        />

                        {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map((day, n) => (

                            <React.Fragment key={day}>

                                <Heading space={false}>{day}</Heading>

                                <Row
                                    gutter={8}
                                    edge={true}
                                >

                                    <Col col={{ xs: 24 }}>

                                        <FieldRepeater
                                            form={form}
                                            name={`slots[${day}]`}
                                            label={"Time Slots"}
                                            addButtonLabel={"Add Time Slot (Court Booking)"}
                                            defaultValues={{ size: 1, blockBookingMin: 2, blockBookingMax: 16 }}
                                        >

                                            {((form.values.slots ? form.values.slots[day] : []) || []).map((slot, i) => (

                                                <Row gutter={8} edge={true} key={i}>

                                                    <Col col={{ xs: 12, sm: 6 }}>

                                                        <FieldSelect
                                                            key={i}
                                                            form={form}
                                                            label={"Start Time"}
                                                            name={`slots[${day}][${i}].startTime`}
                                                            date={false}
                                                            zone={false}
                                                            time={true}
                                                            required={true}
                                                            defaultValue={slot.startTime}
                                                            options={startTimes}
                                                        />

                                                    </Col>

                                                    <Col col={{ xs: 12, sm: 6 }}>

                                                        <FieldSelect
                                                            key={i}
                                                            form={form}
                                                            label={"End Time"}
                                                            name={`slots[${day}][${i}].endTime`}
                                                            date={false}
                                                            zone={false}
                                                            time={true}
                                                            required={true}
                                                            defaultValue={slot.endTime}
                                                            options={endTimes}
                                                        />

                                                    </Col>

                                                    <Col col={{ xs: 24, sm: 12 }}>

                                                        <FieldSelect
                                                            form={form}
                                                            label={"Minimum Session Duration"}
                                                            name={`slots[${day}][${i}].size`}
                                                            required={true}
                                                            defaultValue={slot.size}
                                                            options={[
                                                                { value: "30", label: "30 Minutes" },
                                                                { value: "60", label: "60 Minutes" },
                                                                { value: "90", label: "90 Minutes" },
                                                                { value: "120", label: "120 Minutes" },
                                                                { value: "180", label: "180 Minutes" },
                                                                { value: "240", label: "240 Minutes" },
                                                            ]}
                                                        />

                                                    </Col>

                                                    <Col col={{ xs: 24, md: 12 }}>

                                                        <FieldRelationship
                                                            form={form}
                                                            label={"Product"}
                                                            name={`slots[${day}][${i}].productId`}
                                                            required={true}
                                                            preload={true}
                                                            endpoint={'Product'}
                                                            defaultValue={slot.productId}
                                                            where={{ type: "venueBooking" }}
                                                        />

                                                    </Col>

                                                    {form.values.enableBlockBooking &&
                                                        <Col col={{ xs: 24, md: 12 }}>

                                                            <FieldRelationship
                                                                form={form}
                                                                label={"Block Booking - Product"}
                                                                name={`slots[${day}][${i}].blockBookingProductId`}
                                                                required={false}
                                                                preload={true}
                                                                endpoint={'Product'}
                                                                defaultValue={slot.blockBookingProductId}
                                                                where={{ type: "venueBlockBooking" }}
                                                            />

                                                        </Col>
                                                    }

                                                </Row>

                                            ))}

                                        </FieldRepeater>

                                    </Col>

                                </Row>

                                <Line />

                            </React.Fragment>

                        ))}

                    </>
                }



                {form.values.enableLiveStream &&
                    <>
                        <Seperator
                            top="auto"
                            bottom="auto"
                            heading="Live Stream Restrictions"
                        />

                        <Row gutter={8} edge={true}>

                            <Col col={{ md: 12, xs: 24 }}>

                                <FieldNumber
                                    form={form}
                                    label={"Live Stream Booking - Allowed Up To X Days In Advance"}
                                    name={`daysInAdvanceLiveStream`}
                                    required={false}
                                    suffix={" days"}
                                    defaultValue={form.defaults.daysInAdvanceLiveStream}
                                />

                            </Col>

                            <Col col={{ md: 12, xs: 24 }}>

                                <FieldDate
                                    form={form}
                                    label={"Live Stream Booking - No Bookings After This Date"}
                                    name={`bookingsClosedDateLiveStream`}
                                    required={false}
                                    defaultValue={form.defaults.bookingsClosedDateLiveStream}
                                />

                            </Col>

                        </Row>

                        <Seperator
                            top="auto"
                            bottom="auto"
                            heading="Live Stream Time Slots"
                        />

                        {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map((day, n) => (

                            <React.Fragment key={day}>

                                <Heading space={false}>{day}</Heading>

                                <Row
                                    gutter={8}
                                    edge={true}
                                >

                                    <Col col={{ xs: 24 }}>

                                        <FieldRepeater
                                            form={form}
                                            name={`slotsLiveStream[${day}]`}
                                            label={"Time Slots"}
                                            addButtonLabel={"Add Time Slot (Live Stream)"}
                                            defaultValues={{ size: 1, blockBookingMin: 2, blockBookingMax: 16 }}
                                        >

                                            {((form.values.slotsLiveStream ? form.values.slotsLiveStream[day] : []) || []).map((slot, i) => (

                                                <Row gutter={8} edge={true} key={i}>

                                                    <Col col={{ xs: 12, sm: 6 }}>

                                                        <FieldSelect
                                                            key={i}
                                                            form={form}
                                                            label={"Start Time"}
                                                            name={`slotsLiveStream[${day}][${i}].startTime`}
                                                            date={false}
                                                            zone={false}
                                                            time={true}
                                                            required={true}
                                                            defaultValue={slot.startTime}
                                                            options={startTimes}
                                                        />

                                                    </Col>

                                                    <Col col={{ xs: 12, sm: 6 }}>

                                                        <FieldSelect
                                                            key={i}
                                                            form={form}
                                                            label={"End Time"}
                                                            name={`slotsLiveStream[${day}][${i}].endTime`}
                                                            date={false}
                                                            zone={false}
                                                            time={true}
                                                            required={true}
                                                            defaultValue={slot.endTime}
                                                            options={endTimes}
                                                        />

                                                    </Col>

                                                    <Col col={{ xs: 24, sm: 12 }}>

                                                        <FieldSelect
                                                            form={form}
                                                            label={"Minimum Session Duration"}
                                                            name={`slotsLiveStream[${day}][${i}].size`}
                                                            required={true}
                                                            defaultValue={slot.size}
                                                            options={[
                                                                { value: "30", label: "30 Minutes" },
                                                                { value: "60", label: "60 Minutes" },
                                                                { value: "90", label: "90 Minutes" },
                                                                { value: "120", label: "120 Minutes" },
                                                                { value: "180", label: "180 Minutes" },
                                                                { value: "240", label: "240 Minutes" },
                                                            ]}
                                                        />

                                                    </Col>

                                                    <Col col={{ xs: 24, md: 12 }}>

                                                        <FieldRelationship
                                                            form={form}
                                                            label={"Product"}
                                                            name={`slotsLiveStream[${day}][${i}].productId`}
                                                            required={true}
                                                            preload={true}
                                                            endpoint={'Product'}
                                                            defaultValue={slot.productId}
                                                            where={{ type: "venueLiveStream" }}
                                                        />

                                                    </Col>

                                                </Row>

                                            ))}

                                        </FieldRepeater>

                                    </Col>

                                </Row>

                                <Line />

                            </React.Fragment>

                        ))}

                    </>
                }

                { }
                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default VenueCourtCreateEditForm;
