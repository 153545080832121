import WatchCamerasIndex from './WatchCamerasIndex';
import WatchCamerasSettings from './WatchCamerasSettings';
import WatchCamerasSingle from './WatchCamerasSingle';

export default function (app) {

    const settings = WatchCamerasSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    WatchCamerasIndex(app, settings);

    WatchCamerasSingle(app, settings);

}