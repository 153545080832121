import React, { useMemo } from 'react';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';

const HostRequestsSingleResolved: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Panel
            heading={"Request Verification"}
        >

            <MetaTable
                data={data}
                columnA={[
                    {
                        label: "Action",
                        key: "action",
                        format: "titleCase"
                    },
                ]}
                columnB={[
                    {
                        label: "Message To Host",
                        key: "actionMessage",
                    }
                ]}
                columnC={[
                    {
                        label: "Date",
                        key: "actionDate",
                        format: "onadnby"
                    },
                ]}
            />

        </Panel>

    ), [data]);

}

export default HostRequestsSingleResolved;
