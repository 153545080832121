import IndexPage from '@dex/bubl-dash/src/lib/pages/IndexPage';
import FormFieldCreateEditForm from './Components/FormFieldCreateEditForm';
import FormFieldApi from './FormFieldApi';

export default function (app, settings) {

    app.addRoute({
        name: settings.key + "New",
        zone: settings.zone,
        path: settings.key + "/create",
        caps: settings.createCaps,
        component: IndexPage,
        componentProps: {
            header: {
                getTitle: () => "Add New",
                getButtons: settings.indexButtons,
                getTabs: settings.indexTabs,
                getTabCounts: settings.indexTabCounts,
            },
            create: {
                form: FormFieldCreateEditForm,
                getCreate: FormFieldApi.create,
                defaults: { options: [] },
                successMessage: "FormField Saved",
                successRouteName: settings.key + "View",
                idKey: settings.idKey
            }
        },
    });

}