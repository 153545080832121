import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship';
import FieldRich from '@dex/bubl-dash/src/lib/components/Fields/FieldRich';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';
import FieldTextArea from '@dex/bubl-dash/src/lib/components/Fields/FieldTextArea';
import FieldDate from '@dex/bubl-dash/src/lib/components/Fields/FieldDate';


const AnnouncementsCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit, mode } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    const app = useApp();

    const manageGlobal = app.userCan(['manageGlobal'], false);

    return useMemo(() => (

        <Panel
            heading={"Announcements Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    {manageGlobal && mode === "create" &&
                        <Col col={{ md: 12, xs: 24 }}>

                            <FieldRelationship
                                form={form}
                                label='Host'
                                name='hostId'
                                required={false}
                                preload={true}
                                endpoint={'Hosts'}
                                defaultValue={form.defaults.hostId}
                            />

                        </Col>
                    }

                    <Col col={{ xs: 24, md: 24 }}>

                        <FieldText
                            form={form}
                            label={"Subject"}
                            name='subject'
                            required={true}
                            defaultValue={form.defaults.subject}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 24 }}>

                        <FieldTextArea
                            form={form}
                            label={"Message"}
                            name={'message'}
                            required={true}
                            defaultValue={form.defaults.message}
                            rows={8}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldDate
                            form={form}
                            label={"Expires On"}
                            name='expireOn'
                            required={true}
                            defaultValue={form.defaults.expireOn}
                        />

                    </Col>

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default AnnouncementsCreateEditForm;
