import React, { useCallback, useMemo } from 'react';
import HostsApi from '../HostsApi';
import { useApp } from '@dex/bubl-dash';
import { useForm, Form } from '@dex/bubl-dash';
import { Panel } from '@dex/bubl-dash';
import { Row } from '@dex/bubl-dash';
import { Col } from '@dex/bubl-dash';
import { Line } from '@dex/bubl-dash';
import { ButtonRow } from '@dex/bubl-dash';
import { Button } from '@dex/bubl-dash';
import { FieldNumber } from '@dex/bubl-dash';
import { Seperator } from '@dex/bubl-dash';

const HostsRatesForm: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm(data);

    const app = useApp();

    const update = HostsApi.update(data.id, "updateRates", {
        onComplete: (data) => {

            app.alert('Rates Saved', 'success');

        }
    });

    const handleSubmit = useCallback((values) => {

        update.run({ data: values });

    }, [props]);

    return useMemo(() => (

        <Panel
            heading={"Payout Margin Rates"}
        >

            <Seperator
                description={"Enter amount in % that HUDDLE collects from each order."}
                bottom={"auto"}
            />

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={update.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldNumber
                            form={form}
                            label={"Default Rate"}
                            name={`payoutMarginRates[default]`}
                            required={true}
                            defaultValue={form.defaults.payoutMarginRates.default}
                            suffix={"%"}
                        />

                    </Col>

                </Row>

                <Line />

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldNumber
                            form={form}
                            label={"Pickup Pass"}
                            name={`payoutMarginRates[pickupPass]`}
                            defaultValue={form.defaults.payoutMarginRates.pickupPass}
                            suffix={"%"}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldNumber
                            form={form}
                            label={"Camp Pass"}
                            name={`payoutMarginRates[campPass]`}
                            defaultValue={form.defaults.payoutMarginRates.campPass}
                            suffix={"%"}
                        />

                    </Col>

                </Row>

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldNumber
                            form={form}
                            label={"League Entry Team"}
                            name={`payoutMarginRates[leagueEntryTeam]`}
                            defaultValue={form.defaults.payoutMarginRates.leagueEntryTeam}
                            suffix={"%"}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldNumber
                            form={form}
                            label={"League Entry Player"}
                            name={`payoutMarginRates[leagueEntryPlayer]`}
                            defaultValue={form.defaults.payoutMarginRates.leagueEntryPlayer}
                            suffix={"%"}
                        />

                    </Col>

                </Row>

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldNumber
                            form={form}
                            label={"Venue Booking"}
                            name={`payoutMarginRates[venueBooking]`}
                            defaultValue={form.defaults.payoutMarginRates.venueBooking}
                            suffix={"%"}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldNumber
                            form={form}
                            label={"Venue Booking - Block Booking"}
                            name={`payoutMarginRates[venueBlockBooking]`}
                            defaultValue={form.defaults.payoutMarginRates.venueBlockBooking}
                            suffix={"%"}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldNumber
                            form={form}
                            label={"Venue Booking - Video Playback"}
                            name={`payoutMarginRates[videoPlayback]`}
                            defaultValue={form.defaults.payoutMarginRates.videoPlayback}
                            suffix={"%"}
                        />

                    </Col>

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={update.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>

    ), [props, update.loading]);

}

export default HostsRatesForm;
