import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { Col, FieldHidden, FieldSelect, FieldText, Row, Seperator, Space, useApp } from '@dex/bubl-dash';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form, useForm } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { bgTask, get, loop, numberToLetter, slug } from '@dex/bubl-helpers';
import CSVReader from 'react-csv-reader'
import FieldWrap from '@dex/bubl-dash/src/lib/components/Fields/FieldWrap';

const LeagueSeasonGameBoxEventsImport: React.FC<any> = (props: any) => {

    const { data, teams, players, fields, boxForm, handleClose } = props;

    const app = useApp();

    const form = useForm(data);

    const [key, setKey] = useState<any>(0);

    const [loading, setLoading] = useState<any>(false);

    const [csvRows, setCvsRows] = useState<any>(null);

    const handleSubmit = useCallback((values) => {

        const events: any = csvRows || [];

        setLoading(true);

        bgTask(() => {

            boxForm.handleChange({ name: "events", value: events, reset: true });

            boxForm.setKey();

            handleClose();

            setLoading(false);

        }, 10);

    }, [props, csvRows]);

    const getPlayer = useCallback((players: any, jerseyNumber: any) => {

        let playerId = '';

        loop(players, (player, index) => {

            const num = player.label.split(' | ')[0];

            if (num === jerseyNumber) playerId = player.value;

        })

        return playerId;

    }, [players]);

    const parseCsv = (data, fileInfo, originalFile) => {

        const rows: any = [];

        loop(data, (cells, index) => {

            let n = 0;

            const teamId = cells['home-team'] ? teams[0].value : (cells['away-team'] ? teams[1].value : '');

            const playerId = getPlayer(players[teamId] || [], cells.player);

            const row = {
                teamId: teamId,
                playerId: playerId,
                position: cells.position,
                duration: cells.duration,
                event: cells.event,
                quarter: cells.quarter,
                fieldPosition: cells['field-position'],
                coordinates: cells.coordinates,
            };

            rows.push(row);

        });

        console.log(rows);


        setCvsRows(rows);
    };

    useEffect(() => {

        //Try to auto map colmns and rows

        if (!csvRows) return;

        if (form.values.id === undefined) return;

    }, [csvRows, form.values.id])

    return useMemo(() => (

        <Panel
            heading={"Import Events"}
        >

            <Form
                form={form}
                loading={loading}
                onSubmit={handleSubmit}
            >

                <Row gutter={8} edge={true} key={key}>

                    <Col col={{ xs: 24, md: 12, lg: 8 }}>

                        <FieldWrap
                            label={"Import File"}
                            required={true}
                        >

                            <div>

                                <Space height={12} />

                                <CSVReader
                                    onFileLoaded={parseCsv}
                                    parserOptions={{
                                        header: true,
                                        dynamicTyping: false,
                                        skipEmptyLines: true,
                                        transformHeader: slug,
                                    }}
                                    inputName={"csv"}
                                />

                            </div>

                        </FieldWrap>

                    </Col>

                </Row>

                <Line />

                <ButtonRow>

                    {csvRows &&
                        <Button
                            label={"Update Events"}
                            loading={loading}
                        />
                    }

                    <Button
                        label={"Cancel"}
                        type={"faded"}
                        onClick={handleClose}
                    />

                </ButtonRow>

            </Form>

        </Panel>

    ), [form, csvRows, key, loading]);

}

export default LeagueSeasonGameBoxEventsImport;
