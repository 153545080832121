import React, { useMemo } from 'react';
import { Row, Col, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';

const LeagueSeasonSingleShow: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Panel
            heading={"Show On App / Web"}
        >

            <MetaTable
                data={data}
                columnSize={{ xs: 24 }}
                columnA={[
                    {
                        label: "Show Stats",
                        key: "hasStats",
                        format: "switch",
                        conditions: data.sourceType === "spBasketball"
                    },
                    {
                        label: "Show Results / Upcoming",
                        key: "hasCalendar",
                        format: "switch",
                        conditions: data.sourceType === "spBasketball"
                    },
                    {
                        label: "Show Standings",
                        key: "hasStandings",
                        format: "switch",
                        conditions: data.sourceType === "spBasketball"
                    },
                    {
                        label: "Show Leaderboard",
                        key: "hasLeaderboard",
                        format: "switch",
                        conditions: data.sourceType === "spBasketball"
                    },
                    {
                        label: "Show News Feed",
                        key: "hasNews",
                        format: "switch",
                        conditions: data.sourceType === "spBasketball"
                    },
                    {
                        label: "Show Game Logs",
                        key: "hasGameLogs",
                        format: "switch",
                        conditions: data.sourceType === "spBasketball"
                    },
                    {
                        label: "Show Team Roster",
                        key: "hasTeamRoster",
                        format: "switch",
                        conditions: data.sourceType === "spBasketball"
                    },
                ]}
            />

        </Panel>

    ), [data]);

}

export default LeagueSeasonSingleShow;
