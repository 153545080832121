import React, { useMemo } from 'react';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';
import DataTable from '@dex/bubl-dash/src/lib/components/DataTable/DataTable';
import Link from '@dex/bubl-dash/src/lib/components/Link/Link';

const LeagueSeasonGameSingleViewBoxScores: React.FC<any> = (props: any) => {

    const { data } = props;

    const columns = [
        {
            label: "#",
            key: "jerseyNumber",
            style: { width: 30 }
        },
        {
            label: "Player",
            key: "name",
            render: (value, data) => {

                if (value === 'Totals') return value;

                return <Link path={`/league/seasons/players/${data.id}/view`}>{value}</Link>
            }
        },
    ];

    data.boxScoreColumns.map((column) => {

        if (column.type === "number") column.fallback = "0";

        columns.push({
            ...column,
            key: "stats." + column.key
        })

    })

    return useMemo(() => (

        <Panel
            heading={"Box Scores"}
        >

            {data.teams.map((team, teamIndex) => (

                <React.Fragment key={teamIndex}>

                    <Seperator
                        heading={team.name}
                        top={teamIndex ? "auto" : null}
                        bottom={"auto"}
                    />

                    <DataTable
                        data={team.roster}
                        columns={columns}
                    />

                </React.Fragment>

            ))}


        </Panel>

    ), [data]);

}

export default LeagueSeasonGameSingleViewBoxScores;
