import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldRepeater from '@dex/bubl-dash/src/lib/components/Fields/FieldRepeater';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';
import FieldTextArea from '@dex/bubl-dash/src/lib/components/Fields/FieldTextArea';


const AssessmentFormsCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    const app = useApp();

    const manageGlobal = app.userCan(['manageGlobal'], false);

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <Panel
            heading={"Assessment Form"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 12, md: 12 }}>

                        <FieldText
                            form={form}
                            label={"Title"}
                            name='title'
                            required={true}
                            defaultValue={form.defaults.title}
                        />

                    </Col>

                    {manageGlobal &&
                        <Col col={{ md: 12, xs: 12 }}>

                            <FieldRelationship
                                form={form}
                                label='Host'
                                name='hostId'
                                required={false}
                                preload={true}
                                endpoint={'Hosts'}
                                defaultValue={form.defaults.hostId}
                            />

                        </Col>
                    }

                </Row>

                <Seperator
                    heading={"Assesment Criteria"}
                    top="auto"
                    bottom="auto"
                />

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24 }}>

                        <FieldRepeater
                            form={form}
                            name={'criteria'}
                            defaultValues={""}
                            addButtonLabel={"Add Criteria"}
                        >

                            {
                                Array.isArray(form.values.criteria) &&
                                form.values.criteria.map((row, n) => (

                                    <React.Fragment key={form.key + "-" + n}>

                                        <Row gutter={8} edge={true}>

                                            <Col col={{ xs: 12, md: 12 }}>

                                                <FieldText
                                                    form={form}
                                                    label={"Title"}
                                                    name={`criteria[${n}][title]`}
                                                    required={true}
                                                    defaultValue={row.title}
                                                />

                                            </Col>

                                            <Col col={{ xs: 24, md: 24 }}>

                                                <FieldTextArea
                                                    form={form}
                                                    label={"Description"}
                                                    name={`criteria[${n}][description]`}
                                                    required={false}
                                                    defaultValue={row.description}
                                                />

                                            </Col>

                                        </Row>

                                    </React.Fragment>

                                ))}

                        </FieldRepeater>

                    </Col>

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default AssessmentFormsCreateEditForm;
