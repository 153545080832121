import PickupAttendanceIndex from './PickupAttendanceIndex';
import PickupAttendanceCreate from './PickupAttendanceCreate';
import PickupAttendanceSettings from './PickupAttendanceSettings';
import PickupAttendanceSingle from './PickupAttendanceSingle';

export default function (app) {

    const settings = PickupAttendanceSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    // app.addMenu({
    //     key: settings.key,
    //     zone: settings.zone,
    //     label: settings.title,
    //     priority: 14,
    //     items: [
    //         {
    //             label: "All",
    //             path: settings.path,
    //             caps: settings.indexCaps
    //         },
    //         {
    //             label: "Add New",
    //             path: settings.path + "/create",
    //             caps: settings.createCaps
    //         }
    //     ],
    // });

    PickupAttendanceIndex(app, settings);

    PickupAttendanceCreate(app, settings);

    PickupAttendanceSingle(app, settings);

}