import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ActivityPanel from '@dex/bubl-dash/src/lib/components/SinglePanels/ActivityPanel';
import { Seperator } from '@dex/bubl-dash';
import { DataTable } from '@dex/bubl-dash';

const ShopPayoutSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    const columns: any = [
        {
            label: "Order #",
            key: "this",
            format: "ModelLink",
        },
        {
            label: "Date Paid",
            key: "datePaid",
            format: "date",
        },
        {
            label: "Amount Received",
            key: "amountPaid",
            format: "number",
        },
        {
            label: "Margin Rate",
            key: "payoutRate",
            format: "number",
            suffix: "%",
        },
        {
            label: "Payout Amount",
            key: "payoutAmount",
        },
        {
            label: "Margin Amount",
            key: "payoutMargin",
        },
    ];

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Payout Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                key: "host",
                                label: "Host",
                                format: "modelLink"
                            },
                            {
                                key: "period",
                                label: "Period"
                            },
                            {
                                key: "status",
                                label: "Status",
                                format: "titleCase"
                            },
                        ]}
                        columnB={[
                            {
                                key: "totalSales",
                                label: "Total Sales",
                                format: "number",
                            },
                            {
                                key: "totalPayout",
                                label: "Total Payout",
                                format: "number",
                            },
                            {
                                key: "totalMargin",
                                label: "Total Margin",
                                format: "number",
                            },
                        ]}
                        columnC={[
                            {
                                key: "created",
                                label: "Date Created",
                                format: "onandby"
                            },
                        ]}
                    />

                    <Seperator
                        top="auto"
                        bottom="auto"
                        heading="Orders"
                    />

                    <DataTable
                        data={data.orders}
                        columns={columns}
                        loading={false}
                    />

                </Panel>

            </Col>

            <Col className={'side'}>

                <ActivityPanel parent={data} />

            </Col>

        </Row>

    ), [data]);

}

export default ShopPayoutSingleView;
