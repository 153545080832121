import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import FieldNumber from '@dex/bubl-dash/src/lib/components/Fields/FieldNumber';
import FieldUpload from '@dex/bubl-dash/src/lib/components/Fields/FieldUpload';
import FieldSwitch from '@dex/bubl-dash/src/lib/components/Fields/FieldSwitch';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';

const CampSessionGroupCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    const app = useApp();

    const manageGlobal = app.userCan(['manageGlobal'], false);

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <Panel
            heading={"Camp Session Group Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ md: 16, xs: 24 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ md: 24, xs: 24 }}>

                                <FieldText
                                    form={form}
                                    label={"Title"}
                                    name='title'
                                    required={true}
                                    defaultValue={form.defaults.title}
                                />

                            </Col>

                            <Col col={{ md: 24, xs: 24 }}>

                                <FieldRelationship
                                    form={form}
                                    label={"Operator"}
                                    name='operatorId'
                                    required={true}
                                    preload={true}
                                    endpoint={'CampOperator'}
                                    defaultValue={form.defaults.operatorId}
                                />

                            </Col>

                            <Col col={{ md: 24, xs: 24 }}>

                                <FieldRelationship
                                    form={form}
                                    label={"Sport"}
                                    name='sportId'
                                    required={true}
                                    preload={true}
                                    endpoint={'Sport'}
                                    defaultValue={form.defaults.sportId}
                                />

                            </Col>


                            <Col col={{ md: 24, xs: 24 }}>

                                <FieldRelationship
                                    form={form}
                                    label={"Category"}
                                    name='categoryId'
                                    required={true}
                                    preload={true}
                                    endpoint={'CampCategory'}
                                    defaultValue={form.defaults.categoryId}
                                />

                            </Col>

                            <Col col={{ md: 24, xs: 24 }}>

                                <FieldText
                                    form={form}
                                    label={"Location"}
                                    name='location'
                                    required={false}
                                    placeholder={"City, State"}
                                    defaultValue={form.defaults.location}
                                />

                            </Col>

                            <Col col={{ md: 24, xs: 24 }}>

                                <FieldRadio
                                    form={form}
                                    label={"Court"}
                                    name='court'
                                    required={false}
                                    defaultValue={form.defaults.court}
                                    options={[
                                        { value: "indoor", label: "Indoor" },
                                        { value: "outdoor", label: "Outdoor" }
                                    ]}
                                />

                            </Col>

                            <Col col={{ md: 24, xs: 24 }}>

                                <FieldRadio
                                    form={form}
                                    label={"Level"}
                                    name='level'
                                    required={false}
                                    defaultValue={form.defaults.level}
                                    options={[
                                        { value: "beginner", label: "Beginner" },
                                        { value: "intermediate", label: "Intermediate" },
                                        { value: "advanced", label: "Advanced" },
                                    ]}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 24 }}>

                                <FieldSwitch
                                    form={form}
                                    label={"Attendee Can Cancel?"}
                                    name='canCancel'
                                    required={false}
                                    defaultValue={form.defaults.canCancel}
                                />

                            </Col>

                            {form.values.canCancel === "yes" &&

                                <Col col={{ xs: 24, md: 24 }}>

                                    <FieldNumber
                                        form={form}
                                        label={"Cancellation Prior Notice In Hours"}
                                        name={`cancelPriorNotice`}
                                        required={false}
                                        defaultValue={form.defaults.cancelPriorNotice}
                                    />

                                </Col>
                            }

                        </Row>

                    </Col>

                    <Col col={{ md: 8, xs: 24 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ md: 24, xs: 24 }}>

                                <FieldRadio
                                    form={form}
                                    label={"Status"}
                                    name='status'
                                    required={true}
                                    defaultValue={form.defaults.status}
                                    options={[
                                        { value: "draft", label: "Draft" },
                                        { value: "preview", label: "Preview" },
                                        { value: "publish", label: "Publish" },
                                        { value: "archive", label: "Archive" },
                                    ]}
                                />

                            </Col>

                            {manageGlobal &&
                                <Col col={{ md: 24, xs: 24 }}>

                                    <FieldSwitch
                                        form={form}
                                        label={"Featured"}
                                        name='isFeatured'
                                        required={false}
                                        defaultValue={form.defaults.isFeatured}
                                    />

                                </Col>
                            }

                            {manageGlobal &&
                                <Col col={{ md: 24, xs: 24 }}>

                                    <FieldNumber
                                        form={form}
                                        label={"Priority"}
                                        name={`priority`}
                                        required={false}
                                        defaultValue={form.defaults.priority}
                                    />

                                </Col>
                            }

                            <Col col={{ md: 24, xs: 24 }}>

                                <FieldNumber
                                    form={form}
                                    label={"Attendee Limit"}
                                    name={`attendeeLimit`}
                                    required={false}
                                    defaultValue={form.defaults.attendeeLimit}
                                />

                            </Col>

                            <Col col={{ md: 24, xs: 24 }}>

                                <FieldUpload
                                    form={form}
                                    folder={'admin-media'}
                                    label={"Thumbnail"}
                                    name={`thumbnail`}
                                    required={false}
                                    defaultValue={form.defaults.thumbnail}
                                    limit={1}
                                />

                            </Col>

                        </Row>

                    </Col>

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default CampSessionGroupCreateEditForm;
