import React, { useMemo } from 'react';
import { get } from '@dex/bubl-helpers';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';

import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio'
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldUpload from '@dex/bubl-dash/src/lib/components/Fields/FieldUpload';
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship';
import FieldRepeater from '@dex/bubl-dash/src/lib/components/Fields/FieldRepeater';
import FieldRich from '@dex/bubl-dash/src/lib/components/Fields/FieldRich';
import FieldSwitch from '@dex/bubl-dash/src/lib/components/Fields/FieldSwitch';
import FieldDate from '@dex/bubl-dash/src/lib/components/Fields/FieldDate';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';
import Alert from '@dex/bubl-dash/src/lib/components/Alert/Alert';

const NewsCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    const app = useApp();

    const manageGlobal = app.userCan(['manageGlobal'], false);

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <Panel
            heading={"News Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ md: 16, xs: 24 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ md: 24, xs: 24 }}>

                                <FieldRadio
                                    form={form}
                                    label='Type'
                                    name='type'
                                    required={true}
                                    defaultValue={form.defaults.type}
                                    options={[
                                        { value: "news", label: "News" },
                                        { value: "recap", label: "Recap" },
                                        { value: "watch", label: "Watch" },
                                        { value: "photos", label: "Photos" },
                                    ]}
                                />

                            </Col>

                            <Col col={{ md: 24, xs: 24 }}>

                                <FieldText
                                    form={form}
                                    label='Title'
                                    name='title'
                                    required={true}
                                    defaultValue={form.defaults.title}
                                />

                            </Col>

                            <Col col={{ md: 24, xs: 24 }}>

                                <FieldText
                                    form={form}
                                    label='Short Descripton'
                                    name='description'
                                    required={false}
                                    defaultValue={form.defaults.description}
                                />

                            </Col>

                            {form.values.type === 'recap' &&
                                <>

                                    <Col col={{ md: 12, xs: 24 }}>

                                        <FieldText
                                            form={form}
                                            label='Write-Up Tab Label'
                                            name='tabLabel'
                                            required={false}
                                            defaultValue={form.defaults.tabLabel}
                                        />

                                    </Col>

                                    <Col col={{ md: 12, xs: 24 }}>

                                        <FieldSwitch
                                            form={form}
                                            label="Hide Box Score Tab"
                                            name="boxScore"
                                            required={false}
                                            defaultValue={form.defaults.boxScore}
                                        />

                                    </Col>

                                </>
                            }

                            {(form.values.type === 'recap' || form.values.type === 'watch') &&

                                <>

                                    <Col col={{ md: 24, xs: 24 }}>

                                        <FieldRadio
                                            form={form}
                                            label="Video Source"
                                            name={'video[source]'}
                                            required={true}
                                            defaultValue={get(form.defaults, 'video.source')}
                                            options={[
                                                { value: 'youtube', label: 'Youtube' },
                                                { value: 'facebook', label: 'Facebook' },
                                            ]}
                                        />

                                    </Col>

                                    <Col col={{ md: 24, xs: 24 }}>

                                        <FieldText
                                            form={form}
                                            label={"Video Source ID"}
                                            name={`video[id]`}
                                            required={false}
                                            defaultValue={get(form.defaults, 'video.id')}
                                        />

                                    </Col>

                                </>

                            }

                            {form.values.type === 'recap' &&

                                <Col col={{ md: 24, xs: 24 }}>

                                    {(!form.values.leagueId || !form.values.seasonId || form.values.teamIds.length < 2) &&
                                        <Alert
                                            type={"info"}
                                            message={"Please Select League, Season & Teams."}
                                        />
                                    }

                                    {form.values.leagueId && form.values.seasonId && form.values.teamIds.length >= 2 &&
                                        <FieldRelationship
                                            form={form}
                                            label='Game Log'
                                            name='gameLogId'
                                            required={true}
                                            preload={true}
                                            endpoint={'GameLog'}
                                            defaultValue={form.defaults.gameLogId}
                                            where={{ leagueId: form.values.leagueId, seasonId: form.values.seasonId, teamIds: { inq: form.values.teamIds } }}
                                        />
                                    }

                                </Col>

                            }

                            {(form.values.type === 'recap' || form.values.type === 'photos') &&

                                <Col col={{ md: 24, xs: 24 }}>

                                    <FieldUpload
                                        form={form}
                                        folder={'admin-media'}
                                        label={"Photo Gallery"}
                                        name={`gallery`}
                                        required={form.values.type === "photos"}
                                        defaultValue={form.defaults.gallery}
                                        limit={10}
                                    />

                                </Col>

                            }

                            {/* Content Block Repeater */}

                            {(form.values.type === 'news' || form.values.type === 'recap') &&

                                <Col col={{ md: 24, xs: 24 }}>

                                    <FieldRepeater
                                        form={form}
                                        name={'contentBlocks'}
                                        addButtonLabel={"Add Block"}
                                    >

                                        {Array.isArray(form.values.contentBlocks) && form.values.contentBlocks.map((row, n) => (

                                            <React.Fragment key={form.key + "-" + n}>

                                                <FieldRadio
                                                    form={form}
                                                    label="Block Type"
                                                    name={`contentBlocks[${n}][type]`}
                                                    required={true}
                                                    defaultValue={row.type}
                                                    options={[
                                                        { value: 'text', label: 'Text' },
                                                        { value: 'image', label: 'Image' },
                                                        { value: 'video', label: 'Video' },
                                                    ]}
                                                />

                                                {row.type === 'text' &&

                                                    <FieldRich
                                                        form={form}
                                                        label={"Content"}
                                                        name={`contentBlocks[${n}][content]`}
                                                        required={false}
                                                        defaultValue={row.content}
                                                    />

                                                }

                                                {row.type === 'image' &&

                                                    <FieldUpload
                                                        form={form}
                                                        folder={'admin-media'}
                                                        label={"Image"}
                                                        name={`contentBlocks[${n}][image]`}
                                                        required={false}
                                                        defaultValue={row.image}
                                                        limit={1}
                                                    />

                                                }

                                                {row.type === 'video' &&

                                                    <>

                                                        <FieldRadio
                                                            form={form}
                                                            label="Video Source"
                                                            name={`contentBlocks[${n}][video][source]`}
                                                            required={true}
                                                            defaultValue={get(row, 'video.source')}
                                                            options={[
                                                                { value: 'youtube', label: 'Youtube' },
                                                                { value: 'facebook', label: 'Facebook' },
                                                            ]}
                                                        />

                                                        <FieldText
                                                            form={form}
                                                            label={"Video Source ID"}
                                                            name={`contentBlocks[${n}][video][id]`}
                                                            required={false}
                                                            defaultValue={get(row, 'video.id')}
                                                        />

                                                    </>

                                                }

                                            </React.Fragment>

                                        ))}

                                    </FieldRepeater>

                                </Col>

                            }

                            {/* Content Block Repeater END*/}

                        </Row>

                    </Col>

                    <Col col={{ md: 8, xs: 24 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ md: 24, xs: 24 }}>

                                <FieldRadio
                                    form={form}
                                    label='Status'
                                    name='status'
                                    required={true}
                                    defaultValue={form.defaults.status}
                                    options={[
                                        { value: "draft", label: "Draft" },
                                        { value: "preview", label: "Preview" },
                                        { value: "publish", label: "Publish" },
                                        { value: "archive", label: "Archive" },
                                    ]}
                                />

                            </Col>

                            {form.values.status !== 'draft' &&

                                <Col col={{ md: 24, xs: 24 }}>

                                    <FieldDate
                                        form={form}
                                        label={"Date"}
                                        name={`date`}
                                        required={true}
                                        defaultValue={form.defaults.date}
                                    />

                                </Col>

                            }

                            {form.values.type !== 'photos' &&

                                <Col col={{ md: 24, xs: 24 }}>

                                    <FieldUpload
                                        form={form}
                                        folder={'admin-media'}
                                        label={"Thumbnail"}
                                        name={`thumbnail`}
                                        required={false}
                                        defaultValue={form.defaults.thumbnail}
                                        limit={1}
                                    />

                                </Col>

                            }

                            {manageGlobal &&

                                <>

                                    <Col col={{ md: 24, xs: 24 }}>

                                        <FieldSwitch
                                            form={form}
                                            label="Global Feed"
                                            name="globalFeed"
                                            required={false}
                                            defaultValue={form.defaults.globalFeed}
                                        />

                                    </Col>

                                    <Col col={{ md: 24, xs: 24 }}>

                                        <FieldRelationship
                                            form={form}
                                            label='Category'
                                            name='categoryId'
                                            required={form.values.globalFeed}
                                            preload={true}
                                            endpoint={'NewsCategory'}
                                            defaultValue={form.defaults.categoryId}
                                        />

                                    </Col>

                                    <Col col={{ md: 24, xs: 24 }}>

                                        <FieldRelationship
                                            form={form}
                                            label='Host'
                                            name='hostId'
                                            required={true}
                                            preload={true}
                                            endpoint={'Hosts'}
                                            defaultValue={form.defaults.hostId}
                                        />

                                    </Col>

                                </>

                            }

                            {(!manageGlobal || form.values.hostId) &&
                                <Col col={{ md: 24, xs: 24 }}>

                                    <FieldRelationship
                                        form={form}
                                        label='Tag'
                                        name='tagId'
                                        required={true}
                                        preload={true}
                                        endpoint={'NewsTag'}
                                        defaultValue={form.defaults.tagId}
                                        where={{ hostId: form.values.hostId }}
                                    />

                                </Col>
                            }

                            <Col col={{ md: 24, xs: 24 }}>

                                <FieldRelationship
                                    form={form}
                                    label='League'
                                    name='leagueId'
                                    required={form.values.type === 'recap'}
                                    preload={true}
                                    endpoint={'League'}
                                    defaultValue={form.defaults.leagueId}
                                    where={{ hostId: form.values.hostId }}
                                />

                            </Col>

                            {form.values.leagueId &&

                                <Col col={{ md: 24, xs: 24 }}>

                                    <FieldRelationship
                                        form={form}
                                        label='Season'
                                        name='seasonId'
                                        required={form.values.type === 'recap'}
                                        preload={true}
                                        endpoint={'LeagueSeason'}
                                        where={{ leagueId: form.values.leagueId }}
                                        defaultValue={form.defaults.seasonId}
                                    />

                                </Col>
                            }

                            {form.values.leagueId && form.values.seasonId &&

                                <Col col={{ md: 24, xs: 24 }}>

                                    <FieldRelationship
                                        form={form}
                                        label='Teams'
                                        name='teamIds'
                                        required={false}
                                        preload={true}
                                        isMulti={true}
                                        endpoint={'LeagueSeasonTeam'}
                                        where={{ seasonId: form.values.seasonId }}
                                        defaultValue={form.defaults.teamIds}
                                    />

                                </Col>

                            }

                            {form.values.leagueId && form.values.seasonId && form.values.teamIds &&

                                <Col col={{ md: 24, xs: 24 }}>

                                    <FieldRelationship
                                        form={form}
                                        label='Players'
                                        name='playerIds'
                                        required={false}
                                        preload={true}
                                        isMulti={true}
                                        endpoint={'LeaguePlayer'}
                                        where={{ seasonIds: { inq: [form.values.seasonId] }, teamIds: form.values.teamIds.length > 0 ? { inq: form.values.teamIds } : undefined }}
                                        defaultValue={form.defaults.playerIds}
                                    />

                                </Col>

                            }

                        </Row>

                    </Col>

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default NewsCreateEditForm;
