import React, { useMemo, useState } from 'react';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import DataTable from '@dex/bubl-dash/src/lib/components/DataTable/DataTable';
import { Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import ModelLink from '@dex/bubl-dash/src/lib/components/ModelLink/ModelLink';

const CampPassesSessionsEnrolled: React.FC<any> = (props: any) => {

    const { data } = props;

    const columns = [
        {
            label: "Date & Time",
            key: "dateTime",
            render: (value, data, app) => {
                data.dateTimeStr = app.formatValue('datetime', {}, value);
                return <ModelLink data={data} primaryKey={"dateTimeStr"} />;
            }
        },
        {
            label: "Host",
            key: "host",
            format: "ModelLink",
        },
        {
            label: "Session Group",
            key: "sessionGroup",
            format: "ModelLink",
        },
        {
            label: "Venue",
            key: "venue"
        },
    ];

    return useMemo(() => (

        <Panel
            heading={`${data.sessionIds.length} Session(s) Enrolled`}
            full={true}
        >

            <Space />

            <DataTable
                data={data.sessions}
                columns={columns}
                loading={false}
            />

        </Panel>

    ), [data]);

}

export default CampPassesSessionsEnrolled;
