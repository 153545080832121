import React, { useMemo, useCallback } from 'react';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';
import { useForm, Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import UsersApi from '@dex/bubl-dash/src/lib/views/Users/UsersApi';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship';
import FieldDate from '@dex/bubl-dash/src/lib/components/Fields/FieldDate';

const UsersInfoEditPanel: React.FC<any> = (props: any) => {

    const { data } = props;

    const app = useApp();

    const form = useForm(data);

    const process = UsersApi.update(data.id, "userInfo", {
        onComplete: (data) => {

            app.alert('User Info Saved', 'success');

        }
    });

    const handleSubmit = useCallback((values) => {

        process.run({ data: values });

    }, [props]);

    return useMemo(() => (

        <Panel
            heading={"User Info"}
        >

            <Form
                form={form}
                loading={process.loading}
                onSubmit={handleSubmit}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldText
                            form={form}
                            label={"About Me"}
                            name='aboutMe'
                            required={false}
                            defaultValue={form.defaults.aboutMe}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldText
                            form={form}
                            label={"Location"}
                            name='location'
                            required={false}
                            defaultValue={form.defaults.location}
                        />

                    </Col>

                    <Col col={{ xs: 24 }}>

                        <FieldRelationship
                            form={form}
                            label={"Sports Played"}
                            name='sportsPlayedIds'
                            isMulti={true}
                            required={false}
                            preload={true}
                            endpoint={'Sport'}
                            defaultValue={form.defaults.sportsPlayedIds}
                        />

                    </Col>

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={process.loading}
                        disabled={form.uploading.length}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props, form, process]);

}

export default UsersInfoEditPanel;
