import React, { useMemo, useState } from 'react';
import { useApp } from '@dex/bubl-dash';
import ModalPage from '@dex/bubl-dash/src/lib/pages/ModalPage';

const LeagueSeasonTeamAddNewPlayer: React.FC<any> = (props: any) => {

    const { data, onClose } = props;

    const app = useApp();

    return useMemo(() => (

        <>

            <ModalPage
                show={true}
                onClose={onClose}
                routePath={"league/players/create"}
                routeParams={{
                    create: {
                        mode: "attach",
                        defaults: {
                            seasonId: data.seasonId,
                            teamId: data.id
                        },
                        rowProps: {
                            gutter: null
                        },
                        formProps: {
                            mode: "attach",
                            title: "Add New Player",
                            onAttach: (data) => {
                                onClose();
                            },
                        },
                        successRouteName: null,
                        successCallback: (data) => {
                            onClose();
                        },
                    }
                }}
            />

        </>

    ), [data]);

}

export default LeagueSeasonTeamAddNewPlayer;
