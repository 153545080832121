import React, { useMemo } from 'react';
import renderHTML from 'react-render-html';
import { Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import MediaImage from '@dex/bubl-dash/src/lib/components/MediaImage/MediaImage';

const RenderContentBlocks: React.FC<any> = (props: any) => {

    let { blocks, text } = props;

    if (!blocks && text) {

        blocks = [{ type: "text", content: text }];

    }

    return useMemo(() => {

        return (
            <div style={{ maxWidth: props.width || 640 }}>
                {blocks.map((block, index) => (
                    <React.Fragment key={index}>

                        {index > 0 &&
                            <Space />
                        }

                        {block.type === "text" &&
                            <RenderContentText block={block} />
                        }

                        {block.type === "image" &&
                            <RenderContentImage block={block} />
                        }

                        {block.type === "video" &&
                            <RenderContentVideo block={block} />
                        }

                    </React.Fragment>
                ))}
            </div>
        )

    }, [blocks]);

}

const RenderContentText: React.FC<any> = (props: any) => {

    const { block } = props;

    return useMemo(() => {

        return (
            <div className='rich-text-view'>
                {renderHTML(block.content)}
            </div>
        )

    }, [block]);

}

const RenderContentImage: React.FC<any> = (props: any) => {

    const { block } = props;

    return useMemo(() => {

        return (
            <div className='responsive'>
                <MediaImage id={block.image.fileId} filters={{ width: 1280 }} link={true} />
            </div>
        )

    }, [block]);

}

const RenderContentVideo: React.FC<any> = (props: any) => {

    const { block } = props;

    let player = process.env.REACT_APP_ENV === "production" ? `https://player.huddlehuddle.com` : `https://player.stg.huddlehuddle.com`;

    player = 'http://localhost:3005'

    const { source, id } = block.video;

    let uri: any = "";

    if (source === 'youtube') uri = `${player}/?source=youtube&id=${id}`;
    else if (source === 'facebook') uri = `${player}/?source=facebook&id=${id}`;
    else if (source === 'pixellot') uri = `${player}/?source=pixellot&id=${encodeURIComponent(id)}`;
    else if (source === 'watch') uri = `${player}/?source=watch&id=${encodeURIComponent(id)}`;

    return useMemo(() => {

        return (
            <div className='responsive'>
                <iframe
                    title={id}
                    className="ytplayer"
                    width={block.width ? block.width : 640}
                    height={block.height ? block.height : 360}
                    src={uri}
                    scrolling="no"
                    allowFullScreen
                >
                </iframe>

            </div>
        )

    }, [block]);

}

export default RenderContentBlocks;
