import React from 'react';
import { titleCase, get } from "@dex/bubl-helpers";
import LeagueSeasonTeamApi from './LeagueSeasonTeamApi';
import Button from '@dex/bubl-dash/src/lib/components/Button/Button';

let settings;

export default settings = {
    key: 'league/seasons/teams',
    zone: 'leagues',
    path: '/league/seasons/teams',
    title: "League Team",
    endpoint: LeagueSeasonTeamApi.endpoint,
    modelName: 'LeagueSeasonTeam',
    idKey: 'id',
    primaryKey: 'name',
    actions: ['trash'],
    indexCaps: ["viewLeagueSeasonTeam"],
    viewCaps: ["viewLeagueSeasonTeam"],
    createCaps: ["createLeagueSeasonTeam"],
    updateCaps: ["updateLeagueSeasonTeam"],
    indexTitle: (routeName, params, users) => {

        let title: string = settings.title;

        if (params && params.name) title = title + " / " + titleCase(params.name);

        return title;

    },
    indexTabs: (routeName, params, users) => {

        return [
            {
                label: "All",
                path: settings.path,
                caps: settings.indexCaps
            },
            {
                label: "Add New",
                path: settings.path + "/create",
                caps: settings.createCaps
            },
        ]

    },
    indexButtons: (routeName, params, users) => {

        return [
            {
                label: "Add New",
                path: settings.path + '/create',
                caps: settings.createCaps
            }
        ]

    },
    indexTabCounts: (routeName, params, dontation) => {

        return settings.endpoint + "/getCounts";

    },
    indexFetch: LeagueSeasonTeamApi.get,
    indexFetchParams: (routeName, params, users) => {

        const filters: any = { where: {}, include: ['manager'], order: ['created.date DESC'], limit: 10, skip: 0 };

        if (params && params.status) filters.where.status = { inq: [params.status] };

        return filters;

    },
    indexQuickSearch: (routeName, params, users) => {

        const fields = ['name', 'city', 'shortName', 'managersEmail'];

        return fields;

    },
    indexSearchFields: (routeName, params, users) => {

        const fields = [
            {
                label: "Name",
                key: "name",
                type: "text",
            },
            {
                label: "City",
                key: "city",
                type: "text",
            },
            {
                label: "Manager",
                key: "managerId",
                type: "relationship",
                model: "Users"
            },
            {
                label: "Manager's Email",
                key: "managersEmail",
                type: "text",
            },
        ];

        return fields;

    },

    indexColumns: (routeName, params, users) => {

        const columns = [
            {
                label: "Name",
                key: "this",
                format: "ModelLink",
            },
            {
                label: "Short Name",
                key: "shortName",
            },
            {
                label: "City",
                key: "city",
            },
            {
                label: "Manager",
                key: "manager",
                format: "ModelLink",
            },
            {
                label: "Manager's Email",
                key: "managersEmail",
            },
            {
                label: "Created Date",
                key: "created",
                format: "OnAndBy",
                sort: "created.date",
                alignEnd: true
            },
            {
                label: "Actions",
                key: "actions",
                alignEnd: true,
                format: "actions",
                api: LeagueSeasonTeamApi,
                settings: settings,
            }
        ];

        return columns;

    },
    singleFetch: LeagueSeasonTeamApi.getOne,
    singleFetchParams: (routeName, params, users, fetch) => {

        const filters = { include: ['manager', 'season', 'team'] };

        return filters;

    },
    singleTitle: (routeName, params, users, fetch) => {

        let title: any = "Loading...";

        const complete = get(fetch, 'complete');
        const error = get(fetch, 'error');
        const data = get(fetch, 'data');

        if (error) title = "Error";
        else if (data) title = data[settings.primaryKey];
        else if (complete && !data) title = "Not Found";

        if (data && data.season && data.season.title) title = title + ", " + data.season.title;

        return title;

    },
    singleButtons: (routeName, params, users, fetch) => {

        const data = get(fetch, 'data');

        if (data) {

            return [
                {
                    label: "Manage Season",
                    path: "/league/seasons/" + data.seasonId + "/view",
                }
            ]

        }

        return [];

    },
    singleTabs: (routeName, params, users, fetch) => {

        const path = settings.path + "/" + params.id;

        return [
            {
                label: "View",
                path: path + "/view"
            },
            {
                label: "Edit",
                path: path + "/edit",
                caps: settings.updateCaps
            },
        ]

    },
};