import LeagueSeasonTeamIndex from './LeagueSeasonTeamIndex';
import LeagueSeasonTeamSettings from './LeagueSeasonTeamSettings';
import LeagueSeasonTeamSingle from './LeagueSeasonTeamSingle';

export default function (app) {

    const settings = LeagueSeasonTeamSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    LeagueSeasonTeamIndex(app, settings);

    LeagueSeasonTeamSingle(app, settings);

}