import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ActivityPanel from '@dex/bubl-dash/src/lib/components/SinglePanels/ActivityPanel';
import HostRequestsGoLive from '../../HostRequests/Components/HostRequestsGoLive';
import WebLink from '../../../components/WebLink/WebLink';

const CampSessionGroupSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    console.log(data);

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <WebLink
                path={`/camps/group/${data.id}/${data.slug}`}
            />

            {!data.public && (data.status === "preview" || data.status === "publish") &&
                <Col col={{ xs: 24 }}>
                    <HostRequestsGoLive data={data} />
                </Col>
            }


            <Col
                className={'main'}
            >

                <Panel
                    heading={"Camp Session Group Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Title",
                                key: "title",
                                format: "link",
                            },
                            {
                                label: "Location",
                                key: "location",
                                format: "titleCase"
                            },
                            {
                                label: "Court",
                                key: "court",
                                format: "titleCase"
                            },
                            {
                                label: "Level",
                                key: "level",
                                format: "titleCase"
                            },
                            {
                                label: "Cancellation Prior Notice In Hours",
                                key: "cancelPriorNotice",
                                conditions: data.canCancel
                            },
                            {
                                label: "Attendee Limit",
                                key: "attendeeLimit"
                            },
                        ]}
                        columnB={[
                            {
                                label: "Status",
                                key: "status",
                                format: "titleCase"
                            },
                            {
                                label: "Featured",
                                key: "isFeatured",
                                format: "switch"
                            },
                            {
                                label: "Priority",
                                key: "priority"
                            },
                        ]}
                        columnC={[
                            {
                                label: "Host",
                                key: "host",
                                format: "ModelLink",
                            },
                            {
                                label: "Operator",
                                key: "operator",
                                format: "ModelLink",
                            },
                            {
                                label: "Sport",
                                key: "sport",
                                format: "ModelLink",
                            },
                            {
                                label: "Category",
                                key: "category",
                                format: "ModelLink",
                            }
                        ]}
                    />

                </Panel>

            </Col>

            <Col className={'side'}>

                <ActivityPanel parent={data} />

            </Col>

        </Row>

    ), [data]);

}

export default CampSessionGroupSingleView;
