import React, { useMemo } from 'react';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import DataTable from '@dex/bubl-dash/src/lib/components/DataTable/DataTable';
import Link from '@dex/bubl-dash/src/lib/components/Link/Link';

const LeagueSeasonGameSingleViewResult: React.FC<any> = (props: any) => {

    const { data } = props;


    const columns = [
        {
            label: "Team",
            key: "team",
            render: (value, data) => {
                return <Link path={`/league/seasons/teams/${value.id}/view`}>{value.name}</Link>
            }
        },
        ...data.totalColumns,
        {
            label: "Result",
            key: "result",
            format: "replace",
            find: ["w", "l", "d", "n"],
            replace: ["Win", "Loss", "Draw", "NC"],
        }
    ]

    const tableData = useMemo(() => {

        const table: any = [];

        data.teams.map((team, teamIndex) => {

            const row: any = {};

            columns.map((column, columnIndex) => {

                if (column.key === "team") row.team = team;
                else if (column.key === "result") row.result = data.outcome[teamIndex];
                else if (column.key === "final") row.final = data.totals[teamIndex];
                else row[column.key] = data.breakTotals[teamIndex][column.key];
            })

            table.push(row);

        })

        return table;

    }, [data]);

    return useMemo(() => (

        <Panel
            heading={"Result"}
        >

            <DataTable
                data={tableData}
                columns={columns}
            />

        </Panel>

    ), [data]);

}

export default LeagueSeasonGameSingleViewResult;
