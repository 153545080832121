import WatchTagIndex from './WatchTagIndex';
import WatchTagCreate from './WatchTagCreate';
import WatchTagSettings from './WatchTagSettings';
import WatchTagSingle from './WatchTagSingle';

export default function (app) {

    const settings = WatchTagSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });


    WatchTagIndex(app, settings);

    WatchTagCreate(app, settings);

    WatchTagSingle(app, settings);

}