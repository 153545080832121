import React, { useMemo } from 'react';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';

import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText'
import FieldRepeater from '@dex/bubl-dash/src/lib/components/Fields/FieldRepeater'
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship'


const FormCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    const app = useApp();

    const manageGlobal = app.userCan(['manageGlobal'], false);

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <Panel
            heading={"Form Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 16 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24 }}>

                                <FieldText
                                    form={form}
                                    label="Title"
                                    name="title"
                                    required={true}
                                    defaultValue={form.defaults.title}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldRepeater
                                    form={form}
                                    name={'fieldIds'}
                                    addButtonLabel={"Add Field"}
                                >

                                    {form.values.fieldIds.map((row, n) => (

                                        <React.Fragment key={form.key + "-" + n}>

                                            <FieldRelationship
                                                form={form}
                                                label={"Field"}
                                                name={`fieldIds[${n}]`}
                                                required={true}
                                                preload={true}
                                                endpoint={'FormField'}
                                                defaultValue={row}
                                            />

                                        </React.Fragment>

                                    ))}

                                </FieldRepeater>

                            </Col>

                        </Row>

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <Row gutter={8} edge={true}>

                            {manageGlobal &&
                                <Col col={{ xs: 24 }}>

                                    <FieldRelationship
                                        form={form}
                                        label={"Host"}
                                        name={`hostId`}
                                        required={true}
                                        preload={true}
                                        endpoint={'Hosts'}
                                        defaultValue={form.defaults.hostId}
                                    />

                                </Col>
                            }

                            <Col col={{ xs: 24 }}>

                                <FieldText
                                    form={form}
                                    label="Button Text"
                                    name="buttonText"
                                    required={false}
                                    defaultValue={form.defaults.buttonText}
                                />

                            </Col>

                        </Row>

                    </Col>

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default FormCreateEditForm;
