import React from 'react';
import { titleCase, get } from "@dex/bubl-helpers";
import LeagueSeasonApi from './LeagueSeasonApi';
import LeagueSeasonActions from './Components/LeagueSeasonActions';

let settings;

export default settings = {
    key: 'league/seasons',
    zone: 'leagues',
    path: '/league/seasons',
    title: "League Season",
    endpoint: LeagueSeasonApi.endpoint,
    modelName: 'LeagueSeason',
    idKey: 'id',
    primaryKey: 'title',
    indexCaps: ["viewLeagueSeason"],
    viewCaps: ["viewLeagueSeason"],
    createCaps: ["createLeagueSeason"],
    indexTitle: (routeName, params, users) => {

        let title: string = settings.title;

        if (params && params.name) title = title + " / " + titleCase(params.name);

        return title;

    },
    indexTabs: (routeName, params, users) => {

        return [
            {
                label: "All",
                path: settings.path
            },
            {
                label: "Draft",
                path: settings.path + "/status/draft",
            },
            {
                label: "Preview",
                path: settings.path + "/status/preview",
            },
            {
                label: "Publish",
                path: settings.path + "/status/publish",
            },
            {
                label: "Archive",
                path: settings.path + "/status/archive",
            },
            {
                label: "Add New",
                path: settings.path + "/create",
                caps: settings.createCaps
            },
        ]

    },
    indexButtons: (routeName, params, users) => {

        return [
            {
                label: "Add New",
                path: settings.path + '/create',
                caps: settings.createCaps
            }
        ]

    },
    indexTabCounts: (routeName, params, dontation) => {

        return settings.endpoint + "/getCounts";

    },
    indexFetch: LeagueSeasonApi.get,
    indexFetchParams: (routeName, params, users) => {

        const filters: any = { where: {}, include: ['league', 'host'], order: ['created.date DESC'], limit: 10, skip: 0 };

        if (params && params.status) filters.where.status = { inq: [params.status] };

        return filters;

    },
    indexQuickSearch: (routeName, params, users) => {

        const fields = ['title'];

        return fields;

    },
    indexSearchFields: (routeName, params, users) => {

        const fields = [
            {
                label: "Title",
                key: "title",
                type: "text",
            },
            {
                label: "Status",
                key: "status",
                type: "choice",
                options: [
                    { value: "draft", label: "Draft" },
                    { value: "preview", label: "Preview" },
                    { value: "publish", label: "Publish" },
                    { value: "archive", label: "Archive" },
                ],
            },
            {
                label: "Host",
                key: "hostId",
                type: "relationship",
                model: "Hosts",
            },
            {
                label: "League",
                key: "leagueId",
                type: "relationship",
                model: "League",
            },
            {
                label: "API Type",
                key: "sourceType",
                type: 'text'
            },
            {
                label: "Season ID",
                key: "sourceSesaonId",
                type: 'text'
            },
        ];

        return fields;

    },

    indexColumns: (routeName, params, users) => {

        const columns = [
            {
                label: "Title",
                key: "this",
                format: "ModelLink",
            },
            {
                label: "Status",
                key: "status",
                type: 'text',
                format: "titleCase",
            },
            {
                label: "Host",
                key: "host",
                format: "ModelLink",
            },
            {
                label: "League",
                key: "league",
                format: "ModelLink",
            },
            {
                label: "Priority",
                key: "priority",
                type: 'text'
            },
            {
                label: "Created Date",
                key: "created",
                format: "OnAndBy",
                sort: "created.date",
                alignEnd: true
            },
            {
                label: "Actions",
                key: "actions",
                alignEnd: true,
                render: (value, data) => {

                    return <LeagueSeasonActions data={data} />;

                }
            }
        ];

        return columns;

    },
    singleFetch: LeagueSeasonApi.getOne,
    singleFetchParams: (routeName, params, users, fetch) => {

        const filters = { include: ['league', 'data', 'host', 'sport'] };

        return filters;

    },
    singleTitle: (routeName, params, users, fetch) => {

        let title: string = "Loading...";

        const complete = get(fetch, 'complete');
        const error = get(fetch, 'error');
        const data = get(fetch, 'data');

        if (error) title = "Error";
        else if (data) title = data[settings.primaryKey];
        else if (complete && !data) title = "Not Found";

        return title;

    },
    singleTabs: (routeName, params, users, fetch) => {

        const path = settings.path + "/" + params.id;

        return [
            {
                label: "View",
                path: path + "/view"
            },
            {
                label: "Edit",
                path: path + "/edit"
            },
        ]

    },
};