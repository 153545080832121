import CampSessionIndex from './CampSessionIndex';
import CampSessionCreate from './CampSessionCreate';
import CampSessionSettings from './CampSessionSettings';
import CampSessionSingle from './CampSessionSingle';
import CampSessionGroupSettings from '../CampSessionGroup/CampSessionGroupSettings';
import CampOperatorSettings from '../CampOperator/CampOperatorSettings';
import CampCategorySettings from '../CampCategory/CampCategorySettings';
import CampAthletesSettings from '../CampAthletes/CampAthletesSettings';
import CampCoachesSettings from '../CampCoaches/CampCoachesSettings';
import AssessmentFormsSettings from '../AssessmentForms/AssessmentFormsSettings';
import AssessmentsSettings from '../Assessments/AssessmentsSettings';
import CampPassesSettings from "../CampPasses/CampPassesSettings";
import CampAttendanceSettings from '../CampAttendance/CampAttendanceSettings';

export default function (app) {

    const settings = CampSessionSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    app.addMenu({
        key: settings.key,
        zone: settings.zone,
        label: "Camps",
        priority: 10,
        items: [
            {
                label: "Sessions",
                path: settings.path,
                caps: settings.indexCaps
            },
            {
                label: "Session Groups",
                path: CampSessionGroupSettings.path,
                caps: CampSessionGroupSettings.indexCaps
            },
            {
                label: "Operators",
                path: CampOperatorSettings.path,
                caps: CampOperatorSettings.indexCaps
            },
            {
                label: "Coaches",
                path: CampCoachesSettings.path,
                caps: CampCoachesSettings.indexCaps
            },
            {
                label: "Athletes",
                path: CampAthletesSettings.path,
                caps: CampAthletesSettings.indexCaps
            },
            {
                label: "Categories",
                path: CampCategorySettings.path,
                caps: CampCategorySettings.indexCaps
            },
            {
                label: "Assessments",
                path: AssessmentsSettings.path,
                caps: AssessmentsSettings.indexCaps
            },
            {
                label: "Assessment Forms",
                path: AssessmentFormsSettings.path,
                caps: AssessmentFormsSettings.indexCaps
            },
            {
                label: "Passes",
                path: CampPassesSettings.path,
                caps: CampPassesSettings.indexCaps
            },
            { 
                label: "Attendance",
                path: CampAttendanceSettings.path,
                caps: CampAttendanceSettings.indexCaps
            }
        ],
    });


    app.addMenu({
        key: settings.key,
        zone: settings.zone,
        label: "Actions",
        priority: 10,
        items: [
            {
                label: "Add New Session",
                path: settings.path + "/create",
                caps: settings.createCaps
            },
            {
                label: "Add New Session Group",
                path: CampSessionGroupSettings.path + "/create",
                caps: CampSessionGroupSettings.createCaps
            },
            {
                label: "Add New Operator",
                path: CampOperatorSettings.path + "/create",
                caps: CampOperatorSettings.createCaps
            },
            {
                label: "Add New Coach",
                path: CampCoachesSettings.path + "/create",
                caps: CampCoachesSettings.createCaps
            },
            {
                label: "Add New Athlete",
                path: CampAthletesSettings.path + "/create",
                caps: CampAthletesSettings.createCaps
            },
            {
                label: "Add New Category",
                path: CampCategorySettings.path + "/create",
                caps: CampCategorySettings.createCaps
            },
            // {
            //     label: "Add New Assessment",
            //     path: AssessmentsSettings.path + "/create",
            //     caps: AssessmentsSettings.createCaps
            // },
            {
                label: "Add New Assessment Form",
                path: AssessmentFormsSettings.path + "/create",
                caps: AssessmentFormsSettings.createCaps
            },
        ],
    });

    CampSessionIndex(app, settings);

    CampSessionCreate(app, settings);

    CampSessionSingle(app, settings);

}