import React, { useCallback, useEffect, useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import OrderVenueTimeSlots from './OrderVenueTimeSlots';
import FieldHidden from '@dex/bubl-dash/src/lib/components/Fields/FieldHidden';
import FieldDate from '@dex/bubl-dash/src/lib/components/Fields/FieldDate';
import moment from 'moment';

const OrderCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    const setTimeSlotsCart = useCallback((selected, slot) => {

        let cart: any = null;

        if (selected && selected.length) {

            let slots: any = [];

            selected.map((key: any, i: number) => {

                let keys = key.split(".");

                slots.push(keys[1]);

                if (i === (selected.length - 1)) {
                    let endTime = keys[1].replace(":", "").toString().padStart(4, "0");

                    endTime = moment(form.values.date).clone().set({ 'hour': endTime[0] + endTime[1], 'minute': endTime[2] + endTime[3] }).add(slot.size, "minutes").format("HHmm");
                    endTime = endTime.toString().padStart(4, "0");
                }

                return null;
            })

            cart = {
                ...slot,
                date: moment(form.values.date).format('YYYY-MM-DD'),
                slots: slots,
                total: slot.price * selected.length
            }

        }

        form.handleChange({ name: "productId", value: slot.productId });
        form.handleChange({ name: "slot", value: cart });

    }, [form.values]);

    return useMemo(() => (

        <Panel
            heading={"Order Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24 }}>

                        <FieldRadio
                            form={form}
                            label='Product Type'
                            name='type'
                            required={true}
                            defaultValue={form.defaults.type}
                            options={[
                                { value: "pickupPass", label: "Pickup Pass" },
                                // { value: "leagueEntry", label: "League Entry" },
                                // { value: "leagueEntryTeam", label: "League Entry Team" },
                                { value: "campPass", label: "Camp Pass" },
                                { value: "venueBooking", label: "Venue Booking" },
                                { value: "venueLiveStream", label: "Venue Live Stream" },
                            ]}
                        />

                    </Col>

                    {(form.values.type && form.values.type !== "venueBooking") &&
                        <Col col={{ md: 12, xs: 24 }}>

                            <FieldRelationship
                                key={form.values.type}
                                form={form}
                                label='Product'
                                name='productId'
                                required={true}
                                preload={true}
                                isMulti={false}
                                endpoint={'Product'}
                                defaultValue={form.defaults.productId}
                                where={{
                                    type: form.values.type
                                }}
                            />

                        </Col>
                    }

                    {form.values.type &&
                        <Col col={{ md: 12, xs: 24 }}>

                            <FieldRelationship
                                form={form}
                                label='Customer'
                                name='ownerId'
                                required={true}
                                preload={true}
                                isMulti={false}
                                endpoint={'Users'}
                                defaultValue={form.defaults.ownerId}
                            />

                        </Col>
                    }

                    {(form.values.type === "campPass" && form.values.ownerId) &&
                        <Col col={{ md: 12, xs: 24 }}>

                            <FieldRelationship
                                key={form.values.ownerId}
                                form={form}
                                label='Athlete'
                                name='athleteId'
                                required={true}
                                preload={true}
                                isMulti={false}
                                endpoint={'CampAthletes'}
                                defaultValue={form.defaults.athleteId}
                                where={{
                                    userId: form.values.ownerId
                                }}
                            />

                        </Col>

                    }

                    {(form.values.type && form.values.type === "venueBooking") &&
                        <Col col={{ md: 12, xs: 24 }}>

                            <FieldRelationship
                                form={form}
                                label='Venue Operator'
                                name='operatorId'
                                required={true}
                                preload={true}
                                isMulti={false}
                                endpoint={'VenueOperator'}
                                defaultValue={form.defaults.operatorId}
                            />

                        </Col>
                    }

                    {(form.values.type && form.values.type === "venueBooking" && form.values.operatorId) &&
                        <Col col={{ md: 12, xs: 24 }}>

                            <FieldRelationship
                                key={form.values.operatorId}
                                form={form}
                                label='Venue Court'
                                name='courtId'
                                required={true}
                                preload={true}
                                isMulti={false}
                                endpoint={'VenueCourt'}
                                defaultValue={form.defaults.courtId}
                                where={{
                                    operatorId: form.values.operatorId
                                }}
                            />

                        </Col>
                    }

                    {(form.values.type && form.values.type === "venueBooking" && form.values.operatorId) &&
                        <Col col={{ md: 12, xs: 24 }}>

                            <FieldDate
                                form={form}
                                label={"Date"}
                                name={"date"}
                                required={true}
                                defaultValue={form.defaults.date}
                            />

                        </Col>
                    }

                    {(form.values.type === "venueBooking" && form.values.operatorId && form.values.courtId && form.values.date) &&
                        <Col
                            col={{ md: 24 }}
                            key={form.values.operatorId}
                        >

                            <FieldHidden
                                form={form}
                                name={"slot"}
                                defaultValue={""}
                            />

                            <FieldHidden
                                form={form}
                                name={"productId"}
                                defaultValue={""}
                            />

                            <OrderVenueTimeSlots
                                operatorId={form.values.operatorId}
                                courtId={form.values.courtId}
                                date={form.values.date}
                                onChange={setTimeSlotsCart}
                            />

                        </Col>
                    }

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default OrderCreateEditForm;
