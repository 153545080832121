import React from 'react';
import { titleCase, get } from "@dex/bubl-helpers";
import NewsApi from './NewsApi';
import NewsActions from './Components/NewsActions';

let settings;

export default settings = {
    key: 'content/news',
    zone: 'content',
    path: '/content/news',
    title: "News",
    endpoint: NewsApi.endpoint,
    modelName: 'News',
    idKey: 'id',
    primaryKey: 'title',
    indexCaps: ["viewNews"],
    viewCaps: ["viewNews"],
    createCaps: ["createNews"],
    indexTitle: (routeName, params, users) => {

        let title: string = settings.title;

        if (params && params.name) title = title + " / " + titleCase(params.name);

        return title;

    },
    indexTabs: (routeName, params, users) => {

        return [
            {
                label: "All",
                path: settings.path,
                caps: settings.indexCaps
            },
            {
                label: "Draft",
                path: settings.path + "/status/draft",
                caps: settings.indexCaps
            },
            {
                label: "Preview",
                path: settings.path + "/status/preview",
                caps: settings.indexCaps
            },
            {
                label: "Publish",
                path: settings.path + "/status/publish",
                caps: settings.indexCaps
            },
            {
                label: "Archive",
                path: settings.path + "/status/archive",
                caps: settings.indexCaps
            },
            {
                label: "Add New",
                path: settings.path + "/create",
                caps: settings.createCaps
            },
        ]

    },
    indexButtons: (routeName, params, users) => {

        return [
            {
                label: "Add New",
                path: settings.path + '/create',
                caps: settings.createCaps
            }
        ]

    },
    indexTabCounts: (routeName, params, dontation) => {

        return settings.endpoint + "/getCounts";

    },
    indexFetch: NewsApi.get,
    indexFetchParams: (routeName, params, users) => {

        const filters: any = { where: {}, include: ['category', 'host', 'tag'], order: ['created.date DESC'], limit: 10, skip: 0 };

        if (params && params.type) filters.where.type = params.type;

        if (params && params.status) filters.where.status = params.status;

        return filters;

    },
    indexQuickSearch: (routeName, params, users) => {

        const fields = ['title', 'status'];

        return fields;

    },
    indexSearchFields: (routeName, params, users) => {

        const fields = [
            {
                label: "Title",
                key: "title",
                type: "text",
            },
            {
                label: "Status",
                key: "status",
                type: "choice",
                options: [
                    { value: "draft", label: "Draft" },
                    { value: "preview", label: "Preview" },
                    { value: "publish", label: "Publish" },
                    { value: "archive", label: "Archive" },
                ]
            },
            // {
            //     label: "Global Feed",
            //     key: "globalFeed",
            //     type: "switch",
            // },
            {
                label: "Host",
                key: "hostId",
                type: "relationship",
                model: "Hosts",
            },
            {
                label: "Tag",
                key: "tagId",
                type: "relationship",
                model: "NewsTag",
            },
        ];

        return fields;

    },

    indexColumns: (routeName, params, users) => {

        const columns = [
            {
                label: "Title",
                key: "this",
                format: "ModelLink",
            },
            {
                label: "Status",
                key: "status",
                format: 'titleCase'
            },
            {
                label: "Global",
                key: "globalFeed",
                format: 'switch'
            },
            {
                label: "Host",
                key: "host",
                format: "ModelLink",
            },
            {
                label: "Tag",
                key: "tag",
                format: "ModelLink",
            },
            {
                label: "Type",
                key: "type",
                format: 'titleCase'
            },
            {
                label: "Date Created",
                key: "created",
                format: "OnAndBy",
                sort: "created.date",
                alignEnd: true
            },
            {
                label: "Date Published",
                key: "date",
                format: "DateTime",
                sort: "date",
                alignEnd: true
            },
            {
                label: "Actions",
                key: "actions",
                alignEnd: true,
                render: (value, data) => {

                    return <NewsActions data={data} />;

                }
            }
        ];

        return columns;

    },
    singleFetch: NewsApi.getOne,
    singleFetchParams: (routeName, params, users, fetch) => {

        const filters = { include: ['league', 'category', 'host', 'tag', 'team', 'season', 'player'] };

        return filters;

    },
    singleTitle: (routeName, params, users, fetch) => {

        let title: string = "Loading...";

        const complete = get(fetch, 'complete');
        const error = get(fetch, 'error');
        const data = get(fetch, 'data');

        if (error) title = "Error";
        else if (data) title = data[settings.primaryKey];
        else if (complete && !data) title = "Not Found";

        return title;

    },
    singleTabs: (routeName, params, users, fetch) => {

        const path = settings.path + "/" + params.id;

        return [
            {
                label: "View",
                path: path + "/view"
            },
            {
                label: "Edit",
                path: path + "/edit"
            },
        ]

    },
};