import React, { useCallback, useMemo } from 'react';
import AssessmentsCreateEditForm from '../../../Assessments/Components/AssessmentsCreateEditForm';
import { useForm } from '@dex/bubl-dash/src/lib/functions/useForm';
import AssessmentsApi from '../../../Assessments/AssessmentsApi';

const CampSessionAttendeesAssessment: React.FC<any> = (props: any) => {

    const { athlete, owner, session, attendanceId, onComplete } = props;

    const form = useForm({ athleteId: athlete.id, hostId: session.hostId, date: new Date().toJSON() });

    const create = AssessmentsApi.create({
        onComplete: () => {

            onComplete();

        }
    });

    const handleSubmit = useCallback((values) => {

        values.attendanceId = attendanceId;

        create.run({ data: values });

    }, [props]);

    return useMemo(() => (

        <AssessmentsCreateEditForm
            mode="create"
            form={form}
            process={create}
            handleSubmit={handleSubmit}
        />

    ), [props, form, create]);

}

export default CampSessionAttendeesAssessment;
