import React, { useMemo } from 'react';
import { Row, Col, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ActivityPanel from '@dex/bubl-dash/src/lib/components/SinglePanels/ActivityPanel';
import RenderContentBlocks from '../../../components/RenderContentBlocks/RenderContentBlocks';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';
import WebLink from '../../../components/WebLink/WebLink';

const WatchVideosSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <WebLink
                path={`/watch/video/${data.id}/${data.slug}`}
            />

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Watch Video Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Title",
                                key: "title",
                            },
                            {
                                label: "Description",
                                key: "description",
                            },
                            {
                                label: "Thumbnail",
                                key: "thumbnail.fileId",
                                format: "image",
                                link: true,
                                hideEmpty: true,
                                filters: {
                                    width: 100,
                                    height: 100
                                }
                            }
                        ]}
                        columnB={[
                            {
                                label: "Type",
                                key: "type",
                                format: "titleCase",
                            },
                            {
                                label: "Status",
                                key: "status",
                                format: "titleCase",
                            },
                            {
                                label: "Date",
                                key: "date",
                                format: "dateTime",
                            },
                            {
                                label: "PPV Product",
                                key: "ppvProduct",
                                format: "ModelLink",
                                conditions: data.enablePPV,
                            }
                        ]}
                        columnC={[
                            {
                                label: "Private",
                                key: "private",
                                format: "switch",
                            },
                            {
                                label: "Global Feed",
                                key: "globalFeed",
                                format: "switch",
                                conditions: data.private === false
                            },
                            {
                                label: "Has Game Log",
                                key: "hasGameLog",
                                format: "switch",
                            },
                            {
                                label: "Enable PPV",
                                key: "enablePPV",
                                format: "switch",
                            }
                        ]}
                    />

                    <Seperator
                        top={"auto"}
                        heading={"Tags"}
                        bottom={"auto"}
                    />

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Host",
                                key: "host",
                                format: "ModelLink",
                            },
                            {
                                label: "Tags",
                                key: "tags",
                                format: "ModelLink",
                                conditions: data.globalFeed,
                            }
                        ]}
                        columnB={[
                            {
                                label: "Sport",
                                key: "sport",
                                format: "ModelLink",
                            }
                        ]}
                        columnC={[
                            {
                                label: "Categories",
                                key: "categories",
                                format: "ModelLink",
                                conditions: data.globalFeed,
                            }
                        ]}
                    />

                </Panel>

                <Space size="20" />

                <Panel
                    heading={"Watch Video"}
                >

                    <RenderContentBlocks blocks={[{ type: "video", width: 852, height: 480, video: { source: "watch", id: data.id } }]} />

                </Panel>

            </Col>

            <Col className={'side'}>

                <ActivityPanel parent={data} />

            </Col>

        </Row>

    ), [data]);

}

export default WatchVideosSingleView;
