import React from 'react';
import moment from 'moment';
import { titleCase, get } from "@dex/bubl-helpers";
import VenueBookingApi from './VenueBookingApi';
import ModelLink from '@dex/bubl-dash/src/lib/components/ModelLink/ModelLink';

let settings;

export default settings = {
    key: 'venue/bookings',
    zone: 'venue',
    path: '/venue/bookings',
    title: "Venue Bookings",
    endpoint: VenueBookingApi.endpoint,
    modelName: 'VenueBooking',
    idKey: 'id',
    primaryKey: 'title',
    actions: ['trash'],
    indexCaps: ["viewVenueBooking"],
    viewCaps: ["viewVenueBooking"],
    createCaps: ["createVenueBooking"],
    updateCaps: ["updateVenueBooking"],
    indexTitle: (routeName, params, users) => {

        let title: string = settings.title;

        if (params && params.name) title = title + " / " + titleCase(params.name);

        return title;

    },
    indexTabs: (routeName, params, users) => {

        return [
            {
                label: "All",
                path: settings.path,
                caps: settings.indexCaps
            },
            {
                label: "Today",
                path: settings.path + "/date/today",
                caps: settings.createCaps
            },
            {
                label: "Future",
                path: settings.path + "/date/future",
                caps: settings.createCaps
            },
            {
                label: "Past",
                path: settings.path + "/date/past",
                caps: settings.createCaps
            },
            // {
            //     label: "Add New",
            //     path: settings.path + "/create",
            //     caps: settings.createCaps
            // },
        ]

    },
    indexButtons: (routeName, params, users) => {

        return [
            // {
            //     label: "Add New",
            //     path: settings.path + '/create',
            //     caps: settings.createCaps
            // }
        ]

    },
    indexTabCounts: (routeName, params, dontation) => {

        return settings.endpoint + "/getCounts";

    },
    indexFetch: VenueBookingApi.get,
    indexFetchParams: (routeName, params, users) => {

        const filters: any = { where: {}, include: ['owner', 'host', "court"], order: ['created.date DESC'], limit: 10, skip: 0 };

        if (params && params.date) {

            const today = moment().format("YYYY-MM-DD");

            if (params.date === "today") filters.where.date = today;
            else if (params.date === "past") filters.where.date = { lt: today };
            else if (params.date === "future") filters.where.date = { gt: today };

        }

        return filters;

    },
    indexQuickSearch: (routeName, params, users) => {

        const fields = ['id'];

        return fields;

    },
    indexSearchFields: (routeName, params, users) => {

        const fields = [
            {
                label: "Booked By",
                key: "ownerId",
                type: "relationship",
                model: "Users",
            },
            {
                label: "Court",
                key: "courtId",
                type: "relationship",
                model: "VenueCourt",
            },
            {
                label: "Host",
                key: "hostId",
                type: "relationship",
                model: "Hosts",
            },
            {
                label: "Date",
                key: "date",
                type: "date",
            },
            {
                label: "Status",
                key: "status",
                type: "choice",
                options: [
                    { value: "active", label: "Active" },
                    { value: "cancelled", label: "Cancelled" },
                ],
            }
        ];

        return fields;

    },

    indexColumns: (routeName, params, users) => {

        const columns = [
            {
                label: "",
                key: "this",
                style: { width: 120 },
                // format: "ModelLink",
                render: (value, data) => {
                    return <ModelLink modelName="VenueBooking" data={{ id: data.id, title: "View Booking" }} />
                },
            },
            {
                label: "Booked By",
                key: "owner",
                format: "modelLink",
            },
            {
                label: "Court",
                key: "court",
                format: "modelLink",
            },
            {
                label: "Host",
                key: "host",
                format: "modelLink",
            },
            {
                label: "Status",
                key: "status",
                format: "titleCase"
            },
            {
                label: "Date",
                key: "date",
                format: "date",
                sort: "date",

            },
            {
                label: "Start Time",
                key: "startTime",
            },
            {
                label: "Duration",
                key: "duration",
                format: "number",
                suffix: " Hour(s)"
            },
            {
                label: "Created Date",
                key: "created",
                format: "OnAndBy",
                sort: "created.date",
                alignEnd: true
            },
            {
                label: "Actions",
                key: "actions",
                alignEnd: true,
                format: "actions",
                api: VenueBookingApi,
                settings: settings,
            }
        ];

        return columns;

    },

    indexExportFormats: (routeName, params, user) => {

        const formats = [
            { value: "venueBooking", label: "Venue Bookings" }
        ];

        return formats;

    },
    
    singleFetch: VenueBookingApi.getOne,
    singleFetchParams: (routeName, params, users, fetch) => {

        const filters: any = { include: ['owner', 'host', "court", "attendees", "order", "videoOrder"] };

        return filters;

    },
    singleTitle: (routeName, params, users, fetch) => {

        let title: string = "Loading...";

        const complete = get(fetch, 'complete');
        const error = get(fetch, 'error');
        const data = get(fetch, 'data');

        if (error) title = "Error";
        else if (data) title = data[settings.primaryKey];
        else if (complete && !data) title = "Not Found";

        return title;

    },
    singleTabs: (routeName, params, users, fetch) => {

        const path = settings.path + "/" + params.id;

        return [
            {
                label: "View",
                path: path + "/view"
            },
            {
                label: "Edit",
                path: path + "/edit",
                caps: settings.updateCaps
            },
        ]

    },
};