import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ActivityPanel from '@dex/bubl-dash/src/lib/components/SinglePanels/ActivityPanel';

const FormFieldSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Form Field Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Title",
                                key: "title",
                            },
                            {
                                label: "Type",
                                key: "type",
                                format: "titleCase"
                            },
                            {
                                label: "Label",
                                key: "label",
                            },
                            {
                                label: "Placeholder",
                                key: "placeholder",
                            },
                            {
                                label: "Required",
                                key: "required",
                                format: "switch"
                            }
                        ]}
                        columnB={[
                            {
                                label: "Auto Fill From User Bio (Primary Value)",
                                key: "autoFillFrom",
                            },
                            {
                                label: "Auto Fill From Custom Key",
                                key: "autoFillFromCustomKey",
                            },
                            {
                                label: "Auto Fill From User Bio (Secondary Value)",
                                key: "autoFillFromSecondary",
                            },
                            {
                                label: "Auto Fill From Secondary Custom Key",
                                key: "autoFillFromSecondaryCustomKey",
                            },
                            {
                                label: "Save To User Bio",
                                key: "saveTo",
                            },
                            {
                                label: "Save To Custom Key",
                                key: "saveToCustomKey",
                            }
                        ]}
                        columnC={[
                            {
                                label: "Host",
                                key: "host",
                                format: "modelLink"
                            },
                        ]}
                    />

                </Panel>

            </Col>

            <Col className={'side'}>

                <ActivityPanel parent={data} />

            </Col>

        </Row>

    ), [data]);

}

export default FormFieldSingleView;
