import React, { useCallback, useMemo, useState } from 'react';
import IndexTable from '@dex/bubl-dash/src/lib/components/IndexTable/IndexTable';
import NewsSettings from '../../News/NewsSettings';
import Button from '@dex/bubl-dash/src/lib/components/Button/Button';

const LeagueSeasonManageNews: React.FC<any> = (props: any) => {

    const { data } = props;

    const [tableState, setTableState] = useState({ search: undefined, where: undefined, order: undefined, skip: undefined });

    const preFetchFilters = useCallback((routeName, params, users) => {

        const filters = NewsSettings.indexFetchParams(routeName, params, users);

        filters.where.seasonId = data.id;

        return filters;

    }, []);

    const getColumns = useCallback((routeName, params, users) => {

        const columns = NewsSettings.indexColumns(routeName, params, users);

        // filters.where.seasonId = data.id;
        columns.map((column, index) => {

            if (column.key === "host") delete (columns[index]);
            if (column.key === "globalFeed") delete (columns[index]);

            return column;

        });

        return columns;

    }, []);

    const getDefaults = useMemo(() => {

        const defaults = { hostId: data.hostId, leagueId: data.leagueId, seasonId: data.id }

        return encodeURIComponent(JSON.stringify(defaults));

    }, [data]);

    return useMemo(() => (

        <>

            <IndexTable
                title={"News"}
                getFetch={NewsSettings.indexFetch}
                getColumns={getColumns}
                preFetchFilters={preFetchFilters}
                getQuickSearch={NewsSettings.indexQuickSearch}
                getSearchFields={NewsSettings.indexSearchFields}
                routeParams={tableState}
                manageState={setTableState}
                hideTrashSwitch={true}
                actionButtons={() => (
                    <>
                        <Button
                            label={"Add New Article"}
                            size="small"
                            type="light"
                            path={`/content/news/create?defaults=${getDefaults}`}
                        />
                    </>
                )}
            />

        </>

    ), [data, tableState]);

}

export default LeagueSeasonManageNews;
