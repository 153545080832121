import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import FieldUpload from '@dex/bubl-dash/src/lib/components/Fields/FieldUpload';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldNumber from '@dex/bubl-dash/src/lib/components/Fields/FieldNumber';


const SportCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <Panel
            heading={"Sport Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 12 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24 }}>

                                <FieldText
                                    form={form}
                                    label={"Title"}
                                    name='title'
                                    required={true}
                                    defaultValue={form.defaults.title}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldUpload
                                    form={form}
                                    folder={'admin-media'}
                                    label={"Icon"}
                                    name={`icon`}
                                    required={false}
                                    defaultValue={form.defaults.icon}
                                    limit={1}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldUpload
                                    form={form}
                                    folder={'admin-media'}
                                    label={"Thumbnail"}
                                    name={`thumbnail`}
                                    required={false}
                                    defaultValue={form.defaults.thumbnail}
                                    limit={1}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldUpload
                                    form={form}
                                    folder={'admin-media'}
                                    label={"Cover"}
                                    name={`cover`}
                                    required={false}
                                    defaultValue={form.defaults.cover}
                                    limit={1}
                                />

                            </Col>

                        </Row>

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24 }}>

                                <FieldRadio
                                    form={form}
                                    label={"Status: League"}
                                    name='statusLeague'
                                    required={true}
                                    defaultValue={form.defaults.statusLeague}
                                    options={[
                                        { value: "draft", label: "Draft" },
                                        { value: "preview", label: "Preview" },
                                        { value: "publish", label: "Publish" },
                                        { value: "archive", label: "Archive" },
                                    ]}
                                />

                            </Col>

                            {form.values.statusLeague === 'publish' &&

                                <Col col={{ xs: 24 }}>

                                    <FieldNumber
                                        form={form}
                                        label={"Priority: League"}
                                        name='priorityLeague'
                                        required={false}
                                        defaultValue={form.defaults.priorityLeague}
                                    />

                                </Col>

                            }

                            <Col col={{ xs: 24 }}>

                                <FieldRadio
                                    form={form}
                                    label={"Status: PickUp"}
                                    name='statusPickup'
                                    required={true}
                                    defaultValue={form.defaults.statusPickup}
                                    options={[
                                        { value: "draft", label: "Draft" },
                                        { value: "preview", label: "Preview" },
                                        { value: "publish", label: "Publish" },
                                        { value: "archive", label: "Archive" },
                                    ]}
                                />

                            </Col>

                            {form.values.statusPickup === 'publish' &&

                                <Col col={{ xs: 24 }}>

                                    <FieldNumber
                                        form={form}
                                        label={"Priority: PickUp"}
                                        name='priorityPickup'
                                        required={false}
                                        defaultValue={form.defaults.priorityPickup}
                                    />

                                </Col>

                            }

                            <Col col={{ xs: 24 }}>

                                <FieldRadio
                                    form={form}
                                    label={"Status: Camp"}
                                    name='statusCamp'
                                    required={true}
                                    defaultValue={form.defaults.statusCamp}
                                    options={[
                                        { value: "draft", label: "Draft" },
                                        { value: "preview", label: "Preview" },
                                        { value: "publish", label: "Publish" },
                                        { value: "archive", label: "Archive" },
                                    ]}
                                />

                            </Col>

                            {form.values.statusCamp === 'publish' &&

                                <Col col={{ xs: 24 }}>

                                    <FieldNumber
                                        form={form}
                                        label={"Priority: Camp"}
                                        name='priorityCamp'
                                        required={false}
                                        defaultValue={form.defaults.priorityCamp}
                                    />

                                </Col>

                            }
                            <Col col={{ xs: 24 }}>

                                <FieldRadio
                                    form={form}
                                    label={"Status: Venue"}
                                    name='statusVenue'
                                    required={true}
                                    defaultValue={form.defaults.statusVenue}
                                    options={[
                                        { value: "draft", label: "Draft" },
                                        { value: "preview", label: "Preview" },
                                        { value: "publish", label: "Publish" },
                                        { value: "archive", label: "Archive" },
                                    ]}
                                />

                            </Col>

                            {form.values.statusVenue === 'publish' &&

                                <Col col={{ xs: 24 }}>

                                    <FieldNumber
                                        form={form}
                                        label={"Priority: Venue"}
                                        name='priorityVenue'
                                        required={false}
                                        defaultValue={form.defaults.priorityVenue}
                                    />

                                </Col>

                            }

                            <Col col={{ xs: 24 }}>

                                <FieldRadio
                                    form={form}
                                    label={"Status: Watch"}
                                    name='statusWatch'
                                    required={true}
                                    defaultValue={form.defaults.statusWatch}
                                    options={[
                                        { value: "draft", label: "Draft" },
                                        { value: "preview", label: "Preview" },
                                        { value: "publish", label: "Publish" },
                                        { value: "archive", label: "Archive" },
                                    ]}
                                />

                            </Col>

                            {form.values.statusWatch === 'publish' &&

                                <Col col={{ xs: 24 }}>

                                    <FieldNumber
                                        form={form}
                                        label={"Priority: Watch"}
                                        name='priorityWatch'
                                        required={false}
                                        defaultValue={form.defaults.priorityWatch}
                                    />

                                </Col>

                            }

                            <Col col={{ xs: 24 }}>

                                <FieldRadio
                                    form={form}
                                    label={"Status: Global"}
                                    name='statusGlobal'
                                    required={true}
                                    defaultValue={form.defaults.statusGlobal}
                                    options={[
                                        { value: "draft", label: "Draft" },
                                        { value: "preview", label: "Preview" },
                                        { value: "publish", label: "Publish" },
                                        { value: "archive", label: "Archive" },
                                    ]}
                                />

                            </Col>

                            {form.values.statusGlobal === 'publish' &&

                                <Col col={{ xs: 24 }}>

                                    <FieldNumber
                                        form={form}
                                        label={"Priority: Global"}
                                        name='priorityGlobal'
                                        required={false}
                                        defaultValue={form.defaults.priorityGlobal}
                                    />

                                </Col>

                            }

                        </Row>

                    </Col>

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default SportCreateEditForm;
