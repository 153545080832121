import React, { useCallback, useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Button from '@dex/bubl-dash/src/lib/components/Button/Button';
import ShopPayoutApi from '../ShopPayoutApi';
import { FieldDate } from '@dex/bubl-dash';
import { FieldRelationship } from '@dex/bubl-dash';
import { loop } from '@dex/bubl-helpers';

const ShopPayoutPrepareForm: React.FC<any> = (props: any) => {

    const { form, onPrepare } = props;

    const prepare = ShopPayoutApi.create({}, 'prepare');

    const handleCheckPlayers = useCallback((values) => {

        prepare.run({
            data: values,
            onComplete: (res) => {

                const hostIds = {};

                loop(res, (host) => {
                    hostIds[host.id] = true;
                })

                form.reset({ ...values, hostIds: hostIds });

                form.setKey();

                onPrepare(res);

            }
        });

    }, []);

    return useMemo(() => (

        <>

            <Form
                form={form}
                onSubmit={handleCheckPlayers}
                loading={prepare.loading}
            >

                {!prepare.data &&
                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 6 }}>

                            <FieldDate
                                form={form}
                                name="period"
                                label="Period"
                                required={true}
                                defaultValue={form.defaults.period}
                                dateFormat={"MMMM YYYY"}
                                dateMode={"month"}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 18 }}>

                            <FieldRelationship
                                form={form}
                                label='Hosts'
                                name='hostIds'
                                required={false}
                                preload={true}
                                endpoint={'Hosts'}
                                defaultValue={Array.isArray(form.defaults.hostIds) ? form.defaults.hostIds : []}
                                isMulti={true}
                                placeholder={"Leave empty to prepare for all hosts"}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 6 }}>
                            <Button
                                size='normal'
                                type={'primary'}
                                label={"Prepare Payout List"}
                                loading={prepare.loading}
                            />
                        </Col>

                    </Row>
                }

            </Form>

        </>

    ), [props, prepare.loading]);

}

export default ShopPayoutPrepareForm;
