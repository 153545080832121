import IndexPage from '@dex/bubl-dash/src/lib/pages/IndexPage';
import HelpCreateEditForm from './Components/HelpCreateEditForm';
import HelpApi from './HelpApi';

export default function (app, settings) {

    app.addRoute({
        name: settings.key + "New",
        zone: settings.zone,
        path: settings.key + "/create",
        caps: settings.createCaps,
        component: IndexPage,
        componentProps: {
            header: {
                getTitle: () => "Add New",
                getButtons: settings.indexButtons,
                getTabs: settings.indexTabs,
                getTabCounts: settings.indexTabCounts,
            },
            create: {
                form: HelpCreateEditForm,
                getCreate: HelpApi.create,
                defaults: {},
                successMessage: "Help Saved",
                successRouteName: settings.key + "View",
                idKey: settings.idKey
            }
        },
    });

}