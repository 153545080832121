import { Button } from '@dex/bubl-dash';
import { IndexTable } from '@dex/bubl-dash';
import React, { useCallback, useMemo, useState } from 'react';
import LeagueSeasonLeaderboardSettings from '../../LeagueSeasonLeaderboard/LeagueSeasonLeaderboardSettings';
import LeagueSeasonAddNewLeaderboard from './LeagueSeasonAddNewLeaderboard';

const LeagueSeasonManageLeaderboards: React.FC<any> = (props: any) => {

    const { data } = props;

    const [key, setKey] = useState(0);

    const [showAddNew, setShowAddNew] = useState(false);

    const [tableState, setTableState] = useState({ search: undefined, where: undefined, order: undefined, skip: undefined });

    const preFetchFilters = useCallback((routeName, params, users) => {

        const filters = LeagueSeasonLeaderboardSettings.indexFetchParams(routeName, params, users);

        filters.where.seasonId = data.id;

        return filters;

    }, []);

    const getColumns = useCallback((routeName, params, users) => {

        const columns = LeagueSeasonLeaderboardSettings.indexColumns(routeName, params, users);

        columns.map((column, index) => {

            if (column.key === "host") delete (columns[index]);
            else if (column.key === "season") delete (columns[index]);
            else if (column.key === "league") delete (columns[index]);
            else if (column.key === "created") delete (columns[index]);

            return column;

        });

        return columns;

    }, []);

    const handleClose = useCallback(() => {

        setKey(key + 1);
        setShowAddNew(false);

    }, [key])

    return useMemo(() => (

        <>

            <IndexTable
                key={key}
                title={"Leaderboards"}
                getFetch={LeagueSeasonLeaderboardSettings.indexFetch}
                getColumns={getColumns}
                preFetchFilters={preFetchFilters}
                getQuickSearch={LeagueSeasonLeaderboardSettings.indexQuickSearch}
                getSearchFields={LeagueSeasonLeaderboardSettings.indexSearchFields}
                routeParams={tableState}
                manageState={setTableState}
                hideTrashSwitch={true}
                actionButtons={() => (
                    <>
                        <Button
                            label={"Add New Leaderboard"}
                            size="small"
                            type="light"
                            onClick={setShowAddNew.bind(null, true)}
                        />
                    </>
                )}
            />

            {showAddNew &&
                <LeagueSeasonAddNewLeaderboard
                    onClose={handleClose}
                    data={data}
                />
            }

        </>

    ), [data, key, tableState, showAddNew]);

}

export default LeagueSeasonManageLeaderboards;
