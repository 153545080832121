import VenueBookingIndex from './VenueBookingIndex';
import VenueBookingCreate from './VenueBookingCreate';
import VenueBookingSettings from './VenueBookingSettings';
import VenueBookingSingle from './VenueBookingSingle';

export default function (app) {

    const settings = VenueBookingSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    VenueBookingIndex(app, settings);

    VenueBookingCreate(app, settings);

    VenueBookingSingle(app, settings);

}