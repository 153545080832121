import React, { useCallback, useMemo } from 'react';
import { Form, useForm } from '@dex/bubl-dash/src/lib/functions/useForm';
import { FieldCheckbox, useApp, Seperator } from '@dex/bubl-dash';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldSelect from '@dex/bubl-dash/src/lib/components/Fields/FieldSelect';
import FieldSwitch from '@dex/bubl-dash/src/lib/components/Fields/FieldSwitch';
import LeagueSeasonApi from '../LeagueSeasonApi';

const LeagueSeasonSettingsForm: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm(data);

    const app = useApp();

    const update = LeagueSeasonApi.update(data.id, "updateSettings", {
        onComplete: (data) => {

            app.alert('Settings Saved', 'success');

        }
    });

    const handleSubmit = useCallback((values) => {

        update.run({ data: values });

    }, [props]);

    let options: any = [];

    if (data.sportSlug === "basketball") {

        options = [
            { value: "pts", label: "PTS" },
            { value: "ast", label: "AST" },
            { value: "stl", label: "STL" },
            { value: "blk", label: "BLK" },
            { value: "fgm", label: "FGM" },
            { value: "fga", label: "FGA" },
            { value: "3pm", label: "3PM" },
            { value: "3pa", label: "3PA" },
            { value: "ftm", label: "FTM" },
            { value: "fta", label: "FTA" },
            { value: "oreb", label: "OREB" },
            { value: "dreb", label: "DREB" },
            { value: "to", label: "TO" },
            { value: "pf", label: "PF" },
            { value: "mins", label: "Minutes" },
            { value: "started", label: "Started" },
        ];

    }

    return useMemo(() => (

        <Panel
            heading={"Season Settings"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={update.loading}
            >


                <Row gutter={8} edge={true}>

                    {(data.sportSlug === "basketball" || data.sportSlug === "soccer" || data.sportSlug === "football") &&

                        <Col col={{ xs: 24, md: 8 }}>

                            <FieldSelect
                                form={form}
                                label={"Game Format"}
                                name='gameFormat'
                                defaultValue={form.defaults.gameFormat}
                                options={[
                                    { value: "halfs", label: "2 Halfs" },
                                    { value: "quarters", label: "4 Quarters" },
                                ]}
                            />

                        </Col>
                    }

                </Row>

                <Seperator
                    top={"auto"}
                    bottom={"auto"}
                    heading={"App / Web Display"}
                />

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 12, md: 6 }}>

                        <FieldSwitch
                            form={form}
                            label={"Show Stats"}
                            name='hasStats'
                            required={false}
                            defaultValue={form.defaults.hasStats}
                        />

                    </Col>

                    <Col col={{ xs: 12, md: 6 }}>

                        <FieldSwitch
                            form={form}
                            label={"Show Results / Upcoming"}
                            name='hasCalendar'
                            required={false}
                            defaultValue={form.defaults.hasCalendar}
                        />

                    </Col>

                    <Col col={{ xs: 12, md: 6 }}>

                        <FieldSwitch
                            form={form}
                            label={"Show Standings"}
                            name='hasStandings'
                            required={false}
                            defaultValue={form.defaults.hasStandings}
                        />

                    </Col>

                    <Col col={{ xs: 12, md: 6 }}>

                        <FieldSwitch
                            form={form}
                            label={"Show Brackets"}
                            name='hasBrackets'
                            required={false}
                            defaultValue={form.defaults.hasBrackets}
                        />

                    </Col>

                    <Col col={{ xs: 12, md: 6 }}>

                        <FieldSwitch
                            form={form}
                            label={"Show Leaderboard"}
                            name='hasLeaderboard'
                            required={false}
                            defaultValue={form.defaults.hasLeaderboard}
                        />

                    </Col>

                    <Col col={{ xs: 12, md: 6 }}>

                        <FieldSwitch
                            form={form}
                            label={"Show News Feed"}
                            name='hasNews'
                            required={false}
                            defaultValue={form.defaults.hasNews}
                        />

                    </Col>

                    <Col col={{ xs: 12, md: 6 }}>

                        <FieldSwitch
                            form={form}
                            label={"Show Game Logs"}
                            name='hasGameLogs'
                            required={false}
                            defaultValue={form.defaults.hasGameLogs}
                        />

                    </Col>

                    <Col col={{ xs: 12, md: 6 }}>

                        <FieldSwitch
                            form={form}
                            label={"Show Team Roster"}
                            name='hasTeamRoster'
                            required={false}
                            defaultValue={form.defaults.hasTeamRoster}
                        />

                    </Col>

                </Row>

                <Seperator
                    top={"auto"}
                    bottom={"auto"}
                    heading={"Box Score Columns"}
                    description={<>Select the columns your league <b>does not track</b> to disable them.</>}
                />


                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24 }}>

                        <FieldCheckbox
                            form={form}
                            label={"Disabled Box Score Columns"}
                            name='disabledBoxScoreColumns'
                            button={true}
                            options={options}
                        />

                    </Col>

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={update.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                        loading={update.loading}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props, update.loading]);

}

export default LeagueSeasonSettingsForm;
