import React, { useCallback, useMemo, useState } from 'react';
import IndexTable from '@dex/bubl-dash/src/lib/components/IndexTable/IndexTable';
import LeagueSeasonDivisionSettings from '../../LeagueSeasonDivision/LeagueSeasonDivisionSettings';
import Button from '@dex/bubl-dash/src/lib/components/Button/Button';
import LeagueSeasonAddNewDivision from './LeagueSeasonAddNewDivision';

const LeagueSeasonManageDivisions: React.FC<any> = (props: any) => {

    const { data } = props;

    const [key, setKey] = useState(0);

    const [showAddNew, setShowAddNew] = useState(false);

    const [tableState, setTableState] = useState({ search: undefined, where: undefined, order: undefined, skip: undefined });

    const preFetchFilters = useCallback((routeName, params, users) => {

        const filters = LeagueSeasonDivisionSettings.indexFetchParams(routeName, params, users);

        filters.where.seasonId = data.id;

        return filters;

    }, []);

    const getColumns = useCallback((routeName, params, users) => {

        const columns = LeagueSeasonDivisionSettings.indexColumns(routeName, params, users);

        columns.map((column, index) => {

            if (column.key === "host") delete (columns[index]);
            else if (column.key === "season") delete (columns[index]);
            else if (column.key === "league") delete (columns[index]);
            else if (column.key === "created") delete (columns[index]);

            return column;

        });

        return columns;

    }, []);

    const handleClose = useCallback(() => {

        setKey(key + 1);
        setShowAddNew(false);

    }, [key])

    return useMemo(() => (

        <>

            <IndexTable
                key={key}
                title={"Divisions"}
                getFetch={LeagueSeasonDivisionSettings.indexFetch}
                getColumns={getColumns}
                preFetchFilters={preFetchFilters}
                getQuickSearch={LeagueSeasonDivisionSettings.indexQuickSearch}
                getSearchFields={LeagueSeasonDivisionSettings.indexSearchFields}
                routeParams={tableState}
                manageState={setTableState}
                hideTrashSwitch={true}
                actionButtons={() => (
                    <>
                        <Button
                            label={"Add New Division"}
                            size="small"
                            type="light"
                            onClick={setShowAddNew.bind(null, true)}
                        />
                    </>
                )}
            />

            {showAddNew &&
                <LeagueSeasonAddNewDivision
                    onClose={handleClose}
                    data={data}
                />
            }

        </>

    ), [data, key, tableState, showAddNew]);

}

export default LeagueSeasonManageDivisions;
