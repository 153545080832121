import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import DataTable from '@dex/bubl-dash/src/lib/components/DataTable/DataTable';
import { Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { Form, useForm } from '@dex/bubl-dash/src/lib/functions/useForm';

import PickupSessionAttendeesMark from './PickupSessionAttendeesMark';
import ModelLink from '@dex/bubl-dash/src/lib/components/ModelLink/ModelLink';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import PickupSessionApi from '../PickupSessionApi';
import Modal from '@dex/bubl-dash/src/lib/components/Modal/Modal';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship';

const PickupSessionAttendees: React.FC<any> = (props: any) => {

    const { data } = props;

    const [key, setKey] = useState(0);

    const [showCancelled, setShowCancelled] = useState(data.status === "cancelled");

    const [showAdd, setShowAdd] = useState(false);

    const form = useForm({});

    const attendance = PickupSessionApi.getAttendance(data.id);

    const attending = PickupSessionApi.updateAttending(':id');

    useEffect(() => {

        const params = attendance.options.params;

        params.where.cancelled = showCancelled;

        attendance.run({
            params: params
        });

    }, [showCancelled, key]);

    const updateAttending = useCallback((id, cancelled) => {

        attending.run({
            url: attending.options.url.replace(":id", id),
            data: { cancelled: cancelled },
            onComplete: () => {

                setKey(key + 1);

            }
        });

    }, [key]);

    const handleAdded = useCallback(() => {

        setShowAdd(false);
        setShowCancelled(false);
        setKey(key + 1);

    }, [key]);

    const columns: any = [
        showCancelled ? null : {
            label: "Attended",
            key: "attended",
            style: { width: 120 },
            render: (value, row) => (

                <PickupSessionAttendeesMark
                    form={form}
                    attendee={row}
                />

            )
        },
        {
            label: 'Customer',
            key: 'athlete',
            render: (value, row) => (
                <>
                    <ModelLink data={row.owner} modelName='Users' />
                </>
            )
        },
        {
            label: 'Email',
            key: 'owner.email'
        },
        {
            label: 'Phone',
            key: 'owner.phone'
        },
        {
            label: showCancelled ? 'Cancelled On' : 'Joined On',
            key: showCancelled ? 'cancelledOn' : 'joinedOn',
            format: 'OnAndBy',
        },
        {
            label: 'Actions',
            key: 'actions',
            alignEnd: true,
            style: { width: 100 },
            render: (value, row) => (
                <>
                    {!row.cancelled &&
                        <Button
                            label="Cancel"
                            type="inline"
                            size="small"
                            confirm={{
                                title: `Mark As Cancelled`,
                                message: "Are your sure you want to perform this action?"
                            }}
                            onClick={updateAttending.bind(null, row.id, true)}
                        />
                    }
                    {row.cancelled && data.status !== "cancelled" &&
                        <Button
                            label="Attending"
                            type="inline"
                            size="small"
                            confirm={{
                                title: `Mark As Attending`,
                                message: "Are your sure you want to perform this action?"
                            }}
                            onClick={updateAttending.bind(null, row.id, false)}
                        />
                    }
                </>
            )
        }
    ].filter(Boolean);

    return useMemo(() => (

        <>

            {showAdd &&
                <Modal
                    show={true}
                    onClose={setShowAdd.bind(null, false)}
                    width={480}
                >

                    <PickupSessionAttendeesAdd
                        sessionId={data.id}
                        handleAdded={handleAdded}
                    />

                </Modal>
            }

            <Panel
                heading={`${attendance.loading ? "Loading" : (attendance.data && attendance.data.data) ? attendance.data.data.length : "0"} ` + (showCancelled ? 'Cancelled' : 'Attendee(s)')}
                full={true}
                actions={() => (
                    <ButtonRow>

                        {data.status !== "cancelled" &&
                            <Button
                                label="Add"
                                type="primary"
                                size="small"
                                onClick={setShowAdd.bind(null, true)}
                            />
                        }

                        {!showCancelled &&
                            <Button
                                label="Show Cancelled"
                                type="faded"
                                size="small"
                                onClick={setShowCancelled.bind(null, true)}
                            />
                        }

                        {showCancelled && data.status !== "cancelled" &&
                            <Button
                                label="Hide Cancelled"
                                type="secondary"
                                size="small"
                                onClick={setShowCancelled.bind(null, false)}
                            />
                        }

                    </ButtonRow>
                )}
            >

                <Space />

                <DataTable
                    data={attendance.data && attendance.data.data ? attendance.data.data : []}
                    columns={columns}
                    loading={attendance.loading}
                />

            </Panel>

        </>

    ), [data, attendance.data, showCancelled, showAdd]);

}


const PickupSessionAttendeesAdd: React.FC<any> = (props: any) => {

    const form = useForm({});

    const add = PickupSessionApi.update(props.sessionId, "addAttendee", {
        onComplete: (data) => {

            if (props.handleAdded) props.handleAdded();

        }
    });

    const handleSubmit = useCallback((values) => {

        add.run({ data: values });

    }, [props]);

    return (
        <Panel
            heading={"Add Attendee"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={add.loading}
            >

                <FieldRelationship
                    form={form}
                    label='Attendee'
                    name='userId'
                    required={true}
                    preload={false}
                    endpoint={'Users'}
                    defaultValue={form.defaults.userId}
                />

                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={add.loading}
                    />
                </ButtonRow>

            </Form>

        </Panel>
    )
}

export default PickupSessionAttendees;
