import React from 'react';
import { titleCase, get } from "@dex/bubl-helpers";
import WatchAdsApi from './WatchAdsApi';
import { ModelLink } from '@dex/bubl-dash';

let settings;

export default settings = {
    key: 'watch/ads',
    zone: 'watch',
    path: '/watch/ads',
    title: "Watch Ads",
    endpoint: WatchAdsApi.endpoint,
    modelName: 'WatchAds',
    idKey: 'id',
    primaryKey: 'title',
    actions: ['trash', 'clone'],
    indexCaps: ["viewWatchAds"],
    viewCaps: ["viewWatchAds"],
    createCaps: ["createWatchAds"],
    updateCaps: ["updateWatchAds"],
    indexTitle: (routeName, params, users) => {

        let title: string = settings.title;

        if (params && params.name) title = title + " / " + titleCase(params.name);

        return title;

    },
    indexTabs: (routeName, params, users) => {

        return [
            {
                label: "All",
                path: settings.path,
                caps: settings.indexCaps
            },
            {
                label: "Add New",
                path: settings.path + "/create",
                caps: settings.createCaps
            },
        ]

    },
    indexButtons: (routeName, params, users) => {

        return [
            {
                label: "Add New",
                path: settings.path + '/create',
                caps: settings.createCaps
            }
        ]

    },
    indexTabCounts: (routeName, params, dontation) => {

        return settings.endpoint + "/getCounts";

    },
    indexFetch: WatchAdsApi.get,
    indexFetchParams: (routeName, params, users) => {

        const filters: any = { where: {}, include: ['host', 'league', 'category', 'sport'], order: ['created.date DESC'], limit: 10, skip: 0 };

        if (params && params.status) filters.where.status = { inq: [params.status] };

        return filters;

    },
    indexQuickSearch: (routeName, params, users) => {

        const fields = ['title', 'adTag'];

        return fields;

    },
    indexSearchFields: (routeName, params, users) => {

        const fields = [
            {
                label: "Title",
                key: "title",
                type: "text"
            },
            {
                label: "Ad Tag",
                key: "adTag",
                type: "text"
            },
            {
                label: "Position",
                key: "position",
                type: "choice",
                options: [
                    { value: "pre", label: "Pre" },
                    { value: "post", label: "Post" },
                    { value: "custom", label: "Custom" },

                ],
            },
            {
                label: "Attach By",
                key: "attachBy",
                type: "choice",
                options: [
                    { value: "host", label: "Host" },
                    { value: "league", label: "League" },
                    { value: "category", label: "Category" },
                    { value: "tag", label: "Tag" },
                    { value: "sport", label: "Sport" },
                    { value: "all", label: "All" },
                ],
            },
        ];

        return fields;

    },

    indexColumns: (routeName, params, users) => {

        const columns = [
            {
                label: "Title",
                key: "this",
                format: "ModelLink",
            },
            {
                label: "Position",
                key: "position",
                format: "titleCase",
            },
            {
                label: "Attach By",
                key: "attachBy",
                format: "titleCase",
            },
            {
                label: "Attach To",
                key: "attachBy",
                render: (value, data) => {
                    if (value === "host" && data.host) return <ModelLink modelName="Hosts" data={data.host} />;
                    if (value === "league" && data.league) return <ModelLink modelName="League" data={data.league} />;
                    if (value === "category" && data.category) return <ModelLink modelName="WatchCategory" data={data.category} />;
                    if (value === "sport" && data.sport) return <ModelLink modelName="Sport" data={data.sport} />;
                    else return <></>;
                }
            },
            // {
            //     label: "Ad Tag",
            //     key: "adTag",
            //     format: "titleCase",
            // },
            {
                label: "Created Date",
                key: "created",
                format: "OnAndBy",
                sort: "created.date",
                alignEnd: true
            },
            {
                label: "Actions",
                key: "actions",
                alignEnd: true,
                format: "actions",
                api: WatchAdsApi,
                settings: settings,
            }
        ];

        return columns;

    },
    singleFetch: WatchAdsApi.getOne,
    singleFetchParams: (routeName, params, users, fetch) => {

        const filters = { include: ['host', 'tag', 'league', 'category', 'sport'] };

        return filters;

    },
    singleTitle: (routeName, params, users, fetch) => {

        let title: string = "Loading...";

        const complete = get(fetch, 'complete');
        const error = get(fetch, 'error');
        const data = get(fetch, 'data');

        if (error) title = "Error";
        else if (data) title = data[settings.primaryKey];
        else if (complete && !data) title = "Not Found";

        return title;

    },
    singleTabs: (routeName, params, users, fetch) => {

        const path = settings.path + "/" + params.id;

        return [
            {
                label: "View",
                path: path + "/view"
            },
            {
                label: "Edit",
                path: path + "/edit",
                caps: settings.updateCaps
            },
        ]

    },
};