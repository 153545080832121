import React, { useMemo } from 'react';
import { Row, Col, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ActivityPanel from '@dex/bubl-dash/src/lib/components/SinglePanels/ActivityPanel';
import CampPassesSessionsEnrolled from './CampPassesSessionsEnrolled';

const CampPassesSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Pass Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Customer / Parent / Gardian",
                                key: "owner",
                                format: "modelLink"
                            },
                            {
                                label: "Athlete",
                                key: "athlete",
                                format: "modelLink"
                            },
                            {
                                label: "Title",
                                key: "title",
                            },
                            {
                                label: "Description",
                                key: "description",
                            },
                            {
                                label: "Status",
                                key: "status",
                                format: "titleCase"
                            },
                        ]}
                        columnB={[
                            {
                                label: "Type",
                                key: "passType",
                                format: "titleCase"
                            },
                            {
                                label: "Total Sessions",
                                key: "validSessions",
                                conditions: data.passType === "session",
                            },
                            {
                                label: "Sessions Used",
                                key: "sessionsUsed",
                                conditions: data.passType === "session",
                            },
                            {
                                label: "Sessions Left",
                                key: "sessionsLeft",
                                conditions: data.passType === "session",
                            },
                            {
                                label: "Total Days",
                                key: "validDays",
                                conditions: data.passType === "time",
                            },
                            {
                                label: "Days Left",
                                key: "daysLeft",
                                conditions: data.passType === "time",
                            },
                            {
                                label: "Expiry Date",
                                key: "expiryDate",
                                conditions: data.passType === "time",
                                format: "date"
                            },
                        ]}
                        columnC={[
                            {
                                label: "Host",
                                key: "host",
                                format: "modelLink"
                            },
                            {
                                label: "Operator",
                                key: "operator",
                                format: "modelLink"
                            }
                        ]}
                    />

                </Panel>

                {data.sessionIds &&
                    <>
                        <Space />

                        <CampPassesSessionsEnrolled data={data} />
                    </>
                }

            </Col>

            <Col className={'side'}>

                <ActivityPanel parent={data} />

            </Col>

        </Row>

    ), [data]);

}

export default CampPassesSingleView;
