import SettingsWeb from './Components/SettingsWeb';

export default function (app) {

    app.mods.Settings = {
        groups: [
            {
                key: "web",
                label: "Web Settings",
                caps: ["saveSettings"],
                panel: SettingsWeb
            },
        ]
    }

}