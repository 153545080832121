import { apiGet, apiPatch, baseApi, options, response } from "@dex/bubl-fetch";

const endpoint = 'PickupSession';

const PickupSessionApi: any = baseApi(endpoint);

PickupSessionApi.getAttendance = (sessionId: string, options: options = {}): response => {

    return apiGet({
        url: "PickupAttendance",
        params: {
            where: { sessionId: sessionId },
            limit: 10000,
            include: [
                {
                    relation: "owner",
                    scope: {
                        fields: ["id", "modelName", "firstName", "lastName", "fullName", "email", "phone"]
                    }
                },
            ]
        },
        ...options
    })

}

PickupSessionApi.markAttendance = (id: string, options: options = {}): response => {

    return apiPatch({
        url: "PickupAttendance/" + id + "/mark",
        ...options
    })

}

PickupSessionApi.updateAttending = (id: string, options: options = {}): response => {

    return apiPatch({
        url: "PickupAttendance/" + id + "/update",
        ...options
    })

}

export default PickupSessionApi;