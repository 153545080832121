import React, { useMemo, useState } from 'react';
import { Row, Col, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { FieldNumber } from '@dex/bubl-dash';
import ShopPayoutPrepareForm from './ShopPayoutPrepareForm';
import { Seperator } from '@dex/bubl-dash';
import { FieldSwitch } from '@dex/bubl-dash';
import { FieldHidden } from '@dex/bubl-dash';
import { DataTable } from '@dex/bubl-dash';

const ShopPayoutCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit, mode } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    const [list, setList] = useState<any>(null);

    const columns: any = [
        {
            label: "Order #",
            key: "this",
            format: "ModelLink",
        },
        {
            label: "Date Paid",
            key: "datePaid",
            format: "date",
        },
        {
            label: "Amount Received",
            key: "amountPaid",
            format: "number",
        },
        {
            label: "Margin Rate",
            key: "payoutRate",
            format: "number",
            suffix: "%",
        },
        {
            label: "Payout Amount",
            key: "payoutAmount",
        },
        {
            label: "Margin Amount",
            key: "payoutMargin",
        },
    ];

    return useMemo(() => (

        <Panel
            heading={list ? "Review Payout List" : "Prepare Payout List"}
        >

            {!list &&
                <>
                    <ShopPayoutPrepareForm
                        form={form}
                        onPrepare={setList}
                    />
                </>
            }

            {list &&

                <Form
                    form={form}
                    onSubmit={handleSubmit}
                    loading={process.loading}
                >

                    <FieldHidden
                        form={form}
                        name={"period"}
                        defaultValue={form.defaults.period}
                    />

                    {list.map((host, index) => (

                        <React.Fragment key={index}>

                            <Seperator
                                heading={host.title}
                                top={index ? "auto" : undefined}
                                bottom={"auto"}
                            />

                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24, md: 8 }}>

                                    <FieldNumber
                                        form={form}
                                        label={"Total Margin"}
                                        disabled={true}
                                        defaultValue={host.margin}
                                        prefix={"RM "}
                                    />

                                </Col>

                                <Col col={{ xs: 24, md: 8 }}>

                                    <FieldNumber
                                        form={form}
                                        label={"Total Payout"}
                                        disabled={true}
                                        defaultValue={host.payout}
                                        prefix={"RM "}
                                    />

                                </Col>

                                <Col col={{ xs: 24, md: 8 }}>

                                    <FieldSwitch
                                        form={form}
                                        label={"Create Payout Statement"}
                                        name={"hostIds[" + host.id + "]"}
                                        defaultValue={form.defaults?.hostIds[host.id]}
                                    />

                                </Col>

                            </Row>

                            <Space />

                            <DataTable
                                data={host.orders}
                                columns={columns}
                                loading={false}
                            />

                        </React.Fragment>

                    ))}

                    <Line />

                    <ButtonRow>
                        <Button
                            label={"Submit"}
                            loading={process.loading}
                        />
                        <Button
                            label={"Reset"}
                            type={"faded"}
                            onClick={form.reset}
                        />
                    </ButtonRow>

                </Form>

            }

        </Panel>

    ), [props, process, list]);

}

export default ShopPayoutCreateEditForm;
