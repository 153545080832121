import React from 'react';
import { titleCase, get } from "@dex/bubl-helpers";
import LeagueSeasonGameApi from './LeagueSeasonGameApi';

let settings;

export default settings = {
    key: 'league/games',
    zone: 'leagues',
    path: '/league/games',
    title: "League Games",
    endpoint: LeagueSeasonGameApi.endpoint,
    modelName: 'LeagueSeasonGame',
    idKey: 'id',
    primaryKey: 'title',
    actions: ['trash', 'clone'],
    indexCaps: ["viewLeagueSeasonGame"],
    viewCaps: ["viewLeagueSeasonGame"],
    createCaps: ["createLeagueSeasonGame"],
    updateCaps: ["updateLeagueSeasonGame"],
    indexTitle: (routeName, params, users) => {

        let title: string = settings.title;

        if (params && params.name) title = title + " / " + titleCase(params.name);

        return title;

    },
    indexTabs: (routeName, params, users) => {

        return [
            {
                label: "All",
                path: settings.path,
                caps: settings.indexCaps
            },
            {
                label: "Scheduled",
                path: settings.path + "/status/scheduled",
            },
            {
                label: "Playing",
                path: settings.path + "/status/playing",
            },
            {
                label: "Ended",
                path: settings.path + "/status/ended",
            },
        ]

    },
    indexButtons: (routeName, params, users) => {

        return [
            // {
            //     label: "Add New",
            //     path: settings.path + '/create',
            //     caps: settings.createCaps
            // }
        ]

    },
    indexTabCounts: (routeName, params, dontation) => {

        return settings.endpoint + "/getCounts";

    },
    indexFetch: LeagueSeasonGameApi.get,
    indexFetchParams: (routeName, params, users) => {

        const filters: any = { where: {}, include: ['host', 'season', 'division'], order: ['created.date DESC'], limit: 10, skip: 0 };

        if (params && params.status) filters.where.status = { inq: [params.status] };

        return filters;

    },
    indexQuickSearch: (routeName, params, users) => {

        const fields = ['title'];

        return fields;

    },
    indexSearchFields: (routeName, params, users) => {

        const fields = [
            {
                label: "Title",
                key: "title",
                type: "text",
            },
            {
                label: "Host",
                key: "hostId",
                type: "relationship",
                model: "Hosts",
            },
            {
                label: "Season",
                key: "seasonId",
                type: "relationship",
                model: "LeagueSeason",
            },
            {
                label: "Division",
                key: "divisionId",
                type: "relationship",
                model: "LeagueSeasonDivision",
            },
            {
                label: "Status",
                key: "status",
                type: "choice",
                options: [
                    { value: "scheduled", label: "Scheduled" },
                    { value: "playing", label: "Playing" },
                    { value: "ended", label: "Ended" },
                ],
            },
        ];

        return fields;

    },

    indexColumns: (routeName, params, users) => {

        const columns = [
            {
                label: "Title",
                key: "this",
                format: "ModelLink",
            },
            {
                label: "Description",
                key: "description",
            },
            {
                label: "Status",
                key: "status",
                format: "titleCase",
            },
            {
                label: "Date",
                key: "date.str",
                format: "dateDay",
            },
            {
                label: "Time",
                key: "date.str",
                format: "time",
            },
            {
                label: "Venue",
                key: "venue",
            },
            {
                label: "Host",
                key: "host",
                format: "ModelLink",
            },
            {
                label: "Season",
                key: "season",
                format: "ModelLink",
            },
            {
                label: "Division",
                key: "division",
                format: "ModelLink",
            },
            {
                label: "Created Date",
                key: "created",
                format: "OnAndBy",
                sort: "created.date",
                alignEnd: true
            },
            {
                label: "Actions",
                key: "actions",
                alignEnd: true,
                format: "actions",
                api: LeagueSeasonGameApi,
                settings: settings,
            }
        ];

        return columns;

    },
    singleFetch: LeagueSeasonGameApi.getOne,
    singleFetchParams: (routeName, params, users, fetch) => {

        const filters = { include: ['host', 'season', 'division', 'teams'] };

        return filters;

    },
    singleTitle: (routeName, params, users, fetch) => {

        let title: string = "Loading...";

        const complete = get(fetch, 'complete');
        const error = get(fetch, 'error');
        const data = get(fetch, 'data');

        if (error) title = "Error";
        else if (data) title = data[settings.primaryKey];
        else if (complete && !data) title = "Not Found";

        return title;

    },
    singleTabs: (routeName, params, users, fetch) => {

        const path = settings.path + "/" + params.id;

        return [
            {
                label: "View",
                path: path + "/view"
            },
            {
                label: "Edit",
                path: path + "/edit",
                caps: settings.updateCaps
            },
            {
                label: "Events",
                path: path + "/events",
                caps: settings.updateCaps
            },
        ]

    },
};