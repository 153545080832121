import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldDate from '@dex/bubl-dash/src/lib/components/Fields/FieldDate';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import FieldPhone from '@dex/bubl-dash/src/lib/components/Fields/FieldPhone'
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship';


const CampAthletesCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <Panel
            heading={"Camp Athlete Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldRelationship
                            form={form}
                            label={"User"}
                            name='userId'
                            required={true}
                            preload={true}
                            endpoint={'Users'}
                            defaultValue={form.defaults.userId}
                        />
                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldText
                            form={form}
                            label={"Name"}
                            name='name'
                            required={true}
                            defaultValue={form.defaults.name}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldText
                            form={form}
                            label={"Email"}
                            name='email'
                            required={false}
                            defaultValue={form.defaults.email}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldPhone
                            form={form}
                            label={"Phone"}
                            name={'phone'}
                            required={false}
                            defaultValue={form.defaults.phone}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldText
                            form={form}
                            label={"ID Number"}
                            name='idNumber'
                            required={false}
                            defaultValue={form.defaults.idNumber}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldDate
                            form={form}
                            label={"Date Of Birth"}
                            name={`dateOfBirth`}
                            required={true}
                            defaultValue={form.defaults.dateOfBirth}
                            date={true}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldRadio
                            form={form}
                            label={"Gender"}
                            name='gender'
                            required={true}
                            defaultValue={form.defaults.gender}
                            options={[
                                { value: "male", label: "Male" },
                                { value: "female", label: "Female" }
                            ]}
                        />

                    </Col>

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default CampAthletesCreateEditForm;
