import React from 'react';
import { titleCase, get } from "@dex/bubl-helpers";
import LeagueSeasonLeaderboardApi from './LeagueSeasonLeaderboardApi';

let settings;

export default settings = {
    key: 'league/Leaderboards',
    zone: 'leagues',
    path: '/league/Leaderboards',
    title: "League Leaderboards",
    endpoint: LeagueSeasonLeaderboardApi.endpoint,
    modelName: 'LeagueSeasonLeaderboard',
    idKey: 'id',
    primaryKey: 'name',
    actions: ['trash'],
    indexCaps: ["viewLeagueSeasonLeaderboard"],
    viewCaps: ["viewLeagueSeasonLeaderboard"],
    createCaps: ["createLeagueSeasonLeaderboard"],
    updateCaps: ["updateLeagueSeasonLeaderboard"],
    indexTitle: (routeName, params, users) => {

        let title: string = settings.title;

        if (params && params.name) title = title + " / " + titleCase(params.name);

        return title;

    },
    indexTabs: (routeName, params, users) => {

        return [
            {
                label: "All",
                path: settings.path,
                caps: settings.indexCaps
            },
            {
                label: "Add New",
                path: settings.path + "/create",
                caps: settings.createCaps
            },
        ]

    },
    indexButtons: (routeName, params, users) => {

        return [
            {
                label: "Add New",
                path: settings.path + '/create',
                caps: settings.createCaps
            }
        ]

    },
    indexTabCounts: (routeName, params, dontation) => {

        return settings.endpoint + "/getCounts";

    },
    indexFetch: LeagueSeasonLeaderboardApi.get,
    indexFetchParams: (routeName, params, users) => {

        const filters: any = { where: {}, include: ['host', 'season'], order: ['created.date DESC'], limit: 10, skip: 0 };

        if (params && params.status) filters.where.status = { inq: [params.status] };

        return filters;

    },
    indexQuickSearch: (routeName, params, users) => {

        const fields = ['name'];

        return fields;

    },
    indexSearchFields: (routeName, params, users) => {

        const fields = [
            {
                label: "Name",
                key: "name",
                type: "text",
            },
            {
                label: "Host",
                key: "hostId",
                type: "relationship",
                model: "Hosts",
            },
            {
                label: "Season",
                key: "seasonId",
                type: "relationship",
                model: "LeagueSeason",
            },
            // {
            //     label: "Type",
            //     key: "type",
            //     type: "choice",
            //     options: [
            //         { value: "season", label: "season" },
            //         { value: "group", label: "Group" }
            //     ],
            // },
            {
                label: "Stat",
                key: "stat",
                type: "text",
            },
        ];

        return fields;

    },

    indexColumns: (routeName, params, users) => {

        const columns = [
            {
                label: "Name",
                key: "this",
                format: "ModelLink",
            },
            {
                label: "Host",
                key: "host",
                format: "ModelLink",
            },
            {
                label: "Season",
                key: "season",
                format: "ModelLink",
            },
            {
                label: "Stat",
                key: "stat",
                format: "titleCase",
            },
            {
                label: "Created Date",
                key: "created",
                format: "OnAndBy",
                sort: "created.date",
                alignEnd: true
            },
            {
                label: "Actions",
                key: "actions",
                alignEnd: true,
                format: "actions",
                api: LeagueSeasonLeaderboardApi,
                settings: settings,
            }
        ];

        return columns;

    },
    singleFetch: LeagueSeasonLeaderboardApi.getOne,
    singleFetchParams: (routeName, params, users, fetch) => {

        const filters = { include: ['host', 'season', 'divisions'] };

        return filters;

    },
    singleTitle: (routeName, params, users, fetch) => {

        let title: string = "Loading...";

        const complete = get(fetch, 'complete');
        const error = get(fetch, 'error');
        const data = get(fetch, 'data');

        if (error) title = "Error";
        else if (data) title = data[settings.primaryKey];
        else if (complete && !data) title = "Not Found";

        if (data && data.season && data.season.title) title = title + ", " + data.season.title;

        return title;

    },
    singleButtons: (routeName, params, users, fetch) => {

        const data = get(fetch, 'data');

        if (data) {

            return [
                {
                    label: "Manage Season",
                    path: "/league/seasons/" + data.seasonId + "/view",
                }
            ]

        }

        return [];

    },
    singleTabs: (routeName, params, users, fetch) => {

        const path = settings.path + "/" + params.id;

        return [
            {
                label: "View",
                path: path + "/view"
            },
            {
                label: "Edit",
                path: path + "/edit",
                caps: settings.updateCaps
            },
        ]

    },
};